import { ApiDomainResponse, ApiDomainStatus } from "@incendium/api";
import { Alert, Box, Divider, Grid, Link, Typography } from "@mui/material";
import { landingDomainService } from "Apis";
import Loading from "Components/Loading/Loading";
import NameAndDescriptionDialog from "Components/NameAndDescriptionDialog/NameAndDescriptionDialog";
import { useSelectedProject } from "Hooks";
import { useConfirmation } from "Hooks/useConfirmation";
import { useSubdomains } from "Hooks/useSubdomains";
import {
  AddDomainGuideDrawer,
  SubdomainCard,
  SubdomainForm,
} from "features/subdomains";
import produce from "immer";
import { Suspense, useCallback, useState } from "react";
import { useDebounce } from "react-use";
import { sortByDate } from "Helpers/arrays";
import GlassCard from "Components/GlassCard/GlassCard";

function SubdomainsPage() {
  const [subdomain, setSubdomain] = useState<ApiDomainResponse>({});
  const [guideOpen, setGuideOpen] = useState(false);
  const { selectedProject } = useSelectedProject();
  const { subdomains, setSubdomains, refetch } = useSubdomains();
  const [open, setOpen] = useState(false);
  const handleDelete = useConfirmation();

  const onSubdomainSaved = useCallback(
    (item: ApiDomainResponse) => {
      setSubdomains(
        produce(subdomains, (draft) => {
          const idx = subdomains.findIndex((s) => s.id === item.id);
          if (idx >= 0) {
            draft[idx] = item;
          } else {
            draft.push(item);
          }
        })
      );
    },
    [subdomains, setSubdomains]
  );

  const updateSubdomain = useCallback(
    async (name: string, description: string) => {
      if (!subdomain?.id) {
        return;
      }
      const res = await landingDomainService.landingDomainServiceUpdateDomain({
        projectId: selectedProject?.id as number,
        domainId: subdomain.id,
        payload: {
          name,
          description,
          subdomain: subdomain.subdomain,
        },
      });
      onSubdomainSaved(res.domain!);
    },
    [subdomain, selectedProject, onSubdomainSaved]
  );

  const onEdit = useCallback((domain) => {
    setSubdomain(domain);
    setOpen(true);
  }, []);

  useDebounce(
    () => {
      if (
        !open &&
        subdomains.filter(
          (s) => s.status === ApiDomainStatus.DOMAIN_STATUS_READY
        ).length !== subdomains.length
      ) {
        refetch();
      }
    },
    5000,
    [subdomains, refetch, open]
  );

  const onDelete = useCallback(
    (domain: ApiDomainResponse) => {
      handleDelete({
        title: `Are you sure you want to delete this subdomain`,
        body: "Any landing pages that are using the subdomain will stop working and his action can not be undone",
        callback: async () => {
          await landingDomainService.landingDomainServiceDeleteDomain({
            projectId: selectedProject!.id as number,
            domainId: domain.id as number,
          });

          setSubdomains(
            produce(subdomains, (draft) => {
              const idx = subdomains.findIndex((s) => s.id === domain.id);
              if (idx >= 0) {
                draft[idx].status = ApiDomainStatus.DOMAIN_STATUS_DELETING;
              }
            })
          );
          return `${domain.name || ""} Moved to Deleting.`;
        },
      });
    },
    [selectedProject, subdomains, setSubdomains, handleDelete]
  );

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <GlassCard>
            <Typography variant="subtitle2" mb={2}>
              Enter your desired subdomain name below to generate custom CNAME
              records pointing to our servers.
            </Typography>
            <Suspense fallback={<Loading />}>
              <SubdomainForm
                project={selectedProject!}
                onSaved={onSubdomainSaved}
              />
            </Suspense>
          </GlassCard>
        </Grid>

        <Grid item xs={9}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <GlassCard>
            <Box mb={3}>
              <Typography variant="subtitle1">Subdomains</Typography>
              <Typography variant="body2" color={"secondary"}>
                Utilize the CNAME 'Key' and 'Value' to link your subdomain with
                your domain provider. <br /> Explore our guide for setup
                instructions with popular providers by clicking{" "}
                <strong>
                  <Link component={"button"} onClick={() => setGuideOpen(true)}>
                    here
                  </Link>
                </strong>
              </Typography>
            </Box>

            <Grid container spacing={3} mb={6}>
              {sortByDate([...subdomains], "createdAt", true).map((sd) => (
                <Grid item xs={4} key={sd.id}>
                  <SubdomainCard
                    subdomain={sd}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                </Grid>
              ))}
            </Grid>
          </GlassCard>
        </Grid>
      </Grid>
      <AddDomainGuideDrawer open={guideOpen} setOpen={setGuideOpen} />

      <NameAndDescriptionDialog
        title="Set Name and Description for subdomain"
        open={open}
        setOpen={setOpen}
        onSaved={updateSubdomain}
        name={subdomain.name}
        description={subdomain.description}
      >
        <Alert severity="info">
          You cannot edit the subdomain of a record. If you require a different
          subdomain, kindly create a new one.
        </Alert>
      </NameAndDescriptionDialog>
    </>
  );
}

export default SubdomainsPage;
