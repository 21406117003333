import {
  CircularProgress,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useState, useEffect, useCallback } from "react";
import produce from "immer";
import { ApiJobTagger, ApiJobTaggerUniqueField } from "@incendium/api";
import { StyledButton, TextFieldHeading } from "Components/TagPages";
import { enumToArray, formatEnumVal } from "Helpers/enumToText";
import { AnimatePresence, motion } from "framer-motion";
import { CheckCircleOutline } from "@mui/icons-material";
import { SidebarStyledDivider } from "Components/UI/Sidebar";
import { THighlight } from "@incendium/inc-ts-helpers";
import { usePrevious } from "react-use";
import { useIframeContext } from "features/smartIFrame/providers/OldIframeProvider";

type TState = { [key: string]: string };
type TField = { label: string; model: ApiJobTaggerUniqueField };

export const debuggerColours = [
  "f5ce9d",
  "e8f59d",
  "bbf59d",
  "9df5b8",
  "9df1f5",
  "9db4f5",
  "b39df5",
  "f59d9d",
];

function JobTaggerTab({
  tagger,
  onCancel,
  onSubmit,
}: {
  tagger: ApiJobTagger;
  onCancel: () => void;
  onSubmit: (input: ApiJobTagger) => void;
}) {
  const { activateIframe, deactivateIframe, selectedSelector } =
    useIframeContext();
  const [state, setState] = useState<TState>({
    [ApiJobTaggerUniqueField.TITLE]: "",
    [ApiJobTaggerUniqueField.DESCRIPTION]: "",
    [ApiJobTaggerUniqueField.ID]: "",
    [ApiJobTaggerUniqueField.CATEGORY]: "",
    [ApiJobTaggerUniqueField.SALARY_RANGE]: "",
    [ApiJobTaggerUniqueField.CONTRACT_TYPE]: "",
    [ApiJobTaggerUniqueField.LOCATION]: "",
  });
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [activeField, setActiveField] = useState(ApiJobTaggerUniqueField.TITLE);
  const prevActiveField = usePrevious(activeField);
  const [uniqueField, setUniqueField] = useState(ApiJobTaggerUniqueField.TITLE);

  const fields: TField[] = [
    { label: "Job Title", model: ApiJobTaggerUniqueField.TITLE },
    {
      label: "Job Description",
      model: ApiJobTaggerUniqueField.DESCRIPTION,
    },
    { label: "Job ID", model: ApiJobTaggerUniqueField.ID },
    { label: "Job Category", model: ApiJobTaggerUniqueField.CATEGORY },
    { label: "Job Salary range", model: ApiJobTaggerUniqueField.SALARY_RANGE },
    {
      label: "Job Contract Type",
      model: ApiJobTaggerUniqueField.CONTRACT_TYPE,
    },
    { label: "Job Location", model: ApiJobTaggerUniqueField.LOCATION },
  ];

  const handleChange =
    (field: TField) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setState(
        produce(state, (draft) => {
          draft[field.model] = e.target.value;
        })
      );
    };

  useEffect(() => {
    activateIframe(undefined, "");
    return () => deactivateIframe();
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule("hasJobSelector", (value) => {
      return state[value as ApiJobTaggerUniqueField] !== "";
    });
    return () => {
      ValidatorForm.removeValidationRule("hasJobSelector");
    };
  }, [state]);

  useEffect(() => {
    if (!selectedSelector || !activeField || prevActiveField !== activeField) {
      return;
    }
    setState((state) =>
      produce(state, (draft) => {
        draft[activeField] = selectedSelector;
      })
    );
  }, [selectedSelector, activeField, prevActiveField]);

  useEffect(() => {
    setState({
      [ApiJobTaggerUniqueField.TITLE]: tagger.titleSelector || "",
      [ApiJobTaggerUniqueField.DESCRIPTION]: tagger.descriptionSelector || "",
      [ApiJobTaggerUniqueField.ID]: tagger.idSelector || "",
      [ApiJobTaggerUniqueField.CATEGORY]: tagger.categorySelector || "",
      [ApiJobTaggerUniqueField.SALARY_RANGE]: tagger.salaryRangeSelector || "",
      [ApiJobTaggerUniqueField.CONTRACT_TYPE]:
        tagger.contractTypeSelector || "",
      [ApiJobTaggerUniqueField.LOCATION]: tagger.locationSelector || "",
    });
    setUniqueField(tagger.uniqueField || ApiJobTaggerUniqueField.TITLE);
  }, [tagger]);

  useEffect(() => {
    activateIframe(
      undefined,
      "",
      undefined,
      undefined,
      undefined,
      formatState(state)
    );
  }, [state]);

  const handleSubmit = useCallback(async () => {
    setSaving(true);
    const start = new Date().getTime();
    await onSubmit({
      id: tagger.id,
      titleSelector: state[ApiJobTaggerUniqueField.TITLE],
      descriptionSelector: state[ApiJobTaggerUniqueField.DESCRIPTION],
      idSelector: state[ApiJobTaggerUniqueField.ID],
      categorySelector: state[ApiJobTaggerUniqueField.CATEGORY],
      salaryRangeSelector: state[ApiJobTaggerUniqueField.SALARY_RANGE],
      contractTypeSelector: state[ApiJobTaggerUniqueField.CONTRACT_TYPE],
      locationSelector: state[ApiJobTaggerUniqueField.LOCATION],
      uniqueField: uniqueField,
    });
    let t = new Date().getTime() - start;
    if (t > 1000) {
      t = 0;
    }
    t = 1000 - t;
    setTimeout(() => {
      setSaved(true);
    }, t);
    setTimeout(() => {
      setSaved(false);
      setSaving(false);
    }, t + 1000);
  }, [tagger, onSubmit, state, uniqueField]);

  const formatState = (state: TState): THighlight[] => {
    let elems: THighlight[] = [];
    let i = 0;
    for (const key in state) {
      i++;
      if (state[key] === "") {
        continue;
      }
      elems.push({
        colour: `#${debuggerColours[i - 1]}`,
        el: state[key],
      });
    }
    return elems;
  };
  return (
    <>
      <AnimatePresence mode="wait">
        {saving ? (
          <Box
            component={motion.div}
            display="flex"
            key="saving-box"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: "80%" }}
            p={6}
          >
            <AnimatePresence mode="wait">
              {saved ? (
                <motion.div
                  key="cir-saved"
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <CheckCircleOutline color="primary" sx={{ fontSize: 42 }} />
                </motion.div>
              ) : (
                <motion.div
                  key="cir-saving"
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <CircularProgress size={40} />
                </motion.div>
              )}
              {saved ? (
                <motion.div
                  key="txt-saved"
                  initial={{ y: 50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Job Saved
                  </Typography>
                </motion.div>
              ) : (
                <motion.div
                  key="txt-saving"
                  initial={{ y: 50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Saving Job Info
                  </Typography>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>
        ) : (
          <Box
            component={motion.div}
            display="flex"
            key="form-box"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <ValidatorForm onSubmit={handleSubmit} style={{ maxWidth: "100%" }}>
              <RadioGroup
                value={activeField}
                onChange={(e) =>
                  setActiveField(e.target.value as ApiJobTaggerUniqueField)
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldHeading variant="body1" color="textPrimary">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        Unique Field
                        <ClickableTooltip
                          placement="left"
                          text="This is the field that shall be used to identify a unique job"
                          icon={<StyledHelp />}
                        />
                      </Box>
                    </TextFieldHeading>
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      validators={["hasJobSelector"]}
                      errorMessages={["Unique field selector is emtpy"]}
                      name="uniqueField"
                      value={uniqueField}
                      variant="outlined"
                      size="small"
                      select
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUniqueField(
                          e.target.value as ApiJobTaggerUniqueField
                        )
                      }
                    >
                      {enumToArray(ApiJobTaggerUniqueField).map((item) => (
                        <MenuItem key={item} value={item}>
                          {formatEnumVal(item)} ({state[item] || "not set"})
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                  <Grid item xs={12}>
                    <SidebarStyledDivider />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldHeading variant="body1" color="textPrimary">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                      >
                        Selector
                        <ClickableTooltip
                          placement="left"
                          text="This is the css selector that is used to scrape the jon from pages, you can either click into the input and select the correct element from the left, or manually type the correct selector"
                          icon={<StyledHelp />}
                        />
                      </Box>
                    </TextFieldHeading>
                  </Grid>

                  {fields.map((field, idx) => (
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={1}
                      key={field.model}
                      alignItems="center"
                    >
                      <Grid item xs={1}>
                        <Box
                          sx={{
                            bgcolor: `#${debuggerColours[idx]}`,
                            height: "38px",
                            width: "100%",
                            borderRadius: 10,
                          }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <TextValidator
                          value={state[field.model]}
                          variant="outlined"
                          name="selector"
                          label={field.label}
                          fullWidth
                          size="small"
                          autoFocus={activeField === field.model}
                          disabled={saving}
                          onChange={handleChange(field)}
                          onFocus={() => setActiveField(field.model)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Radio value={field.model} />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
              <Box mt={4} display="flex" justifyContent="space-between">
                <StyledButton
                  disableElevation
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  color="primary"
                >
                  {saving ? (
                    <CircularProgress size={"1rem"} color="inherit" />
                  ) : (
                    "save"
                  )}
                </StyledButton>

                <StyledButton
                  disableElevation
                  size="large"
                  fullWidth
                  variant="contained"
                  onClick={onCancel}
                  color="secondary"
                >
                  cancel
                </StyledButton>
              </Box>
            </ValidatorForm>
          </Box>
        )}
      </AnimatePresence>
    </>
  );
}

export default JobTaggerTab;
