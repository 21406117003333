import { ApiDimension } from "@incendium/api";
import { Autocomplete, TextField, Typography } from "@mui/material";
import AnimatedList from "Components/AnimatedList/AnimatedList";
import { dimensionToName } from "features/analytics";
import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";
import produce from "immer";
import { CallbackOrVal, IChart } from "Interfaces";
import { useCallback, useEffect, useMemo } from "react";

const AdditionalDimensionRow = ({
  row,
  onEdit,
  rowProps,
}: {
  row: ApiDimension;
  onEdit: (v: ApiDimension) => void;
  rowProps?: { dimensions: ApiDimension[] };
}) => {
  const onChange = useCallback(
    (e, v) => {
      onEdit(v);
    },
    [onEdit]
  );

  const getOptionLabel = useCallback(
    (o) => dimensionToName(o as ApiDimension),
    []
  );

  const renderOutput = useCallback(
    (params) => (
      <TextField
        {...params}
        size="small"
        label="Select Dimension"
        variant="outlined"
      />
    ),
    []
  );

  return (
    <Autocomplete
      id="x"
      size="small"
      onChange={onChange}
      fullWidth
      value={row || ""}
      options={rowProps?.dimensions || []}
      getOptionLabel={getOptionLabel}
      renderInput={renderOutput}
    />
  );
};

interface IChartBuilderAddittionalDimensionSelectorProps {
  dimensions: ApiDimension[];
  chart: IChart;
  setChart: (v: CallbackOrVal<IChart>) => void;
}

function ChartBuilderAddittionalDimensionSelector({
  dimensions,
  chart,
  setChart,
}: IChartBuilderAddittionalDimensionSelectorProps) {
  const addedAvailableDimensions = useMemo(() => {
    return (chart.displayOptions?.availableDimensions || []).filter(
      (d) => !chart.dimension?.includes(d)
    );
  }, [chart]);

  const onAdd = useCallback(() => {
    setChart((chart) =>
      produce(chart, (draft) => {
        if (!draft.displayOptions) {
          draft.displayOptions = {};
        }

        if (!draft.displayOptions.availableDimensions) {
          draft.displayOptions.availableDimensions = [];
        }

        if (draft.displayOptions.availableDimensions.length === 0) {
          draft.displayOptions.availableDimensions = chart.dimension
            ? [...(chart.dimension as ApiDimension[])]
            : [];
        }
        draft.displayOptions.availableDimensions.push(
          ApiDimension.DIMENSION_NOT_SET
        );
      })
    );
  }, [setChart]);

  const onRemove = useCallback(
    (idx: number, value: ApiDimension) => {
      setChart((chart) =>
        produce(chart, (draft) => {
          const index = (
            draft.displayOptions?.availableDimensions || []
          ).findIndex((d) => d === value);

          if (index >= 0) {
            draft.displayOptions?.availableDimensions?.splice(index, 1);
          }
        })
      );
    },
    [setChart]
  );

  const onEdit = useCallback(
    (idx: number) => (value: ApiDimension) => {
      setChart((chart) =>
        produce(chart, (draft) => {
          // the idx here is after removing set dimensions, need to calc real index
          const originalValue = addedAvailableDimensions[idx];
          const index = (
            draft.displayOptions!.availableDimensions || []
          ).findIndex((d) => d === originalValue);

          if (index >= 0) {
            draft.displayOptions!.availableDimensions![index] = value;
          }
        })
      );
    },
    [setChart, addedAvailableDimensions]
  );

  useEffect(() => {
    if (
      addedAvailableDimensions.length === 0 &&
      (chart.displayOptions?.availableDimensions || []).length > 0
    ) {
      setChart((chart) =>
        produce(chart, (draft) => {
          draft.displayOptions!.availableDimensions = [];
        })
      );
    }
  }, [
    addedAvailableDimensions,
    chart.displayOptions?.availableDimensions,
    setChart,
  ]);

  const disabledAddMore = useMemo(() => {
    return (chart.displayOptions?.availableDimensions || []).includes(
      ApiDimension.DIMENSION_NOT_SET
    );
  }, [chart.displayOptions?.availableDimensions]);

  const selecteableDimensions = useMemo(() => {
    return dimensions.filter(
      (d) => !(chart.displayOptions?.availableDimensions || []).includes(d)
    );
  }, [dimensions, chart.displayOptions?.availableDimensions]);

  return (
    <AccordianChartBuilderSidebarBlock
      title={`Optional Rows / Dimensions`}
      subTitle={
        <>
          <Typography variant="body2">
            Use this feature to allow chart viewers to dynamically switch
            between selected dimensions.
          </Typography>
          <Typography variant="body2" fontStyle={"italic"}>
            Note that enabling this option restricts the display to one
            dimension at a time and excludes these dimensions from data exports.
          </Typography>
        </>
      }
      defaultClosed={
        !addedAvailableDimensions || addedAvailableDimensions.length === 0
      }
    >
      <AnimatedList
        onAdd={onAdd}
        onRemove={onRemove}
        onEdit={onEdit}
        items={addedAvailableDimensions}
        RowComponent={AdditionalDimensionRow}
        btnText={"Add Optional Dimensions"}
        rowProps={{ dimensions: selecteableDimensions }}
        allowDeleteAll
        isDisabled={disabledAddMore}
        droppableId="additional-dimensions"
      />
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderAddittionalDimensionSelector;
