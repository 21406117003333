import { Stack, Box, Button, styled, Tooltip } from "@mui/material";
import { getAttributionTitle } from "Helpers/getAttributionTitle";
import produce from "immer";
import { IChart } from "Interfaces";
import { AttributionMetric, AttributionType } from "../types/types";
import { enumToArray } from "Helpers/enumToText";
import { useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import { ApiMetric, ApiChartYAxisKey } from "@incendium/api";
import { isEqual } from "lodash";
import { mergeMapsSortByKey } from "Helpers/maps";
import { uniqueArray } from "Helpers/arrays";
import {
  hasFirstClickAttribition,
  hasImpactedAttribition,
  hasLastClickAttribition,
  hasLastNonDirectClickAttribition,
  hasLinearClickAttribition,
  hasPositionAttribition,
  metricsFromChart,
} from "../utils/utils";
import {
  metricByAttribution,
  metricConfigByName,
} from "../services/metricsFormatter";
import AnalyticsAttributionIcon from "../components/AnalyticsAttributionIcon";

interface IAnalyticsAttributionSwitchProps {
  chart: IChart;
  setChart: React.Dispatch<React.SetStateAction<IChart>>;
  multiple?: boolean;
  onModelsChange?: (models: AttributionType[]) => void;
}

const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: 10,
  padding: "5px 6px",
  maxWidth: "100%",
  width: 365,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: "transparent",
  padding: `0`,
  borderRadius: 14,
  minWidth: 28,
  height: 24,

  "&.active": {
    background: theme.palette.primary.main,
    "& svg": {
      fill: theme.palette.common.white,
    },
  },

  "& svg": {
    height: "100%",
    fill: theme.palette.primary.main,
  },
  "&:hover svg": {
    transition: "all 1.2s",
    fill: theme.palette.common.white,
  },
}));

function AnalyticsAttributionSwitch({
  chart,
  setChart,
  multiple,
  onModelsChange,
}: IAnalyticsAttributionSwitchProps) {
  const [selectedModels, setSelectedModels] = useState<AttributionType[]>([]);

  const chartMetrics = useMemo(() => metricsFromChart(chart), [chart]);
  const prevChartMetrics = usePrevious(chartMetrics);

  useEffect(() => {
    if (isEqual(chartMetrics, prevChartMetrics)) {
      return;
    }

    let models = new Set<AttributionType>([]);

    if (hasFirstClickAttribition(chartMetrics)) {
      models.add(AttributionType.FIRST);
    }
    if (hasLastClickAttribition(chartMetrics)) {
      models.add(AttributionType.LAST);
    }
    if (hasLastNonDirectClickAttribition(chartMetrics)) {
      models.add(AttributionType.LAST_NON);
    }
    if (hasLinearClickAttribition(chartMetrics)) {
      models.add(AttributionType.LINEAR);
    }
    if (hasPositionAttribition(chartMetrics)) {
      models.add(AttributionType.POSITION);
    }
    if (hasImpactedAttribition(chartMetrics)) {
      models.add(AttributionType.IMPACTED);
    }

    setSelectedModels(multiple ? Array.from(models) : [Array.from(models)[0]]);
  }, [chartMetrics, prevChartMetrics, multiple]);

  useEffect(() => {
    if (onModelsChange) {
      onModelsChange(selectedModels);
      return;
    }

    setChart((chart) =>
      produce(chart, (draft) => {
        if (selectedModels.length === 0) {
          return;
        }

        draft.yAxisKeys.forEach((y, yidx) => {
          if (typeof y === "string") {
            return;
          }

          // Initialize Maps for non-attribution and attribution metrics
          let nonAttributionMetrics = new Map<number, string>();
          let attributionMetrics = new Map<number, any>();

          // Iterate through fields to separate attribution and non-attribution metrics
          let fields = y.fields || [];
          fields.forEach((f, index) => {
            const map = metricConfigByName(f as ApiMetric);
            if (!map || !map.attribtionType) {
              nonAttributionMetrics.set(index, f);
            } else {
              attributionMetrics.set(index, { ...map, key: f });
            }
          });

          // Create a map to store attribution metrics by index
          let attibutionFields = new Map<number, string[]>();
          // Iterate over sorted models to assign attribution metrics
          selectedModels.forEach((model) => {
            attributionMetrics.forEach((am, idx) => {
              const metricToPush = metricByAttribution(
                model,
                am?.attribtionMetric as AttributionMetric
              ) as ApiMetric;

              if (attibutionFields.has(idx)) {
                attibutionFields.get(idx)?.push(metricToPush);
              } else {
                attibutionFields.set(idx, [metricToPush]);
              }

              if (y.chart) {
                y.chart[metricToPush] = y.chart[am.key];
              }
            });
          });

          // Merge non-attribution and attribution metrics
          let mergedMetrics = mergeMapsSortByKey(
            nonAttributionMetrics,
            attibutionFields
          );

          // Flatten the values as above attribution field are string[]
          let uniqueFields = Array.from(mergedMetrics.values()).flat();

          // Update the fields of the current yAxisKey with unique values
          (draft.yAxisKeys[yidx] as ApiChartYAxisKey).fields =
            uniqueArray(uniqueFields);
        });
      })
    );
  }, [selectedModels, setChart, onModelsChange]);

  return (
    <StyledContainer>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        {enumToArray(AttributionType).map((m) => (
          <Tooltip title={getAttributionTitle(m)} key={m}>
            <StyledButton
              onClick={() => {
                if (multiple) {
                  setSelectedModels((models) =>
                    produce(models, (draft) => {
                      const idx = draft.indexOf(m);
                      if (idx < 0) {
                        draft.push(m);
                      } else if (draft.length > 1) {
                        draft.splice(idx, 1);
                      }
                    })
                  );
                  return;
                }
                setSelectedModels([m]);
              }}
              className={selectedModels.includes(m) ? "active" : ""}
            >
              <AnalyticsAttributionIcon sx={{ width: "100%" }} model={m} />
            </StyledButton>
          </Tooltip>
        ))}
      </Stack>
    </StyledContainer>
  );
}

export default AnalyticsAttributionSwitch;
