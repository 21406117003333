import { Editor } from "@tinymce/tinymce-react";

interface ISimpleTextEditorProps {
  content: string;
  setContent: (v: string) => void;
  height?: number;
}

function SimpleTextEditor({
  content,
  setContent,
  height,
}: ISimpleTextEditorProps) {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={content}
      onEditorChange={(v) => setContent(v)}
      init={{
        height: height || 500,
        menubar: false,
        plugins: [],
      }}
    />
  );
}

export default SimpleTextEditor;
