import {
  ApiClassificationRule,
  ApiClassificationRulePeriodType,
  ApiClassificationVariable,
  ApiCondition,
  ApiOperator,
} from "@incendium/api";
import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import ConversionsDropdown from "Components/Conversions/ConversionsDropdown";
import InputGroup, { InputGroupItem } from "Components/InputGroup/InputGroup";
import LeadStatusDropdown from "Components/LeadStatusRule/LeadStatusDropdown";
import LocationDropdown from "Components/Location/LocationDropdown";
import PageDataDropdown from "Components/PageData/PageDataDropwdown";
import { ProductsDropdown } from "features/analytics";
import { enumToArray, formatEnumVal } from "Helpers/enumToText";
import { useClassifications } from "Hooks";
import produce from "immer";
import { useEffect, useMemo, useState } from "react";

type TGroupedVariable = {
  key: "Visits" | "Pageviews" | "Lead" | "Sales" | "Trait";
  value: ApiClassificationVariable | "trait";
  label: string;
};

const groupedVariables: TGroupedVariable[] = [
  {
    key: "Pageviews",
    value: ApiClassificationVariable.URL,
    label: "Visited Page with X URL",
  },
  {
    key: "Pageviews",
    value: ApiClassificationVariable.PATH,
    label: "Visited Page with X Path",
  },
  {
    key: "Pageviews",
    value: ApiClassificationVariable.VISITED_LOCATION,
    label: "Visited X Location",
  },
  {
    key: "Pageviews",
    value: ApiClassificationVariable.LANDING_PAGE_LOCATION,
    label: "Landed on X Location",
  },
  {
    key: "Pageviews",
    value: ApiClassificationVariable.PAGEVIEWS,
    label: "No. of pageviews",
  },
  {
    key: "Pageviews",
    value: ApiClassificationVariable.AVERAGE_TIME_ON_PAGE,
    label: "Avg. time on page",
  },
  {
    key: "Pageviews",
    value: ApiClassificationVariable.AVERAGE_SCROLL_SPEED,
    label: "Avg. scroll speed on page",
  },
  {
    key: "Pageviews",
    value: ApiClassificationVariable.EFFECTIVE_PAGEVIEWS,
    label: "Lead has had X no. of effective pageviews",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.AVERAGE_PAGE_VIEWS,
    label: "Avg. pageviews per visit",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.NUMBER_OF_SESSIONS,
    label: "No. of visits",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.DAYS_SINCE_LAST_SESSION,
    label: "No. of days since last visit",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.CONVERSIONS,
    label: "No. of conversions",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.SESSION_CHANNEL,
    label: "Came from X Channel",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.SESSION_SOURCE,
    label: "Came from X Source",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.SESSION_MEDIUM,
    label: "Came from X Medium",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.SESSION_CAMPAIGN,
    label: "Came from X Campaign",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.SESSION_TERM,
    label: "Came to site with X search term",
  },
  {
    key: "Visits",
    value: ApiClassificationVariable.SESSION_BUCKET,
    label: "Came from X Bucket",
  },
  {
    key: "Lead",
    value: ApiClassificationVariable.BROWSER,
    label: "Lead's Browser",
  },
  {
    key: "Lead",
    value: ApiClassificationVariable.OS,
    label: "Lead's OS",
  },
  {
    key: "Lead",
    value: ApiClassificationVariable.IS_MOBILE,
    label: "Lead on mobile",
  },
  {
    key: "Lead",
    value: ApiClassificationVariable.LEAD_SCORE,
    label: "Lead's lead score",
  },
  {
    key: "Lead",
    value: ApiClassificationVariable.PERCENTAGE_LEAD_SCORE,
    label: "Lead's in top X percentage of leads by lead score",
  },
  {
    key: "Lead",
    value: ApiClassificationVariable.LEAD_STATUS,
    label: "Lead has status",
  },
  {
    key: "Sales",
    value: ApiClassificationVariable.REVENUE,
    label: "Lead's total revenue (in cents)",
  },
  {
    key: "Sales",
    value: ApiClassificationVariable.PURCHASES,
    label: "Lead's no. of purchases",
  },
  {
    key: "Sales",
    value: ApiClassificationVariable.DAYS_SINCE_LAST_PURCHASED,
    label: "No. of days since last purchase",
  },
  {
    key: "Sales",
    value: ApiClassificationVariable.PERCENTAGE_BY_REVENUE,
    label: "Lead in top X % of leads by revenue",
  },
  {
    key: "Sales",
    value: ApiClassificationVariable.PERCENTAGE_BY_PURCHASES,
    label: "Lead in top X % of leads by no. of sales",
  },
];

const stringOnlyVars = [
  ApiClassificationVariable.URL,
  ApiClassificationVariable.PATH,
  ApiClassificationVariable.BROWSER,
  ApiClassificationVariable.OS,
  ApiClassificationVariable.SESSION_CHANNEL,
  ApiClassificationVariable.SESSION_SOURCE,
  ApiClassificationVariable.SESSION_MEDIUM,
  ApiClassificationVariable.SESSION_CAMPAIGN,
  ApiClassificationVariable.SESSION_TERM,
  ApiClassificationVariable.SESSION_BUCKET,
];

const numberOnlyVars = [
  ApiClassificationVariable.PAGEVIEWS,
  ApiClassificationVariable.AVERAGE_TIME_ON_PAGE,
  ApiClassificationVariable.AVERAGE_SCROLL_SPEED,
  ApiClassificationVariable.EFFECTIVE_PAGEVIEWS,
  ApiClassificationVariable.AVERAGE_PAGE_VIEWS,
  ApiClassificationVariable.NUMBER_OF_SESSIONS,
  ApiClassificationVariable.DAYS_SINCE_LAST_SESSION,
  ApiClassificationVariable.CONVERSIONS,
  ApiClassificationVariable.LEAD_SCORE,
  ApiClassificationVariable.REVENUE,
  ApiClassificationVariable.PURCHASES,
  ApiClassificationVariable.DAYS_SINCE_LAST_PURCHASED,
];

const percentageOnlyVars = [
  ApiClassificationVariable.PERCENTAGE_LEAD_SCORE,
  ApiClassificationVariable.PERCENTAGE_BY_REVENUE,
  ApiClassificationVariable.PERCENTAGE_BY_PURCHASES,
];

const booleanVars = [ApiClassificationVariable.IS_MOBILE];

const hasTimerange = [
  ApiClassificationVariable.URL,
  ApiClassificationVariable.PATH,
  ApiClassificationVariable.PAGEVIEWS,
  ApiClassificationVariable.AVERAGE_SCROLL_SPEED,
  ApiClassificationVariable.AVERAGE_TIME_ON_PAGE,
  ApiClassificationVariable.AVERAGE_PAGE_VIEWS,
  ApiClassificationVariable.LANDING_PAGE_LOCATION,
  ApiClassificationVariable.NUMBER_OF_SESSIONS,
  ApiClassificationVariable.REVENUE,
  ApiClassificationVariable.PURCHASES,
  ApiClassificationVariable.CONVERSIONS,
  ApiClassificationVariable.VISITED_LOCATION,
  ApiClassificationVariable.LANDING_PAGE_LOCATION,
  ApiClassificationVariable.LEAD_SCORE,
  ApiClassificationVariable.EFFECTIVE_PAGEVIEWS,
  ApiClassificationVariable.SESSION_CHANNEL,
  ApiClassificationVariable.SESSION_SOURCE,
  ApiClassificationVariable.SESSION_MEDIUM,
  ApiClassificationVariable.SESSION_CAMPAIGN,
  ApiClassificationVariable.SESSION_TERM,
  ApiClassificationVariable.SESSION_BUCKET,
];
const hasLocation = [
  ApiClassificationVariable.VISITED_LOCATION,
  ApiClassificationVariable.LANDING_PAGE_LOCATION,
  ApiClassificationVariable.PAGEVIEWS,
  ApiClassificationVariable.AVERAGE_SCROLL_SPEED,
  ApiClassificationVariable.AVERAGE_TIME_ON_PAGE,
  ApiClassificationVariable.EFFECTIVE_PAGEVIEWS,
];
const hasPagedata = [
  ApiClassificationVariable.PAGEVIEWS,
  ApiClassificationVariable.AVERAGE_SCROLL_SPEED,
  ApiClassificationVariable.AVERAGE_TIME_ON_PAGE,
  ApiClassificationVariable.EFFECTIVE_PAGEVIEWS,
];
const hasLeadStatus = [ApiClassificationVariable.LEAD_STATUS];
const hasProduct = [
  ApiClassificationVariable.REVENUE,
  ApiClassificationVariable.PURCHASES,
  ApiClassificationVariable.DAYS_SINCE_LAST_PURCHASED,
];

const TraitCols = ({
  rule,
  setRule,
  condition,
  setCondition,
  size,
}: IAudienceRuleProps) => {
  const { classifications } = useClassifications();

  const operators = useMemo(
    () =>
      enumToArray(ApiOperator).filter((o) =>
        [
          ApiOperator.EQUAL,
          ApiOperator.NOT_EQUAL,
          ApiOperator.IS_IN,
          ApiOperator.NOT_IN,
        ].includes(o)
      ),
    []
  );

  const possibleValues = useMemo(() => {
    if (!condition?.classificationId) {
      return [];
    }
    const selectedClass = classifications.find(
      (c) => c.id === condition.classificationId
    );
    if (!selectedClass) {
      return [];
    }
    return selectedClass.classificationItems || [];
  }, [classifications, condition]);

  if (!condition) {
    return <></>;
  }
  return (
    <>
      <InputGroupItem>
        <TextField
          select
          size={size}
          fullWidth
          label="Triat"
          value={condition.classificationId}
          onChange={(e) =>
            setCondition &&
            setCondition(
              produce(condition, (draft) => {
                draft.classificationId = Number(e.target.value);
                draft.classificationItemId = undefined;
                draft.operator = undefined;
              })
            )
          }
        >
          {classifications.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </TextField>
      </InputGroupItem>
      <InputGroupItem>
        <TextField
          select
          size={size}
          fullWidth
          label="Condition"
          value={condition.operator}
          onChange={(e) =>
            setCondition &&
            setCondition(
              produce(condition, (draft) => {
                draft.operator = e.target.value as ApiOperator;
              })
            )
          }
        >
          {operators.map((o) => (
            <MenuItem key={o} value={o}>
              {formatEnumVal(o)}
            </MenuItem>
          ))}
        </TextField>
      </InputGroupItem>
      {possibleValues.length > 0 &&
        condition.operator &&
        [ApiOperator.EQUAL, ApiOperator.NOT_EQUAL].includes(
          condition.operator
        ) && (
          <InputGroupItem>
            <TextField
              select
              size={size}
              fullWidth
              label="Trait Value"
              value={condition.classificationItemId}
              onChange={(e) =>
                setCondition &&
                setCondition(
                  produce(condition, (draft) => {
                    draft.classificationItemId = Number(e.target.value);
                  })
                )
              }
            >
              {possibleValues.map((p) => (
                <MenuItem key={p.id} value={p.id}>
                  {p.value}
                </MenuItem>
              ))}
            </TextField>
          </InputGroupItem>
        )}
    </>
  );
};

const OperatorCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  const operators = useMemo(
    () =>
      enumToArray(ApiOperator).filter((o) =>
        rule.variable && stringOnlyVars.includes(rule.variable)
          ? [
              ApiOperator.EQUAL,
              ApiOperator.NOT_EQUAL,
              ApiOperator.CONTAINS,
              ApiOperator.NOT_CONTAINS,
              ApiOperator.ONE_OF,
            ].includes(o)
          : rule.variable &&
            (numberOnlyVars.includes(rule.variable) ||
              percentageOnlyVars.includes(rule.variable))
          ? [
              ApiOperator.EQUAL,
              ApiOperator.NOT_EQUAL,
              ApiOperator.IS_GREATER_THAN_OR_EQUAL,
              ApiOperator.IS_GREATER_THAN,
              ApiOperator.IS_LESS_THAN_OR_EQUAL,
              ApiOperator.IS_LESS_THAN,
            ].includes(o)
          : rule.variable === ApiClassificationVariable.VISITED_LOCATION ||
            rule.variable === ApiClassificationVariable.LANDING_PAGE_LOCATION
          ? [ApiOperator.EQUAL, ApiOperator.NOT_EQUAL].includes(o)
          : rule.variable === ApiClassificationVariable.LEAD_STATUS
          ? [
              ApiOperator.EQUAL,
              ApiOperator.NOT_EQUAL,
              ApiOperator.IS_IN,
              ApiOperator.NOT_IN,
            ].includes(o)
          : []
      ),
    [rule]
  );

  if (rule.variable && booleanVars.includes(rule.variable)) {
    return (
      <InputGroupItem>
        <TextField
          select
          size={size}
          fullWidth
          label="Condition"
          value={rule.operator}
          onChange={(e) =>
            setRule(
              produce(rule, (draft) => {
                draft.operator = e.target.value as ApiOperator;
              })
            )
          }
        >
          {[ApiOperator.EQUAL, ApiOperator.NOT_EQUAL].map((o) => (
            <MenuItem key={o} value={o}>
              Is {o === ApiOperator.EQUAL ? "True" : "False"}
            </MenuItem>
          ))}
        </TextField>
      </InputGroupItem>
    );
  }

  return (
    <InputGroupItem>
      <TextField
        select
        size={size}
        fullWidth
        label="Condition"
        value={rule.operator}
        onChange={(e) =>
          setRule(
            produce(rule, (draft) => {
              draft.operator = e.target.value as ApiOperator;
            })
          )
        }
      >
        {operators.map((o) => (
          <MenuItem key={o} value={o}>
            {formatEnumVal(o)}
          </MenuItem>
        ))}
      </TextField>
    </InputGroupItem>
  );
};

const ValueCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  if (
    !rule.variable ||
    booleanVars.includes(rule.variable) ||
    rule.variable === ApiClassificationVariable.VARIABLE_UNSPECIFIED ||
    !rule.operator ||
    rule.operator === ApiOperator.OPERATOR_UNASSIGNED
  ) {
    return <></>;
  }
  return (
    <InputGroupItem>
      <TextField
        size={size}
        fullWidth
        label="Value"
        inputProps={{
          min: percentageOnlyVars.includes(rule.variable) ? 0 : undefined,
          max: percentageOnlyVars.includes(rule.variable) ? 100 : undefined,
        }}
        type={
          [...numberOnlyVars, ...percentageOnlyVars].includes(rule.variable)
            ? "number"
            : "string"
        }
        value={rule.value || ""}
        onChange={(e) =>
          setRule(
            produce(rule, (draft) => {
              draft.value = e.target.value;
            })
          )
        }
      />
    </InputGroupItem>
  );
};

const TimerangeCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  return (
    <>
      <InputGroupItem sx={{ maxWidth: 90 }}>
        <TextField
          size={size}
          fullWidth
          label="Period"
          type={"number"}
          value={rule.period || ""}
          onChange={(e) =>
            setRule(
              produce(rule, (draft) => {
                draft.period = Number(e.target.value);
              })
            )
          }
        />
      </InputGroupItem>
      {!!rule.period && rule.period > 0 && (
        <InputGroupItem sx={{ maxWidth: 110 }}>
          <TextField
            size={size}
            fullWidth
            label="Range"
            select
            value={rule.periodType || ""}
            onChange={(e) =>
              setRule(
                produce(rule, (draft) => {
                  draft.periodType = e.target
                    .value as ApiClassificationRulePeriodType;
                })
              )
            }
          >
            {enumToArray(ApiClassificationRulePeriodType).map((t) => (
              <MenuItem value={t} key={t}>
                {formatEnumVal(t)}
              </MenuItem>
            ))}
          </TextField>
        </InputGroupItem>
      )}
    </>
  );
};

const LocationCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  return (
    <InputGroupItem>
      <LocationDropdown
        label="Location"
        size={size}
        variant="outlined"
        fullWidth
        value={rule.locationId}
        onChange={(locationId) => {
          setRule(
            produce(rule, (draft) => {
              draft.locationId = locationId;
            })
          );
        }}
      />
    </InputGroupItem>
  );
};

// todo: lead status id
const LeadStatusCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  return (
    <InputGroupItem>
      <LeadStatusDropdown
        label="Lead Status"
        size={size}
        variant="outlined"
        fullWidth
        value={rule.leadStatusId}
        onChange={(id) => {
          setRule(
            produce(rule, (draft) => {
              draft.leadStatusId = id;
            })
          );
        }}
      />
    </InputGroupItem>
  );
};
const PageDataCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  return (
    <InputGroupItem>
      <PageDataDropdown
        label="Page Data"
        size={size}
        variant="outlined"
        fullWidth
        value={{
          locationPageTaggerId: rule.locationPageTaggerId,
          pageTagValue: rule.pageTagValue,
        }}
        onChange={(v) => {
          setRule(
            produce(rule, (draft) => {
              draft.locationPageTaggerId = v?.locationPageTaggerId;
              draft.pageTagValue = v?.pageTagValue;
            })
          );
        }}
      />
    </InputGroupItem>
  );
};
const ConversionCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  return (
    <InputGroupItem>
      <ConversionsDropdown
        label="Conversions"
        size={size}
        variant="outlined"
        fullWidth
        value={rule.conversionId}
        onChange={(cID) => {
          setRule(
            produce(rule, (draft) => {
              draft.conversionId = cID;
            })
          );
        }}
      />
    </InputGroupItem>
  );
};
const ProductCol = ({ rule, setRule, size }: IAudienceRuleProps) => {
  return (
    <InputGroupItem>
      <ProductsDropdown
        label="Products"
        size={size}
        variant="outlined"
        fullWidth
        value={rule.productId}
        onChange={(id) => {
          setRule(
            produce(rule, (draft) => {
              draft.productId = id;
            })
          );
        }}
      />
    </InputGroupItem>
  );
};

interface IAudienceRuleProps {
  rule: ApiClassificationRule;
  setRule: (rule: ApiClassificationRule) => void;
  size?: "small" | "medium";
  allowTraits?: boolean;
  condition?: ApiCondition;
  setCondition?: (c: ApiCondition) => void;
}

function AudienceRule({
  rule,
  setRule,
  size,
  allowTraits,
  condition,
  setCondition,
}: IAudienceRuleProps) {
  const [useTrait, setUseTrait] = useState(false);
  const vars: TGroupedVariable[] = useMemo(
    () =>
      allowTraits
        ? [
            {
              key: "Trait",
              value: "trait",
              label: "Select Existing Trait",
            },
            ...groupedVariables,
          ]
        : groupedVariables,
    [allowTraits]
  );

  useEffect(() => {
    if (
      condition?.classificationId &&
      condition?.classificationId !== 0 &&
      (!rule ||
        !rule.variable ||
        rule.variable === ApiClassificationVariable.VARIABLE_UNSPECIFIED)
    ) {
      setUseTrait(true);
    }
  }, [condition, rule]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputGroup>
          <InputGroupItem>
            <Autocomplete
              fullWidth
              key={useTrait ? "ut" : "va"}
              groupBy={(option) => option.key}
              onChange={(e, v) => {
                setRule(
                  produce(rule, (draft) => {
                    draft.productId = undefined;
                    draft.locationId = undefined;
                    draft.leadStatusId = undefined;
                    draft.locationPageTaggerId = undefined;
                    draft.pageTagValue = undefined;
                    draft.variable = undefined;
                    draft.operator = ApiOperator.OPERATOR_UNASSIGNED;
                    draft.value = undefined;

                    if (!v) {
                      return;
                    }
                    if (v.value === "trait") {
                      draft.variable =
                        ApiClassificationVariable.VARIABLE_UNSPECIFIED;
                      setUseTrait(true);
                      return;
                    }

                    setUseTrait(false);
                    draft.variable = v.value;
                  })
                );
              }}
              value={
                useTrait ? vars[0] : vars.find((g) => g.value === rule.variable)
              }
              options={vars}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic"
                  label="Variable"
                  variant="outlined"
                  size={size}
                />
              )}
            />
          </InputGroupItem>
          {useTrait && (
            <TraitCols
              rule={rule}
              setRule={setRule}
              size={size}
              condition={condition}
              setCondition={setCondition}
            />
          )}

          {rule.variable &&
            rule.variable !==
              ApiClassificationVariable.VARIABLE_UNSPECIFIED && (
              <>
                <OperatorCol rule={rule} setRule={setRule} size={size} />
                {![
                  ApiClassificationVariable.VISITED_LOCATION,
                  ApiClassificationVariable.LANDING_PAGE_LOCATION,
                  ApiClassificationVariable.LEAD_STATUS,
                ].includes(rule.variable) && (
                  <ValueCol rule={rule} setRule={setRule} size={size} />
                )}
              </>
            )}
          {rule.variable && hasLeadStatus.includes(rule.variable) && (
            <LeadStatusCol rule={rule} setRule={setRule} size={size} />
          )}
        </InputGroup>
        {!!rule.variable &&
          rule.variable !== ApiClassificationVariable.VARIABLE_UNSPECIFIED &&
          (hasLocation.includes(rule.variable) ||
            hasPagedata.includes(rule.variable) ||
            hasLeadStatus.includes(rule.variable) ||
            hasProduct.includes(rule.variable) ||
            hasTimerange.includes(rule.variable)) && (
            <InputGroup mt={2}>
              {hasTimerange.includes(rule.variable) && (
                <TimerangeCol rule={rule} setRule={setRule} size={size} />
              )}
              {rule.variable === ApiClassificationVariable.CONVERSIONS && (
                <ConversionCol rule={rule} setRule={setRule} size={size} />
              )}
              {hasProduct.includes(rule.variable) && (
                <ProductCol rule={rule} setRule={setRule} size={size} />
              )}
              {hasLocation.includes(rule.variable) && (
                <LocationCol rule={rule} setRule={setRule} size={size} />
              )}
              {hasPagedata.includes(rule.variable) && (
                <PageDataCol rule={rule} setRule={setRule} size={size} />
              )}
            </InputGroup>
          )}
      </Grid>
    </Grid>
  );
}

export default AudienceRule;
