/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAPITokenValueType;
(function (ApiAPITokenValueType) {
    ApiAPITokenValueType["API_TOKEN_VALUE_TYPE_WOOCOMMERCE_ORDER_PATH"] = "APITokenValueTypeWoocommerceOrderPath";
    ApiAPITokenValueType["API_TOKEN_VALUE_TYPE_PROJECT_ID"] = "APITokenValueTypeProjectId";
    ApiAPITokenValueType["API_TOKEN_VALUE_TYPE_SAVE_CONTACT"] = "APITokenValueTypeSaveContact";
    ApiAPITokenValueType["API_TOKEN_VALUE_TYPE_PRODUCT_UNIQUE_FIELD"] = "APITokenValueTypeProductUniqueField";
})(ApiAPITokenValueType || (ApiAPITokenValueType = {}));
export function ApiAPITokenValueTypeFromJSON(json) {
    return ApiAPITokenValueTypeFromJSONTyped(json, false);
}
export function ApiAPITokenValueTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAPITokenValueTypeToJSON(value) {
    return value;
}
