import { ISyncConfigComponentProps } from "features/dataConnectors/types";
import SyncAccountConfigDrawer from "features/dataConnectors/components/SyncAccountConfigDrawer";
import { ApiService, ApiSyncType } from "@incendium/api";

function GoogleAds({
  oauthClient,
  syncs,
  open,
  setOpen,
}: ISyncConfigComponentProps) {
  return (
    <SyncAccountConfigDrawer
      oauthClient={oauthClient}
      syncs={syncs}
      provider="google"
      type={ApiSyncType.GOOGLE_ADS}
      open={open}
      setOpen={setOpen}
      service={ApiService.GOOGLE}
      features={["connect", "setup", "mapping", "google-match-type"]}
    />
  );
}

export default GoogleAds;
