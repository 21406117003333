import { ApiTabResponse, ApiProject, ApiReportResponse } from "@incendium/api";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import produce from "immer";
import { useRef, useState } from "react";
import { TabButton } from "Components/UI/TabButton";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import { ArrowDropDownCircleOutlined, Delete, Edit } from "@mui/icons-material";
import { saveReportTab } from "features/reports";

interface IReportTabTitleProps {
  active: boolean;
  index: number;
  project: ApiProject;
  report: ApiReportResponse;
  reportTab: ApiTabResponse;
  tabs: ApiTabResponse[];
  setTab: (value: React.SetStateAction<number | null>) => void;
  setTabs: React.Dispatch<React.SetStateAction<ApiTabResponse[]>>;
  editMode: boolean;
  onRemoveTab: (t: ApiTabResponse) => void;
}

function ReportTabTitle({
  active,
  index,
  project,
  report,
  reportTab,
  tabs,
  setTabs,
  setTab,
  editMode,
  onRemoveTab,
}: IReportTabTitleProps) {
  const [openTabNameDialog, setOpenTabNameDialog] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const onEditTabName = async (name: string) => {
    const res = await saveReportTab(
      project?.id as number,
      report.id as number,
      {
        name,
        order: reportTab.order,
      },
      reportTab.id as number
    );

    setTabs(
      produce(tabs, (draft) => {
        const idx = draft.findIndex((t) => t.id === reportTab.id);
        if (idx >= 0) {
          draft[idx] = res;
        }
      })
    );

    setOpenTabNameDialog(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  if (editMode) {
    return (
      <>
        <ButtonGroup
          disableElevation
          sx={{
            borderRadius: 0,
            border: 0,
            ":not(:last-of-type)": {
              marginRight: 0.75,
            },
            minWidth: active ? 200 : 40,
          }}
          color={active ? "primary" : "secondary"}
          variant="contained"
          ref={anchorRef}
        >
          <TabButton
            isActive={active}
            onClick={() => {
              setTab(index);
            }}
            fullWidth
          >
            <Tooltip title={reportTab.name || ""} placement="top">
              <span>{reportTab.name}</span>
            </Tooltip>
          </TabButton>
          <Button onClick={handleToggle}>
            <ArrowDropDownCircleOutlined
              fontSize="small"
              sx={{
                transition: "all 0.1s linear",
                transform: open ? "rotate(180deg)" : undefined,
              }}
            />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      onClick={(event) =>
                        !editMode || setOpenTabNameDialog(true)
                      }
                    >
                      <ListItemIcon>
                        <Edit fontSize="small" />
                      </ListItemIcon>
                      Edit Tab Name
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => !editMode || onRemoveTab(reportTab)}
                    >
                      <ListItemIcon>
                        <Delete fontSize="small" />
                      </ListItemIcon>
                      Delete Tab
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <CreateNameDialog
          open={openTabNameDialog}
          setOpen={setOpenTabNameDialog}
          title="Edit Tab Name"
          onSaved={onEditTabName}
          name={reportTab.name}
        />
      </>
    );
  }

  return (
    <>
      <TabButton
        sx={{ height: "auto" }}
        isActive={active}
        onClick={() => {
          setTab(index);
        }}
      >
        <Tooltip title={reportTab.name || ""} placement="top">
          <span>{reportTab.name}</span>
        </Tooltip>
      </TabButton>
    </>
  );
}

export default ReportTabTitle;
