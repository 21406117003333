import { Portal } from "@mui/material";
import { Stack } from "@mui/system";
import { PageAction, PageActionPreSlot } from "consts";
import { DatePickerDropdown } from "features/analytics";

const withDatepicker = (Comp: React.ComponentType<any>) => {
  return (props) => {
    return (
      <>
        <Portal container={() => document.getElementById(PageAction)}>
          <Stack direction={"row"} spacing={2} justifyContent="flex-end">
            <span id={PageActionPreSlot} />
            <DatePickerDropdown size="small" label="select date range" />
          </Stack>
        </Portal>
        <Comp {...(props as any)} />
      </>
    );
  };
};

export default withDatepicker;
