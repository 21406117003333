import { ApiMetric } from "@incendium/api";
import { metricMap } from "../services/metricsFormatter";
import { getCurrencySymbol } from "features/currency";
import { formatNumber } from "Helpers/numbers";
import { useSelectedProject } from "Hooks";
import { useCallback } from "react";

const useFormatMetric = () => {
  const { selectedProject } = useSelectedProject();

  return useCallback(
    (metric: ApiMetric, value?: number) => {
      const m =
        metricMap[((metric as string) || "").split("\\")[0] as ApiMetric];
      const currency = selectedProject?.currency
        ? getCurrencySymbol(selectedProject?.currency)
        : "$";

      return m && m.format
        ? m.format(value || 0, currency)
        : formatNumber(value || 0);
    },
    [selectedProject]
  );
};
export default useFormatMetric;
