import {
  Box,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  styled,
  Divider,
  Button,
  SxProps,
  Theme,
  ButtonGroup,
} from "@mui/material";
import IncreaseDecrease from "../components/IncreaseDecrease";
import { ICompare, IOnClickMap } from "../types/types";
import { useMemo } from "react";

interface IStyledTooltipProps extends TooltipProps {
  wide?: boolean;
}

const StyledTooltip = styled(
  ({ className, ...props }: IStyledTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "wide",
  }
)(({ theme, wide }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    textShadow: "none",
    border: `2px solid ${theme.palette.primary.main}`,
    padding: 0,
    textAlign: "center",
    maxWidth: wide ? 700 : undefined,
    minWidth: 150,
    "& .MuiButtonGroup-root": {
      boxShadow: "none",
      overflow: "hidden",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    "& .MuiButtonBase-root": {
      borderRadius: 0,
      borderColor: "white",
    },
    "& .MuiButton-icon svg": {
      fontSize: 16,
    },
    "& .MuiStack-root": {
      overflow: "hidden",
    },
  },
}));

interface ICellProps {
  dimensionName: string;
  valueFormatted: string;
  onClick?: IOnClickMap[];
  compare?: ICompare;
  headerName: string;
  isDimension?: boolean;
  sx?: SxProps<Theme>;
}

function AnalyticsCell({
  dimensionName,
  valueFormatted,
  onClick,
  compare,
  headerName,
  isDimension,
  sx,
}: ICellProps) {
  const fontSize = useMemo(() => {
    const l = valueFormatted.length;
    if (l > 30) {
      return "subtitle2";
    }
    return "subtitle1";
  }, [valueFormatted]);

  const renderedBody = useMemo(() => {
    return (
      <Stack
        sx={{
          alignItems: isDimension ? "flex-start" : "center",
          height: "100%",
        }}
      >
        <Stack direction={"row"} spacing={0.25}>
          <Typography variant="body1" sx={sx || { fontSize: "13px" }}>
            {valueFormatted}
          </Typography>

          {compare && compare.value ? (
            <IncreaseDecrease
              direction={compare?.direction}
              value={compare.value}
              formatedValue={compare.formatedValue}
              useThemeColours
              fontSize={14}
              show="icon"
            />
          ) : (
            ""
          )}
        </Stack>
        {compare && compare.value ? (
          <Box>
            <IncreaseDecrease
              direction={compare?.direction}
              value={compare.value}
              formatedValue={compare.formatedValue}
              useThemeColours
              show="value"
            />
          </Box>
        ) : (
          ""
        )}
      </Stack>
    );
  }, [compare, isDimension, valueFormatted, sx]);

  if (!isDimension) {
    return (
      <StyledTooltip
        placement={valueFormatted.length > 100 ? "top" : "right"}
        wide={valueFormatted.length > 100}
        arrow
        title={
          <Stack>
            <Stack px={2.5}>
              <Typography
                variant="subtitle2"
                color={"primary"}
                mt={0.5}
                gutterBottom
              >
                {dimensionName} - {headerName}
              </Typography>
              <Divider />
              <Stack alignItems={"center"} mt={1} mb={1.5}>
                <Typography
                  variant={fontSize}
                  fontWeight={500}
                  color={"inherit"}
                >
                  {valueFormatted}
                </Typography>
                {compare && compare.value ? (
                  <IncreaseDecrease
                    direction={compare?.direction}
                    value={compare.value}
                    formatedValue={compare.formatedValue}
                    useThemeColours
                    fontSize={14}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Stack>

            {(onClick || []).length > 0 && (
              <ButtonGroup orientation="vertical" variant="contained" fullWidth>
                {(onClick || []).map((oc) => (
                  <Button
                    key={oc.text}
                    size="extraSmall"
                    color="primary"
                    fullWidth
                    onClick={oc.fn}
                    startIcon={oc.icon}
                  >
                    {oc.text}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </Stack>
        }
      >
        {renderedBody}
      </StyledTooltip>
    );
  }
  return (
    <StyledTooltip
      placement={valueFormatted.length > 100 ? "top" : "right"}
      arrow
      title={
        <>
          <Stack px={2.5}>
            <Typography
              variant="subtitle2"
              color={"primary"}
              mt={0.5}
              gutterBottom
              sx={{ textWrap: "pretty" }}
            >
              {headerName}
            </Typography>
            <Divider />
            <Stack alignItems={"center"} mt={1} mb={1.5}>
              <Typography
                mt={1}
                variant={fontSize}
                color={"inherit"}
                fontWeight={500}
              >
                {valueFormatted}
              </Typography>
            </Stack>
          </Stack>
          {(onClick || []).length > 0 && (
            <ButtonGroup orientation="vertical" variant="contained" fullWidth>
              {(onClick || []).map((oc) => (
                <Button
                  key={oc.text}
                  size="extraSmall"
                  color="primary"
                  fullWidth
                  onClick={oc.fn}
                  startIcon={oc.icon}
                >
                  {oc.text}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </>
      }
    >
      {renderedBody}
    </StyledTooltip>
  );
}

export default AnalyticsCell;
