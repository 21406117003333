/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSimpleContractFromJSON, ApiSimpleContractToJSON, } from './ApiSimpleContract';
import { ApiSimpleProductFromJSON, ApiSimpleProductToJSON, } from './ApiSimpleProduct';
import { ApiSimpleProjectFromJSON, ApiSimpleProjectToJSON, } from './ApiSimpleProject';
import { ApiSubscriptionStatusFromJSON, ApiSubscriptionStatusToJSON, } from './ApiSubscriptionStatus';
import { ApiSubscriptionTierFromJSON, ApiSubscriptionTierToJSON, } from './ApiSubscriptionTier';
export function ApiSimpleSubscriptionFromJSON(json) {
    return ApiSimpleSubscriptionFromJSONTyped(json, false);
}
export function ApiSimpleSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'contract': !exists(json, 'contract') ? undefined : ApiSimpleContractFromJSON(json['contract']),
        'subscribed': !exists(json, 'subscribed') ? undefined : (json['subscribed'].map(ApiSimpleProjectFromJSON)),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'contractId': !exists(json, 'contractId') ? undefined : json['contractId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'status': !exists(json, 'status') ? undefined : ApiSubscriptionStatusFromJSON(json['status']),
        'tier': !exists(json, 'tier') ? undefined : ApiSubscriptionTierFromJSON(json['tier']),
        'product': !exists(json, 'product') ? undefined : ApiSimpleProductFromJSON(json['product']),
    };
}
export function ApiSimpleSubscriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'contract': ApiSimpleContractToJSON(value.contract),
        'subscribed': value.subscribed === undefined ? undefined : (value.subscribed.map(ApiSimpleProjectToJSON)),
        'price': value.price,
        'contractId': value.contractId,
        'productId': value.productId,
        'status': ApiSubscriptionStatusToJSON(value.status),
        'tier': ApiSubscriptionTierToJSON(value.tier),
        'product': ApiSimpleProductToJSON(value.product),
    };
}
