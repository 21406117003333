import { useTheme } from "@mui/material";
import { useMemo } from "react";

function AnalyticsCardCustomListLabel({ cx, cy, name, ...rest }: any) {
  const theme = useTheme();

  // split at every 13 characters wrapping words
  const labels = useMemo(() => {
    return name.split(" ").reduce((agg: string, p: string, i: number) => {
      const split = agg.split("\n");
      const combined = split[split.length - 1] + p;
      const b = i > 0 && combined.length > 13 ? " \n" : " ";
      return agg + b + p;
    }, "");
  }, [name]);

  // using a random number between 2 and 4 to make each lable a different angle, was just 2 before
  // const randPercentageX = Math.floor(Math.random() * (4 - 2 + 1)) + 2;
  // const randPercentageY = Math.floor(Math.random() * (4 - 2 + 1)) + 2;

  const endPointX = cx + rest.width / 2;
  const endPointY = cy + rest.height / 2;
  return (
    <>
      <text
        {...rest}
        pointerEvents={"none"}
        x={cx}
        fill={theme.palette.info.main}
        textAnchor="left"
      >
        {labels.split("\n").map((label: string, i: number) => (
          <tspan
            key={i}
            x={endPointX + 10}
            y={endPointY + i * 15 + 4}
            // y={rest.y}
            fontSize={12}
          >
            {label}
          </tspan>
        ))}
      </text>
      <line
        x1={cx}
        y1={cy}
        x2={endPointX}
        y2={endPointY}
        stroke={theme.palette.info.main}
      />
      <line
        x1={endPointX}
        x2={endPointX + 8}
        y1={endPointY}
        y2={endPointY}
        stroke={theme.palette.info.main}
      />
    </>
  );
}

export default AnalyticsCardCustomListLabel;
