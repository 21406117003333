import { ApiChartYAxisKey } from "@incendium/api";
import StyledTreeMap from "../components/StyledTreeMap";
import { ITreeMapChartData } from "../types/types";
import { groupBy } from "Helpers/arrays";
import { IChartData, TChartData } from "Interfaces";
import { useMemo } from "react";

interface IAnalyticsReportTreeMapProps {
  chartData: IChartData;
  dimensions: string[];
}

function AnalyticsReportTreeMap({
  chartData,
  dimensions,
}: IAnalyticsReportTreeMapProps) {
  const activeDimensions = useMemo(
    () => dimensions.filter((d) => d !== ""),
    [dimensions]
  );

  const formatedChartData: ITreeMapChartData[] = useMemo(() => {
    const { data, y } = chartData;
    if (!data) {
      return [];
    }

    const metric = y ? ((y[0] as ApiChartYAxisKey).fields || [])[0] || "" : "";

    let gg: Record<string, TChartData[]> = {};
    gg["all"] = data;
    const hasCategoryDimension = activeDimensions.length > 1;
    if (hasCategoryDimension) {
      gg = groupBy(data || [], "name");
    }

    const childName = hasCategoryDimension
      ? activeDimensions[activeDimensions.length - 1]
      : "name";

    const arr = Object.keys(gg).map((k) => {
      // important: this is just a tmp function till we have the nwe analytics, this splitcs the journeys by '->' then sums their count
      const tmp: { [key: string]: number } = {};
      const tmpFn = (data: TChartData) => {
        const parts = `${data[childName]}`.split(" -> ");
        parts.forEach((p) => {
          if (!tmp[p]) {
            tmp[p] = 0;
          }
          tmp[p] = tmp[p] + Number(data[metric]);
        });
      };

      gg[k].forEach(tmpFn);
      if (activeDimensions.length > 1) {
        return {
          name: k,
          children: Object.entries(tmp).map(([name, metric]) => ({
            name,
            metric,
          })),
        };
      }
      return Object.entries(tmp).map(([name, metric]) => ({
        name,
        children: [{ name, metric }],
      }));
    });

    return hasCategoryDimension ? arr : (arr[0] as any);
  }, [chartData, activeDimensions]);
  return (
    <StyledTreeMap
      chartData={formatedChartData}
      noGrouping={activeDimensions.length < 2}
    />
  );
}

export default AnalyticsReportTreeMap;
