import { IDebuggerMessage } from "@incendium/inc-ts-helpers";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

type ISmartIFrameContext = {
  sendMessage: (msg: IDebuggerMessage) => void;
  iframeRef: React.RefObject<any>;
  productSchemaFound: boolean;
  setProductSchemaFound: Dispatch<SetStateAction<boolean>>;
};

export const SmartIFrameContext = createContext<ISmartIFrameContext | null>(
  null
);
export const useSmartIframeContext = () =>
  useContext(SmartIFrameContext) as ISmartIFrameContext;

export const SmartIFrameProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const iframeRef = useRef<any>(null);
  const [productSchemaFound, setProductSchemaFound] = useState(false);

  const sendMessage = useCallback(
    (message) => {
      iframeRef.current.sendMessage(message);
    },
    [iframeRef]
  );

  return (
    <SmartIFrameContext.Provider
      value={{
        sendMessage,
        iframeRef,
        productSchemaFound,
        setProductSchemaFound,
      }}
    >
      {children}
    </SmartIFrameContext.Provider>
  );
};
