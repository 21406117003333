import { Stack, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { BarFunnel, ChartContainer } from "Components/BarFunnel";
import { percentageOf } from "Helpers/percentage";
import { useMemo } from "react";
import { useSelectedProject } from "Hooks";
import {
  incDec,
  IncreaseDecrease,
  useChartData,
  useFormatMetric,
} from "features/analytics";
import { ApiMetric } from "@incendium/api";
import { useFromToContext } from "Providers/FromToProvider";
import GlassCard from "Components/GlassCard/GlassCard";

function JobCompareStep() {
  const theme = useTheme();
  const { selectedProject } = useSelectedProject();
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const formatMetric = useFormatMetric();

  const chart = useMemo(
    () => ({
      name: "Job Conversion Funnel",
      dimension: [],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_JOB_PAGEVIEW_COUNT,
            ApiMetric.METRIC_JOB_IMPRESSION_COUNT,
            ApiMetric.METRIC_JOB_IMPRESSION_RATE,
            ApiMetric.METRIC_JOB_CLICK_THROUGH_COUNT,
            ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE,
            ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE,
            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE,
            ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE,
          ],
        },
      ],
      attributes: [],
    }),
    []
  );

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );
  const { chartData: compChartData } = useChartData(
    selectedProject,
    chart,
    chartComparisonOutput
  );

  const maxDomain = useMemo(() => {
    const { name: n, ...chartValues } = chartData?.data[0] || {};
    const { name: n2, ...compChartValues } = compChartData?.data[0] || {};
    const max = Math.max(
      ...[
        ...Object.values(chartValues || {}).map((v) => Number(v)),
        ...Object.values(compChartValues || {}).map((v) => Number(v)),
      ]
    );

    const top = percentageOf(max, 2) + max;
    return top;
  }, [chartData, compChartData]);
  const xWidth = 80; // todo, calculate

  return (
    <GlassCard>
      <Stack
        direction={"row"}
        mb={3}
        justifyContent="space-between"
        alignItems={"flex-start"}
      >
        <Box>
          <Typography variant={"h3"}>Conversion Funnel</Typography>
          <Typography variant={"body2"} color="secondary">
            Sessions compared with previous equal time period
          </Typography>
        </Box>
        <Box>
          <Typography>CTR: Click-through rate</Typography>
          <Typography>CR: Conversion rate</Typography>
          <Typography>ACR: Application completion rate</Typography>
        </Box>
        <Stack
          px={3}
          py={1}
          alignItems="center"
          sx={{ background: theme.palette.charts[2], borderRadius: "10px" }}
        >
          <Typography variant="body1" sx={{ color: "rgba(255,255,255,0.9)" }}>
            Visit to application rate:
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography variant="h3" sx={{ color: "white" }}>
              {formatMetric(
                ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE,
                Number(
                  chartData?.data[0][
                    ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE
                  ] || 0
                )
              )}
            </Typography>
            <Typography variant="h3" sx={{ color: "white" }}>
              <IncreaseDecrease
                direction={
                  incDec(
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE
                      ] || 0
                    ),
                    Number(
                      chartData?.data[0][
                        ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE
                      ] || 0
                    ),
                    true
                  ).direction
                }
                value={
                  incDec(
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE
                      ] || 0
                    ),
                    Number(
                      chartData?.data[0][
                        ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE
                      ] || 0
                    ),
                    true
                  ).value
                }
                size={"medium"}
              />
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <BarFunnel
        loading={loading}
        headers={["impressions", "pageviews", "apply click", "job application"]}
      >
        <>
          <ChartContainer
            fields={{
              chartData: [
                {
                  name: "impressions",
                  value:
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_JOB_IMPRESSION_COUNT
                      ]
                    ) || 0.1,
                },
                {
                  name: "pageviews",
                  value:
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_JOB_PAGEVIEW_COUNT
                      ]
                    ) || 0.1,
                },
                {
                  name: "click",
                  value:
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT
                      ]
                    ) || 0.1,
                },
                {
                  name: "application",
                  value:
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                      ]
                    ) || 0.1,
                },
              ],
              steps: [
                {
                  name: "CTR",
                  value:
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE
                      ]
                    ) || 0,
                },
                {
                  name: "CR",
                  value:
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE
                      ]
                    ) || 0,
                },
                {
                  name: "ACR",
                  value:
                    Number(
                      compChartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE
                      ]
                    ) || 0,
                },
              ],
            }}
            direction="down"
            maxDomain={maxDomain}
            xWidth={xWidth}
          />
          <ChartContainer
            fields={{
              chartData: [
                {
                  name: "impressions",
                  value:
                    Number(
                      chartData?.data[0][ApiMetric.METRIC_JOB_IMPRESSION_COUNT]
                    ) || 0.1,
                },
                {
                  name: "pageviews",
                  value:
                    Number(
                      chartData?.data[0][ApiMetric.METRIC_JOB_PAGEVIEW_COUNT]
                    ) || 0.1,
                },
                {
                  name: "click",
                  value:
                    Number(
                      chartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT
                      ]
                    ) || 0.1,
                },
                {
                  name: "application",
                  value:
                    Number(
                      chartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT
                      ]
                    ) || 0.1,
                },
              ],
              steps: [
                {
                  name: "CTR",
                  value:
                    Number(
                      chartData?.data[0][
                        ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE
                      ]
                    ) || 0,
                },
                {
                  name: "CR",
                  value:
                    Number(
                      chartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE
                      ]
                    ) || 0,
                },
                {
                  name: "ACR",
                  value:
                    Number(
                      chartData?.data[0][
                        ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE
                      ]
                    ) || 0,
                },
              ],
            }}
            direction="up"
            maxDomain={maxDomain}
            xWidth={xWidth}
          />
        </>
      </BarFunnel>
    </GlassCard>
  );
}

export default JobCompareStep;
