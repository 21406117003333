import {
  Box,
  CircularProgress,
  Portal,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import FullWidthHeightGlass from "Components/Page/FullWidthHeightGlass";
import { groupBy } from "Helpers/arrays";
import { percentageChange } from "Helpers/percentage";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useMemo, useState } from "react";
import StyledTreeMap from "features/analytics/components/StyledTreeMap";
import { ChartLegend, useChartData } from "features/analytics";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { ApiDimension, ApiMetric } from "@incendium/api";
import { PageActionPreSlot } from "consts";

function JobsPerformanceMapPage() {
  const { selectedProject } = useSelectedProject();
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const [useClickApply, setUseClickApply] = useState(false);

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [
        ApiDimension.DIMENSION_JOB_CATEGORY,
        ApiDimension.DIMENSION_JOB_TITLE,
      ],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
          ],
        },
      ],
      attributes: [],
      includeEmpty: false,
    }),
    []
  );

  const metric = useMemo(
    () =>
      useClickApply
        ? ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT
        : ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
    [useClickApply]
  );

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );
  const { chartData: compData } = useChartData(
    selectedProject,
    chart,
    chartComparisonOutput
  );

  const parsedChartData = useMemo(() => {
    if (!chartData?.data) {
      return [];
    }
    const gg = groupBy(chartData?.data || [], "name");

    return Object.keys(gg).map((k) => ({
      name: k,
      children: gg[k].map((d) => {
        let diff = 0;
        const found = (compData?.data || []).find(
          (f) =>
            f[ApiDimension.DIMENSION_JOB_TITLE] ===
            d[ApiDimension.DIMENSION_JOB_TITLE]
        );
        if (found) {
          diff = percentageChange(
            Number(found[metric]) || 0,
            Number(d[metric]) || 0
          );
        }
        return {
          name: `${d[ApiDimension.DIMENSION_JOB_TITLE]}`,
          metric: Number(d[metric]) || 0,
          diff,
        };
      }),
    }));
  }, [chartData, metric, compData]);

  if (loading) {
    return (
      <CenterPage>
        <CircularProgress />
        <Typography variant="h3" mt={2}>
          Fetching job data
        </Typography>
      </CenterPage>
    );
  }

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Stack>
          <Typography variant="h1" mb={2}>
            Jobs Performance Map
          </Typography>
          <StyledFlexBox sx={{ maxWidth: 360 }} compact>
            <Box display="flex" alignItems="center">
              <Typography color={!useClickApply ? "primary" : "secondary"}>
                Session Applications
              </Typography>
              <Switch
                checked={useClickApply}
                onChange={(e, checked) => setUseClickApply(checked)}
              />
              <Typography color={useClickApply ? "primary" : "secondary"}>
                Click to Apply
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={
                <Stack>
                  <Typography color={"inherit"} mb={1}>
                    <strong>Session Applications</strong> - this is the count of
                    applications (any job) made in the same session where they
                    saw this job
                  </Typography>
                  <Typography color={"inherit"}>
                    <strong>Click to Apply</strong> - This is the number of
                    times someone clicked the "click to apply" button on this
                    job page
                  </Typography>
                </Stack>
              }
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </Stack>
      </Portal>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <ChartLegend
          items={parsedChartData.map((ch) => ch.name)}
          flexAfter={3}
        />
      </Portal>
      <FullWidthHeightGlass height={"calc(100% - 4px)"}>
        <StyledTreeMap chartData={parsedChartData} />
      </FullWidthHeightGlass>
    </>
  );
}

export default JobsPerformanceMapPage;
