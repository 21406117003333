// todo: refactor into smartIframeProvider

import { THighlight } from "@incendium/inc-ts-helpers";
import { TIframeJob } from "features/smartIFrame/types";
import { createContext, useContext } from "react";

type IIFrameContext = {
  activateIframe: (
    targets?: string[],
    selector?: string,
    repeated?: boolean,
    targetText?: string,
    useTextMode?: boolean,
    highlightCollection?: THighlight[],
    job?: TIframeJob
  ) => void;
  deactivateIframe: () => void;
  selectedSelector: string;
  selectedText: string;
  productSchemaFound?: boolean;
};

export const IFrameContext = createContext<IIFrameContext | null>(null);
export const useIframeContext = () =>
  useContext(IFrameContext) as IIFrameContext;
