import { ApiACLRole } from "@incendium/api";
import { KeyboardArrowUp } from "@mui/icons-material";
import { Stack, Button } from "@mui/material";
import { styled } from "@mui/material";
import { setSubActive } from "Actions";
import {
  findChildToProps,
  useLeftmenuProviderContext,
} from "features/leftMenu";
import { motion } from "framer-motion";
import { useUser } from "Hooks/useUser";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import { subVariants } from "./LeftMenuItem";

interface ILeftMenuSubItemProps {
  text: string;
  children?: React.ReactNode;
  icon?: React.ReactElement;
  to?: string;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isOpen" && prop !== "isHidden",
})<{ isOpen: boolean; isHidden?: boolean }>(({ isOpen, isHidden }) => ({
  height: 44,
  width: "100%",
  borderRadius: 0,
  background: isOpen ? "#282746" : "#151530",
  justifyContent: "flex-start",
  paddingLeft: 36,
  fontSize: 12,
  textTransform: "capitalize",
  opacity: isHidden ? 0.5 : 1,
  "& .MuiButton-startIcon svg": {
    transform: "translateX(-2px)",
    height: 17,
  },
  "&:hover": {
    textDecoration: "underline",
    background: "#282746",
  },
  "& .MuiButton-endIcon": {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    marginRight: 0,
    transform: "none",
    "& svg": {
      transform: isOpen ? "none" : "rotate(180deg)",
      transition: "all 0.2s",
    },
  },
}));

function LeftMenuSubItem({
  text,
  children,
  icon: Icon,
  to,
}: ILeftMenuSubItemProps) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { subKey } = useSelector(leftMenuSelector);
  const history = useHistory();
  const { leftMenuLinkIsVisible } = useLeftmenuProviderContext();
  const { user } = useUser();

  useEffect(() => {
    if (subKey === text) {
      setIsOpen(true);
      return;
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 10);
  }, [text, subKey]);

  const isVisible = useMemo(() => {
    const childTos = findChildToProps(children);
    return childTos.filter((t) => leftMenuLinkIsVisible(t)).length > 0;
  }, [children, leftMenuLinkIsVisible]);

  const isSuper = useMemo(
    () =>
      (user.permissions || []).findIndex(
        (p) => p.role === ApiACLRole.SUPER_ADMIN
      ) >= 0,
    [user]
  );

  if (!isSuper && !isVisible) {
    return <></>;
  }

  return (
    <>
      <StyledButton
        startIcon={Icon}
        isHidden={!isVisible}
        endIcon={<KeyboardArrowUp />}
        onClick={() => {
          dispatch(isOpen ? setSubActive("") : setSubActive(text));
          if (to && !isOpen) {
            history.push(to);
          }
        }}
        isOpen={isOpen}
        sx={{
          paddingLeft: !Icon ? "56px" : undefined,
        }}
      >
        {text}
      </StyledButton>
      {children && (
        <Stack
          component={motion.div}
          variants={subVariants}
          sx={{
            background: "black",
            maxHeight: isOpen ? 500 : 0,
            overflow: "hidden",
            willChange: "max-height",
            transition: "max-height 0.3s linear",
            transitionDelay: "0.001s",
            "& > a": {
              fontSize: 11,
            },
          }}
        >
          {children}
        </Stack>
      )}
    </>
  );
}

export default LeftMenuSubItem;
