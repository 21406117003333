import {
  ApiConversion,
  ApiConversionType,
  ApiProject,
  ApiLocation,
  ApiConversionStateTrigger,
  ApiConversionState,
  ApiReservedConversion,
} from "@incendium/api";
import React, { useState, useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { TriggerList } from "Components/TriggerList";
import {
  Box,
  CircularProgress,
  Grid,
  Switch,
  Typography,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
  Stack,
  FormControlLabel,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useNotification } from "Hooks";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useClientIdFromUrl } from "Hooks";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import produce from "immer";
import { StyledButton, TextFieldHeading } from "Components/TagPages";
import { ReservedConversionFields } from "config";
import { formatEnumVal } from "Helpers/enumToText";
import { SidebarStyledDivider, SidebarSubtitle } from "Components/UI/Sidebar";
import { preventSubmitTooltipText } from "Components/FormHandlersForm";
import { useCallback } from "react";
import { deleteConversionState, saveConversionState } from "features/location";
import Meta_Ads_logo from "Assets/Images/ThirdParty/Meta_lockup_positive primary_RGB.svg";
import Image from "mui-image";
import { IConversionPayload } from "features/conversions/types";
import { useUpdateEffect } from "react-use";
import { useIframeContext } from "features/smartIFrame/providers/OldIframeProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

interface triggerWithTempId extends ApiConversionState {
  tempId?: number;
}

const ReservedTipText = (
  <>
    <p>
      <strong>Reserved conversions</strong> are a special type of conversion
      that are used in particular reports in an analyse
    </p>
    <p>
      <strong>Add To Basket</strong> - this is used in product reports and
      should be used for the action that add's a item to your cart for ecom
    </p>
  </>
);

const MicroMacroToolTipText = (
  <>
    <p>
      <strong>Conversions:</strong> core conversions which are critical
      performance measures, e.g. contact us form submission, or a sale on an
      ecom site.
      <br />
      Important: We only track 1 Conversion per pageview
    </p>
    <p>
      <strong>Interactions: </strong> smaller conversion metrices, e.g. tracking
      button clicks or downloads.
      <br />
      Important: We can track multiple Interactions per pageview but if the same
      conversion happens within a second of each other we count these together
    </p>
  </>
);

const TriggerToolTipText = (
  <>
    <p>The trigger is the user action which creates the conversion.</p>
    <span>This could be:</span>
    <p>
      <strong>click:</strong> when user clicks on something, e.g a button
    </p>
    <p>
      <strong>submit:</strong> when user submits a form
    </p>
    <p>
      <strong>manual:</strong>when you need to record a user conversion not
      visble on the page, you can manually assing a key
    </p>
    <p>
      <strong>time on page:</strong> in seconds
    </p>
  </>
);

const PushEventsTooltip = (
  <>
    <p>Select a provider to push this event to.</p>
    <p>
      If this conversion is a reserved conversion we shall match with standard
      event from the provider otherwise it will be a custom event.
    </p>
  </>
);

const TriggerCommaValue = ({
  loading,
  initialValue,
  handleChange,
}: {
  loading: boolean;
  initialValue: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, v: string) => void;
}) => {
  const [values, setValues] = useState(
    initialValue && initialValue !== "" ? initialValue.split(",") : [""]
  );

  useEffect(() => {
    const v = values
      .filter((a) => a !== "")
      .join(",")
      .trim();

    handleChange({} as any, v);
  }, [values, handleChange]);

  const onChange = useCallback(
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues((oldValues) =>
        produce(oldValues, (draft) => {
          draft[i] = e.target.value;
        })
      );
    },
    []
  );

  return (
    <>
      {values.map((value, i) => (
        <Box key={i} mb={1}>
          <TextValidator
            value={value}
            variant="outlined"
            size="small"
            name="selector"
            fullWidth
            disabled={loading}
            validators={["required"]}
            errorMessages={["selector is required"]}
            onChange={onChange(i)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {values.length > 1 && (
                    <IconButton
                      onClick={() =>
                        setValues(
                          produce(values, (draft) => {
                            draft.splice(i, 1);
                          })
                        )
                      }
                      edge="end"
                    >
                      <Remove />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => setValues([...values, ""])}
                    edge="end"
                  >
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ))}
    </>
  );
};

export const ConversionForm = ({
  project,
  location,
  conversion,
  onCancel,
  onAdd,
  onEdit,
  isMobile,
  initialConversionType,
}: {
  project: ApiProject;
  location: ApiLocation;
  conversion?: ApiConversion;
  onCancel: () => void;
  onEdit?: (conversion: ApiConversion, triggers: ApiConversionState[]) => void;
  onAdd?: (conversion: ApiConversion, triggers: ApiConversionState[]) => void;
  isMobile: boolean;
  initialConversionType: ApiConversionType;
}) => {
  const classes = useStyles();
  const clientId = useClientIdFromUrl();
  const [state, setState] = useState<IConversionPayload>(
    conversion
      ? {
          name: conversion.name as string,
          selector: "",
          targetText: "",
          reserved: conversion.reserved,
          value: null,
          pushToMeta: !!conversion.pushToMeta,
        }
      : {
          name: "",
          selector: "",
          targetText: "",
          reserved: ApiReservedConversion.NOT_RESERVED,
          value: null,
          pushToMeta: false,
        }
  );

  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [type, setType] = useState(
    conversion && conversion.type
      ? conversion.type
      : initialConversionType !== ApiConversionType.UNSPECIFIED
      ? initialConversionType
      : ApiConversionType.MICRO
  );
  const [useReserved, setUseReserved] = useState(
    conversion && conversion.reserved !== ApiReservedConversion.NOT_RESERVED
  );
  const [selectedTag, setSelectedTag] = useState<
    string | null | triggerWithTempId
  >(null);
  const [trigger, setTrigger] = useState(ApiConversionStateTrigger.CLICK);
  const [newTriggers, setNewTriggers] = useState<triggerWithTempId[]>([]);
  const [triggers, setTriggers] = useState<ApiConversionState[]>([]);
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [selectorRepeated, setSelectorRepeated] = useState(false);
  const [trackOffline, setTrackOffline] = useState<boolean | null>(false);
  const [preventSubmit, setPreventSubmit] = useState<boolean>(false);
  const [offlineFieldName, setOfflineFieldName] = useState<string | null>(null);
  const [useText, setUseText] = useState(false);
  const { activateIframe, deactivateIframe, selectedSelector, selectedText } =
    useIframeContext();

  useEffect(() => {
    if (conversion && conversion.states) {
      setTriggers(conversion.states);
    }
  }, [conversion]);

  useEffect(() => {
    if (selectedSelector && !useText) {
      setState((old) => ({
        ...old,
        selector: selectedSelector,
      }));
    }
    if (selectedText && useText) {
      setState((old) => ({
        ...old,
        targetText: selectedText,
      }));
    }
  }, [selectedSelector, selectedText, useText]);

  useEffect(() => {
    deactivateIframe();
    if (trigger === ApiConversionStateTrigger.CLICK) {
      activateIframe(
        ["a", "button"],
        state.selector,
        selectorRepeated,
        state.targetText
      );
    } else if (trigger === ApiConversionStateTrigger.SUBMIT) {
      activateIframe(["form"], state.selector);
    } else if (trigger === ApiConversionStateTrigger.TIME_ON_ELEMENT) {
      activateIframe(undefined, state.selector);
    }
    return () => deactivateIframe();
  }, [trigger, state.selector, isMobile, selectorRepeated, state.targetText]);

  useEffect(() => {
    if (!selectedTag) {
      setTrigger(ApiConversionStateTrigger.CLICK);
      setTrackOffline(false);
      setOfflineFieldName(null);
      setPreventSubmit(false);
      setState(
        produce(state, (draft) => {
          draft.selector = "";
          draft.targetText = "";
          draft.value = null;
        })
      );
      setSelectorRepeated(false);
      return;
    }

    setState((old) => ({ ...old, selector: "" }));
    if (typeof selectedTag !== "string") {
      selectedTag.trigger && setTrigger(selectedTag.trigger);
      selectedTag.offlineFieldName &&
        setOfflineFieldName(selectedTag.offlineFieldName);
      selectedTag.offline && setTrackOffline(selectedTag.offline);
      selectedTag.preventSubmit && setPreventSubmit(selectedTag.preventSubmit);
      selectedTag.repeatedSelector &&
        setSelectorRepeated(selectedTag.repeatedSelector);

      selectedTag.targetInnerText &&
        selectedTag.targetInnerText !== "" &&
        setUseText(true);

      setState(
        produce(state, (draft) => {
          draft.selector = selectedTag.target || "";
          draft.targetText = selectedTag.targetInnerText || "";
          draft.value = selectedTag.value || null;
        })
      );
    }

    return () => deactivateIframe();
  }, [selectedTag]);

  useEffect(() => {
    setIsReady(true);
  }, []);

  const handleTrackChange = () => setTrackOffline((state) => !state);

  const handleSelectChange = (event: any) => {
    const name = event.target.name as keyof typeof state;
    setState({
      ...state,
      [name]: event?.target?.value,
    });
  };

  const handleTriggerTypeChange = (event: SelectChangeEvent) => {
    setState((state) => ({ ...state, selector: "", value: null }));
    setTrigger(event.target.value as ApiConversionStateTrigger);
  };

  const handleSubmit = () => {
    setLoading(true);

    const newState: ApiConversion = {
      name: state.name,
      type,
      reserved: state.reserved as ApiReservedConversion,
      pushToMeta: state.pushToMeta,
    };

    const triggers = newTriggers.map((t) => {
      const { tempId, ...rest } = t;
      return rest as ApiConversionState;
    });

    if (conversion) {
      onEdit &&
        onEdit(
          {
            ...newState,
            id: conversion.id,
          },
          triggers
        );
    } else {
      onAdd && onAdd(newState, triggers);
    }
    setLoading(false);
  };

  const handleChange = useCallback(
    (key: keyof IConversionPayload) =>
      (e: React.ChangeEvent<HTMLInputElement>, value?: string | boolean) => {
        let val = typeof value !== "undefined" ? value : e.target.value;
        setState((old) =>
          produce(old, (draft) => {
            draft[key as string] = val;
          })
        );
      },
    []
  );

  const handleTypeChange = () =>
    setType((state) =>
      state === ApiConversionType.MACRO
        ? ApiConversionType.MICRO
        : ApiConversionType.MACRO
    );
  const handleOfflineFieldNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setOfflineFieldName(e.target.value);

  const handleTriggerDelete = async (conversionState?: triggerWithTempId) => {
    if (conversionState?.conversionId) {
      try {
        await deleteConversionState(
          Number(clientId),
          project.id!,
          location.id!,
          conversionState.conversionId!,
          conversionState.id!
        );
        setTriggers((prevStates) =>
          produce(prevStates, (draft) => {
            const idx = draft.findIndex((d) => d.id === conversionState.id);
            if (idx >= 0) {
              draft.splice(idx, 1);
            }
          })
        );
        showSuccessNotification("Trigger deleted");
      } catch (err) {
        showErrorNotification("Failed to deleted trigger");
      }
    } else {
      setNewTriggers((state) =>
        state.filter((trigger) => trigger.tempId !== conversionState?.tempId)
      );
      showSuccessNotification("Trigger deleted");
    }
    // setNewTriggers(state => state.filter(trigger => trigger.id !== conversion?.id))
  };

  const handleSubmitTrigger = async () => {
    const triggerState: triggerWithTempId = {
      tempId: Math.random() * 100,
      trigger: trigger,
      target: state.selector,
      value: state.value || undefined,
      offline: trackOffline || false,
      offlineFieldName: offlineFieldName || "",
      repeatedSelector: selectorRepeated || false,
      preventSubmit: preventSubmit,
      targetInnerText: state.targetText,
    };

    if (selectedTag && typeof selectedTag !== "string") {
      if (selectedTag.tempId) {
        setNewTriggers((state) =>
          state.map((trigger) =>
            trigger.tempId === selectedTag.tempId ? triggerState : trigger
          )
        );
        showSuccessNotification("Trigger updated");
      } else {
        // standard update
        const res = await saveConversionState(
          Number(clientId),
          project.id!,
          location.id!,
          selectedTag?.conversionId!,
          {
            id: selectedTag?.id as number,
            trigger: trigger,
            target: selectedSelector,
            value: triggerState.value,
            offlineFieldName: triggerState.offlineFieldName,
            offline: triggerState.offline,
            repeatedSelector: triggerState.repeatedSelector,
            preventSubmit: triggerState.preventSubmit,
            targetInnerText: triggerState.targetInnerText,
          }
        );
        showSuccessNotification("Trigger updated");
        setTriggers((state) =>
          state.map((trigger) =>
            trigger.id === selectedTag?.id
              ? {
                  ...res?.result,
                }
              : trigger
          )
        );
      }
    } else {
      setNewTriggers([...newTriggers, triggerState]);
      showSuccessNotification("Trigger added");
    }
    setSelectedTag(null);
  };

  const toggleTriggerText = useCallback((checked) => {
    setState((state) =>
      produce(state, (draft) => {
        if (!checked) {
          draft.targetText = "";
        } else {
          draft.selector = "";
        }
      })
    );

    setUseText(checked);
  }, []);

  useUpdateEffect(() => {
    if (
      useReserved &&
      state.reserved &&
      state.reserved !== ApiReservedConversion.NOT_RESERVED
    ) {
      setState((state) =>
        produce(state, (draft) => {
          draft.name = formatEnumVal(state.reserved as ApiReservedConversion);
        })
      );
    }
  }, [useReserved, state.reserved]);

  if (!isReady) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={"2rem"} />
      </Box>
    );
  }

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Box paddingBottom={3}>
        <StyledFlexBox>
          <Box display="flex" alignItems="center">
            <Switch
              disabled={loading}
              checked={useReserved}
              onChange={() => setUseReserved(!useReserved)}
            />
            <Typography>Use Reserved Conversion</Typography>
          </Box>
          <ClickableTooltip text={ReservedTipText} icon={<StyledHelp />} />
          {useReserved && (
            <Box my={1} sx={{ width: "100%" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  select reserved conversion
                </InputLabel>
                <Select
                  value={state.reserved || ""}
                  onChange={handleSelectChange}
                  label="select reserved conversion"
                  name="reserved"
                >
                  {ReservedConversionFields.map((item) => (
                    <MenuItem key={item} value={item}>
                      {formatEnumVal(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </StyledFlexBox>
      </Box>
      <Box paddingBottom={3}>
        <TextValidator
          label="name of conversion"
          value={state.name}
          disabled={loading}
          variant="outlined"
          name="name"
          fullWidth
          validators={["required"]}
          errorMessages={["name is required"]}
          onChange={handleChange("name")}
        />
      </Box>

      <StyledFlexBox>
        <Box display="flex" alignItems="center">
          <Typography>Interaction</Typography>
          <Switch
            disabled={loading}
            checked={type === ApiConversionType.MACRO}
            onChange={handleTypeChange}
          />
          <Typography>Conversion</Typography>
        </Box>
        <ClickableTooltip text={MicroMacroToolTipText} icon={<StyledHelp />} />
      </StyledFlexBox>
      <Box my={3}>
        <SidebarStyledDivider />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingRight={2}
        paddingBottom={2}
      >
        <SidebarSubtitle>Push Events</SidebarSubtitle>
        <ClickableTooltip text={PushEventsTooltip} icon={<StyledHelp />} />
      </Box>

      <Box>
        <Stack direction={"row"} justifyContent="space-between">
          <Image
            height={40}
            src={Meta_Ads_logo}
            duration={50}
            fit={"contain"}
            sx={{ objectPosition: "left" }}
          />

          <FormControlLabel
            style={{ minWidth: 136, marginRight: 0 }}
            control={
              <Switch
                checked={state.pushToMeta}
                onChange={handleChange("pushToMeta")}
              />
            }
            label="Meta Pixel"
          />
        </Stack>
      </Box>
      <Box my={3}>
        <SidebarStyledDivider />
      </Box>

      {!selectedTag ? (
        <>
          {/* trigger list */}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingRight={2}
            paddingBottom={2}
          >
            <SidebarSubtitle>triggers</SidebarSubtitle>
            <ClickableTooltip text={TriggerToolTipText} icon={<StyledHelp />} />
          </Box>
          <Box>
            <TriggerList
              title="add new trigger"
              tags={[...triggers, ...newTriggers]}
              onAdd={() => setSelectedTag("new")}
              onEdit={(conversion) => {
                setSelectedTag(conversion);
              }}
              onDelete={handleTriggerDelete}
            />
          </Box>
        </>
      ) : (
        // trigger form
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <h3>new trigger</h3>
            <ClickableTooltip text={TriggerToolTipText} icon={<StyledHelp />} />
          </Box>
          <Box paddingTop={3}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Select trigger type
              </InputLabel>
              <Select
                value={trigger}
                onChange={(e) => handleTriggerTypeChange(e)}
                label="Select trigger type"
                name="trigger"
              >
                <MenuItem value={ApiConversionStateTrigger.CLICK}>
                  click
                </MenuItem>
                <MenuItem value={ApiConversionStateTrigger.SUBMIT}>
                  submit
                </MenuItem>
                <MenuItem value={ApiConversionStateTrigger.MANUAL}>
                  manual
                </MenuItem>
                <MenuItem value={ApiConversionStateTrigger.PAGE_VIEW}>
                  time on page
                </MenuItem>
                <MenuItem value={ApiConversionStateTrigger.ONSITE_PAGEVIEWS}>
                  onsite session pageviews
                </MenuItem>
                <MenuItem value={ApiConversionStateTrigger.TIME_ON_ELEMENT}>
                  time element in view
                </MenuItem>
              </Select>
            </FormControl>
            {(trigger === ApiConversionStateTrigger.CLICK ||
              trigger === ApiConversionStateTrigger.TIME_ON_ELEMENT ||
              trigger === ApiConversionStateTrigger.SUBMIT) && (
              <>
                {trigger === ApiConversionStateTrigger.CLICK && (
                  <StyledFlexBox mt={2}>
                    <Box display="flex" alignItems="center">
                      <Switch
                        disabled={loading}
                        checked={useText}
                        onChange={(e, checked) => toggleTriggerText(checked)}
                      />
                      <Typography color={useText ? "primary" : "secondary"}>
                        Use Trigger Text
                      </Typography>
                    </Box>
                    <ClickableTooltip
                      text={
                        "This Conversion will fire when ever a element that contains the following text is clicked"
                      }
                      icon={<StyledHelp />}
                    />
                  </StyledFlexBox>
                )}

                {useText ? (
                  <>
                    <Box paddingTop={3}>
                      <TextFieldHeading variant="body1" color="textPrimary">
                        containing
                      </TextFieldHeading>
                    </Box>
                    <Box paddingBottom={1}>
                      <Typography variant="caption" color="textSecondary">
                        please type the text the element should contain
                      </Typography>
                    </Box>
                    <TextValidator
                      value={state.targetText}
                      variant="outlined"
                      name="targetText"
                      fullWidth
                      disabled={loading}
                      validators={["required"]}
                      errorMessages={["Target Text is required"]}
                      onChange={handleChange("targetText")}
                    />
                  </>
                ) : (
                  <>
                    <Box paddingTop={3}>
                      <TextFieldHeading variant="body1" color="textPrimary">
                        selector
                      </TextFieldHeading>
                    </Box>

                    <Box paddingBottom={1}>
                      <Typography variant="caption" color="textSecondary">
                        please select item on page preview
                      </Typography>
                    </Box>
                    <TextValidator
                      value={state.selector}
                      variant="outlined"
                      name="selector"
                      fullWidth
                      disabled={loading}
                      validators={["required"]}
                      errorMessages={["selector is required"]}
                      onChange={handleChange("selector")}
                    />
                    {trigger === ApiConversionStateTrigger.CLICK && (
                      <>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>unique</Grid>
                            <Grid item>
                              <Switch
                                disabled={loading}
                                checked={
                                  selectorRepeated ? selectorRepeated : false
                                }
                                onChange={() =>
                                  setSelectorRepeated(!selectorRepeated)
                                }
                              />
                            </Grid>
                            <Grid item>repeated</Grid>
                          </Grid>
                        </Typography>
                        {selectorRepeated && (
                          <Typography variant="body2">
                            You may need to refine the selector manually to
                            provide context for the repeated selector
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}

                {trigger !== ApiConversionStateTrigger.TIME_ON_ELEMENT && (
                  <StyledFlexBox marginTop={3} marginBottom={1}>
                    <Box display="flex" alignItems="center">
                      <Switch
                        disabled={loading}
                        checked={trackOffline ? trackOffline : false}
                        onChange={handleTrackChange}
                      />
                      <Typography>track offline</Typography>
                    </Box>
                    <ClickableTooltip
                      text="switching this on will enable you to track the conversion offline, by assigning a key to this trigger. You can later use the key to close the conversion and assign revenue."
                      icon={<StyledHelp />}
                    />
                  </StyledFlexBox>
                )}
                {trackOffline && (
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    mb={1}
                  >
                    <Box sx={{ width: "100%" }}>
                      <TextValidator
                        label="offline field name"
                        value={offlineFieldName || ""}
                        disabled={loading}
                        variant="outlined"
                        name="offlinefield"
                        fullWidth
                        validators={["required"]}
                        errorMessages={["name is required"]}
                        onChange={handleOfflineFieldNameChange}
                      />
                    </Box>

                    <ClickableTooltip
                      text="This is the name of the field that we will attach to your page or form, which gets submitted with the form data and contains the unique conversion key. you can use this later to add data such as closed offline sale revenue, to this specific conversion."
                      icon={<StyledHelp />}
                    />
                  </Stack>
                )}
                {trigger === ApiConversionStateTrigger.SUBMIT && (
                  <StyledFlexBox mt={2}>
                    <Box display="flex" alignItems="center">
                      <Switch
                        disabled={loading}
                        checked={preventSubmit}
                        onChange={(e, checked) => setPreventSubmit(checked)}
                      />
                      <Typography
                        color={preventSubmit ? "primary" : "secondary"}
                      >
                        prevent submit
                      </Typography>
                    </Box>
                    <ClickableTooltip
                      text={preventSubmitTooltipText}
                      icon={<StyledHelp />}
                    />
                  </StyledFlexBox>
                )}
              </>
            )}
            {trigger === ApiConversionStateTrigger.PAGE_VIEW && (
              <>
                <Box paddingTop={3}>
                  <TextFieldHeading variant="body1" color="textPrimary">
                    specify time
                  </TextFieldHeading>
                </Box>
                <Box paddingBottom={1}>
                  <Typography variant="caption" color="textSecondary">
                    in seconds -e.g type 120 for 120 seconds
                  </Typography>
                </Box>
                <TextValidator
                  value={state.value}
                  variant="outlined"
                  name="value"
                  type="number"
                  fullWidth
                  disabled={loading}
                  validators={["required"]}
                  errorMessages={["value is required"]}
                  onChange={handleChange("value")}
                />
              </>
            )}
            {trigger === ApiConversionStateTrigger.MANUAL && (
              <>
                <Box paddingTop={3}>
                  <TextFieldHeading variant="body1" color="textPrimary">
                    manual conversion key
                  </TextFieldHeading>
                </Box>
                <Box paddingBottom={1}>
                  <Typography variant="caption" color="textSecondary">
                    assign a key to this conversion
                  </Typography>
                </Box>
                <TextValidator
                  value={state.selector}
                  variant="outlined"
                  name="selector"
                  fullWidth
                  disabled={loading}
                  validators={["required"]}
                  errorMessages={["selector is required"]}
                  onChange={handleChange("selector")}
                />
              </>
            )}
            {trigger === ApiConversionStateTrigger.ONSITE_PAGEVIEWS && (
              <>
                <Box paddingTop={3}>
                  <TextFieldHeading variant="body1" color="textPrimary">
                    Onsite pages
                  </TextFieldHeading>
                </Box>
                <Box paddingBottom={1}>
                  <Typography variant="caption" color="textSecondary">
                    list of page urls visited in 1 sessions
                    <br />
                    note that a sessions is a leads pageviews that are within 30
                    ins apart from the last one
                    <br />
                    do not include domain here, to use contains wrap string in %
                    % e.g `%my-part-of-path%`
                  </Typography>
                </Box>
                <TriggerCommaValue
                  loading={loading}
                  initialValue={state.selector}
                  handleChange={handleChange("selector")}
                />
              </>
            )}
            {trigger === ApiConversionStateTrigger.TIME_ON_ELEMENT && (
              <>
                <Box paddingTop={3}>
                  <TextFieldHeading variant="body1" color="textPrimary">
                    Time Element in View
                  </TextFieldHeading>
                </Box>

                <Box paddingBottom={1}>
                  <Typography variant="caption" color="textSecondary">
                    in seconds -e.g type 120 for 120 seconds
                  </Typography>
                </Box>

                <TextValidator
                  value={state.value}
                  variant="outlined"
                  name="value"
                  type="number"
                  fullWidth
                  disabled={loading}
                  validators={["required"]}
                  errorMessages={["value is required"]}
                  onChange={handleChange("value")}
                />
              </>
            )}
          </Box>

          <Box marginY={3} display="flex" justifyContent="space-between">
            <StyledButton
              disableElevation
              size="large"
              variant="contained"
              type="button"
              onClick={handleSubmitTrigger}
              fullWidth
              color="primary"
              disabled={
                trigger === ApiConversionStateTrigger.PAGE_VIEW
                  ? !state.value
                  : trigger === ApiConversionStateTrigger.TIME_ON_ELEMENT
                  ? !state.selector || !state.value
                  : state.selector || state.targetText
                  ? false
                  : true
              }
            >
              {loading ? (
                <CircularProgress size={"1rem"} color="inherit" />
              ) : (
                "save trigger"
              )}
            </StyledButton>
            <StyledButton
              disableElevation
              size="large"
              fullWidth
              variant="contained"
              onClick={() => setSelectedTag(null)}
              color="secondary"
            >
              cancel
            </StyledButton>
          </Box>
        </Box>
      )}
      {!selectedTag && (
        <Box marginY={3} display="flex" justifyContent="space-between">
          <StyledButton
            disableElevation
            size="large"
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
          >
            {loading ? (
              <CircularProgress size={"1rem"} color="inherit" />
            ) : (
              "save"
            )}
          </StyledButton>
          <StyledButton
            disableElevation
            size="large"
            fullWidth
            variant="contained"
            onClick={onCancel}
            color="secondary"
          >
            cancel
          </StyledButton>
        </Box>
      )}
    </ValidatorForm>
  );
};
