import {
  ApiLocationPageTagger,
  ApiReservedLocationPageTagger,
} from "@incendium/api";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Switch,
  Typography,
} from "@mui/material";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { StyledButton, TextFieldHeading } from "Components/TagPages";
import React, { useEffect } from "react";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import produce from "immer";
import { enumToArray, formatEnumVal } from "Helpers/enumToText";
import { useIframeContext } from "features/smartIFrame/providers/OldIframeProvider";

export const TagForm = ({
  tag,
  onCancel,
  onAdd,
  onEdit,
}: {
  tag?: ApiLocationPageTagger;
  onCancel: () => void;
  onEdit?: (tag: { name: string; selector: string; regex?: string }) => void;
  onAdd?: (tag: { name: string; selector: string; regex?: string }) => void;
}) => {
  const [state, setState] = useState(
    tag
      ? {
          name: tag.name as string,
          regex: tag.regex,
          selector: tag.selector as string,
          crawlSearchParams: tag.crawlSearchParams,
        }
      : { name: "", selector: "", regex: "", crawlSearchParams: false }
  );
  const [showAdvancedSelector, setShowAdvancedSelected] = useState(
    tag?.regex ? true : false
  );

  const handleChange =
    (key: keyof typeof state) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setState((old) => ({ ...old, [key]: val }));
    };

  const [loading, setLoading] = useState(false);

  const { activateIframe, deactivateIframe, selectedSelector } =
    useIframeContext();

  useEffect(() => {
    activateIframe(undefined, state.selector);
  }, [state.selector]);

  useEffect(() => {
    if (selectedSelector) {
      setState((old) => ({ ...old, selector: selectedSelector }));
    }
  }, [selectedSelector]);

  useEffect(() => {
    activateIframe(undefined, state.selector);
    return () => {
      deactivateIframe();
    };
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    if (tag) {
      onEdit && onEdit(state);
    } else {
      onAdd && onAdd(state);
    }
    setLoading(false);
  };

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Box paddingBottom={2}>
        <Autocomplete
          freeSolo
          disabled={loading}
          autoSelect
          value={state.name}
          options={enumToArray(ApiReservedLocationPageTagger)
            .filter((v) => !v.includes("NOT_RESERVED"))
            .map(formatEnumVal)}
          onChange={(e, v) => {
            setState(
              produce(state, (draft) => {
                draft.name = v || "";
              })
            );
          }}
          renderInput={(params) => (
            <TextValidator
              {...params}
              label="name of tag"
              margin="normal"
              variant="outlined"
              name="name"
              value={state.name}
              helperText={
                enumToArray(ApiReservedLocationPageTagger)
                  .map(formatEnumVal)
                  .findIndex((v) => state.name === v) > 0
                  ? "(reserved page data)"
                  : null
              }
              validators={["required"]}
              errorMessages={["name is required"]}
            />
          )}
        />
      </Box>
      <TextFieldHeading variant="body1" color="textPrimary">
        selector
      </TextFieldHeading>
      <Box paddingBottom={1}>
        <Typography variant="caption" color="textSecondary">
          please select item on page preview
        </Typography>
      </Box>
      <TextValidator
        value={state.selector}
        variant="outlined"
        name="selector"
        fullWidth
        disabled={loading}
        validators={["required"]}
        errorMessages={["selector is required"]}
        onChange={handleChange("selector")}
      />
      <br />
      <StyledFlexBox>
        <Box display="flex" alignItems="center">
          <Switch
            disabled={loading}
            checked={state.crawlSearchParams || false}
            onChange={(e, checked) =>
              setState(
                produce(state, (draft) => {
                  draft.crawlSearchParams = checked;
                })
              )
            }
          />
          <Typography color={showAdvancedSelector ? "primary" : "secondary"}>
            use Search Params
          </Typography>
        </Box>
        <ClickableTooltip
          text={
            <>
              By default we only crawl the path part of each url this is to
              reduce load on your server whilst crawling. turning this option on
              will mean we also crawl for each unique search param recording on
              your site.
              <br /> - path = parts after you domain seperated by /
              <br />- search params are everything after the path, these start
              after ?, e.g ?id=q&size=sm
            </>
          }
          icon={<StyledHelp />}
        />
      </StyledFlexBox>
      <br />
      <StyledFlexBox>
        <Box display="flex" alignItems="center">
          <Switch
            disabled={loading}
            checked={showAdvancedSelector}
            onChange={(e, checked) => setShowAdvancedSelected(checked)}
          />
          <Typography color={showAdvancedSelector ? "primary" : "secondary"}>
            advanced selector
          </Typography>
        </Box>
        <ClickableTooltip
          text="You can use regex to apply further rules to the selection."
          icon={<StyledHelp />}
        />
      </StyledFlexBox>
      {showAdvancedSelector && (
        <>
          <br />
          <TextFieldHeading variant="body1" color="textPrimary">
            add regex
          </TextFieldHeading>
          <Box paddingBottom={1}>
            <Typography variant="caption" color="textSecondary">
              to target specific sections of an item
            </Typography>
          </Box>
          <TextValidator
            value={state.regex}
            variant="outlined"
            name="regex"
            fullWidth
            disabled={loading}
            validators={["required"]}
            errorMessages={["regex is required"]}
            onChange={handleChange("regex")}
          />
        </>
      )}

      <Box marginY={3} display="flex" justifyContent="space-between">
        <StyledButton
          disableElevation
          size="large"
          variant="contained"
          type="submit"
          fullWidth
          color="primary"
        >
          {loading ? (
            <CircularProgress size={"1rem"} color="inherit" />
          ) : (
            "save"
          )}
        </StyledButton>

        <StyledButton
          disableElevation
          size="large"
          fullWidth
          variant="contained"
          onClick={onCancel}
          color="secondary"
        >
          cancel
        </StyledButton>
      </Box>
    </ValidatorForm>
  );
};
