import DataTable from "Components/UI/DataTable";
import { styled, Box, Divider } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { IChartData, TChartData } from "Interfaces";
import {
  attributesFromChartDataRow,
  buildTicks,
  isDimension,
  isJourneyDimension,
  isTrendDimension,
} from "..//utils/utils";
import AnalyticsCell from "../components/AnalyticsCell";
import AnalyticsUserJourneyColumns from "../components/AnalyticsUserJourneyColumns";
import {
  dimensionToName,
  formatDimension,
} from "../services/dimensionsFormatter";
import { metricToName } from "../services/metricsFormatter";
import { ApiChartAttribute, ApiDimension, ApiMetric } from "@incendium/api";
import { percentageChange } from "Helpers/percentage";
import moment from "moment";
import useMetricExplorerNavigation from "../hooks/useMetricExplorerNavigation";
import useFormatMetric from "../hooks/useFormatMetric";
import { ICompare, IOnClickMap } from "../types/types";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: -14,
  right: -14,
  bottom: -47,
  top: 10,
}));

interface IAnalyticsDataTableProps {
  data: TChartData[];
  chartAttributes: ApiChartAttribute[];
  totals?: TChartData;
  comparisonTotals?: TChartData;
  onClick?: { [field: string]: (v: string, o?: string) => void };
  pageSize?: number;
  comparison?: boolean;
  comparisonChartData?: IChartData;
  colWidths?: { [d: string]: number };
  pinAt?: number;
  disableMetricClick?: boolean;
}

function AnalyticsDataTable({
  data,
  chartAttributes,
  totals,
  onClick,
  comparison,
  comparisonChartData,
  comparisonTotals,
  pageSize,
  colWidths,
  pinAt,
  disableMetricClick,
}: IAnalyticsDataTableProps) {
  const [columns, setColumns] = useState<(ColDef | ColGroupDef)[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<any[] | undefined>(undefined);
  const formatMetric = useFormatMetric();
  const onMetricClick = useMetricExplorerNavigation();

  const tickedComparisonChartData = useMemo(() => {
    return buildTicks(comparisonChartData?.data || []);
  }, [comparisonChartData]);

  // map of Comparison ticks to improve performance for find comparison
  const tickedComparisonMap = useMemo(() => {
    const map = new Map();
    tickedComparisonChartData.forEach((d) => {
      map.set(d.tick, d);
    });
    return map;
  }, [tickedComparisonChartData]);

  const cellRenderer = useCallback(
    (key, { valueFormatted, value, colDef, data, ...f }, colIsDimension) => {
      let compare: ICompare = {};
      let found: TChartData | undefined;
      if (comparison && tickedComparisonMap.size > 0) {
        if (data.id === "Total") {
          found = comparisonTotals;
        } else {
          found = tickedComparisonMap.get(data.tick);
        }

        if (!colIsDimension && found && typeof value === "number") {
          let change = percentageChange(
            Number(found[colDef.field]) || 0,
            value || 0
          );
          compare = {
            direction: change > 0 ? "up" : change < 0 ? "down" : "same",
            value: Number(change) || 0,
            formatedValue: formatMetric(
              key as ApiMetric,
              Number(found[colDef.field]) || 0
            ),
          };
        }
      }

      const fn: IOnClickMap[] | undefined =
        onClick && onClick[key]
          ? [
              {
                text: "Click To Explore",
                fn: () => onClick[key](value),
              },
            ]
          : [];
      if (!colIsDimension && !disableMetricClick) {
        fn.push({
          text: "View in Explorer",
          fn: () =>
            onMetricClick(key, [
              ...chartAttributes,
              ...attributesFromChartDataRow(data),
            ]),
        });
      }

      return colIsDimension && isJourneyDimension(key as ApiDimension) ? (
        <AnalyticsUserJourneyColumns
          formatedValue={valueFormatted}
          onClick={onClick ? () => onClick[key](value) : undefined}
        />
      ) : (
        <AnalyticsCell
          dimensionName={data.name}
          valueFormatted={valueFormatted}
          onClick={fn}
          compare={compare}
          headerName={colDef.headerName}
          isDimension={colIsDimension}
        />
      );
    },
    [
      onClick,
      formatMetric,
      onMetricClick,
      disableMetricClick,
      chartAttributes,
      tickedComparisonMap,
      comparisonTotals,
      comparison,
    ]
  );

  const tickedData = useMemo(() => buildTicks(data), [data]);

  useEffect(() => {
    if (!tickedData || !tickedData[0]) {
      return;
    }
    const cols: (ColDef | ColGroupDef)[] = Object.keys(tickedData[0])
      .filter((k) => !["name", "tick"].includes(k))
      .map((d, i) => {
        const colIsDimension = isDimension(d);
        let headerName = colIsDimension
          ? dimensionToName(d as ApiDimension)
          : metricToName(d as ApiMetric);

        const c: ColDef = {
          headerName,
          field: d,
          pinned: pinAt
            ? i < pinAt
              ? "left"
              : undefined
            : colIsDimension
            ? "left"
            : undefined,
          minWidth:
            colWidths && colWidths[`${d}`]
              ? colWidths[`${d}`]
              : colIsDimension
              ? isJourneyDimension(d as ApiDimension)
                ? 500
                : 200
              : 130,
          width: colWidths && colWidths[`${d}`] ? colWidths[`${d}`] : undefined,
          valueFormatter: ({ value }) => {
            return colIsDimension
              ? formatDimension(d as ApiDimension, value)
              : value === "..."
              ? "..."
              : formatMetric(d as ApiMetric, value);
          },
          cellRenderer: (params) => cellRenderer(d, params, colIsDimension),
        };

        if (isTrendDimension(d as ApiDimension)) {
          c.comparator = (date1: string, date2: string) => {
            return moment(date1).diff(moment(date2));
          };
        }

        return c;
      });

    const rows = (tickedData || []).map((v, i) => {
      return {
        id: i,
        ...v,
      };
    });

    setColumns(cols);
    setRows(rows);
    if (totals) {
      setTotalRow([totals]);
    }
  }, [
    tickedData,
    totals,
    comparison,
    comparisonChartData,
    cellRenderer,
    colWidths,
    pinAt,
    formatMetric,
    comparisonTotals,
  ]);

  return (
    <StyledWrapper>
      <Divider />
      <DataTable
        colDefs={columns}
        rows={rows}
        totalRow={totalRow}
        pageSize={pageSize}
      />
    </StyledWrapper>
  );
}

export default AnalyticsDataTable;
