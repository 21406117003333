import { ApiChartAttribute, ApiMetric } from "@incendium/api";
import { Box, DrawerProps, Typography } from "@mui/material";
import StyledDrawer, { StyledDrawerTitle } from "Components/UI/StyledDrawer";
import MetricSelector from "features/analytics/components/MetricSelector";
import {
  AccordianChartBuilderSidebarBlock,
  ChartBuilderAttributes,
} from "features/chartLibrary";
import { CallbackOrVal } from "Interfaces";
import { useEffect, useState } from "react";

interface IMetricExplorerDrawerProps extends DrawerProps {
  metric: ApiMetric | null;
  setMetric: (v: CallbackOrVal<ApiMetric | null>) => void;
  attributes: ApiChartAttribute[];
  setAttributes: (v: CallbackOrVal<ApiChartAttribute[]>) => void;
}

function MetricExplorerDrawer({
  open,
  onClose,
  metric,
  setMetric,
  attributes,
  setAttributes,
}: IMetricExplorerDrawerProps) {
  const [internalMetric, setInternalMetric] = useState(metric);
  const [internalAttributes, setInternalAttributes] = useState(attributes);

  useEffect(() => {
    if (!open) {
      setMetric(internalMetric);
      setAttributes(internalAttributes);
    }
  }, [open, internalMetric, internalAttributes, setMetric, setAttributes]);

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1"> Explorer Configuration</Typography>
        <Typography variant="body2">Adjust Settings to deep dive.</Typography>
      </StyledDrawerTitle>

      <AccordianChartBuilderSidebarBlock
        title={" Which Metric are you exploring?"}
      >
        <Box mb={1}>
          <MetricSelector
            metric={metric}
            setMetric={setInternalMetric}
            fullWidth
          />
        </Box>
      </AccordianChartBuilderSidebarBlock>

      <ChartBuilderAttributes
        title="Explorer Filters"
        subTitle="Select Filters to drill down further into your selected metric"
        attributes={internalAttributes || []}
        setAttributes={(input) => {
          setInternalAttributes((input || []) as ApiChartAttribute[]);
        }}
      />
    </StyledDrawer>
  );
}

export default MetricExplorerDrawer;
