import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  AttributionHeader,
  // allAttributionMetrics,
  metricsByAttributionType,
  table5Rows,
} from "features/analytics";
import { AttributionType } from "features/analytics/types/types";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";
import { useMemo } from "react";

function PaidCampaignInsights({ paidChannelAttributes }) {
  const attrMetrics = useMemo(() => {
    return metricsByAttributionType(AttributionType.LAST);
  }, []);

  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={12}>
        <AttributionHeader
          dimension={ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN}
          title={"Paid Campaigns"}
          attributes={paidChannelAttributes}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "",
            dimension: [ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: paidChannelAttributes,
            comparison: true,
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ...attrMetrics,
                  ApiMetric.METRIC_CPA,
                  ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
                  ApiMetric.METRIC_CAC,
                  ApiMetric.METRIC_ECR,
                  ApiMetric.METRIC_CPNV,
                ],
              },
            ],
            displayOptions: {
              noTitle: true,
              rowsPerPage: 5,
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "",
            dimension: [
              ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
              ApiDimension.DIMENSION_DOMAIN,
              ApiDimension.DIMENSION_LANDING_PAGE_PATH,
            ],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: paidChannelAttributes,
            comparison: true,
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ...attrMetrics,
                  ApiMetric.METRIC_CPA,
                  ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
                  ApiMetric.METRIC_CAC,
                  ApiMetric.METRIC_ECR,
                  ApiMetric.METRIC_CPNV,
                ],
              },
            ],
            displayOptions: {
              noTitle: true,
              rowsPerPage: 5,
              noFilters: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default withPaidChannelAttributes(PaidCampaignInsights);
