import { Box, Grid, Stack } from "@mui/material";
import { LeadTimelineList } from "features/leadAnalytics";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { AnalyticsStatCard, useFormatMetric } from "features/analytics";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { StatCardTypography } from "Components/UI/StatCard";
import { useFromToContext } from "Providers/FromToProvider";
import { useEffect } from "react";
import { AnalyticsSpacing } from "consts";

function UserDashboardPage() {
  const history = useHistory();
  const formatMetric = useFormatMetric();
  const { recordedIn, chartOutput } = useFromToContext();

  const { setLastNDays } = useFromToContext();

  useEffect(() => {
    setLastNDays(365);
    return () => {
      setLastNDays(30);
    };
  }, [setLastNDays]);

  const onClick = useCallback(
    (l, page) => {
      history.push(`./user-dashboard/${l.id}`, {
        page,
      });
    },
    [history]
  );
  return (
    <>
      <Grid container spacing={AnalyticsSpacing}>
        <Grid item xs={9}>
          <LeadTimelineList onClick={onClick} useParamsPagination={true} />
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={AnalyticsSpacing}>
            <AnalyticsStatCard
              recordedIn={recordedIn(chartOutput)}
              minHeight={100}
              chart={{
                name: "",
                dimension: [],
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.GRAPH,
                attributes: [],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_LEADS_COUNT],
                  },
                ],
              }}
              renderBody={(data) => (
                <>
                  <StatCardTypography size="small" opactity={0.8}>
                    Total Number of Leads
                  </StatCardTypography>
                  <StatCardTypography size="large" opactity={1}>
                    {formatMetric(
                      ApiMetric.METRIC_LEADS_COUNT,
                      Number(data[0][ApiMetric.METRIC_LEADS_COUNT])
                    )}
                  </StatCardTypography>
                </>
              )}
            />
            <AnalyticsStatCard
              recordedIn={recordedIn(chartOutput)}
              chart={{
                name: "",
                dimension: [],
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.GRAPH,
                attributes: [],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS],
                  },
                ],
              }}
              renderBody={(data) => (
                <>
                  <StatCardTypography size="small" opactity={0.8}>
                    Were Active in the Last 7 days
                  </StatCardTypography>
                  <StatCardTypography size="large" opactity={1}>
                    {formatMetric(
                      ApiMetric.METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS,
                      Number(
                        data[0][ApiMetric.METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS]
                      )
                    )}
                  </StatCardTypography>
                </>
              )}
            />
            <Box sx={{ height: 350 }}>
              <AnalyticsCard
                chart={{
                  name: "New & Returning Users Composition",
                  dimension: [ApiDimension.DIMENSION_USER_TYPE],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_LEADS_COUNT],
                    },
                  ],
                  displayOptions: {
                    showTotals: true,
                  },
                }}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default UserDashboardPage;
