import { styled, Typography } from "@mui/material";

export const ClickableTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main, // Optional: Change color on hover
  },
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: -2,
    width: 0,
    height: 2,
    backgroundColor: theme.palette.primary.main,
    transition: "width 0.3s ease",
  },
  "&:hover::after": {
    width: "100%",
  },
}));
