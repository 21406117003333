/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiProjectSubscriptionStatus;
(function (ApiProjectSubscriptionStatus) {
    ApiProjectSubscriptionStatus["PROJECT_SUBSCRIPTION_STATUS_CREATED"] = "ProjectSubscriptionStatusCreated";
    ApiProjectSubscriptionStatus["PROJECT_SUBSCRIPTION_STATUS_PAID"] = "ProjectSubscriptionStatusPaid";
})(ApiProjectSubscriptionStatus || (ApiProjectSubscriptionStatus = {}));
export function ApiProjectSubscriptionStatusFromJSON(json) {
    return ApiProjectSubscriptionStatusFromJSONTyped(json, false);
}
export function ApiProjectSubscriptionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiProjectSubscriptionStatusToJSON(value) {
    return value;
}
