import {
  ApiChartAttribute,
  ApiChartYAxisKey,
  ApiDimension,
  ApiMetric,
  ApiOperator,
  ApiRow,
} from "@incendium/api";
import { alpha, darken, lighten } from "@mui/material";
import { mergeArraysByKey } from "Helpers/arrays";
import { enumToArray } from "Helpers/enumToText";
import { percentageChange, percentageOf } from "Helpers/percentage";
import { IChart, IYKey, TChartData } from "Interfaces";
import {
  metricConfigByName,
  metricOriginalNameToEnum,
  metricToName,
} from "../services/metricsFormatter";
import { dimensionOriginalNameToEnum } from "../services/dimensionsFormatter";
import { AttributionMetric, AttributionType } from "../types/types";
import produce from "immer";
import { isArrayEmpty } from "Helpers/isEmpty";
import { formatNumber } from "Helpers/numbers";

export const getChartColor = (
  index: number,
  colours: string[],
  opactity?: number
) => {
  const value = Math.floor(index / (colours.length - 1));
  const n = 0 + Number(`0.${value}`) * 1.3;
  const colour = alpha(colours[index % (colours.length - 1)], opactity || 1);
  return value % 2 !== 0 ? lighten(colour, n) : darken(colour, n);
};

export const labelMetricToName = (item: string) => {
  const parts = item.includes("\\") ? item.split("\\") : item.split(" ");
  // remove previous
  let name = parts[parts.length - 1];
  parts[parts.length - 1] = metricToName(name as ApiMetric);
  let modifiedString = parts.join(" ");
  const isPrevious = modifiedString.includes("previous_");
  modifiedString = isPrevious
    ? modifiedString.replace("previous_", "")
    : modifiedString;
  return isPrevious ? `Previous ${modifiedString}` : modifiedString;
};

export const isPairingDecorator = (dimension: ApiDimension) => {
  return [
    ApiDimension.DIMENSION_LOCATION_TAGGER,
    ApiDimension.DIMENSION_LANDING_PAGE_LOCATION_TAGGER,
    ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER,
  ].includes(dimension);
};

export const pairingDecorator = (
  dimension: string | ApiDimension
): ApiDimension | null => {
  switch (dimension) {
    case ApiDimension.DIMENSION_LOCATION_TAGGER_VALUE:
      return ApiDimension.DIMENSION_LOCATION_TAGGER;
    case ApiDimension.DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE:
      return ApiDimension.DIMENSION_LANDING_PAGE_LOCATION_TAGGER;
    case ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE:
      return ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER;
    default:
      return null;
  }
};

export const removeFirstDimension = (
  data: TChartData[],
  dimensions: ApiDimension[]
) =>
  data.map((d) => {
    let o: TChartData = {
      name: d.name,
    };
    for (const key in d) {
      if (key === dimensions[0]) {
        continue;
      }

      o[key] = d[key];
    }

    return o;
  });

const trendDimensions = [
  ApiDimension.DIMENSION_SESSION_START_BY_HOUR,
  ApiDimension.DIMENSION_SESSION_START_BY_DAY,
  ApiDimension.DIMENSION_SESSION_START_BY_WEEK,
  ApiDimension.DIMENSION_SESSION_START_BY_MONTH,
  ApiDimension.DIMENSION_SESSION_START_DATE,
];

export const filterTrendDimensions = (dimensions: ApiDimension[]) =>
  (dimensions || []).filter(
    (d) =>
      trendDimensions.includes(d) ||
      trendDimensions.includes(
        dimensionOriginalNameToEnum(d as string) as ApiDimension
      )
  );

export const hasTrendDimensions = (dimensions: ApiDimension[]) =>
  filterTrendDimensions(dimensions).length > 0;

export const hasDayTrend = (dimensions: ApiDimension[]) =>
  (dimensions || []).filter((d) => {
    return (
      [ApiDimension.DIMENSION_SESSION_START_BY_DAY].includes(d) ||
      [ApiDimension.DIMENSION_SESSION_START_BY_DAY].includes(
        dimensionOriginalNameToEnum(d as string) as ApiDimension
      )
    );
  }).length > 0;

export const hasHourTrend = (dimensions: ApiDimension[]) =>
  (dimensions || []).filter((d) => {
    return (
      [ApiDimension.DIMENSION_SESSION_START_BY_HOUR].includes(d) ||
      [ApiDimension.DIMENSION_SESSION_START_BY_HOUR].includes(
        dimensionOriginalNameToEnum(d as string) as ApiDimension
      )
    );
  }).length > 0;

export const filterNonTrendDimensions = (dimensions: ApiDimension[]) =>
  (dimensions || []).filter((d) => {
    return (
      !trendDimensions.includes(d) &&
      !trendDimensions.includes(
        dimensionOriginalNameToEnum(d as string) as ApiDimension
      )
    );
  });

export const isTrendDimension = (dimension: ApiDimension) =>
  trendDimensions.includes(dimension);

// sees if string passed is a dimension
export const isDimension = (value: string) =>
  enumToArray(ApiDimension).includes(value as ApiDimension);

export const isAverageMetric = (metric: ApiMetric) =>
  [
    ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
    ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
    ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION,
    ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
    ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
    ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
    ApiMetric.METRIC_AVERAGE_DAYS_TO_SECOND_VISIT,
    ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION,
    ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
    ApiMetric.METRIC_AVERAGE_SALE_VALUE,
    ApiMetric.METRIC_AVERAGE_SCROLL_SPEED,
    ApiMetric.METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION,
    ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
  ].includes(metric);

export const isPercentageMetric = (metric: ApiMetric) =>
  [
    ApiMetric.METRIC_RETURNING_LEAD_PERCENTAGE,
    ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
    ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
    ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
    ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK,
    ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
    ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
    ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
    ApiMetric.METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE,
    ApiMetric.METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE,
  ].includes(metric);

export const getUniqueMetrics = (data: any[]) => [
  ...new Set(
    data
      .map((s) => {
        const { name, ...rest } = s;
        return Object.keys(rest);
      })
      .flat()
  ),
];
export const getUniqueDimensionValues = (
  rows: ApiRow[],
  startIndex?: number
) => {
  const dims = new Set();
  (rows || []).forEach((row) => {
    (row.dimensions || []).forEach((d, i) => {
      if (!startIndex || i >= startIndex) {
        dims.add(d);
      }
    });
  });
  return [...dims];
};

export const remapYaxisFromUniqueMetrics = (
  ykeys: ApiChartYAxisKey[],
  uniqueMetrics: string[]
): ApiChartYAxisKey[] => {
  return ykeys
    .map((yKey) =>
      produce(yKey, (draft) => {
        if (!isArrayEmpty(draft.fields)) {
          let mappedFields = (draft.fields || [])
            .map((f) => {
              const arr: any[] = [];
              uniqueMetrics.forEach((uniq) => {
                if (uniq.includes(f)) {
                  arr.push(uniq);
                }
              });
              return arr;
            })
            .flat();
          draft.fields = mappedFields;
        }

        draft.chart = Object.assign(
          {},
          ...Object.keys(draft.chart || {})
            .map((k) => {
              if (draft.chart && draft.chart.hasOwnProperty(k)) {
                const value = draft.chart[k];
                let o: any = {};
                uniqueMetrics.forEach((uniq) => {
                  if (uniq.includes(k)) {
                    o[uniq] = value;
                  }
                });
                return o;
              }
            })
            .flat()
        );
      })
    )
    .filter((v) => v);
};

export const remapYAxisBackwardCompatable = (keys: IYKey[]) => {
  return keys.map((y) => {
    if (typeof y === "string") {
      return {};
    }
    return {
      ...y,
      fields: (y.fields || []).map(metricOriginalNameToEnum),
      chart: Object.fromEntries(
        Object.entries(y.chart || {}).map(([key, value]) => [
          `${metricOriginalNameToEnum(key)}`,
          value,
        ])
      ),
    };
  });
};

export const trendDimensionToInterval = (
  trend: ApiDimension
): "hour" | "day" | "week" | "month" => {
  switch (trend) {
    case ApiDimension.DIMENSION_SESSION_START_BY_HOUR:
      return "hour";
    case ApiDimension.DIMENSION_SESSION_START_BY_DAY:
      return "day";
    case ApiDimension.DIMENSION_SESSION_START_BY_WEEK:
      return "week";
    case ApiDimension.DIMENSION_SESSION_START_BY_MONTH:
      return "month";

    default:
      return "day";
  }
};

export const getFirstMetricFromY = (yAxisKeys?: IYKey[]) => {
  return getMetricByIndex(0, yAxisKeys);
};

export const getMetricByIndex = (index: number, yAxisKeys?: IYKey[]) => {
  if (!yAxisKeys) {
    return;
  }
  const y = yAxisKeys[0];
  if (!y) {
    return;
  }
  if (typeof y === "string") {
    return y[index] as ApiMetric;
  }
  return (y.fields || [])[index] as ApiMetric;
};

export const percentageTopOfTotal = (data: TChartData[], metric: ApiMetric) => {
  const total = (data || []).reduce(
    (agg, v) => agg + Number(v[metric as ApiMetric] || 0),
    0
  );

  const value = data[0] ? data[0][metric] : 0;
  return percentageOf(Number(value), total);
};

export const percentageTopChangeByMetric = (
  data: TChartData[],
  comp: TChartData[],
  metric: ApiMetric
) => {
  const top = data[0] ? data[0][metric] : 0;
  const toCompare = comp.find((c) => c.name === data[0]?.name);
  if (!toCompare) {
    return 0;
  }

  return percentageChange(Number(toCompare[metric]), Number(top));
};
export const percentageChangeByRowByMetric = (
  data: TChartData,
  comp: TChartData[],
  metric: ApiMetric
) => {
  const top = Number(data[metric]) || 0;
  const toCompare = comp.find((c) => c.name === data.name);
  if (!toCompare) {
    return 0;
  }

  return percentageChange(Number(toCompare[metric]), Number(top));
};

export const mostImprovedByMetric = (
  data: TChartData[],
  comp: TChartData[],
  metric: ApiMetric
): TChartData => {
  const improvements = dataWithImprovement(data, comp, metric);

  // Find the item with the greatest improvement
  const { improvement, ...row } = improvements.reduce(
    (prev, current) =>
      prev.improvement > current.improvement ? prev : current,
    [] as any
  );
  return row as TChartData;
};

export const biggestRegressionByMetric = (
  data: TChartData[],
  comp: TChartData[],
  metric: ApiMetric
): TChartData => {
  const regressions = dataWithImprovement(data, comp, metric);

  // Find the item with the greatest regression
  const { improvement, ...row } = regressions.reduce(
    (prev, current) =>
      prev.improvement < current.improvement ? prev : current,
    [] as any
  );
  return row as TChartData;
};

const dataWithImprovement = (
  data: TChartData[],
  comp: TChartData[],
  metric: ApiMetric
) => {
  return (data || []).map((currentItem) => {
    const previousItem = comp.find(
      (prevItem) => prevItem.name === currentItem.name
    );
    if (previousItem) {
      return {
        ...currentItem,
        improvement: Number(currentItem[metric]) - Number(previousItem[metric]),
      };
    } else {
      // Handle the case where the corresponding previous data doesn't exist
      return {
        ...currentItem,
        improvement: 0, // No improvement if previous data is missing
      };
    }
  });
};

export const mergeApiChartYAxisKeys = <T extends ApiChartYAxisKey>(
  arr1: T[],
  arr2: T[]
): T[] => {
  const mergedArray: T[] = [];
  const mergedKeys: Set<string> = new Set();

  // Merge objects with the same 'key' property
  for (const obj1 of arr1) {
    if (obj1?.key && !mergedKeys.has(obj1.key)) {
      const matchingObj2 = arr2.find((obj2) => obj2.key === obj1.key);

      if (matchingObj2) {
        mergedArray.push({
          ...obj1,
          fields: [...(obj1.fields || []), ...(matchingObj2.fields || [])],
          chart: {
            ...(obj1.chart || {}),
            ...(matchingObj2.chart || {}),
          },
        });
        mergedKeys.add(obj1.key);
      } else {
        mergedArray.push(obj1);
      }
    }
  }

  // Add remaining objects from arr2 that have unique keys
  for (const obj2 of arr2) {
    if (obj2?.key && !mergedKeys.has(obj2.key)) {
      mergedArray.push(obj2);
      mergedKeys.add(obj2.key);
    }
  }

  return mergedArray;
};

export const hasFirstClickAttribition = (metrics: ApiMetric[]) =>
  [
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE,
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_LTV,
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROAS,
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV,
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROI,
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV,
  ].some((s) => metrics.includes(s));

export const hasLastClickAttribition = (metrics: ApiMetric[]) =>
  [
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_REVENUE,
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_LTV,
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV,
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROILTV,
  ].some((s) => metrics.includes(s));

export const hasLastNonDirectClickAttribition = (metrics: ApiMetric[]) =>
  [
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE,
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV,
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS,
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV,
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI,
    ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV,
  ].some((s) => metrics.includes(s));

export const hasLinearClickAttribition = (metrics: ApiMetric[]) =>
  [
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_SALES_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_REVENUE,
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_LTV,
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROAS,
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROASLTV,
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROI,
    ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROILTV,
  ].some((s) => metrics.includes(s));

export const hasPositionAttribition = (metrics: ApiMetric[]) =>
  [
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE,
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV,
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS,
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV,
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI,
    ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV,
  ].some((s) => metrics.includes(s));

export const hasImpactedAttribition = (metrics: ApiMetric[]) =>
  [
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT,
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE,
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV,
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS,
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV,
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI,
    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV,
  ].some((s) => metrics.includes(s));

export const isJourneyDimension = (dimension: ApiDimension) =>
  [
    ApiDimension.DIMENSION_SESSION_JOURNEY,
    ApiDimension.DIMENSION_CONVERTING_SESSION_JOURNEY,
    ApiDimension.DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY,
    ApiDimension.DIMENSION_SALES_SESSION_JOURNEY,
    ApiDimension.DIMENSION_CHANNEL_SALES_JOURNEY,
  ].includes(dimension);

export const metricsFromChart = (chart: IChart) => {
  let metrics: ApiMetric[] = [];
  for (let i = 0; i < (chart.yAxisKeys || []).length; i++) {
    const y = (chart.yAxisKeys || [])[i];
    if (typeof y === "string") {
      metrics.push(y as ApiMetric);
    } else {
      metrics.push(...((y.fields || []) as ApiMetric[]));
    }
  }
  return metrics;
};

export const hasAttributionMetric = (chart: IChart) => {
  let metrics = metricsFromChart(chart);

  return (
    hasFirstClickAttribition(metrics) ||
    hasLastClickAttribition(metrics) ||
    hasLastNonDirectClickAttribition(metrics) ||
    hasLinearClickAttribition(metrics) ||
    hasPositionAttribition(metrics) ||
    hasImpactedAttribition(metrics)
  );
};

export const mergeComparisonData = (
  data: TChartData[],
  comparisonData: TChartData[]
) => {
  const tickedData = buildTicks(data);
  const tickedComparisonData = buildTicks(comparisonData);
  return mergeArraysByKey(tickedData, tickedComparisonData, "tick");
};

const attributionMetricOrder = {
  [AttributionMetric.MACRO]: 1,
  [AttributionMetric.SALES]: 2,
  [AttributionMetric.REVENUE]: 3,
  [AttributionMetric.LTV]: 4,
  [AttributionMetric.ROAS]: 5,
  [AttributionMetric.ROASLTV]: 6,
  [AttributionMetric.ROI]: 7,
  [AttributionMetric.ROILTV]: 8,
};

const attributionTypeOrder = {
  [AttributionType.FIRST]: 1,
  [AttributionType.LAST]: 2,
  [AttributionType.LAST_NON]: 3,
  [AttributionType.LINEAR]: 4,
  [AttributionType.POSITION]: 5,
  [AttributionType.IMPACTED]: 6,
};

export const orderAttributionType = (types: AttributionType[]) => {
  return [...types].sort(
    (a, b) => attributionTypeOrder[a] - attributionTypeOrder[b]
  );
};

export const orderAttributionMetrics = (metrics: ApiMetric[]) => {
  return metrics.sort((a, b) => {
    const metricConfigA = metricConfigByName(a);
    const metricConfigB = metricConfigByName(b);
    if (!metricConfigA?.attribtionMetric || !metricConfigB?.attribtionMetric) {
      return 0;
    }
    // Compare by attributionMetric first
    const metricA = metricConfigA?.attribtionMetric;
    const metricB = metricConfigB?.attribtionMetric;
    if (attributionMetricOrder[metricA] < attributionMetricOrder[metricB]) {
      return -1;
    } else if (
      attributionMetricOrder[metricA] > attributionMetricOrder[metricB]
    ) {
      return 1;
    }

    if (!metricConfigA?.attribtionType || !metricConfigB?.attribtionType) {
      return 0;
    }

    // If the attributionMetric is the same, compare by attributionType
    const typeA = metricConfigA?.attribtionType;
    const typeB = metricConfigB?.attribtionType;
    return attributionTypeOrder[typeA] - attributionTypeOrder[typeB];
  });
};

export const nonEmptyDimensions = (
  dimensions: (ApiDimension | string)[] | undefined
) => {
  return (dimensions || []).filter(
    (d) => d !== "" && d !== ApiDimension.DIMENSION_NOT_SET
  );
};

export const attributesFromChartDataRow = (
  data: TChartData
): ApiChartAttribute[] => {
  const ats: ApiChartAttribute[] = [];
  Object.keys(data).forEach((key) => {
    if (isDimension(key)) {
      ats.push({
        key,
        operator: ApiOperator.EQUAL,
        value: `${data[key]}`,
      });
    }
  });
  return ats;
};

export const mergeDimensionToMetrics = (
  groupedData: Record<string, TChartData[]>,
  lastNonTrendDimension: ApiDimension,
  prefix?: string
) => {
  return Object.keys(groupedData).map((key) => {
    let o: any = {
      name: key,
    };
    groupedData[key].forEach((g) => {
      const { name, tick, ...rest } = g;
      o["tick"] = tick;
      Object.keys(rest).forEach((metricKey) => {
        if (metricKey === lastNonTrendDimension) {
          return;
        }
        o[`${prefix || ""}${rest[lastNonTrendDimension] || ""}\\${metricKey}`] =
          rest[metricKey];
      });
    });

    return o;
  });
};

export const buildTicks = (chartData: TChartData[]) => {
  // Extract unique values that are valid dates
  const dateNames = Array.from(
    new Set(
      chartData
        .map((d) => d.name)
        .filter((name) => !isNaN(new Date(name).valueOf()))
    )
  );

  // Sort the date names chronologically
  dateNames.sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf());

  // Create a mapping of date to ordinal position
  const dateMap = new Map<string, number>();
  dateNames.forEach((name, index) => {
    dateMap.set(name, index);
  });

  // Map data to include the corrected tick values
  return chartData.map((d) => {
    const isDate = !isNaN(new Date(d.name).valueOf());
    const dateOrdinal = isDate ? dateMap.get(d.name) : d.name; // Use ordinal if date, else keep the original value

    let ticks = [dateOrdinal];
    Object.keys(d).forEach((k) => {
      if (isDimension(k) && !isTrendDimension(k as ApiDimension)) {
        ticks.push(`${d[k]}`);
      }
    });

    return { ...d, tick: ticks.join("+") };
  });
};

export const displayDataMapFn = (row) => {
  return Object.fromEntries(
    Object.entries(row).filter(
      ([key]) =>
        key !== "tick" && !key.endsWith("_tick") && !key.endsWith("\\tick")
    )
  ) as TChartData;
};

export const groupWithPrevious = (arr: any, key?: string) => {
  // strip our previous into new array
  const previous: any[] = [];
  return arr
    .filter((a) => {
      if (a[key || "name"].startsWith("previous_")) {
        previous.push(a);
        return false;
      }
      return true;
    })
    .map((a) => {
      const found = previous.find((p) =>
        p[key || "name"].endsWith(a[key || "name"])
      );
      if (found) {
        return { ...a, previous: found };
      }
      return a;
    });
};

export const incDec = (
  comp: number = 0,
  curr: number = 0,
  isPercentage?: boolean
): {
  direction?: "up" | "down";
  value: number;
} => {
  const change = isPercentage ? curr - comp : percentageChange(comp, curr);
  return {
    value: Number(formatNumber(change || 0)),
    direction:
      Math.sign(change) > 0 ? "up" : Math.sign(change) < 0 ? "down" : undefined,
  };
};
