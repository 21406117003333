import {
  styled,
  Table,
  TableCell,
  TableCellProps,
  TableContainer,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { alpha } from "@mui/material";
import { Glass } from "Components/Glass";
import React, { useCallback } from "react";
import SpacedListContainer from "Components/UI/SpacedListContainer";

const useTableStyles = makeStyles((theme: Theme) => ({
  container: {
    width: `calc(100% + 20px)`,
    padding: `0 10px`,
    marginLeft: -10,
  },
  root: {
    borderCollapse: "separate",
    borderStyle: "hidden",
    "& .dragging": {
      "& .MuiTableRow-root": {
        "&:nth-last-child(2)": {
          "& .MuiTableCell-root": {
            borderBottom: 0,

            "&:first-child": {
              borderBottomLeftRadius: "10px",
              "&::before": {
                borderBottomLeftRadius: "10px",
              },
            },
            "&:last-child": {
              borderBottomRightRadius: "10px",
              "&::before": {
                borderBottomRightRadius: "10px",
              },
            },
          },
        },
      },
    },
    "& .dropping": {
      "& .MuiTableRow-root": {
        transition: "none",
      },
    },

    "& .MuiTableCell-root": {
      maxWidth: 450,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: theme.palette.primary.dark,
      borderBottom: `${theme.spacing(1)} solid transparent`,
      borderRight: `1px solid transparent`,
      position: "relative",
      background: alpha(theme.palette.common.white, 0.7),
      backgroundClip: "padding-box",
      "&::before": {
        position: "absolute",
        content: "''",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        boxShadow: "2px 2px 5px #6B85B319",
      },
    },

    "& .MuiTableRow-head": {
      overflow: "hidden",
      "& .MuiTableCell-root": {
        background: theme.palette.primary.main,
        backgroundClip: "padding-box",
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 600,

        "&:first-child": {
          borderTopLeftRadius: "10px",
          "&::before": {
            borderTopLeftRadius: "10px",
          },
        },
        "&:last-child": {
          borderTopRightRadius: "10px",
          "&::before": {
            borderTopRightRadius: "10px",
          },
        },
      },
    },
    "& .MuiTableCell-head": {
      color: theme.palette.common.white,
    },
    "& .MuiTableBody-root": {
      position: "relative",
      overflow: "hidden",
      "& .MuiTableRow-root": {
        left: "auto!important",
        top: "auto!important",

        "&.dragging": {
          transition: "none",
          borderBottom: `${theme.spacing(1)} solid ${
            theme.palette.secondary.light
          }`,
        },

        "&:last-child": {
          "& .MuiTableCell-root": {
            borderBottom: 0,

            "&:first-child": {
              borderBottomLeftRadius: "10px",
              "&::before": {
                borderBottomLeftRadius: "10px",
              },
            },
            "&:last-child": {
              borderBottomRightRadius: "10px",
              "&::before": {
                borderBottomRightRadius: "10px",
              },
            },
          },
        },
        transition: "transform 0.17s",
        "&:hover": {
          transform: `scale(1.007)`,
        },
      },
    },
  },
}));

interface ISpacedCellProps extends TableCellProps {}
export const SpacedCell = styled(TableCell)<ISpacedCellProps>(
  ({ theme }) => ({})
);

export const SpacedLinkButton = styled(Typography)(({ theme }) => ({
  position: "relative",
  display: "inline-block",

  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 2,
    left: 0,
    width: "0%",
    height: 1,
    background: alpha(theme.palette.primary.main, 0.5),
    transition: "all 0.2s",
  },
  "&:hover": {
    cursor: "pointer",
    "&::after": {
      width: "100%",
      height: 1,
      background: theme.palette.primary.main,
    },
  },
}));
SpacedLinkButton.defaultProps = {
  color: "primary",
  variant: "subtitle2",
};

interface ISpacedListProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  subTitle?: string;
  action?: React.ReactNode;
  isEmpty?: boolean;
  height?: number | string;
}

function SpacedList({
  isEmpty,
  title,
  subTitle,
  action,
  children,
  height,
  ...rest
}: ISpacedListProps) {
  const { root, container } = useTableStyles();

  const renderBody = useCallback(
    () => (
      <TableContainer className={container}>
        <Table {...rest} className={root}>
          {children}
        </Table>
        {isEmpty && (
          <Glass
            noShadow
            sx={{
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color={"secondary"} variant="subtitle2">
              No Data
            </Typography>
          </Glass>
        )}
      </TableContainer>
    ),
    [children, container, isEmpty, rest, root]
  );

  return title ? (
    <SpacedListContainer
      title={title}
      subTitle={subTitle}
      action={action}
      height={height}
    >
      <>{renderBody()}</>
    </SpacedListContainer>
  ) : (
    renderBody()
  );
}

export default SpacedList;
