import { useNotification } from "Hooks/useNotification";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useCallback } from "react";

export const useConfirmation = () => {
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showSuccessNotification, showErrorNotification } = useNotification();

  const handleConfirmation = useCallback(
    ({
      title,
      body,
      callback,
    }: {
      title: string;
      body: string;
      callback: () => Promise<string>;
    }) => {
      openConfirmation({
        title,
        body,
        callback: async () => {
          try {
            const res = await callback();
            showSuccessNotification(res);
            closeConfirmation();
          } catch (error) {
            showErrorNotification(`Internal Error, please try again`);
          }
        },
      });
    },
    [
      openConfirmation,
      closeConfirmation,
      showErrorNotification,
      showSuccessNotification,
    ]
  );

  return handleConfirmation;
};
