/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiCreatePageMetricsPayloadToJSON, ApiCreatePageViewResponseFromJSON, ApiSessionFromJSON, InlineObject5ToJSON, } from '../models';
/**
 *
 */
export class SessionServiceApi extends runtime.BaseAPI {
    /**
     * method to add data meta to a pageview custom to allow us to use beacon which has to be post be has no body
     */
    sessionServiceCreatePageMetricsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling sessionServiceCreatePageMetrics.');
            }
            if (requestParameters.leadKey === null || requestParameters.leadKey === undefined) {
                throw new runtime.RequiredError('leadKey', 'Required parameter requestParameters.leadKey was null or undefined when calling sessionServiceCreatePageMetrics.');
            }
            if (requestParameters.pageviewKey === null || requestParameters.pageviewKey === undefined) {
                throw new runtime.RequiredError('pageviewKey', 'Required parameter requestParameters.pageviewKey was null or undefined when calling sessionServiceCreatePageMetrics.');
            }
            const queryParameters = {};
            if (requestParameters.payloadAverageScrollSpeed !== undefined) {
                queryParameters['payload.averageScrollSpeed'] = requestParameters.payloadAverageScrollSpeed;
            }
            if (requestParameters.payloadTimeOnPageSeconds !== undefined) {
                queryParameters['payload.timeOnPageSeconds'] = requestParameters.payloadTimeOnPageSeconds;
            }
            if (requestParameters.payloadScrolledPercentage !== undefined) {
                queryParameters['payload.scrolledPercentage'] = requestParameters.payloadScrolledPercentage;
            }
            if (requestParameters.payloadViewedPercentage !== undefined) {
                queryParameters['payload.viewedPercentage'] = requestParameters.payloadViewedPercentage;
            }
            if (requestParameters.payloadClsValue !== undefined) {
                queryParameters['payload.clsValue'] = requestParameters.payloadClsValue;
            }
            if (requestParameters.payloadClsScore !== undefined) {
                queryParameters['payload.clsScore'] = requestParameters.payloadClsScore;
            }
            if (requestParameters.payloadInpValue !== undefined) {
                queryParameters['payload.inpValue'] = requestParameters.payloadInpValue;
            }
            if (requestParameters.payloadInpScore !== undefined) {
                queryParameters['payload.inpScore'] = requestParameters.payloadInpScore;
            }
            if (requestParameters.payloadLcpValue !== undefined) {
                queryParameters['payload.lcpValue'] = requestParameters.payloadLcpValue;
            }
            if (requestParameters.payloadLcpScore !== undefined) {
                queryParameters['payload.lcpScore'] = requestParameters.payloadLcpScore;
            }
            if (requestParameters.payloadFcpValue !== undefined) {
                queryParameters['payload.fcpValue'] = requestParameters.payloadFcpValue;
            }
            if (requestParameters.payloadFcpScore !== undefined) {
                queryParameters['payload.fcpScore'] = requestParameters.payloadFcpScore;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/session/v1/projects/{projectKey}/leads/{leadKey}/data/{pageviewKey}/meta`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))).replace(`{${"leadKey"}}`, encodeURIComponent(String(requestParameters.leadKey))).replace(`{${"pageviewKey"}}`, encodeURIComponent(String(requestParameters.pageviewKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * method to add data meta to a pageview custom to allow us to use beacon which has to be post be has no body
     */
    sessionServiceCreatePageMetrics(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionServiceCreatePageMetricsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * method to create a data (pageview) record from given page
     */
    sessionServiceCreatePageViewRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling sessionServiceCreatePageView.');
            }
            if (requestParameters.leadKey === null || requestParameters.leadKey === undefined) {
                throw new runtime.RequiredError('leadKey', 'Required parameter requestParameters.leadKey was null or undefined when calling sessionServiceCreatePageView.');
            }
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling sessionServiceCreatePageView.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/session/v1/projects/{projectKey}/leads/{leadKey}/data`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))).replace(`{${"leadKey"}}`, encodeURIComponent(String(requestParameters.leadKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InlineObject5ToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCreatePageViewResponseFromJSON(jsonValue));
        });
    }
    /**
     * method to create a data (pageview) record from given page
     */
    sessionServiceCreatePageView(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionServiceCreatePageViewRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * method that pushed meta to be stored in cache. This is later handled by either the above if fired otherwise a worker task
     */
    sessionServicePushPageMetricsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectKey === null || requestParameters.projectKey === undefined) {
                throw new runtime.RequiredError('projectKey', 'Required parameter requestParameters.projectKey was null or undefined when calling sessionServicePushPageMetrics.');
            }
            if (requestParameters.leadKey === null || requestParameters.leadKey === undefined) {
                throw new runtime.RequiredError('leadKey', 'Required parameter requestParameters.leadKey was null or undefined when calling sessionServicePushPageMetrics.');
            }
            if (requestParameters.pageviewKey === null || requestParameters.pageviewKey === undefined) {
                throw new runtime.RequiredError('pageviewKey', 'Required parameter requestParameters.pageviewKey was null or undefined when calling sessionServicePushPageMetrics.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling sessionServicePushPageMetrics.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/session/v1/projects/{projectKey}/leads/{leadKey}/data/{pageviewKey}/meta:push`.replace(`{${"projectKey"}}`, encodeURIComponent(String(requestParameters.projectKey))).replace(`{${"leadKey"}}`, encodeURIComponent(String(requestParameters.leadKey))).replace(`{${"pageviewKey"}}`, encodeURIComponent(String(requestParameters.pageviewKey))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreatePageMetricsPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * method that pushed meta to be stored in cache. This is later handled by either the above if fired otherwise a worker task
     */
    sessionServicePushPageMetrics(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionServicePushPageMetricsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sessionServiceReadSessionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling sessionServiceReadSession.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/session/v1/sessions/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSessionFromJSON(jsonValue));
        });
    }
    /**
     */
    sessionServiceReadSession(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sessionServiceReadSessionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
