import {
  ApiSimpleProduct,
  ApiSimpleSubscription,
  ApiSubscriptionTier,
} from "@incendium/api";
import { subscriptionService } from "Apis";

export const listSubscriptions = async () => {
  const res = await subscriptionService.subscriptionServiceListSubscriptions();
  return (res.result || []).map((r) => ({
    ...r,
    contractId: r.contract?.id,
    productId: r.product?.id,
  }));
};

export const listSubscriptionProducts = async () => {
  const res = await subscriptionService.subscriptionServiceListProducts();
  return res.result || [];
};

export const listProjectSubscriptions = async () => {
  const res =
    await subscriptionService.subscriptionServiceListProjectSubscriptions();
  return res.result || [];
};

export const saveSubcription = async (subscription: ApiSimpleSubscription) => {
  const payload = { ...subscription };
  if (subscription.product?.id) {
    payload.productId = subscription.product.id;
  }
  if (subscription.contract) {
    payload.contractId = subscription.contract.id;
  }
  const fn = subscription.id
    ? subscriptionService.subscriptionServiceUpdateSubscription({
        subscriptionId: subscription.id,
        payload,
      })
    : subscriptionService.subscriptionServiceCreateSubscription({
        payload,
      });

  const res = await fn;
  return res;
};
export const saveSubcriptionProduct = async (product: ApiSimpleProduct) => {
  const payload = { ...product };
  if (!product.tier) {
    payload.tier = ApiSubscriptionTier.SUBSCRIPTION_TIER_ENTERPRISE;
  }
  const fn = product.id
    ? subscriptionService.subscriptionServiceUpdateProduct({
        productId: product.id,
        payload,
      })
    : subscriptionService.subscriptionServiceCreateProduct({
        payload,
      });

  const res = await fn;
  return res;
};
export const createProjectSubscription = async (
  projectId: number,
  subscriptionId: string,
  redirectUrl: string
) => {
  const fn = subscriptionService.subscriptionServiceCreateProjectSubscription({
    projectId,
    subscriptionId,
    body: {
      redirectUrl,
    },
  });

  const res = await fn;
  return res;
};

export const deleteSubscription = async (id: string) => {
  return subscriptionService.subscriptionServiceDeleteSubscription({
    subscriptionId: id,
  });
};

export const deleteSubscriptionProduct = async (id: string) => {
  return subscriptionService.subscriptionServiceDeleteProduct({
    productId: id,
  });
};
export const activateSubscription = async (id: string) => {
  return subscriptionService.subscriptionServiceActivateSubscription({
    subscriptionId: id,
    payload: {},
  });
};

export const getProjectSubscrition = async (projectId: number) => {
  const res =
    await subscriptionService.subscriptionServiceReadProjectSubscription({
      projectId,
    });
  return res;
};

export const markSubscriptionAsSeen = async (projectSubscriptionId: string) => {
  return subscriptionService.subscriptionServiceMarkProjectSubscriptionAsSeen({
    projectSubscriptionId,
  });
};
