import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { enableMapSet } from "immer";
import { createRoot } from "react-dom/client";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://ab6fcb22dc0c47e4b90239ea9a4e9a32@o1428798.ingest.sentry.io/4503892650557440",
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    environment: window.location.hostname,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      //@ts-ignore
      if ([403, 401, 500].includes(event.message?.status)) {
        return null;
      }
      return event;
    },
  });
}

if (process.env.NODE_ENV === "production") {
  const script = document.createElement("script");
  script.src =
    "https://api.wildfire.incendium.ai/firefly/G1YYAZInc/incendium.js";
  document.body.appendChild(script);
} else {
  const script = document.createElement("script");
  script.src =
    "https://api.staging.incendium.ai/firefly/icGSp6Loc/incendium.js";
  document.body.appendChild(script);
}

// enable immer for maps.
// warning, it is advised that you do not use maps in redux (hense not enambled by default)
// this is becouse maps can be un serializable so dont use maps anywhere where you are storing that data.
enableMapSet();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
