import {
  ApiChartAttribute,
  ApiChartHaving,
  ApiChartRank,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { AttributionType } from "features/analytics/types/types";
import { ExploreConfig } from "features/explorer/types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

interface IExplorerContext {
  rankingIndex: number;
  setRankingIndex: React.Dispatch<React.SetStateAction<number>>;
  dimension: ApiDimension;
  setDimension: React.Dispatch<React.SetStateAction<ApiDimension>>;
  selectedRanks: ApiChartRank[];
  setSelectedRanks: React.Dispatch<React.SetStateAction<ApiChartRank[]>>;
  having: ApiChartHaving[];
  setHaving: React.Dispatch<React.SetStateAction<ApiChartHaving[]>>;
  config: ExploreConfig;
  attributes: ApiChartAttribute[];
  setAttributes: React.Dispatch<React.SetStateAction<ApiChartAttribute[]>>;
  selectedAttributionTypes: AttributionType[];
  setSelectedAttributionTypes: React.Dispatch<
    React.SetStateAction<AttributionType[]>
  >;
}

export const ExplorerContext = createContext<IExplorerContext>({
  rankingIndex: 0,
  setRankingIndex: () => null,
  dimension: ApiDimension.DIMENSION_NOT_SET,
  setDimension: () => null,
  selectedRanks: [],
  setSelectedRanks: () => null,
  having: [],
  setHaving: () => null,
  attributes: [],
  setAttributes: () => null,
  selectedAttributionTypes: [],
  setSelectedAttributionTypes: () => null,
  config: {
    title: "",
    desc: "",
    ranking: [],
  },
});

export const useExplorerContext = () => useContext(ExplorerContext);

const ExplorerProvider = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config: ExploreConfig;
}) => {
  const [rankingIndex, setRankingIndex] = useState(0);
  const [dimension, setDimension] = useState<ApiDimension>(
    config.analyseBy?.grouping[0].dimensions[0] ||
      ApiDimension.DIMENSION_NOT_SET
  );
  const [selectedRanks, setSelectedRanks] = useState<ApiChartRank[]>([]);
  const [having, setHaving] = useState<ApiChartHaving[]>([]);
  const [attributes, setAttributes] = useState<ApiChartAttribute[]>(
    config.attributes || []
  );
  const [selectedAttributionTypes, setSelectedAttributionTypes] = useState<
    AttributionType[]
  >([]);

  useEffect(() => {
    setRankingIndex(0);
    setSelectedRanks(
      config.ranking[0]
        ? (config.ranking[0].ranks || []).filter((r) =>
            (config.default || []).includes(r.metric as ApiMetric)
          )
        : []
    );
    setHaving(config.having?.havings || []);
    setAttributes(config.attributes || []);

    setDimension(
      config.analyseBy?.grouping[0].dimensions[0] ||
        ApiDimension.DIMENSION_NOT_SET
    );
  }, [config]);

  useUpdateEffect(() => {
    setSelectedRanks(
      config.ranking[rankingIndex]
        ? (config.ranking[rankingIndex].ranks || []).filter((r) =>
            (config.default || []).includes(r.metric as ApiMetric)
          )
        : []
    );
  }, [rankingIndex]);

  const state = useMemo(
    () => ({
      dimension,
      setDimension,
      selectedRanks,
      setSelectedRanks,
      having,
      setHaving,
      attributes,
      setAttributes,
      selectedAttributionTypes,
      setSelectedAttributionTypes,
      config,
      rankingIndex,
      setRankingIndex,
    }),
    [
      dimension,
      setDimension,
      selectedRanks,
      setSelectedRanks,
      having,
      setHaving,
      attributes,
      setAttributes,
      selectedAttributionTypes,
      setSelectedAttributionTypes,
      config,
      rankingIndex,
      setRankingIndex,
    ]
  );

  return (
    <ExplorerContext.Provider value={state}>
      {children}
    </ExplorerContext.Provider>
  );
};

export default ExplorerProvider;
