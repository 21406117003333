import { ApiSimpleContract } from "@incendium/api";
import { subscriptionService } from "Apis";

export const listContracts = async () => {
  const res = await subscriptionService.subscriptionServiceListContracts();
  return res.result;
};

export const saveContract = async (contract: ApiSimpleContract) => {
  const fn = contract.id
    ? subscriptionService.subscriptionServiceUpdateContract({
        contractId: contract.id,
        payload: contract,
      })
    : subscriptionService.subscriptionServiceCreateContract({
        payload: contract,
      });

  const res = await fn;
  return res;
};

export const deleteContract = async (id: string) => {
  return subscriptionService.subscriptionServiceDeleteContract({
    contractId: id,
  });
};

export const getContract = async (contractId: string) => {
  const res = await subscriptionService.subscriptionServiceReadContract({
    contractId,
  });
  return res;
};
