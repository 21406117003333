import IframeResizer from "iframe-resizer-react";
import { useEvent, usePrevious } from "react-use";
import { useState } from "react";
import { removeTrailingSlash } from "Helpers";
import { Box, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useCallback } from "react";
import { useEffect } from "react";
import {
  appendQueryParams,
  debugHighlight,
  debugHighlightCollection,
  debugHighlightFromText,
  debugNone,
  debugParam,
  debugRepeated,
  debugTags,
  debugUnique,
  IDebuggerMessage,
  THighlight,
} from "@incendium/inc-ts-helpers";
import { TIframeJob } from "features/smartIFrame/types";
import { useSmartIframeContext } from "features/smartIFrame/providers/SmartIFrameProvider";

const initialState = {
  isVisible: false,
  coords: { x: 0, y: 0, width: 0, height: 0 },
};

const useSmartIframeStyles = makeStyles(() => ({
  loading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: `blur(3px)`,
    background: "rgba(255,255, 255, 0.1)",
  },
}));

export const SmartIframe = ({
  src,
  onClick,
  active,
  targets,
  selector,
  repeated,
  targetText,
  useTextMode,
  highlightCollection,
  width = "100%",
  job,
}: {
  src: string;
  onClick?: (e: { selector: string; dimensions: any; text?: string }) => void;
  active?: boolean;
  targets?: string[];
  selector: string | undefined;
  repeated: boolean;
  targetText: string | undefined;
  width?: string;
  useTextMode?: boolean;
  highlightCollection?: THighlight[];
  job?: TIframeJob;
}) => {
  const { iframeRef, sendMessage, setProductSchemaFound } =
    useSmartIframeContext();

  const [state, setState] = useState(initialState);
  const [loaded, setLoaded] = useState(false);
  const classes = useSmartIframeStyles();
  const prevRepeated = usePrevious(repeated);
  const prevUseTextMode = usePrevious(useTextMode);

  const getStyles = (): React.CSSProperties => {
    if (state.isVisible) {
      const { x, y, width, height } = state.coords;
      return {
        position: "fixed",
        top: y - 2,
        left: x - 8,
        width: width + 6,
        height: height + 4,
        border: `2px dashed rgb(245,206,157)`,
        background: `rgba(245,206,157, 0.2)`,
        pointerEvents: "none",
        cursor: "default",
      };
    }
    return { display: "none" };
  };

  const resetRect = useCallback(() => {
    setState(initialState);
  }, []);

  useEffect(() => {
    setState(initialState);
    let message: IDebuggerMessage = {
      type: debugNone,
    };
    if (prevRepeated !== repeated) {
      message = { type: repeated ? debugRepeated : debugUnique };
      sendMessage(message);
    }
    if (targets) {
      message = {
        type: debugTags,
        data: targets,
      };
      sendMessage(message);
    } else {
      message = { type: debugNone };
      sendMessage(message);
    }
    if (selector !== "" && !highlightCollection) {
      message = { type: debugHighlight, data: selector };
      sendMessage(message);
    }

    if (targetText !== "" && selector === "") {
      message = { type: debugHighlightFromText, data: targetText };
      sendMessage(message);
    }

    if (highlightCollection) {
      message = { type: debugHighlightCollection, data: highlightCollection };
      sendMessage(message);
    }
    if (job && active) {
      sendMessage({
        type: job,
      });
    }

    if (!active) {
      setState(initialState);
      message = { type: debugNone };
      sendMessage(message);
    }
  }, [
    targets?.length,
    targets,
    active,
    selector,
    repeated,
    prevRepeated,
    targetText,
    prevUseTextMode,
    useTextMode,
    highlightCollection,
    job,
    sendMessage,
  ]);

  const logElement = useCallback(
    (e: MessageEvent) => {
      let message: IDebuggerMessage = {
        type: "none",
      };

      if (e.data.type) {
        switch (e.data.type) {
          case "start": {
            // start inirial empty message
            sendMessage(message);
            break;
          }
          case "click": {
            onClick && onClick(e.data);
            break;
          }
          case "mouseenter": {
            const rect: DOMRect = e.data.dimensions;
            const iframeRect = document
              .getElementById("smartiframe")
              ?.getBoundingClientRect();

            setState({
              isVisible: true,
              coords: {
                x: rect.x + iframeRect!.x,
                y: rect.y + iframeRect!.y - 5,
                width: rect.width + 10,
                height: rect.height + 10,
              },
            });
            return;
          }
          case "mouseleave": {
            return setState(initialState);
          }
          case "sendTargets": {
            if (targets) {
              message = { type: "tags", data: targets };
              sendMessage(message);
            }
            return;
          }
          case "productschema":
            setProductSchemaFound(true);

            // onAction && onAction("productschema");
            return;

          default: {
            return;
          }
        }
      }
    },
    [onClick, targets, sendMessage, setProductSchemaFound]
  );

  useEvent("message", logElement);

  return (
    <Box position="relative" height="100%">
      {!loaded && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.loading}
        >
          <CircularProgress size={"2rem"} />
        </Box>
      )}

      <IframeResizer
        forwardRef={iframeRef}
        onMouseLeave={resetRect}
        onLoad={() => {
          setLoaded(true);
        }}
        style={{
          height: "100%",
          width,
          border: 0,
        }}
        log
        id="smartiframe"
        title="smartiframe"
        scrolling={true}
        src={`${appendQueryParams(removeTrailingSlash(src), [
          debugParam,
          "true",
        ])}`}
      />

      <div id="iframeOverlay" style={getStyles()} />
    </Box>
  );
};
