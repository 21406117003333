import { Box, Typography } from "@mui/material";
import { Glass } from "Components/Glass";
import ChartBuilderMap from "./ChartBuilderMap";
import ChartBuilderSnapshot from "./ChartBuilderSnapshot";
import ChartBuuilderTypeTransitioning from "./ChartBuuilderTypeTransitioning";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { AnimatePresence } from "framer-motion";
import { memo, useCallback } from "react";
import { ApiChartTemplate, ApiChartType } from "@incendium/api";
import SetupNeeded from "Components/UI/SetupNeeded";
import Loading from "Components/Loading/Loading";
import useChartTypeTransition from "features/analytics/hooks/useChartTypeTransition";
import AnalyticsChartStatCard from "features/analytics/components/AnalyticsChartStatCard";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { hasAttributionMetric } from "features/analytics/utils/utils";

function ChartBuilder() {
  const { chart } = useReportBuilderContext();
  const transitioning = useChartTypeTransition(chart);

  const renderChart = useCallback(() => {
    if (chart.type === ApiChartType.STAT_CARD) {
      return <AnalyticsChartStatCard chart={chart} noToolbar />;
    }

    switch (chart.template) {
      case ApiChartTemplate.SNAPSHOT:
      case ApiChartTemplate.TREND:
      case ApiChartTemplate.TABLE:
      case ApiChartTemplate.MAP:
      case ApiChartTemplate.ATTRIBUTION:
        return <AnalyticsCard chart={chart} />;

      default:
        return (
          <SetupNeeded>
            <Typography variant="h6">No Chart available.</Typography>
          </SetupNeeded>
        );
    }
  }, [chart]);

  const renderBody = useCallback(() => {
    if (!chart.template) {
      return <Loading fullHeight />;
    }
    switch (chart.template) {
      case ApiChartTemplate.UNDEFINED:
      case ApiChartTemplate.SNAPSHOT:
      case ApiChartTemplate.TREND:
      case ApiChartTemplate.TABLE:
      case ApiChartTemplate.ATTRIBUTION:
        return <ChartBuilderSnapshot />;
      case ApiChartTemplate.MAP:
        return <ChartBuilderMap />;

      default:
        return <>not available</>;
    }
  }, [chart.template]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `60% 40%`,
        height: "100%",
      }}
    >
      <StyledMiddleBox p={5}>
        <Box
          sx={{
            height: hasAttributionMetric(chart) ? 565 : 530,
            display: "flex",
            justifyContent: "center",
            width: chart.type === ApiChartType.STAT_CARD ? 500 : 800,
            maxWidth: "100%",
          }}
        >
          {!chart || !chart.template ? (
            <Loading fullHeight />
          ) : (
            <AnimatePresence>
              {transitioning ? (
                <ChartBuuilderTypeTransitioning />
              ) : (
                renderChart()
              )}
            </AnimatePresence>
          )}
        </Box>
      </StyledMiddleBox>

      <Glass square>
        <Box>{renderBody()}</Box>
      </Glass>
    </Box>
  );
}

export default memo(ChartBuilder);
