import { ApiDimension, ApiMetric } from "@incendium/api";
import { OpenInNew } from "@mui/icons-material";
import {
  alpha,
  Stack,
  styled,
  TableCell,
  TableRow,
  TableRowProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AnalyticsCell,
  formatDimension,
  metricByAttribution,
  metricsByAttribution,
  useFormatMetric,
} from "features/analytics";
import useMetricExplorerNavigation from "features/analytics/hooks/useMetricExplorerNavigation";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";
import { attributesFromChartDataRow } from "features/analytics/utils/utils";
import ProviderIcon from "../components/ProviderIcon";
import { HTMLMotionProps, motion } from "framer-motion";
import { formatPercentage, percentageChange } from "Helpers/percentage";
import { TFramerAnimationProps } from "Interfaces";
import { useCallback, useMemo, useState } from "react";

interface IStyledTableRowProps
  extends TableRowProps,
    Pick<HTMLMotionProps<any>, TFramerAnimationProps> {
  hovered?: boolean;
  actionHovered?: boolean;
  active?: boolean;
}
const StyledTableRow = styled(TableRow)<IStyledTableRowProps>(
  ({ theme, hovered, actionHovered, active }) => ({
    background: active
      ? theme.palette.secondary.main
      : actionHovered
      ? alpha(theme.palette.primary.main, 0.8)
      : hovered
      ? alpha(theme.palette.secondary.main, 0.8)
      : undefined,
    "& .MuiTableCell-root": {
      color: hovered ? "white" : undefined,
    },
  })
);
const StyledTableCell = styled(TableCell)<{
  clickable?: boolean;
  active?: boolean;
}>(({ theme, clickable, active }) => ({
  cursor: clickable ? "pointer" : undefined,
  color: active ? " white" : theme.palette.secondary.main,
}));

interface ICampaignAnalysisTableRowProps {
  current: boolean;
  data: any;
  firstCols: { key: ApiDimension; text: string }[];
  onClick?: (v: string) => void;
  onMetricClick?: (m: ApiMetric, d: ApiDimension, dv: string) => void;
  publisherConversion: ApiMetric;
  attributionModel: AttributionType;
  conversionMetric: AttributionMetric;
  selecteValues: string[];
  dimension: ApiDimension;
  showProviderIcon?: boolean;
}

function CampaignAnalysisTableRow({
  current,
  data,
  firstCols,
  onClick,
  onMetricClick,
  publisherConversion,
  attributionModel,
  conversionMetric,
  selecteValues,
  dimension,
  showProviderIcon,
}: ICampaignAnalysisTableRowProps) {
  const [hovered, setHovered] = useState(false);
  const [actionHovered, setActionHovered] = useState(false);
  const formatMetric = useFormatMetric();
  const onMetricStandardClick = useMetricExplorerNavigation();

  const dimensionText = useCallback(
    (dimension: ApiDimension) => {
      return data[dimension];
    },
    [data]
  );

  const difference = useCallback(
    (compareValue: number, currentValue: number) => {
      const change = percentageChange(compareValue, currentValue);
      return formatPercentage(change);
    },
    []
  );

  const attributionMetric = useMemo(() => {
    return (
      metricByAttribution(attributionModel, conversionMetric) ||
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT
    );
  }, [attributionModel, conversionMetric]);

  const getValue = useCallback(
    (metric: ApiMetric) => {
      return formatMetric(metric, Number(data[metric] || 0));
    },
    [data]
  );

  const getDifference = useCallback(
    (metrics: ApiMetric[]) => {
      const value = Number(data[metrics[1]] || 0);
      return difference(Number(data[metrics[0]] || 0), Number(value));
    },
    [data, difference]
  );

  return current ? (
    <StyledTableRow
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key={`${dimensionText(firstCols[0].key)}`}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      hovered={hovered}
      actionHovered={actionHovered}
    >
      {firstCols.map((firstCol) => (
        <StyledTableCell
          align="center"
          onClick={(e) => {
            e.preventDefault();
            onClick && onClick(`${dimensionText(firstCol.key)}`);
          }}
          onMouseOver={() => onClick && setActionHovered(true)}
          onMouseLeave={() => onClick && setActionHovered(false)}
          clickable={typeof onClick !== "undefined"}
        >
          <Stack direction={"row"} spacing={1} alignItems="center">
            {showProviderIcon &&
              dimension === ApiDimension.DIMENSION_SOURCE && (
                <ProviderIcon provider={`${dimensionText(firstCol.key)}`} />
              )}
            <strong>
              {formatDimension(firstCol.key, `${dimensionText(firstCol.key)}`)}
            </strong>
          </Stack>
        </StyledTableCell>
      ))}

      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(ApiMetric.METRIC_PUBLISHER_CLICKS)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_PUBLISHER_CLICKS,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Publisher Clicks"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={formatMetric(
            ApiMetric.METRIC_SESSIONS_COUNT,
            Number(data[ApiMetric.METRIC_SESSIONS_COUNT] || 0)
          )}
          onClick={
            onMetricClick
              ? [
                  {
                    text: "View in Explorer",
                    fn: () =>
                      onMetricStandardClick(
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        attributesFromChartDataRow(data)
                      ),
                  },
                  {
                    text: "Click To See Visits",
                    fn: () =>
                      onMetricClick(
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        dimension,
                        `${dimensionText(firstCols[0].key)}`
                      ),

                    icon: <OpenInNew />,
                  },
                ]
              : [
                  {
                    text: "View in Explorer",
                    fn: () =>
                      onMetricStandardClick(
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        attributesFromChartDataRow(data)
                      ),
                  },
                ]
          }
          headerName={"Actual Sessions"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        {getDifference([
          ApiMetric.METRIC_PUBLISHER_CLICKS,
          ApiMetric.METRIC_SESSIONS_COUNT,
        ])}
      </TableCell>

      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(ApiMetric.METRIC_PUBLISHER_IMPRESSIONS)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_PUBLISHER_IMPRESSIONS,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Impressions"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(ApiMetric.METRIC_PUBLISHER_CTR)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_PUBLISHER_CTR,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Publisher CTR"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(ApiMetric.METRIC_CALCULATED_CTR)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_CALCULATED_CTR,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Calculated CTR"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(ApiMetric.METRIC_PUBLISHER_CPC)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_PUBLISHER_CPC,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"CPCs"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(ApiMetric.METRIC_SPEND)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_SPEND,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Total Spend"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION
          )}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Avg. Time on Page"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(ApiMetric.METRIC_EFFECTIVE_SESSION_RATE)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Effective Session Rate"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(
            ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT
          )}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Interaction Conv."}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(publisherConversion)}
          onClick={[
            {
              text: "View in Explorer",
              fn: () =>
                onMetricStandardClick(
                  publisherConversion,
                  attributesFromChartDataRow(data)
                ),
            },
          ]}
          headerName={"Publisher Conversions"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
      <TableCell align="center">
        <AnalyticsCell
          dimensionName={dimensionText(firstCols[0].key)}
          valueFormatted={getValue(attributionMetric)}
          onClick={
            onMetricClick &&
            !metricsByAttribution(AttributionMetric.MACRO).includes(
              attributionMetric
            )
              ? [
                  {
                    text: "View in Explorer",
                    fn: () =>
                      onMetricStandardClick(
                        attributionMetric,
                        attributesFromChartDataRow(data)
                      ),
                  },
                  {
                    text: "Click To See Last Click Sales",
                    fn: () =>
                      onMetricClick(
                        attributionMetric,
                        dimension,
                        `${dimensionText(firstCols[0].key)}`
                      ),
                    icon: <OpenInNew />,
                  },
                ]
              : [
                  {
                    text: "View in Explorer",
                    fn: () =>
                      onMetricStandardClick(
                        attributionMetric,
                        attributesFromChartDataRow(data)
                      ),
                  },
                ]
          }
          headerName={"Actual Sales & Conversions"}
          isDimension={false}
          sx={{ color: "inherit", fontSize: 11 }}
        />
      </TableCell>
    </StyledTableRow>
  ) : (
    <StyledTableRow
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key={`${dimensionText(firstCols[0].key)}`}
      hovered={hovered}
      active={selecteValues.includes(`${dimensionText(firstCols[0].key)}`)}
    >
      {firstCols.map((firstCol) => (
        <Tooltip
          arrow
          key={firstCol.key}
          title={formatDimension(dimension, `${dimensionText(firstCol.key)}`)}
        >
          <StyledTableCell
            onClick={() => onClick && onClick(`${dimensionText(firstCol.key)}`)}
            clickable={typeof onClick !== "undefined"}
            active={selecteValues.includes(`${dimensionText(firstCol.key)}`)}
            onMouseOver={() => onClick && setHovered(true)}
            onMouseLeave={() => onClick && setHovered(false)}
          >
            <Typography
              variant="body2"
              fontWeight={"bold"}
              color="inherit"
              noWrap
            >
              {formatDimension(dimension, `${dimensionText(firstCol.key)}`)}
            </Typography>
          </StyledTableCell>
        </Tooltip>
      ))}
    </StyledTableRow>
  );
}

export default CampaignAnalysisTableRow;
