import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  BoxProps,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import Loading from "Components/Loading/Loading";
import { useLocalstorageState } from "Hooks/useLocalstorageState";
import { useUpdateEffect } from "react-use";

export const StyledAccordianSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  minHeight: 16,
  padding: 0,
  "&.Mui-expanded": {
    marginBottom: theme.spacing(2),
  },
  transition: `margin-bottom 400ms cubic-bezier(0.4, 0, 0.2, 1), min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    marginLeft: theme.spacing(2),
  },
}));

interface IAccordianGlassCardProps extends Omit<BoxProps, "title"> {
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  defaultClosed?: boolean;
  onOpenChange?: (b: boolean) => void; //event fired when opened and closed
  icon?: React.ComponentType<any>;
  localStorageKey?: string;
}

function AccordianGlassCard({
  title,
  subTitle,
  defaultClosed,
  icon: Icon,
  onOpenChange,
  localStorageKey,
  children,
}: IAccordianGlassCardProps) {
  const [open, setOpen, isLoaded] = useLocalstorageState<boolean>(
    `${localStorageKey}`,
    typeof defaultClosed !== "undefined" ? !defaultClosed : true,
    typeof localStorageKey === "undefined"
  );

  useUpdateEffect(() => {
    if (onOpenChange) {
      onOpenChange(open);
    }
  }, [open]);

  return (
    <GlassCard>
      {!isLoaded ? (
        <Loading />
      ) : (
        <Accordion
          disableGutters
          sx={{ background: "transparent", padding: 0 }}
          elevation={0}
          expanded={open}
          onChange={() => {
            setOpen(!open);
          }}
        >
          <StyledAccordianSummary>
            <Stack
              sx={{ width: "100%" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Box sx={{ width: "100%" }}>
                {typeof title === "function" ? (
                  title
                ) : (
                  <Typography variant="subtitle1">{title}</Typography>
                )}
                {subTitle && typeof subTitle === "function" ? (
                  subTitle
                ) : (
                  <Typography variant="body2">{subTitle}</Typography>
                )}
              </Box>
              {Icon && <Icon />}
            </Stack>
          </StyledAccordianSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box mt={1}>{children}</Box>
          </AccordionDetails>
        </Accordion>
      )}
    </GlassCard>
  );
}

export default AccordianGlassCard;
