import { Grid } from "@mui/material";
import { table5Rows } from "features/analytics";
import {
  ApiChartLayout,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";

function AudienceTrafficAndConversionsTab() {
  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={8} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "Session & Engagement Levels by Page Location",
            dimension: [ApiDimension.DIMENSION_LOCATION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE],
                chart: {
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE]:
                    ApiYAxisChartType.DOT,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              metricsOrder: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsCard
          chart={{
            name: "Audience Page Locations Composition",
            dimension: [ApiDimension.DIMENSION_LOCATION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              showTotals: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={8} sx={{ height: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "Top 10 Interactions by Page Locations",
            dimension: [ApiDimension.DIMENSION_MICRO_CONVERSION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            limit: 10,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT],
                chart: {
                  [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              layout: ApiChartLayout.VERTICAL,
              filterDropdowns: [
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
                ApiDimension.DIMENSION_LOCATION,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "Audience Interaction Composition",
            dimension: [ApiDimension.DIMENSION_MICRO_CONVERSION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            limit: 15,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT],
                chart: {
                  [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
              filterDropdowns: [
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
                ApiDimension.DIMENSION_LOCATION,
              ],
            },
          }}
        />
      </Grid>

      <Grid item xs={8} sx={{ minHeight: 590 }}>
        <AnalyticsCard
          chart={{
            name: "Audience Page Locations & Interactions Stats",
            dimension: [
              ApiDimension.DIMENSION_LOCATION,
              ApiDimension.DIMENSION_MICRO_CONVERSION,
            ],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 590 }}>
        <AnalyticsCard
          chart={{
            name: "Day of Week Primary Stats by Audience",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_WEEKDAY],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                ],
              },
            ],
            orderBy: `${ApiDimension.DIMENSION_SESSION_START_BY_WEEKDAY}`,
            displayOptions: {
              noFilters: true,
              filterDropdowns: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default AudienceTrafficAndConversionsTab;
