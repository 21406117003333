import {
  ApiChartDisplayOptions,
  ApiChartTemplate,
  ApiChartType,
  ApiMetric,
} from "@incendium/api";
import { Box, styled } from "@mui/material";
import { AnalyticsChart } from "features/analytics";
import { useExplorerContext } from "features/explorer/providers/ExplorerProvider";
import { useMemo } from "react";

const StyledWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  marginRight: `20px`,
  "& > .MuiBox-root": {
    height: "calc(100% - 32px)",
    transform: "translate(14px, -12px)!important",
  },
}));

interface IExplorerTableProps {
  onClick?: { [field: string]: (v: string, o?: string | undefined) => void };
  metrics: ApiMetric[];
  limit?: number;
  colWidths?: { [key: string]: number };
}

function ExplorerTable({
  onClick,
  metrics,
  limit,
  colWidths,
}: IExplorerTableProps) {
  const { dimension, selectedRanks, having, attributes } = useExplorerContext();

  const displayOptions = useMemo(() => {
    const o: ApiChartDisplayOptions = {
      noFilters: true,
      noTitle: true,
      noAttributionToggle: true,
      showTotals: true,
      rowsPerPage: 20,
    };
    if (colWidths) {
      o.tableColWidths = colWidths;
    }
    return o;
  }, [colWidths]);

  return (
    <StyledWrapper>
      <AnalyticsChart
        chart={{
          name: "",
          dimension: [dimension],
          template: ApiChartTemplate.TABLE,
          type: ApiChartType.TABLE,
          attributes,
          ranks: selectedRanks,
          having: having,
          comparison: true,
          limit: limit,
          includeEmpty: true,
          orderBy: `${
            metrics.includes(ApiMetric.METRIC_RANK)
              ? ApiMetric.METRIC_RANK
              : metrics[0]
          } desc`,
          yAxisKeys: [
            {
              key: "l",
              fields: metrics,
            },
          ],
          displayOptions,
        }}
        onClick={onClick ? onClick : undefined}
      />
    </StyledWrapper>
  );
}

export default ExplorerTable;
