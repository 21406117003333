import { Grid } from "@mui/material";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import AnalyticsChartStatCard from "features/analytics/components/AnalyticsChartStatCard";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";

function SiteOverview() {
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                ],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              statTitle: "Total Conversions",
              statTitle2: "Conversion Rate",
            },
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Sitewide Traffic Trend",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Sitewide Engagement Trend",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                ],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Sitewide Conversion Trend",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: "Channel Performance by",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              showTotals: true,
              availableMetrics: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
                ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{ height: 450 }}>
        <AnalyticsCard
          chart={{
            name: "Top 5 Channels by",
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_CHANNEL,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            limit: 5,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              availableMetrics: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
                ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{ height: 450 }}>
        <AnalyticsCard
          chart={{
            name: "Primary Metrics by User Type",
            dimension: [ApiDimension.DIMENSION_USER_TYPE],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            limit: 10,
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_LEADS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Site Visitors by User Type",
            dimension: [ApiDimension.DIMENSION_USER_TYPE],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            displayOptions: {
              showTotals: true,
              pieTotalText: "Total Leads",
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_LEADS_COUNT],
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}

export default SiteOverview;
