import { ApiAPITokenResponse } from "@incendium/api";
import { Assignment, AssignmentTurnedIn } from "@mui/icons-material";
import {
  Button,
  DrawerProps,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import StyledDrawer from "Components/UI/StyledDrawer";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import { useState } from "react";

const StyledCopyButton = styled(Button)(({}) => ({
  minWidth: "auto",
  whiteSpace: "nowrap",
}));

interface IAPITokenDrawerProps extends DrawerProps {
  token?: ApiAPITokenResponse;
}

function APITokenDrawer({
  open,
  onClose,
  token,
  children,
}: IAPITokenDrawerProps) {
  const [tokenCopied, setTokenCopied] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  return (
    <StyledDrawer open={open} onClose={onClose}>
      {!!token && (
        <>
          <AccordianChartBuilderSidebarBlock
            title="Webhook URL and Token"
            subTitle="Use this URL / token to setup your webhook"
          >
            {token.url && (
              <Stack>
                <Typography variant="subtitle2">URL</Typography>

                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems="center"
                  spacing={1}
                >
                  <Typography noWrap variant="caption">
                    {token.url}
                  </Typography>
                  <StyledCopyButton
                    size="extraSmall"
                    onClick={() => {
                      setUrlCopied(true);
                      navigator.clipboard.writeText(token.url!);
                    }}
                    endIcon={
                      urlCopied ? <AssignmentTurnedIn /> : <Assignment />
                    }
                  >
                    Copy URL
                  </StyledCopyButton>
                </Stack>
              </Stack>
            )}
            <Stack>
              <Typography variant="subtitle2">Token</Typography>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                spacing={1}
              >
                <Tooltip title={token.value} arrow>
                  <Typography noWrap variant="caption">
                    {token.value}
                  </Typography>
                </Tooltip>

                <StyledCopyButton
                  size="extraSmall"
                  onClick={() => {
                    setTokenCopied(true);
                    navigator.clipboard.writeText(token?.value || "");
                  }}
                  endIcon={
                    tokenCopied ? <AssignmentTurnedIn /> : <Assignment />
                  }
                >
                  Copy Token
                </StyledCopyButton>
              </Stack>
            </Stack>
          </AccordianChartBuilderSidebarBlock>
          {children}
        </>
      )}
    </StyledDrawer>
  );
}

export default APITokenDrawer;
