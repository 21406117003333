import {
  Grid,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import { useSnackbar } from "notistack";
import { alertService } from "Apis";
import { useSelectedProject } from "Hooks";
import { ApiAlert } from "@incendium/api";
import { useHistory } from "react-router-dom";
import { useAlerts } from "Hooks/useAlerts";
import produce from "immer";
import SpacedList from "Components/UI/SpacedList";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useConfirmation } from "Hooks/useConfirmation";
import { cell2Icons } from "consts";
import withPagetitle, { IWithPagetitleProps } from "HoC/withPagetitle";

function AlertsPageInner() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedProject: project } = useSelectedProject();
  const { alerts, setAlerts } = useAlerts();
  const [open, setOpen] = useState(false);
  const handleDelete = useConfirmation();

  const onSave = async (name: string) => {
    const payload = {
      name,
    };
    const res = await alertService.alertServiceCreateAlert({
      projectId: project?.id as number,
      payload,
    });

    setAlerts(
      produce(alerts, (draft) => {
        draft.push(res);
      })
    );

    enqueueSnackbar(`Alert (${name}) Created, redirecting`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
    onEdit(res);
  };

  const onEdit = (alert: ApiAlert) => {
    history.push(
      `/clients/${project?.clientId}/projects/${project?.id}/train/alerts/${alert.id}`
    );
  };

  const onDelete = (alert: ApiAlert) => {
    handleDelete({
      title: `Are you sure you want to delete this alert`,
      body: "This action can not be undone",
      callback: async () => {
        await alertService.alertServiceDeleteAlert({
          projectId: project?.id as number,
          alertId: alert.id as number,
        });

        setAlerts(
          produce(alerts, (draft) => {
            const idx = alerts.findIndex((a) => alert.id === a.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );
        return `Alert (${alert.name}) Deleted`;
      },
    });
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={12}>
          <SpacedList
            title="Alerts List"
            action={<Button onClick={() => setOpen(true)}>New Alert</Button>}
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts.map((alert) => (
                <TableRow key={alert.id}>
                  <TableCell>{alert.name}</TableCell>
                  <TableCell align="right" style={{ width: cell2Icons }}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => onEdit(alert)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton size="small" onClick={() => onDelete(alert)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </SpacedList>
        </Grid>
      </Grid>
      <CreateNameDialog
        open={open}
        setOpen={setOpen}
        onSaved={onSave}
        title="Add Alert Name"
      />
    </>
  );
}

const EnhancedComponent = withPagetitle(
  AlertsPageInner
) as React.ComponentType<IWithPagetitleProps>;

function AlertsPage() {
  const { selectedProject } = useSelectedProject();
  return (
    <EnhancedComponent
      title={`Manage ${selectedProject?.name} Alerts`}
      subTitle="Create Alerts to inform your self of unusual behaviour on your site."
    />
  );
}

export default AlertsPage;
