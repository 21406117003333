import { Box } from "@mui/material";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { StatCardRound, StatCardRoundTypography } from "Components/UI/StatCard";
import { IChart, TChartData } from "Interfaces";
import {
  formatPercentage,
  percentageChange,
  percentageOf,
} from "Helpers/percentage";
import { useCallback, useMemo, useState } from "react";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";

function JobTopCategoryComp() {
  const [currentChartData, setCurrentChartData] = useState<TChartData[] | null>(
    null
  );
  const [compChartData, setCompChartData] = useState<TChartData[] | null>(null);

  const chart: IChart = useMemo(
    () => ({
      name: "Top Job Categories Comparison",
      description: "See which job categories performed best",
      dimension: [ApiDimension.DIMENSION_JOB_CATEGORY],
      yAxisKeys: [
        {
          key: "l",
          fields: [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT],
          chart: {
            [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]:
              ApiYAxisChartType.BAR,
          },
        },
      ],
      attributes: [],
      template: ApiChartTemplate.SNAPSHOT,
      type: ApiChartType.GRAPH,
    }),
    []
  );

  const onDataChange = useCallback((a, b) => {
    setCurrentChartData(a);
    setCompChartData(b);
  }, []);

  const chartData: TChartData[] = useMemo(() => {
    if (!currentChartData) {
      return [];
    }

    return (currentChartData || []).map((d) => {
      const o: TChartData = {
        name: d.name || "",
        current: d[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT],
      };

      const f = (compChartData || []).find((v) => v.name === d.name);
      if (f) {
        o.previous = f[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT];
      }
      return o;
    });
  }, [currentChartData, compChartData]);

  const topApplicationsCurrent = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.current as number) || 0) - ((a.current as number) || 0)
      )[0],
    [chartData]
  );
  const topApplicationsPrevious = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.previous as number) || 0) - ((a.previous as number) || 0)
      )[0],
    [chartData]
  );

  const topShareOfApplications = useMemo(() => {
    const total = chartData.reduce((agg, v) => agg + (v.current as number), 0);
    return percentageOf(
      (topApplicationsCurrent?.current as number) || 0,
      total
    );
  }, [topApplicationsCurrent, chartData]);

  const biggestDifference = useMemo(() => {
    return chartData
      .filter((v) => v.current || v.previous)
      .map((v) => {
        const diff = percentageChange(
          (v.previous as number) || 0,
          (v.current as number) || 0
        );
        return {
          ...v,
          diff,
        };
      })
      .sort((a, b) => {
        return b.diff - a.diff;
      })[0];
  }, [chartData]);

  return (
    <AnalyticsCard
      chart={chart}
      onDataChange={onDataChange}
      customFullRight={
        <Box
          sx={{
            minWidth: 330,
            height: 390,
            position: "relative",
          }}
        >
          <StatCardRound
            size={217}
            sx={{ position: "absolute", top: 5, left: -20 }}
          >
            <StatCardRoundTypography size="small">
              Looks like
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={2}>
              {topApplicationsCurrent?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small" lines={3}>
              {topApplicationsCurrent?.name ===
              topApplicationsPrevious?.name ? (
                <>performed best</>
              ) : (
                <>
                  overtook <br /> {topApplicationsPrevious?.name}
                </>
              )}
              <br /> in this period
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={179}
            backgroundColor={2}
            sx={{ position: "absolute", bottom: 0, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              Applications from
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={1}>
              {biggestDifference?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              {biggestDifference?.diff >= 0 ? "increased" : "decreased"} by
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(Math.abs(biggestDifference?.diff))}
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={155}
            backgroundColor={1}
            sx={{ position: "absolute", top: 217 - 155 + 5, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              generating
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(topShareOfApplications)}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              of the total applications
            </StatCardRoundTypography>
          </StatCardRound>
        </Box>
      }
    />
  );
}

export default JobTopCategoryComp;
