import { ApiProject } from "@incendium/api";
import { Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { AllProjectsSelector } from "features/project";
import { useSelectedProject } from "Hooks";
import { useMemo, useState } from "react";

import { Redirect, useHistory } from "react-router-dom";
import { useUpdateEffect } from "react-use";

function AccountPage() {
  const history = useHistory();
  const { selectedProject, lastSelectedProject } = useSelectedProject();
  const [projectSelection, setProjectSelection] = useState<ApiProject | null>(
    null
  );

  useUpdateEffect(() => {
    if (!projectSelection) {
      return;
    }
    history.push(
      `/clients/${projectSelection.clientId}/projects/${projectSelection.id}/account/payment`
    );
  }, [projectSelection]);

  const project = useMemo(() => {
    return selectedProject || lastSelectedProject;
  }, [selectedProject, lastSelectedProject]);

  // todo: when we have more than 1 option in accounts page will be accounts page
  if (project) {
    return (
      <Redirect
        to={`/clients/${project.clientId}/projects/${project.id}/account/payment`}
      />
    );
  }
  return (
    <CenterPage calcHeight>
      <Typography variant="subtitle1" mb={2}>
        Which Project would you like to manage?
      </Typography>
      <AllProjectsSelector
        project={projectSelection}
        setProject={setProjectSelection}
        variant="outlined"
        size="medium"
        sx={{ width: 300 }}
      />
    </CenterPage>
  );
}

export default AccountPage;
