import {
  ApiChartTemplate,
  ApiChartType,
  ApiConditionCustom,
  ApiDimension,
  ApiEngagementRule,
  ApiMetric,
} from "@incendium/api";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { useSelectedProject } from "Hooks";
import { useEngagements } from "Hooks/useEngagements";
import React, { useEffect, useLayoutEffect, useState } from "react";
import produce from "immer";
import { useSnackbar } from "notistack";
import { formatEnumVal } from "Helpers/enumToText";
import { engagementService } from "Apis";
import { useHistory } from "react-router-dom";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import { Box } from "@mui/system";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";
import { AnalyticsStatCard, useFormatMetric } from "features/analytics";
import MaxLeadScoreCard from "Components/MaxLeadScoreCard/MaxLeadScoreCard";
import LeadScoreAdjustmentCard from "Components/LeadScoreAdjustment/LeadScoreAdjustmentCard";
import { useLayoutContext } from "Providers/LayoutProvider";
import { friendlyOperator } from "Helpers/operators";
import { StatCardTypography } from "Components/UI/StatCard";
import { useFromToContext } from "Providers/FromToProvider";
import { useConfirmation } from "Hooks/useConfirmation";
import { cell1Icon } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";

function LeadScoringPage() {
  const { selectedProject } = useSelectedProject();
  const { engagements, setEngagements } = useEngagements();
  const [open, setOpen] = useState(false);
  const handleDelete = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { setPageTitle, setTitleAction } = useLayoutContext();
  const { setLastNDays } = useFromToContext();
  const formatMetric = useFormatMetric();

  useEffect(() => {
    setLastNDays(2000);
    return () => {
      setLastNDays(30);
    };
  }, []);

  useLayoutEffect(() => {
    setPageTitle(
      <Box display="flex" alignItems="baseline" mb={2}>
        <Typography variant="h1" display="inline">
          Lead Scoring
        </Typography>
        <Box ml={1} display="flex" alignItems="center">
          <ClickableTooltip
            placement="right"
            text={
              "When a visitor browses and interacts with your website, you can record points against certain important actions to give you an idea of how warm, or hot, a lead is. You can use this to find your top leads at any given time, or trigger notifications to your sales team to get in touch with hot leads proactively."
            }
            icon={<StyledHelp />}
          />
        </Box>
      </Box>
    );
    return () => {
      setPageTitle("");
      setTitleAction(undefined);
    };
  }, [selectedProject]);

  const newEngagement = () => {
    setOpen(true);
  };

  const onEdit = (engagement: ApiEngagementRule) => {
    history.push(
      `/clients/${selectedProject?.clientId}/projects/${selectedProject?.id}/train/lead-scoring/${engagement.id}`
    );
  };

  const onDelete = (engagement: ApiEngagementRule) => {
    handleDelete({
      title: `Are you sure you want to delete this engagement`,
      body: `This action will remove this engagement metric from analytics`,
      callback: async () => {
        await engagementService.engagementServiceDeleteEngagement({
          projectId: selectedProject?.id as number,
          engagementId: engagement.id as number,
        });

        setEngagements(
          produce(engagements, (draft) => {
            const idx = engagements.findIndex((e) => e.id === engagement.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );

        return "Engagement Deleted";
      },
    });
  };

  const onSaved = async (name: string) => {
    const payload = {
      name,
    };
    const res = await engagementService.engagementServiceCreateEngagement({
      projectId: selectedProject?.id as number,
      payload,
    });

    setEngagements(
      produce(engagements, (draft) => {
        draft.push(res);
      })
    );

    enqueueSnackbar(`Engagement (${name}) Created, redirecting`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
    onEdit(res);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={7} container spacing={3}>
          <Grid item xs={5}>
            <Stack spacing={2}>
              <AnalyticsStatCard
                minHeight={100}
                chart={{
                  name: "",
                  dimension: [],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_AVERAGE_LEAD_SCORE],
                    },
                  ],
                }}
                renderBody={(data) => (
                  <>
                    <StatCardTypography size="xs" opactity={0.8}>
                      Avg. Lead Score
                    </StatCardTypography>
                    <StatCardTypography size="large" lines={1}>
                      {formatMetric(
                        ApiMetric.METRIC_AVERAGE_LEAD_SCORE,
                        Number(data[0][ApiMetric.METRIC_AVERAGE_LEAD_SCORE])
                      )}
                    </StatCardTypography>
                  </>
                )}
              />
              <AnalyticsStatCard
                minHeight={100}
                chart={{
                  name: "",
                  dimension: [],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_LEADS_COUNT_WITH_LEADS_SCORE],
                    },
                  ],
                }}
                renderBody={(data) => (
                  <>
                    <StatCardTypography size="xs" opactity={0.8}>
                      No. Scored Leads
                    </StatCardTypography>
                    <StatCardTypography size="large" lines={1}>
                      {formatMetric(
                        ApiMetric.METRIC_LEADS_COUNT_WITH_LEADS_SCORE,
                        Number(
                          data[0][ApiMetric.METRIC_LEADS_COUNT_WITH_LEADS_SCORE]
                        )
                      )}
                    </StatCardTypography>
                  </>
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={7}>
            <AnalyticsCard
              chart={{
                name: `score by landing page`,
                dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.PIE,
                attributes: [],
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_AVERAGE_LEAD_SCORE],
                  },
                ],
                displayOptions: {
                  noFilters: true,
                  noTitle: true,
                  showTotals: true,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <MaxLeadScoreCard />
        </Grid>

        <Grid item xs={12}>
          <IndentDivider />
        </Grid>
        <Grid item xs={12} container spacing={4}>
          <Grid item xs={4}>
            <LeadScoreAdjustmentCard />
          </Grid>
          <Grid item xs={8}>
            <Stack spacing={2} alignItems={"flex-end"}>
              <Button
                variant="contained"
                color="primary"
                onClick={newEngagement}
              >
                New Pageview Lead Scoring
              </Button>
              <Box sx={{ width: "100%" }}>
                <SpacedList isEmpty={engagements.length === 0}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Pageview Rules</TableCell>
                      <TableCell>Locations</TableCell>
                      <TableCell>Score / Max Score</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(engagements || []).map((e) => (
                      <TableRow key={e.id}>
                        <TableCell>
                          <SpacedLinkButton onClick={() => onEdit(e)}>
                            {e.name || ""}
                          </SpacedLinkButton>
                        </TableCell>
                        <TableCell>
                          {(e.conditionGroups || []).map((group, idx) => {
                            const rules = group?.conditions || [];
                            return (
                              <div key={group.id}>
                                {rules.map((rule, i) => (
                                  <React.Fragment key={rule.id}>
                                    {rule.custom !==
                                    ApiConditionCustom.UNSPECIFIED ? (
                                      <Typography
                                        display="inline"
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        <Typography
                                          component={"span"}
                                          variant="caption"
                                          color="textSecondary"
                                          fontWeight={"bold"}
                                        >
                                          {formatEnumVal(
                                            rule.custom?.replace(
                                              "CONDITION",
                                              ""
                                            ) as string
                                          )}
                                        </Typography>{" "}
                                        {friendlyOperator(rule.operator)}{" "}
                                        <Typography
                                          component={"span"}
                                          variant="caption"
                                          color="textSecondary"
                                          fontWeight={"bold"}
                                        >
                                          {rule.value}
                                        </Typography>
                                      </Typography>
                                    ) : (
                                      <>
                                        <Typography
                                          display="inline"
                                          variant="caption"
                                          color="textSecondary"
                                          fontWeight={"bold"}
                                        >
                                          {rule.conversion?.name}
                                        </Typography>
                                        <Typography
                                          display="inline"
                                          variant="caption"
                                          color="textSecondary"
                                        >
                                          {" fired"}
                                        </Typography>
                                      </>
                                    )}

                                    {i + 1 < rules.length && (
                                      <Typography
                                        display="inline"
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        {" & "}
                                      </Typography>
                                    )}
                                  </React.Fragment>
                                ))}

                                {idx + 1 < (e.conditionGroups || []).length && (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    -- Or --
                                  </Typography>
                                )}
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell>
                          {e.locations?.map((l, i) => (
                            <span key={l.id}>
                              {l.name}
                              {i + 1 < (e?.locations || []).length && ", "}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell>
                          {e.score} - {e.maxScore || "N/A"}
                        </TableCell>

                        <TableCell align="right" style={{ width: cell1Icon }}>
                          <IconButton size="small" onClick={() => onDelete(e)}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </SpacedList>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <CreateNameDialog
        open={open}
        setOpen={setOpen}
        title="Name the Pageview Lead Scoring Rule"
        onSaved={onSaved}
      />
    </Box>
  );
}
export default LeadScoringPage;
