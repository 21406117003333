import { ApiCurrency } from "@incendium/api";
import { Autocomplete, TextField, StandardTextFieldProps } from "@mui/material";
import { enumToArray } from "Helpers/enumToText";
import { CallbackOrVal } from "Interfaces";
import { useMemo } from "react";
import currencyData from "../currencys.json";

interface ICurrencyPickerProps extends StandardTextFieldProps {
  currency: ApiCurrency | undefined;
  setCurrency: (v: CallbackOrVal<ApiCurrency>) => void;
}

function CurrencyPicker({
  currency,
  setCurrency,
  ...rest
}: ICurrencyPickerProps) {
  const options = useMemo(() => {
    return enumToArray(ApiCurrency).filter((c) => !!currencyData[c]);
  }, []);

  const selectedValue = useMemo(() => {
    return currency
      ? currencyData[currency]
        ? `${currencyData[currency].symbol} (${currencyData[currency].name})`
        : currency
      : "";
  }, [currency]);

  return (
    <Autocomplete
      id="currency-picker"
      freeSolo
      size="small"
      onChange={(e, value) => setCurrency(value as ApiCurrency)}
      value={selectedValue}
      options={options}
      getOptionLabel={(o) =>
        currencyData[o]
          ? `${currencyData[o].symbol} (${currencyData[o].name})`
          : o
      }
      renderInput={(params) => (
        <TextField {...params} {...rest} variant="outlined" />
      )}
    />
  );
}

export default CurrencyPicker;
