import { ApiMetric } from "@incendium/api";
import { ApiDimension } from "@incendium/api";
import { ContentExplorer } from "features/explorer";
import { ExplorerProvider } from "features/explorer";
import { TExploreConfig } from "features/explorer/types";
import { useMemo } from "react";

type Tab = "explore";

function ReferralTrafficPage() {
  const config: TExploreConfig<Tab> = useMemo(
    () => ({
      explore: {
        title: "Referral Traffic Explorer",
        desc: `Discover which referrers you visitors are coming from and how they are interacting with your site.`,
        type: "top",
        ranking: [],
        analyseBy: {
          description: "Select between referrer or its domain.",
          grouping: [
            {
              option: "Referrer",
              dimensions: [
                ApiDimension.DIMENSION_REFERRER,
                ApiDimension.DIMENSION_REFERRER_DOMAIN,
                ApiDimension.DIMENSION_REFERRER_PATH,
              ],
            },
          ],
        },
      },
    }),
    []
  );

  return (
    <>
      <ExplorerProvider config={config["explore"]}>
        <ContentExplorer
          noTabs
          dimensionNoClick
          metrics={[
            ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
            ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
            ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
            ApiMetric.METRIC_SESSION_REVENUE,
          ]}
          limit={1000}
          colWidths={{
            [ApiDimension.DIMENSION_REFERRER.toString()]: 350,
            [ApiDimension.DIMENSION_REFERRER_DOMAIN.toString()]: 250,
            [ApiDimension.DIMENSION_REFERRER_PATH.toString()]: 250,
          }}
        />
      </ExplorerProvider>
    </>
  );
}

export default ReferralTrafficPage;
