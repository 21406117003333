/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiProjectSubscriptionPaymentFromJSON, ApiProjectSubscriptionPaymentToJSON, } from './ApiProjectSubscriptionPayment';
import { ApiProjectSubscriptionStatusFromJSON, ApiProjectSubscriptionStatusToJSON, } from './ApiProjectSubscriptionStatus';
import { ApiSimpleProjectFromJSON, ApiSimpleProjectToJSON, } from './ApiSimpleProject';
import { ApiSimpleSubscriptionFromJSON, ApiSimpleSubscriptionToJSON, } from './ApiSimpleSubscription';
export function ApiSimpleProjectSubscriptionFromJSON(json) {
    return ApiSimpleProjectSubscriptionFromJSONTyped(json, false);
}
export function ApiSimpleProjectSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subscription': !exists(json, 'subscription') ? undefined : ApiSimpleSubscriptionFromJSON(json['subscription']),
        'project': !exists(json, 'project') ? undefined : ApiSimpleProjectFromJSON(json['project']),
        'status': !exists(json, 'status') ? undefined : ApiProjectSubscriptionStatusFromJSON(json['status']),
        'paymentLink': !exists(json, 'paymentLink') ? undefined : json['paymentLink'],
        'paymentLinkQr': !exists(json, 'paymentLinkQr') ? undefined : json['paymentLinkQr'],
        'seenAt': !exists(json, 'seenAt') ? undefined : (new Date(json['seenAt'])),
        'nextPaymentAt': !exists(json, 'nextPaymentAt') ? undefined : (new Date(json['nextPaymentAt'])),
        'payments': !exists(json, 'payments') ? undefined : (json['payments'].map(ApiProjectSubscriptionPaymentFromJSON)),
    };
}
export function ApiSimpleProjectSubscriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'subscription': ApiSimpleSubscriptionToJSON(value.subscription),
        'project': ApiSimpleProjectToJSON(value.project),
        'status': ApiProjectSubscriptionStatusToJSON(value.status),
        'paymentLink': value.paymentLink,
        'paymentLinkQr': value.paymentLinkQr,
        'seenAt': value.seenAt === undefined ? undefined : (value.seenAt.toISOString()),
        'nextPaymentAt': value.nextPaymentAt === undefined ? undefined : (value.nextPaymentAt.toISOString()),
        'payments': value.payments === undefined ? undefined : (value.payments.map(ApiProjectSubscriptionPaymentToJSON)),
    };
}
