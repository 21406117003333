import { ApiDimension } from "@incendium/api";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";

interface SelectedDimensionContextType {
  selectedDimension: ApiDimension;
  setSelectedDimension: React.Dispatch<React.SetStateAction<ApiDimension>>;
}

const SelectedDimensionContext = createContext<SelectedDimensionContextType>({
  selectedDimension: ApiDimension.DIMENSION_NOT_SET,
  setSelectedDimension: () => {},
});

export const useSelectedDimension = () => useContext(SelectedDimensionContext);

const SelectedDimensionProvider = ({
  children,
  initialDimension,
}: {
  children: ReactNode;
  initialDimension: ApiDimension;
}) => {
  const [selectedDimension, setSelectedDimension] =
    useState<ApiDimension>(initialDimension);
  const contextValue = useMemo(
    () => ({ selectedDimension, setSelectedDimension }),
    [selectedDimension]
  );
  return (
    <SelectedDimensionContext.Provider value={contextValue}>
      {children}
    </SelectedDimensionContext.Provider>
  );
};

export default SelectedDimensionProvider;
