import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiYAxisChartType,
} from "@incendium/api";
import { Settings } from "@mui/icons-material";
import { Button, Grid, Portal, Stack } from "@mui/material";
import Loading from "Components/Loading/Loading";
import AccordianGlassCard from "Components/UI/AccordianGlassCard";
import { AnalyticsSpacing, PageSubAction } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  InsightsCard,
  InsightsBlock,
  useSelectedMetric,
  MetricExplorerDrawer,
  MetricSelector,
  useSelectedAttributes,
} from "features/analytics";
import { SelectedDimensionProvider } from "features/analytics";
import { ChartAttributesSelected } from "features/chartLibrary";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

function MetricExplorer() {
  const { selectedMetric, setSelectedMetric } = useSelectedMetric();
  const { selectedAttributes, setSelectedAttributes } = useSelectedAttributes();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const searchParamsLoaded = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.has("at") && params.has("m");
  }, [location]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!searchParamsLoaded) {
    return <Loading fullHeight />;
  }

  return (
    <>
      <Portal container={() => document.getElementById(PageSubAction)}>
        <Grid container spacing={2} sx={{ width: "100%", marginX: 0 }}>
          <Grid item xs={6}>
            <ChartAttributesSelected
              attributes={selectedAttributes}
              setAttributes={setSelectedAttributes}
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                maxHeight: 80,
                overflowY: "auto",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MetricSelector
              metric={selectedMetric}
              setMetric={setSelectedMetric}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              size="small"
              startIcon={<Settings />}
              onClick={() => setOpen(true)}
              fullWidth
            >
              Configure Explorer
            </Button>
          </Grid>
        </Grid>
      </Portal>

      {selectedMetric ? (
        <Stack spacing={2}>
          <AccordianGlassCard
            title="Overview"
            localStorageKey="metric-explorer-overview"
          >
            <Grid container spacing={AnalyticsSpacing} mb={4}>
              <Grid item xs={8} sx={{ minHeight: 400 }}>
                <AnalyticsCard
                  chart={{
                    name: "Over Time",
                    dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
                    template: ApiChartTemplate.TREND,
                    type: ApiChartType.GRAPH,
                    attributes: selectedAttributes || [],
                    comparison: true,
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [selectedMetric],
                      },
                    ],
                    displayOptions: {
                      noAttributionToggle: true,
                      disabledMetricClick: true,
                      noFilters: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <AnalyticsCard
                  chart={{
                    name: "By Week",
                    dimension: [ApiDimension.DIMENSION_SESSION_START_BY_WEEK],
                    template: ApiChartTemplate.TREND,
                    type: ApiChartType.GRAPH,
                    comparison: true,
                    attributes: selectedAttributes || [],
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [selectedMetric],
                        chart: {
                          [selectedMetric]: ApiYAxisChartType.BAR,
                        },
                      },
                    ],
                    displayOptions: {
                      noAttributionToggle: true,
                      disabledMetricClick: true,
                      noFilters: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4} sx={{ minHeight: 400 }}>
                <AnalyticsCard
                  chart={{
                    name: "New vs Returning",
                    dimension: [ApiDimension.DIMENSION_USER_TYPE],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: selectedAttributes || [],
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [selectedMetric],
                        chart: {
                          [selectedMetric]: ApiYAxisChartType.BAR,
                        },
                      },
                    ],
                    displayOptions: {
                      noAttributionToggle: true,
                      disabledMetricClick: true,
                      noFilters: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={8} sx={{ minHeight: 400 }}>
                <AnalyticsCard
                  chart={{
                    name: "By Channel",
                    dimension: [ApiDimension.DIMENSION_CHANNEL],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: selectedAttributes || [],
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [selectedMetric],
                        chart: {
                          [selectedMetric]: ApiYAxisChartType.BAR,
                        },
                      },
                    ],
                    displayOptions: {
                      noAttributionToggle: true,
                      disabledMetricClick: true,
                      noFilters: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </AccordianGlassCard>
          <SelectedDimensionProvider
            initialDimension={ApiDimension.DIMENSION_CHANNEL}
          >
            <InsightsCard
              title="Traffic Source Insights"
              dimensions={[
                ApiDimension.DIMENSION_CHANNEL,
                ApiDimension.DIMENSION_SOURCE,
                ApiDimension.DIMENSION_MEDIUM,
                ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                ApiDimension.DIMENSION_CONTENT,
                ApiDimension.DIMENSION_TERM,
              ]}
              body={<InsightsBlock />}
            />
          </SelectedDimensionProvider>

          <SelectedDimensionProvider
            initialDimension={ApiDimension.DIMENSION_LANDING_PAGE_LOCATION}
          >
            <InsightsCard
              title="Content Insights"
              dimensions={[
                ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
                ApiDimension.DIMENSION_LOCATION,
                ApiDimension.DIMENSION_LANDING_PAGE_PATH,
                ApiDimension.DIMENSION_PATH,
              ]}
              body={<InsightsBlock />}
            />
          </SelectedDimensionProvider>

          <SelectedDimensionProvider
            initialDimension={ApiDimension.DIMENSION_DEVICE}
          >
            <InsightsCard
              title="Technology Insights"
              dimensions={[
                ApiDimension.DIMENSION_DEVICE,
                ApiDimension.DIMENSION_BROWSER,
                ApiDimension.DIMENSION_BROWSER_WITH_VERSION,
                ApiDimension.DIMENSION_OS,
              ]}
              body={<InsightsBlock />}
            />
          </SelectedDimensionProvider>
          <SelectedDimensionProvider
            initialDimension={ApiDimension.DIMENSION_CITY}
          >
            <InsightsCard
              title="Geographic Insights"
              dimensions={[
                ApiDimension.DIMENSION_CITY,
                ApiDimension.DIMENSION_REGION,
                ApiDimension.DIMENSION_COUNTRY,
              ]}
              body={<InsightsBlock />}
            />
          </SelectedDimensionProvider>
        </Stack>
      ) : (
        <Loading />
      )}
      <MetricExplorerDrawer
        open={open}
        onClose={onClose}
        metric={selectedMetric}
        setMetric={setSelectedMetric}
        attributes={selectedAttributes}
        setAttributes={setSelectedAttributes}
      />
    </>
  );
}

export default MetricExplorer;
