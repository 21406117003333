import { ApiDimension, ApiMetric } from "@incendium/api";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  ListItem,
  Stack,
  TextField,
} from "@mui/material";
import Loading from "Components/Loading/Loading";
import { useChartData } from "features/analytics";
import { useSelectedProject } from "Hooks";
import { useChannels } from "Hooks/useChannels";
import produce from "immer";
import { IChart, TFilterableDimensions } from "Interfaces";
import { useFromToContext } from "Providers/FromToProvider";
import { useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

interface ICampaignAnalysisAnalyzeFiltersProps {
  selectedPlatforms: string[];
  setSelectedPlatforms: (s: string[]) => void;
  selectedCampaigns: string[];
  setSelectedCampaigns: (s: string[]) => void;
  selectedGroups: string[];
  setSelectedGroups: (s: string[]) => void;
  selectedKeywords: string[];
  setSelectedKeywords: (s: string[]) => void;
}

function CampaignAnalysisAnalyzeFilters({
  selectedPlatforms,
  setSelectedPlatforms,
  selectedCampaigns,
  setSelectedCampaigns,
  selectedGroups,
  setSelectedGroups,
  selectedKeywords,
  setSelectedKeywords,
}: ICampaignAnalysisAnalyzeFiltersProps) {
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();
  const [optionOpen, setOptionOpen] = useState(false);
  const { paidChannelAttributes } = useChannels();

  const chart: IChart = useMemo(
    () => ({
      dimension: [
        ApiDimension.DIMENSION_SOURCE,
        ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
        ApiDimension.DIMENSION_CONTENT,
        ApiDimension.DIMENSION_TERM,
      ],
      includeEmpty: true,
      yAxisKeys: [
        {
          key: "l",
          fields: [ApiMetric.METRIC_LEADS_COUNT],
        },
      ],
      attributes: [...paidChannelAttributes],
    }),
    [paidChannelAttributes]
  );

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );

  const [selectedValues, setSelectedValues] = useState<
    Partial<TFilterableDimensions>
  >({
    [ApiDimension.DIMENSION_SOURCE]: selectedPlatforms,
    [ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN]: selectedCampaigns,
    [ApiDimension.DIMENSION_CONTENT]: selectedGroups,
    [ApiDimension.DIMENSION_TERM]: selectedKeywords,
  });

  const combinedData = useMemo(() => {
    const uniqueData = chartData?.data || [];
    return {
      [ApiDimension.DIMENSION_SOURCE]: Array.from(
        new Set(uniqueData.map((u) => u[ApiDimension.DIMENSION_SOURCE]))
      ),
      [ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN]: Array.from(
        new Set(
          uniqueData.map((u) => u[ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN])
        )
      ),
      [ApiDimension.DIMENSION_CONTENT]: Array.from(
        new Set(uniqueData.map((u) => u[ApiDimension.DIMENSION_CONTENT]))
      ),
      [ApiDimension.DIMENSION_TERM]: Array.from(
        new Set(uniqueData.map((u) => u[ApiDimension.DIMENSION_TERM]))
      ),
    };
  }, [chartData?.data]);

  useUpdateEffect(() => {
    if (optionOpen) {
      return;
    }

    setSelectedPlatforms(selectedValues[ApiDimension.DIMENSION_SOURCE] || []);
    setSelectedCampaigns(
      selectedValues[ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN] || []
    );
    setSelectedGroups(selectedValues[ApiDimension.DIMENSION_CONTENT] || []);
    setSelectedKeywords(selectedValues[ApiDimension.DIMENSION_TERM] || []);
  }, [optionOpen, selectedValues]);

  const onChange = useCallback(
    (dimension: ApiDimension) => (values: string[]) => {
      setSelectedValues(
        produce(selectedValues, (draft) => {
          draft[dimension] = values;
        })
      );
    },
    [selectedValues]
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Stack direction="row" spacing={3} justifyContent="flex-end">
          {[
            {
              key: ApiDimension.DIMENSION_SOURCE,
              text: "Select Publishers",
            },
            {
              key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
              text: "Select Campaigns",
            },
            {
              key: ApiDimension.DIMENSION_CONTENT,
              text: "Select Ad Groups",
            },
            {
              key: ApiDimension.DIMENSION_TERM,
              text: "Select Keywords",
            },
          ].map((o) => (
            <Autocomplete
              key={o.key}
              fullWidth
              options={combinedData[o.key] || []}
              multiple
              sx={{ maxWidth: 250 }}
              value={selectedValues[o.key]}
              disableCloseOnSelect
              disableListWrap
              onChange={(e, v) => {
                onChange(o.key)(v);
              }}
              onClose={() => setOptionOpen(false)}
              onOpen={() => setOptionOpen(true)}
              renderOption={(props, option, { selected }) => (
                <ListItem {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    checked={selected}
                  />
                  {option}
                </ListItem>
              )}
              renderInput={(params) => <TextField {...params} label={o.text} />}
            />
          ))}
        </Stack>
      )}
    </>
  );
}

export default CampaignAnalysisAnalyzeFilters;
