import { ApiDimension } from "@incendium/api";
import { IStatCardProps } from "Components/UI/StatCard";
import { IChart, TChartData } from "Interfaces";
import { IFromToChartOutput } from "Providers/FromToProvider";
import { ReactNode } from "react";

export type TDimensionMetricMap = {
  originalName: string;
  name: string;
  description: string;
  format?: (val: unknown, currency?: string) => string;
  excludedDimensions?: ApiDimension[]; // list of dimensions this metric is not compatible with (empty means none)
  compatibleDimensions?: ApiDimension[]; // list of dimensions this metric is compatible with (empty means none)
  attribtionType?: AttributionType;
  attribtionMetric?: AttributionMetric;
  plural?: string;
};

export interface IAnalyticsStatCardProps extends IStatCardProps {
  chart: IChart;
  renderBody: (
    data: TChartData[],
    comp?: TChartData[],
    trendData?: TChartData[]
  ) => ReactNode;
  setDirection?: (data: TChartData[], comp?: TChartData[]) => "up" | "down";
  chartOutput?: IFromToChartOutput;
  includeTrendedSpark?: boolean;
  noBg?: boolean;
}

export enum AttributionType {
  FIRST = "first",
  LAST = "last",
  LAST_NON = "lastNon",
  LINEAR = "linear",
  POSITION = "position",
  IMPACTED = "impacted",
}
export enum AttributionMetric {
  REVENUE = "revenue",
  SALES = "sales",
  MACRO = "macro",
  LTV = "ltv",
  ROAS = "roas",
  ROASLTV = "roasltv",
  ROI = "roi",
  ROILTV = "roiltv",
}

export interface ICompare {
  direction?: "up" | "down" | "same";
  value?: number; // value change
  formatedValue?: string; // original value
}

export interface IOnClickMap {
  text: string;
  fn: () => void;
  icon?: ReactNode;
}

export interface ITreeMapChartData {
  name: string;
  children: {
    name: string | null;
    metric: number;
    diff?: number;
  }[];
}
