import { ApiProjectSettingKey } from "@incendium/api";
import { useClientSelector } from "./useClientSelector";
import { useProjectSelector } from "./useProjectSelector";
import { enumToArray } from "Helpers/enumToText";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import produce from "immer";
import { useLocalstorageState } from "Hooks/useLocalstorageState";

export type TProjectSettings = { [key in ApiProjectSettingKey]?: string };

export const useSelectedProject = () => {
  const [projectSettings, setProjectSettings] = useState<TProjectSettings>({});
  const selectedProject = useProjectSelector();
  const [lastSelectedProject, setLastSelectedProject] = useLocalstorageState(
    "selected-project",
    selectedProject
  );
  const client = useClientSelector();

  useEffect(() => {
    if (selectedProject) {
      setLastSelectedProject(selectedProject);
    }
  }, [selectedProject, setLastSelectedProject]);

  useEffect(() => {
    setProjectSettings((settings) =>
      produce(settings, (draft) => {
        enumToArray(ApiProjectSettingKey).forEach((key) => {
          let value = selectedProject?.settings?.find(
            (s) => s.key === key
          )?.value;
          if (value) {
            draft[key] = value;
          }
        });
      })
    );
  }, [selectedProject?.settings]);

  const wipeLastSelected = useCallback(() => {
    setLastSelectedProject(null);
  }, [setLastSelectedProject]);

  return {
    selectedProject,
    wipeLastSelected,
    lastSelectedProject,
    selectedClient: client,
    projectSettings,
    setProjectSettings,
  };
};
