/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSyncTypeFromJSON, ApiSyncTypeToJSON, } from './ApiSyncType';
import { ApiUTMMappingTypeFromJSON, ApiUTMMappingTypeToJSON, } from './ApiUTMMappingType';
export function ApiSimpleUTMFromJSON(json) {
    return ApiSimpleUTMFromJSONTyped(json, false);
}
export function ApiSimpleUTMFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'syncType': !exists(json, 'syncType') ? undefined : ApiSyncTypeFromJSON(json['syncType']),
        'utmSource': !exists(json, 'utmSource') ? undefined : json['utmSource'],
        'utmMedium': !exists(json, 'utmMedium') ? undefined : json['utmMedium'],
        'utmCampaign': !exists(json, 'utmCampaign') ? undefined : json['utmCampaign'],
        'utmTerm': !exists(json, 'utmTerm') ? undefined : json['utmTerm'],
        'utmContent': !exists(json, 'utmContent') ? undefined : json['utmContent'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'type': !exists(json, 'type') ? undefined : ApiUTMMappingTypeFromJSON(json['type']),
        'utmSourceAlias': !exists(json, 'utmSourceAlias') ? undefined : json['utmSourceAlias'],
        'utmCampaignId': !exists(json, 'utmCampaignId') ? undefined : json['utmCampaignId'],
        'utmAdgroupId': !exists(json, 'utmAdgroupId') ? undefined : json['utmAdgroupId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'utmMatchType': !exists(json, 'utmMatchType') ? undefined : json['utmMatchType'],
    };
}
export function ApiSimpleUTMToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'syncType': ApiSyncTypeToJSON(value.syncType),
        'utmSource': value.utmSource,
        'utmMedium': value.utmMedium,
        'utmCampaign': value.utmCampaign,
        'utmTerm': value.utmTerm,
        'utmContent': value.utmContent,
        'name': value.name,
        'default': value._default,
        'type': ApiUTMMappingTypeToJSON(value.type),
        'utmSourceAlias': value.utmSourceAlias,
        'utmCampaignId': value.utmCampaignId,
        'utmAdgroupId': value.utmAdgroupId,
        'order': value.order,
        'utmMatchType': value.utmMatchType,
    };
}
