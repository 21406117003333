import { ApiChartTemplate, ApiChartType } from "@incendium/api";
import { AddCircle } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "Components/Loading/Loading";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer } from "Components/UI/TabContainer";
import { sortByDate } from "Helpers/arrays";
import { useCharts } from "Hooks/useCharts";
import { IChart } from "Interfaces";
import { memo, useCallback, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import ChartLibraryCard from "./ChartLibraryCard";

interface IChartLibraryListProps {
  onClick: (c: IChart) => void;
  onCreate: () => void;
  gridSize?: number;
  selectOnly?: boolean;
  hideTabs?: boolean;
  template?: ApiChartTemplate;
  type?: ApiChartType;
}

const StyledNewBtn = styled(Button)(({ theme }) => ({
  height: "100%",
  minHeight: 194,
  width: "100%",
  position: "relative",
  borderRadius: theme.spacing(3),
  borderWidth: 0,
  flexDirection: "column",
  background: theme.palette.secondary.main,
  color: "white",
  "& .MuiSvgIcon-root": {
    fontSize: 50,
    transition: ".2s ease-in-out",
    color: "white",
  },
  "&:hover ": {
    borderWidth: 4,
    color: theme.palette.secondary.main,
    "& .MuiSvgIcon-root": {
      transform: "rotate(90deg)",
      color: theme.palette.secondary.main,
    },
  },
}));

const Search = memo(
  ({
    setSearch,
  }: {
    setSearch: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    const [innerSearch, setInnerSearch] = useState("");
    useDebounce(() => setSearch(innerSearch), 500, [innerSearch]);

    const handleSearch = useCallback((e) => {
      setInnerSearch(e.target.value);
    }, []);

    return (
      <TextField
        sx={{ width: 266 }}
        size="small"
        label="search by name"
        value={innerSearch}
        onChange={handleSearch}
      />
    );
  }
);

function ChartLibraryList({
  onClick,
  onCreate,
  gridSize,
  selectOnly,
  hideTabs,
  template: initialTemplate,
  type: initialType,
}: IChartLibraryListProps) {
  const { charts, loading } = useCharts();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [search, setSearch] = useState("");

  const [template, setTemplate] = useState<ApiChartTemplate>(
    initialTemplate || ApiChartTemplate.UNDEFINED
  );
  const [type, setType] = useState<ApiChartType>(
    initialType || ApiChartType.UNDEFINED
  );

  const filteredCharts = useMemo(() => {
    return sortByDate(
      charts
        .filter(
          (c) =>
            template === ApiChartTemplate.UNDEFINED || c.template === template
        )
        .filter((c) => type === ApiChartType.UNDEFINED || c.type === type)
        .filter(
          (c) =>
            search.length < 3 ||
            (c.name || "").toLowerCase().includes(search.toLowerCase())
        ),
      "updatedAt"
    );
  }, [charts, template, type, search]);

  const onTemplateClick = useCallback((template: ApiChartTemplate) => {
    setTemplate(template);
    setType(ApiChartType.UNDEFINED);
  }, []);

  const onTypeClick = useCallback((type: ApiChartType) => {
    setType(type);
    setTemplate(ApiChartTemplate.UNDEFINED);
  }, []);

  return (
    <Stack pb={4}>
      <Stack
        sx={{ transform: "translatey(-24px)" }}
        spacing={3}
        direction="row"
        mt={1}
        mb={1}
        alignItems="flex-end"
      >
        {!hideTabs && (
          <TabContainer>
            <TabButton
              sx={{ width: 114 }}
              onClick={() => onTemplateClick(ApiChartTemplate.UNDEFINED)}
              isActive={
                template === ApiChartTemplate.UNDEFINED &&
                type === ApiChartType.UNDEFINED
              }
            >
              show all
            </TabButton>
            <TabButton
              sx={{ width: 114 }}
              onClick={() => onTemplateClick(ApiChartTemplate.SNAPSHOT)}
              isActive={template === ApiChartTemplate.SNAPSHOT}
            >
              snapshots
            </TabButton>
            <TabButton
              sx={{ width: 114 }}
              onClick={() => onTemplateClick(ApiChartTemplate.TREND)}
              isActive={template === ApiChartTemplate.TREND}
            >
              trends
            </TabButton>
            <TabButton
              sx={{ width: 114 }}
              onClick={() => onTemplateClick(ApiChartTemplate.ATTRIBUTION)}
              isActive={template === ApiChartTemplate.ATTRIBUTION}
            >
              attributions
            </TabButton>
            <TabButton
              sx={{ width: 114 }}
              onClick={() => onTemplateClick(ApiChartTemplate.TABLE)}
              isActive={template === ApiChartTemplate.TABLE}
            >
              tables
            </TabButton>
            <TabButton
              sx={{ width: 114 }}
              onClick={() => onTemplateClick(ApiChartTemplate.MAP)}
              isActive={template === ApiChartTemplate.MAP}
            >
              maps
            </TabButton>
            <TabButton
              sx={{ width: 114 }}
              onClick={() => onTypeClick(ApiChartType.STAT_CARD)}
              isActive={type === ApiChartType.STAT_CARD}
            >
              stat cards
            </TabButton>
          </TabContainer>
        )}
        <Search setSearch={setSearch} />
      </Stack>
      {loading ? (
        <Loading fullHeight />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={gridSize || 2}>
            <StyledNewBtn
              variant="outlined"
              color="secondary"
              onClick={onCreate}
            >
              <Typography variant="h3" color="inherit" gutterBottom>
                Create new chart
              </Typography>

              <AddCircle />
            </StyledNewBtn>
          </Grid>

          {filteredCharts.map((chart) => (
            <Grid item xs={gridSize || 2} key={chart.id}>
              <Box sx={{ position: "relative" }}>
                <ChartLibraryCard
                  selectOnly={selectOnly}
                  chart={chart}
                  onClick={onClick}
                  setShowBackdrop={setShowBackdrop}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      <Backdrop open={showBackdrop} />
    </Stack>
  );
}

export default memo(ChartLibraryList);
