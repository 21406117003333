import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid, Stack } from "@mui/material";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  AnalyticsIncreaseDecreaseStatCard,
  AnalyticsStatCard,
  metricToName,
  table5Rows,
} from "features/analytics";
import { useState } from "react";
import { StatCardTypography } from "Components/UI/StatCard";
import { percentageOf } from "Helpers/percentage";
import { formatNumber } from "Helpers/numbers";
import { AnalyticsSpacing } from "consts";

function ProductInsights() {
  const [metric, setMetric] = useState(ApiMetric.METRIC_SALES_COUNT);

  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={3}>
        <Stack spacing={AnalyticsSpacing}>
          <AnalyticsStatCard
            chart={{
              name: "",
              dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [metric],
                },
              ],
            }}
            renderBody={(data) => (
              <>
                <StatCardTypography size="small" opactity={0.8}>
                  Highest Performing Product by {metricToName(metric)}
                </StatCardTypography>
                <StatCardTypography mt={1} size="medium" lines={1}>
                  {data[0]?.name}
                </StatCardTypography>
              </>
            )}
          />
          <AnalyticsStatCard
            chart={{
              name: "",
              dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [metric],
                },
              ],
            }}
            renderBody={(data) => {
              const total = [...(data || [])].reduce((agg, v) => {
                return agg + Number(v[metric] || 0);
              }, 0);

              const percentage = percentageOf(
                Number(data?.[0]?.[metric] || 0),
                total
              );

              return (
                <>
                  <StatCardTypography size="small" opactity={0.8}>
                    Generating
                  </StatCardTypography>
                  <StatCardTypography size="medium" lines={1} mb={1}>
                    {formatNumber(percentage)}%
                  </StatCardTypography>
                  <StatCardTypography size="small" opactity={0.8}>
                    of total {metricToName(metric)}
                  </StatCardTypography>
                </>
              );
            }}
          />
          <AnalyticsIncreaseDecreaseStatCard
            metric={metric}
            chart={{
              name: "",
              dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [metric as ApiMetric],
                },
              ],
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={9} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: `Top 5 Products by`,
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_PRODUCT_NAME,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            limit: 5,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SALES_COUNT],
                stackId: "l",
                chart: {
                  [ApiMetric.METRIC_SALES_COUNT]: ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_PAGE_VIEWS_COUNT]: ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              noAttributionToggle: true,
              availableMetrics: [
                ApiMetric.METRIC_SALES_COUNT,
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
                ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
              ],
            },
          }}
          onChangeMetric={(m) => setMetric(m)}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: `Product Insights`,
            dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                  ApiMetric.METRIC_PRODUCT_REVENUE,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_SALES_COUNT,
                  ApiMetric.METRIC_SALES_COMPLETION_RATE,
                  ApiMetric.METRIC_SALES_REVENUE,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_PRODUCT_GATEWAY_LTV,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              noAttributionToggle: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: `Product Category Insights`,
            dimension: [ApiDimension.DIMENSION_PRODUCT_CATEGORY],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                  ApiMetric.METRIC_PRODUCT_REVENUE,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_SALES_COUNT,
                  ApiMetric.METRIC_SALES_COMPLETION_RATE,
                  ApiMetric.METRIC_SALES_REVENUE,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_PRODUCT_GATEWAY_LTV,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              noAttributionToggle: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: `Product Brand Stats`,
            dimension: [ApiDimension.DIMENSION_PRODUCT_BRAND],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                  ApiMetric.METRIC_PRODUCT_REVENUE,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_SALES_COUNT,
                  ApiMetric.METRIC_SALES_COMPLETION_RATE,
                  ApiMetric.METRIC_SALES_REVENUE,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_PRODUCT_GATEWAY_LTV,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              noAttributionToggle: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ProductInsights;
