import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { AttributionHeader, allAttributionMetrics } from "features/analytics";

function ChannelInsights() {
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={12}>
        <AttributionHeader
          dimension={ApiDimension.DIMENSION_CHANNEL}
          title={"Channels"}
          attributes={[]}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: 915 }}>
        <AnalyticsCard
          chart={{
            name: "",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [],
            comparison: true,
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ...allAttributionMetrics,
                  ApiMetric.METRIC_CPA,
                  ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
                  ApiMetric.METRIC_CAC,
                  ApiMetric.METRIC_ECR,
                  ApiMetric.METRIC_CPNV,
                ],
              },
            ],
            displayOptions: {
              noTitle: true,
              noAttributionToggle: true,
              rowsPerPage: 15,
              availableDimensions: [
                ApiDimension.DIMENSION_CHANNEL,
                ApiDimension.DIMENSION_SOURCE,
                ApiDimension.DIMENSION_MEDIUM,
                ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                ApiDimension.DIMENSION_CONTENT,
                ApiDimension.DIMENSION_TERM,
              ],
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ChannelInsights;
