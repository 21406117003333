import {
  ApiProjectSubscriptionStatus,
  ApiSimpleContract,
  ApiSimpleProjectSubscription,
} from "@incendium/api";
import { Grid, Typography } from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";
import Loading from "Components/Loading/Loading";
import {
  getProjectSubscrition,
  markSubscriptionAsSeen,
  SubscriptionPaymentCard,
} from "features/subscription";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

function PaymentPage() {
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const [subscription, setSubscription] =
    useState<ApiSimpleProjectSubscription | null>(null);
  const [contract, setContract] = useState<ApiSimpleContract | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [paid, setPaid] = useState(false);

  const loadSubcription = useCallback(async (projectId: number) => {
    const res = await getProjectSubscrition(projectId);
    setSubscription(res);
    setContract(res.subscription?.contract || {});
  }, []);

  const markAsSeen = useCallback((projectSubscriptionId: string) => {
    markSubscriptionAsSeen(projectSubscriptionId);
  }, []);

  useEffect(() => {
    loadSubcription(Number(projectId));
  }, [projectId, loadSubcription]);

  useEffect(() => {
    if (subscription?.id) {
      markAsSeen(subscription.id);

      if (subscription?.paymentLinkQr) {
        const base64Data = subscription?.paymentLinkQr.startsWith(
          "data:image/png;base64,"
        )
          ? subscription?.paymentLinkQr
          : `data:image/png;base64,${subscription?.paymentLinkQr}`;

        setImageSrc(base64Data);
      }
    }
  }, [subscription, markAsSeen]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has("success")) {
      if (searchParams.get("success") === "true") {
        setPaid(true);
      } else {
        setPaid(false);
      }
      return;
    }
    if (
      subscription?.status ===
      ApiProjectSubscriptionStatus.PROJECT_SUBSCRIPTION_STATUS_PAID
    ) {
      setPaid(true);
    }
  }, [subscription, search]);

  if (!subscription?.subscription) {
    return <Loading fullHeight />;
  }

  return (
    <GlassCard sx={{ maxWidth: 900 }}>
      <Typography mb={2} variant="subtitle1">
        Billing & Payment
      </Typography>
      <IndentDivider />
      <Grid mt={2} container>
        <Grid item xs={6}>
          <SubscriptionPaymentCard
            name={subscription.subscription.product?.name || "Starter"}
            description={subscription.subscription.description || ""}
            price={subscription.subscription.price || 0}
            link={subscription?.paymentLink || ""}
            qr={imageSrc}
            terms={contract?.body || ""}
            paid={paid}
          />
        </Grid>
      </Grid>
    </GlassCard>
  );
}

export default PaymentPage;
