import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiProjectSettingKey,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { useSelectedProject } from "Hooks";
import { BucketsROICharts } from "features/analytics";
import { AnalyticsSpacing } from "consts";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";

function KeywordInsights({ paidChannelAttributes }) {
  const { projectSettings } = useSelectedProject();

  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={6} sx={{ minHeight: 500 }}>
        <AnalyticsCard
          chart={{
            name: `Keyword Engagement & Conversion`,
            dimension: [ApiDimension.DIMENSION_TERM],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                  ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              rowsPerPage: 5,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 500 }}>
        <AnalyticsCard
          chart={{
            name: `Keyword Performance Overview`,
            dimension: [ApiDimension.DIMENSION_TERM],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.BUBBLE,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      {projectSettings[
        ApiProjectSettingKey.SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS
      ] === "true" && (
        <Grid item xs={12}>
          <BucketsROICharts dimensions={[ApiDimension.DIMENSION_TERM]} />
        </Grid>
      )}

      <Grid item xs={12} sx={{ minHeight: 1450 }}>
        <AnalyticsCard
          chart={{
            name: `Keyword Level Attribution Modelling`,
            dimension: [ApiDimension.DIMENSION_TERM],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_SESSION_ALL_INCLUSIVE_COST,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
                  ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              rowsPerPage: 25,
            },
          }}
        />
      </Grid>
      {/* <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <ProblemTable
          dimensions={[ApiDimension.DIMENSION_TERM]}
          title="Problem Keywords"
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <HiddenValueTable
          dimensions={[ApiDimension.DIMENSION_TERM]}
          title="Hidden Value Keywords"
        />
      </Grid> */}
    </Grid>
  );
}

export default withPaidChannelAttributes(KeywordInsights);
