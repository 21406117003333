import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

type CallbackOrVal<T> = T | ((prevState: T) => T);

let queryUpdateQueue: Record<string, string | null> = {};
let updateTimeout: NodeJS.Timeout | null = null;

const appendQueryParams = (newParams: Record<string, string | null>) => {
  // Merge newParams into the queued params
  Object.entries(newParams).forEach(([key, value]) => {
    if (value === null) {
      delete queryUpdateQueue[key]; // Remove the key if the value is null
    } else {
      queryUpdateQueue[key] = value;
    }
  });

  // Batch updates to avoid overwrites
  if (updateTimeout) {
    clearTimeout(updateTimeout);
  }

  updateTimeout = setTimeout(() => {
    const currentParams = new URLSearchParams(window.location.search);

    Object.entries(queryUpdateQueue).forEach(([key, value]) => {
      if (value === null) {
        currentParams.delete(key);
      } else {
        currentParams.set(key, value);
      }
    });

    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, "", newUrl);

    // Clear the queue and timeout
    queryUpdateQueue = {};
    updateTimeout = null;
  }, 10);
};

function useAppendSearchState<T>(
  key: string,
  initialValue: T | null,
  options?: {
    serialize?: (value: T | null) => string | null; // Custom serialization function
    deserialize?: (value: string | null) => T | null; // Custom deserialization function
  }
): [T | null, (v: CallbackOrVal<T | null>) => void] {
  const location = useLocation();
  const serializeFn = useMemo(
    () =>
      options?.serialize ||
      ((value: T | null) => {
        if (value === null) return null;
        if (typeof value === "boolean") return value ? "true" : "false"; // Handle booleans
        if (typeof value === "number") return value.toString(); // Handle numbers
        return typeof value === "object"
          ? JSON.stringify(value)
          : String(value);
      }),
    [options]
  );

  const deserializeFn = useMemo(
    () =>
      options?.deserialize ||
      ((value: string | null) => {
        if (value === null) return null;

        // Handle booleans
        if (value === "true" || value === "false") return value === "true";

        // Handle numbers
        if (!isNaN(Number(value))) return Number(value) as T;

        try {
          // Handle objects/arrays
          return value.startsWith("{") || value.startsWith("[")
            ? JSON.parse(value)
            : (value as T);
        } catch {
          return value as T;
        }
      }),
    [options]
  );

  // Handle initial value logic
  const getInitialValue = (): T | null => {
    const params = new URLSearchParams(location.search);
    const urlValue = params.get(key);
    const deserializedValue =
      urlValue !== null ? deserializeFn(urlValue) : initialValue;

    return deserializedValue;
  };

  // State for storing the value
  const [value, setValue] = useState<T | null>(getInitialValue);

  useEffect(() => {
    const serializedValue = serializeFn(value);

    appendQueryParams({
      [key]: serializedValue,
    });
  }, [value, key, serializeFn]);

  return [value, setValue];
}

export default useAppendSearchState;
