import { ColDef, ColGroupDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-material.css";
import { styled, Box } from "@mui/material";
import Loading from "Components/Loading/Loading";
import NoData from "Components/NoData";

const StyledContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  "& .ag-theme-material": {
    "--ag-font-family": theme.typography.fontFamily,
    "--ag-material-primary-color": theme.palette.primary.main,
    "--ag-background-color": "transparent",
    "--ag-header-background-color": "transparent",
    "--ag-data-color": theme.palette.text.primary,
    "--ag-border-color": theme.palette.divider,
    "--ag-row-border-color": theme.palette.divider,
    "--ag-secondary-foreground-color": theme.palette.text.secondary,
  },

  "& .ag-floating-bottom": {
    overflowY: "hidden!important",
  },
  "& .ag-horizontal-left-spacer": {
    height: 0,
  },
  "& .ag-header-cell": {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& .ag-ltr .ag-cell": {
    borderRight: `1px solid ${theme.palette.divider}`,
    "& > .MuiStack-root": {
      justifyContent: "center",
    },
  },
  "& .ag-header-group-cell": {
    borderRight: `1px solid ${theme.palette.divider}`,
    justifyContent: "center",
  },
  "& .ag-paging-panel": {
    justifyContent: "flex-start",
  },
  "& .ag-paging-page-size": {
    display: "none",
  },
  "& .ag-cell-wrapper": {
    height: "100%",
  },
  "& .ag-header-viewport .ag-header-cell-label": {
    justifyContent: "center",
    textAlign: "center",
  },
}));

interface IDataTableProps {
  colDefs: (ColDef<any, any> | ColGroupDef<any>)[];
  defaultColDef?: ColDef<any, any> | ColGroupDef<any>;
  rows: any[];
  totalRow?: any[];
  pageSize?: number;
}

function DataTable({
  colDefs,
  rows,
  defaultColDef,
  totalRow,
  pageSize,
}: IDataTableProps) {
  return (
    //  wrapping container with theme & size
    <StyledContainer>
      <div
        className="ag-theme-material" // applying the grid theme
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <AgGridReact
          rowData={rows}
          columnDefs={colDefs}
          defaultColDef={
            defaultColDef || {
              wrapHeaderText: true,
              flex: 1,
              autoHeaderHeight: true,
              // autoHeight: true, // commented out as diabled column virtulisation (only rendering columns in view)
            }
          }
          pinnedBottomRowData={totalRow}
          pagination
          paginationPageSize={pageSize || 10}
          paginationPageSizeSelector={false}
          loadingOverlayComponent={Loading}
          noRowsOverlayComponent={NoData}
          suppressCellFocus
          suppressBrowserResizeObserver
        />
      </div>
    </StyledContainer>
  );
}

export default DataTable;
