import {
  ApiAttributionTagReserved,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Grid, Typography } from "@mui/material";
import SetupNeeded from "Components/UI/SetupNeeded";
import { formatEnumVal } from "Helpers/enumToText";
import { useTrafficBuckets } from "Hooks/useTrafficBuckets";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { AnalyticsSpacing } from "consts";

interface IBucketsROIChartsProps {
  dimensions: ApiDimension[];
}

function BucketsROICharts({ dimensions }: IBucketsROIChartsProps) {
  const { trafficBuckets, loading: bucketsLoading } = useTrafficBuckets();
  const buckets = useMemo(() => {
    return [
      ApiAttributionTagReserved.AWARENESS,
      ApiAttributionTagReserved.ACQUISITION,
      ApiAttributionTagReserved.RETENTION,
    ]
      .map((key) => ({
        name: key,
        bucketNames: trafficBuckets
          .filter(
            (b) => (b.tags || []).findIndex((t) => t.reserved === key) >= 0
          )
          .map((d) => d.name || ""),
      }))
      .filter((d) => d.bucketNames.length > 0);
  }, [trafficBuckets]);

  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={12}>
        {!bucketsLoading && buckets.length === 0 ? (
          <SetupNeeded>
            <Typography variant="subtitle1" mb={1}>
              Insights not available
            </Typography>
            <Typography variant="body1">
              To see purchase funnel reports, looking at awareness, acquisition
              and retention, you'll need to configure your traffic buckets and
              tag these sources. You can do that{" "}
              <Link to={"../../../../train/traffic/buckets"}>here</Link>
            </Typography>
          </SetupNeeded>
        ) : (
          <Grid container spacing={AnalyticsSpacing}>
            {buckets.map((bucket) => (
              <Grid item key={bucket.name} xs={4} sx={{ minHeight: 450 }}>
                <AnalyticsCard
                  chart={{
                    name: `${formatEnumVal(
                      (bucket.name || "").replace("ATTRIBUTION_TAG_", "")
                    )} ROI`,
                    dimension: [dimensions[0]],
                    template: ApiChartTemplate.TABLE,
                    type: ApiChartType.TABLE,
                    attributes: (bucket.bucketNames || []).map((b) => ({
                      key: ApiDimension.DIMENSION_SOURCE_BUCKET,
                      value: b,
                    })),
                    limit: 5,
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [
                          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
                          ApiMetric.METRIC_SESSION_ALL_INCLUSIVE_COST,
                          ApiMetric.METRIC_SESSION_EFFICIENCY_RATION,
                          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                          ApiMetric.METRIC_SESSION_REVENUE,
                        ],
                      },
                    ],
                    displayOptions: {
                      noFilters: true,
                      rowsPerPage: 4,
                      noAttributionToggle: true,
                      availableDimensions:
                        dimensions.length > 1 ? dimensions : undefined,
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default BucketsROICharts;
