import { ApiSimpleProduct } from "@incendium/api";
import { listSubscriptionProducts } from "features/subscription";
import { useSharedState } from "Hooks/useSharedState";

const fetchSubscriptionProducts = async () => {
  const subscriptionProducts = await listSubscriptionProducts();
  return subscriptionProducts || [];
};
const useSubscriptionProducts = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiSimpleProduct,
    ApiSimpleProduct[]
  >("subscriptionProducts", [], fetchSubscriptionProducts);

  return {
    subscriptionProducts: state,
    setSubscriptionProducts: setState,
    loading,
    refetch,
  };
};
export default useSubscriptionProducts;
