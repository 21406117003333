import { ApiAPITokenType, ApiAPITokenValueResponse } from "@incendium/api";
import { APITokenService } from "Apis";

export const createAPIToken = async (
  clientId: number,
  type: ApiAPITokenType,
  values: Array<ApiAPITokenValueResponse>
) => {
  const res = await APITokenService.aPITokenServiceCreateAPIToken({
    clientId,
    payload: {
      type,
      values,
    },
  });

  return res;
};

export const deleteToken = async (clientId: number, tokenId: number) => {
  const res = await APITokenService.aPITokenServiceDeleteAPIToken({
    clientId,
    tokenId,
  });
  return res;
};

export const updateAPITokenValues = async (
  clientId: number,
  tokenId: number,
  values: Array<ApiAPITokenValueResponse>
) => {
  const res = await APITokenService.aPITokenServiceUpdateAPITokenValues({
    clientId,
    tokenId,
    payload: {
      values,
    },
  });
  return res;
};
