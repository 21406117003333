import { ApiMetric } from "@incendium/api";
import {
  MetricExplorer,
  SelectedAttributesProvider,
  SelectedMetricProvider,
} from "features/analytics";

function MetricExplorerPage() {
  return (
    <>
      <SelectedAttributesProvider initialAttributes={[]}>
        <SelectedMetricProvider
          initialMetric={ApiMetric.METRIC_PAGE_VIEWS_COUNT}
        >
          <MetricExplorer />
        </SelectedMetricProvider>
      </SelectedAttributesProvider>
    </>
  );
}

export default MetricExplorerPage;
