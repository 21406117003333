/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiListUTMsResponseFromJSON, ApiListValuesResponseFromJSON, ApiReadUTMResponseFromJSON, ApiSimpleUTMToJSON, } from '../models';
/**
 *
 */
export class UTMServiceApi extends runtime.BaseAPI {
    /**
     */
    uTMServiceCreateUTMRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling uTMServiceCreateUTM.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling uTMServiceCreateUTM.');
            }
            const queryParameters = {};
            if (requestParameters.utmId !== undefined) {
                queryParameters['utmId'] = requestParameters.utmId;
            }
            if (requestParameters.syncType !== undefined) {
                queryParameters['syncType'] = requestParameters.syncType;
            }
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/utm/v1/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleUTMToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadUTMResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    uTMServiceCreateUTM(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uTMServiceCreateUTMRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    uTMServiceDeleteUTMRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling uTMServiceDeleteUTM.');
            }
            if (requestParameters.utmId === null || requestParameters.utmId === undefined) {
                throw new runtime.RequiredError('utmId', 'Required parameter requestParameters.utmId was null or undefined when calling uTMServiceDeleteUTM.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadCreatedAt !== undefined) {
                queryParameters['payload.createdAt'] = requestParameters.payloadCreatedAt.toISOString();
            }
            if (requestParameters.payloadUpdatedAt !== undefined) {
                queryParameters['payload.updatedAt'] = requestParameters.payloadUpdatedAt.toISOString();
            }
            if (requestParameters.payloadSyncType !== undefined) {
                queryParameters['payload.syncType'] = requestParameters.payloadSyncType;
            }
            if (requestParameters.payloadUtmSource !== undefined) {
                queryParameters['payload.utmSource'] = requestParameters.payloadUtmSource;
            }
            if (requestParameters.payloadUtmMedium !== undefined) {
                queryParameters['payload.utmMedium'] = requestParameters.payloadUtmMedium;
            }
            if (requestParameters.payloadUtmCampaign !== undefined) {
                queryParameters['payload.utmCampaign'] = requestParameters.payloadUtmCampaign;
            }
            if (requestParameters.payloadUtmTerm !== undefined) {
                queryParameters['payload.utmTerm'] = requestParameters.payloadUtmTerm;
            }
            if (requestParameters.payloadUtmContent !== undefined) {
                queryParameters['payload.utmContent'] = requestParameters.payloadUtmContent;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDefault !== undefined) {
                queryParameters['payload.default'] = requestParameters.payloadDefault;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadUtmSourceAlias !== undefined) {
                queryParameters['payload.utmSourceAlias'] = requestParameters.payloadUtmSourceAlias;
            }
            if (requestParameters.payloadUtmCampaignId !== undefined) {
                queryParameters['payload.utmCampaignId'] = requestParameters.payloadUtmCampaignId;
            }
            if (requestParameters.payloadUtmAdgroupId !== undefined) {
                queryParameters['payload.utmAdgroupId'] = requestParameters.payloadUtmAdgroupId;
            }
            if (requestParameters.payloadOrder !== undefined) {
                queryParameters['payload.order'] = requestParameters.payloadOrder;
            }
            if (requestParameters.payloadUtmMatchType !== undefined) {
                queryParameters['payload.utmMatchType'] = requestParameters.payloadUtmMatchType;
            }
            if (requestParameters.syncType !== undefined) {
                queryParameters['syncType'] = requestParameters.syncType;
            }
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/utm/v1/projects/{projectId}/utm/{utmId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"utmId"}}`, encodeURIComponent(String(requestParameters.utmId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    uTMServiceDeleteUTM(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uTMServiceDeleteUTMRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Aliases ----------------
     */
    uTMServiceListSourceValuesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling uTMServiceListSourceValues.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/utm/v1/projects/{projectId}/sources`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListValuesResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Aliases ----------------
     */
    uTMServiceListSourceValues(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uTMServiceListSourceValuesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- UTMs ----------------
     */
    uTMServiceListUTMsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling uTMServiceListUTMs.');
            }
            const queryParameters = {};
            if (requestParameters.utmId !== undefined) {
                queryParameters['utmId'] = requestParameters.utmId;
            }
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadCreatedAt !== undefined) {
                queryParameters['payload.createdAt'] = requestParameters.payloadCreatedAt.toISOString();
            }
            if (requestParameters.payloadUpdatedAt !== undefined) {
                queryParameters['payload.updatedAt'] = requestParameters.payloadUpdatedAt.toISOString();
            }
            if (requestParameters.payloadSyncType !== undefined) {
                queryParameters['payload.syncType'] = requestParameters.payloadSyncType;
            }
            if (requestParameters.payloadUtmSource !== undefined) {
                queryParameters['payload.utmSource'] = requestParameters.payloadUtmSource;
            }
            if (requestParameters.payloadUtmMedium !== undefined) {
                queryParameters['payload.utmMedium'] = requestParameters.payloadUtmMedium;
            }
            if (requestParameters.payloadUtmCampaign !== undefined) {
                queryParameters['payload.utmCampaign'] = requestParameters.payloadUtmCampaign;
            }
            if (requestParameters.payloadUtmTerm !== undefined) {
                queryParameters['payload.utmTerm'] = requestParameters.payloadUtmTerm;
            }
            if (requestParameters.payloadUtmContent !== undefined) {
                queryParameters['payload.utmContent'] = requestParameters.payloadUtmContent;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDefault !== undefined) {
                queryParameters['payload.default'] = requestParameters.payloadDefault;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadUtmSourceAlias !== undefined) {
                queryParameters['payload.utmSourceAlias'] = requestParameters.payloadUtmSourceAlias;
            }
            if (requestParameters.payloadUtmCampaignId !== undefined) {
                queryParameters['payload.utmCampaignId'] = requestParameters.payloadUtmCampaignId;
            }
            if (requestParameters.payloadUtmAdgroupId !== undefined) {
                queryParameters['payload.utmAdgroupId'] = requestParameters.payloadUtmAdgroupId;
            }
            if (requestParameters.payloadOrder !== undefined) {
                queryParameters['payload.order'] = requestParameters.payloadOrder;
            }
            if (requestParameters.payloadUtmMatchType !== undefined) {
                queryParameters['payload.utmMatchType'] = requestParameters.payloadUtmMatchType;
            }
            if (requestParameters.syncType !== undefined) {
                queryParameters['syncType'] = requestParameters.syncType;
            }
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/utm/v1/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListUTMsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- UTMs ----------------
     */
    uTMServiceListUTMs(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uTMServiceListUTMsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    uTMServiceReadUTMRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling uTMServiceReadUTM.');
            }
            if (requestParameters.utmId === null || requestParameters.utmId === undefined) {
                throw new runtime.RequiredError('utmId', 'Required parameter requestParameters.utmId was null or undefined when calling uTMServiceReadUTM.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadCreatedAt !== undefined) {
                queryParameters['payload.createdAt'] = requestParameters.payloadCreatedAt.toISOString();
            }
            if (requestParameters.payloadUpdatedAt !== undefined) {
                queryParameters['payload.updatedAt'] = requestParameters.payloadUpdatedAt.toISOString();
            }
            if (requestParameters.payloadSyncType !== undefined) {
                queryParameters['payload.syncType'] = requestParameters.payloadSyncType;
            }
            if (requestParameters.payloadUtmSource !== undefined) {
                queryParameters['payload.utmSource'] = requestParameters.payloadUtmSource;
            }
            if (requestParameters.payloadUtmMedium !== undefined) {
                queryParameters['payload.utmMedium'] = requestParameters.payloadUtmMedium;
            }
            if (requestParameters.payloadUtmCampaign !== undefined) {
                queryParameters['payload.utmCampaign'] = requestParameters.payloadUtmCampaign;
            }
            if (requestParameters.payloadUtmTerm !== undefined) {
                queryParameters['payload.utmTerm'] = requestParameters.payloadUtmTerm;
            }
            if (requestParameters.payloadUtmContent !== undefined) {
                queryParameters['payload.utmContent'] = requestParameters.payloadUtmContent;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDefault !== undefined) {
                queryParameters['payload.default'] = requestParameters.payloadDefault;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadUtmSourceAlias !== undefined) {
                queryParameters['payload.utmSourceAlias'] = requestParameters.payloadUtmSourceAlias;
            }
            if (requestParameters.payloadUtmCampaignId !== undefined) {
                queryParameters['payload.utmCampaignId'] = requestParameters.payloadUtmCampaignId;
            }
            if (requestParameters.payloadUtmAdgroupId !== undefined) {
                queryParameters['payload.utmAdgroupId'] = requestParameters.payloadUtmAdgroupId;
            }
            if (requestParameters.payloadOrder !== undefined) {
                queryParameters['payload.order'] = requestParameters.payloadOrder;
            }
            if (requestParameters.payloadUtmMatchType !== undefined) {
                queryParameters['payload.utmMatchType'] = requestParameters.payloadUtmMatchType;
            }
            if (requestParameters.syncType !== undefined) {
                queryParameters['syncType'] = requestParameters.syncType;
            }
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/utm/v1/projects/{projectId}/utm/{utmId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"utmId"}}`, encodeURIComponent(String(requestParameters.utmId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadUTMResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    uTMServiceReadUTM(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uTMServiceReadUTMRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    uTMServiceUpdateUTMRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling uTMServiceUpdateUTM.');
            }
            if (requestParameters.utmId === null || requestParameters.utmId === undefined) {
                throw new runtime.RequiredError('utmId', 'Required parameter requestParameters.utmId was null or undefined when calling uTMServiceUpdateUTM.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling uTMServiceUpdateUTM.');
            }
            const queryParameters = {};
            if (requestParameters.syncType !== undefined) {
                queryParameters['syncType'] = requestParameters.syncType;
            }
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/utm/v1/projects/{projectId}/utm/{utmId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"utmId"}}`, encodeURIComponent(String(requestParameters.utmId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleUTMToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadUTMResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    uTMServiceUpdateUTM(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uTMServiceUpdateUTMRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var UTMServiceCreateUTMSyncTypeEnum;
(function (UTMServiceCreateUTMSyncTypeEnum) {
    UTMServiceCreateUTMSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceCreateUTMSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceCreateUTMSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceCreateUTMSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceCreateUTMSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceCreateUTMSyncTypeEnum || (UTMServiceCreateUTMSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceCreateUTMTypeEnum;
(function (UTMServiceCreateUTMTypeEnum) {
    UTMServiceCreateUTMTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceCreateUTMTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceCreateUTMTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceCreateUTMTypeEnum || (UTMServiceCreateUTMTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceDeleteUTMPayloadSyncTypeEnum;
(function (UTMServiceDeleteUTMPayloadSyncTypeEnum) {
    UTMServiceDeleteUTMPayloadSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceDeleteUTMPayloadSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceDeleteUTMPayloadSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceDeleteUTMPayloadSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceDeleteUTMPayloadSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceDeleteUTMPayloadSyncTypeEnum || (UTMServiceDeleteUTMPayloadSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceDeleteUTMPayloadTypeEnum;
(function (UTMServiceDeleteUTMPayloadTypeEnum) {
    UTMServiceDeleteUTMPayloadTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceDeleteUTMPayloadTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceDeleteUTMPayloadTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceDeleteUTMPayloadTypeEnum || (UTMServiceDeleteUTMPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceDeleteUTMSyncTypeEnum;
(function (UTMServiceDeleteUTMSyncTypeEnum) {
    UTMServiceDeleteUTMSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceDeleteUTMSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceDeleteUTMSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceDeleteUTMSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceDeleteUTMSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceDeleteUTMSyncTypeEnum || (UTMServiceDeleteUTMSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceDeleteUTMTypeEnum;
(function (UTMServiceDeleteUTMTypeEnum) {
    UTMServiceDeleteUTMTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceDeleteUTMTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceDeleteUTMTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceDeleteUTMTypeEnum || (UTMServiceDeleteUTMTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceListUTMsPayloadSyncTypeEnum;
(function (UTMServiceListUTMsPayloadSyncTypeEnum) {
    UTMServiceListUTMsPayloadSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceListUTMsPayloadSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceListUTMsPayloadSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceListUTMsPayloadSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceListUTMsPayloadSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceListUTMsPayloadSyncTypeEnum || (UTMServiceListUTMsPayloadSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceListUTMsPayloadTypeEnum;
(function (UTMServiceListUTMsPayloadTypeEnum) {
    UTMServiceListUTMsPayloadTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceListUTMsPayloadTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceListUTMsPayloadTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceListUTMsPayloadTypeEnum || (UTMServiceListUTMsPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceListUTMsSyncTypeEnum;
(function (UTMServiceListUTMsSyncTypeEnum) {
    UTMServiceListUTMsSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceListUTMsSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceListUTMsSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceListUTMsSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceListUTMsSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceListUTMsSyncTypeEnum || (UTMServiceListUTMsSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceListUTMsTypeEnum;
(function (UTMServiceListUTMsTypeEnum) {
    UTMServiceListUTMsTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceListUTMsTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceListUTMsTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceListUTMsTypeEnum || (UTMServiceListUTMsTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceReadUTMPayloadSyncTypeEnum;
(function (UTMServiceReadUTMPayloadSyncTypeEnum) {
    UTMServiceReadUTMPayloadSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceReadUTMPayloadSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceReadUTMPayloadSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceReadUTMPayloadSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceReadUTMPayloadSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceReadUTMPayloadSyncTypeEnum || (UTMServiceReadUTMPayloadSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceReadUTMPayloadTypeEnum;
(function (UTMServiceReadUTMPayloadTypeEnum) {
    UTMServiceReadUTMPayloadTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceReadUTMPayloadTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceReadUTMPayloadTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceReadUTMPayloadTypeEnum || (UTMServiceReadUTMPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceReadUTMSyncTypeEnum;
(function (UTMServiceReadUTMSyncTypeEnum) {
    UTMServiceReadUTMSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceReadUTMSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceReadUTMSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceReadUTMSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceReadUTMSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceReadUTMSyncTypeEnum || (UTMServiceReadUTMSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceReadUTMTypeEnum;
(function (UTMServiceReadUTMTypeEnum) {
    UTMServiceReadUTMTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceReadUTMTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceReadUTMTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceReadUTMTypeEnum || (UTMServiceReadUTMTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceUpdateUTMSyncTypeEnum;
(function (UTMServiceUpdateUTMSyncTypeEnum) {
    UTMServiceUpdateUTMSyncTypeEnum["UNSPECIFIED"] = "SYNC_TYPE_UNSPECIFIED";
    UTMServiceUpdateUTMSyncTypeEnum["GOOGLE_ADS"] = "SYNC_TYPE_GOOGLE_ADS";
    UTMServiceUpdateUTMSyncTypeEnum["BING_ADS"] = "SYNC_TYPE_BING_ADS";
    UTMServiceUpdateUTMSyncTypeEnum["FACEBOOK_ADS"] = "SYNC_TYPE_FACEBOOK_ADS";
    UTMServiceUpdateUTMSyncTypeEnum["ECWID_API"] = "SYNC_TYPE_ECWID_API";
})(UTMServiceUpdateUTMSyncTypeEnum || (UTMServiceUpdateUTMSyncTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UTMServiceUpdateUTMTypeEnum;
(function (UTMServiceUpdateUTMTypeEnum) {
    UTMServiceUpdateUTMTypeEnum["UTM_MAPPING_TYPE_UNSPECIFIED"] = "UTMMappingTypeUnspecified";
    UTMServiceUpdateUTMTypeEnum["UTM_MAPPING_TYPE_PARAM"] = "UTMMappingTypeParam";
    UTMServiceUpdateUTMTypeEnum["UTM_MAPPING_TYPE_ALIAS_SOURCE"] = "UTMMappingTypeAliasSource";
})(UTMServiceUpdateUTMTypeEnum || (UTMServiceUpdateUTMTypeEnum = {}));
