import { Moving } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { formatNumber } from "Helpers/numbers";
import { useMemo } from "react";

interface IIncreaseDecreaseProps {
  direction?: "up" | "down" | "same";
  value: number;
  formatedValue?: string;
  size?: "small" | "inherit" | "large" | "medium";
  fontSize?: number;
  fontWeight?: number;
  useThemeColours?: boolean;
  show?: "all" | "icon" | "value";
}

function IncreaseDecrease({
  direction: directionOverride,
  value,
  formatedValue,
  useThemeColours,
  size,
  fontSize,
  fontWeight,
  show,
}: IIncreaseDecreaseProps) {
  const direction = useMemo(() => {
    return directionOverride
      ? directionOverride
      : Math.sign(value) > 0
      ? "up"
      : Math.sign(value) < 0
      ? "down"
      : "same";
  }, [value, directionOverride]);

  const colours = useMemo(() => {
    return useThemeColours
      ? ["success.main", "error.main", "secondary"]
      : ["#fff", "#fff", "#fff"];
  }, [useThemeColours]);

  return (
    <Stack
      sx={{
        position: "relative",
        paddingLeft:
          !show || ["all", "icon"].includes(show)
            ? fontSize
              ? `${fontSize + 6}px`
              : 3
            : 0,
      }}
      direction="row"
      alignItems={"baseline"}
      color={
        direction === "up"
          ? colours[0]
          : direction === "down"
          ? colours[1]
          : colours[2]
      }
    >
      {(!show || ["all", "icon"].includes(show)) && (
        <Moving
          fontSize={size || "small"}
          sx={{
            fontSize: fontSize,
            fontWeight,
            transform: `translateY(-50%) scale(1, ${
              direction === "up" ? 1 : -1
            })`,
            position: "absolute",
            left: 0,
            top: "50%",
          }}
        />
      )}
      {(!show || ["all", "value"].includes(show)) && (
        <Typography
          variant={!size || size === "small" ? "body2" : "body1"}
          sx={
            colours
              ? {
                  fontSize: fontSize,
                  fontWeight,
                  color: direction === "up" ? colours[0] : colours[1],
                }
              : {
                  fontSize: fontSize,
                  fontWeight,
                }
          }
          color={
            direction === "up"
              ? colours[0]
              : direction === "down"
              ? colours[1]
              : colours[2]
          }
          noWrap
        >
          {formatedValue && `${formatedValue}`} ({formatNumber(Math.abs(value))}
          %)
        </Typography>
      )}
    </Stack>
  );
}

export default IncreaseDecrease;
