import { ApiProject } from "@incendium/api";
import { Autocomplete, OutlinedTextFieldProps, TextField } from "@mui/material";
import { initializeClientsThunk } from "Actions";
import { listAllProjects } from "features/project/services/projectService";
import { sortNum } from "Helpers/arrays";
import { CallbackOrVal } from "Interfaces";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { clientListSelector } from "Selectors/clientListSelector";

interface IAllProjectsSelectorProps
  extends Omit<OutlinedTextFieldProps, "onChange"> {
  project: ApiProject | null;
  setProject: (v: CallbackOrVal<ApiProject | null>) => void;
}

function AllProjectsSelector({
  project,
  setProject,
  ...rest
}: IAllProjectsSelectorProps) {
  const [projects, setProjects] = useState<ApiProject[]>([]);
  const clients = useSelector(clientListSelector);
  const dispatch = useDispatch();

  const getClientName = useCallback(
    (id) => {
      return clients.list.find((c) => c.id === id)?.businessName || "";
    },
    [clients]
  );

  useMount(async () => {
    dispatch(initializeClientsThunk());
    // todo: move all projects to global state
    const res = await listAllProjects();
    setProjects(
      sortNum(
        (res || []).map((p) => ({
          ...p,
          client: {
            id: p.clientId,
            businessName: getClientName(p.clientId),
          },
        })),
        "clientId"
      )
    );
  });

  return (
    <Autocomplete
      id="project-picker"
      size="small"
      onChange={(e, value) => {
        setProject(value);
      }}
      disableClearable
      value={project || {}}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id;
      }}
      options={projects}
      getOptionLabel={(o) => o.name || ""}
      groupBy={(o) => o.client?.businessName || ""}
      renderInput={(params) => (
        <TextField {...params} label="Select a project" {...rest} />
      )}
    />
  );
}

export default AllProjectsSelector;
