/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSubscriptionTierFromJSON, ApiSubscriptionTierToJSON, } from './ApiSubscriptionTier';
export function ApiSimpleProductFromJSON(json) {
    return ApiSimpleProductFromJSONTyped(json, false);
}
export function ApiSimpleProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tier': !exists(json, 'tier') ? undefined : ApiSubscriptionTierFromJSON(json['tier']),
    };
}
export function ApiSimpleProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'tier': ApiSubscriptionTierToJSON(value.tier),
    };
}
