import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  ProductCompareWrapper,
  ProductTopCategoryBrandComp,
} from "features/analytics";
import { useSelectedProject } from "Hooks";
import { useLocalstorageState } from "Hooks/useLocalstorageState";
import { useProducts } from "Hooks/useProducts";
import { useHistory } from "react-router-dom";

function ProductOverviewPage() {
  const { products } = useProducts();
  const { selectedClient, selectedProject } = useSelectedProject();
  const history = useHistory();
  const [mode, setMode] = useLocalstorageState<"category" | "brand">(
    "ProductOverviewPage-mode",
    "category"
  );

  const toProductCategory = (c: string) => {
    history.push(
      `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-category-deep-dive`,
      {
        selectedProductCategory: c,
      }
    );
  };
  const toProductBrand = (c: string) => {
    history.push(
      `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-brand-deep-dive`,
      {
        selectedProductBrand: c,
      }
    );
  };
  const toProduct = (n: string) => {
    history.push(
      `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-deep-dive`,
      {
        selectedProductId: products.find((p) => p.name === n)?.id,
      }
    );
  };
  return (
    <>
      <Box
        mt={1}
        mb={3}
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: `repeat(12, 1fr)`,
          gridTemplateRows: `minmax(127px, 1fr) repeat(11, 127px)`,
          rowGap: 3,
          columnGap: AnalyticsSpacing,
        }}
      >
        <ProductCompareWrapper />

        <Box sx={{ gridArea: "4 / 1 / 7 / 13" }}>
          <ProductTopCategoryBrandComp />
        </Box>
        <Box sx={{ gridArea: "7 / 1 / 11 / 7" }}>
          <AnalyticsCard
            onClick={{
              [ApiDimension.DIMENSION_PRODUCT_CATEGORY]: toProductCategory,
              [ApiDimension.DIMENSION_PRODUCT_BRAND]: toProductBrand,
            }}
            customRightTop={
              <StyledFlexBox>
                <Box display="flex" alignItems="center">
                  <Typography>Categories</Typography>
                  <Switch
                    size="small"
                    checked={mode === "brand"}
                    onChange={(e, checked) =>
                      setMode(checked ? "brand" : "category")
                    }
                  />
                  <Typography>Brands</Typography>
                </Box>
              </StyledFlexBox>
            }
            chart={{
              name: `Product ${
                mode === "category" ? "Category" : "Brand"
              } Insights`,
              dimension: [
                mode === "category"
                  ? ApiDimension.DIMENSION_PRODUCT_CATEGORY
                  : ApiDimension.DIMENSION_PRODUCT_BRAND,
              ],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                    ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                    ApiMetric.METRIC_PRODUCT_REVENUE,
                    ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                    ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                    ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                    ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                    ApiMetric.METRIC_SALES_COUNT,
                    ApiMetric.METRIC_SALES_COMPLETION_RATE,
                    ApiMetric.METRIC_SALES_REVENUE,
                    ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
                noAttributionToggle: true,
                rowsPerPage: 6,
              },
            }}
          />
        </Box>
        <Box sx={{ gridArea: "7 / 7 / 11 / 13" }}>
          <AnalyticsCard
            onClick={{
              [ApiDimension.DIMENSION_PRODUCT_NAME]: toProduct,
            }}
            chart={{
              name: `Product Insights`,
              dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                    ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                    ApiMetric.METRIC_PRODUCT_REVENUE,
                    ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                    ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                    ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                    ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                    ApiMetric.METRIC_SALES_COUNT,
                    ApiMetric.METRIC_SALES_COMPLETION_RATE,
                    ApiMetric.METRIC_SALES_REVENUE,
                    ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
                noAttributionToggle: true,
                rowsPerPage: 6,
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default ProductOverviewPage;
