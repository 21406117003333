import {
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import { ApiSimpleSubscription, ApiSubscriptionStatus } from "@incendium/api";
import SubscriptionPaymentCard from "features/subscription/components/SubscriptionPaymentCard";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";

interface ISubscriptionCardProps {
  subscription: ApiSimpleSubscription;
  onEdit?: (s: ApiSimpleSubscription) => void;
  onDelete?: (s: ApiSimpleSubscription) => void;
  onActivate?: (b: boolean, s: ApiSimpleSubscription) => void;
}

function SubscriptionCard({
  subscription,
  onEdit,
  onDelete,
  onActivate,
}: ISubscriptionCardProps) {
  return (
    <>
      <SubscriptionPaymentCard
        name={subscription.product?.name || ""}
        description={subscription.description || ""}
        price={subscription.price || 0}
        hidePayment
        action={
          onEdit || onDelete || onActivate ? (
            <Grid mt={2} container spacing={2}>
              <Grid item xs={12}>
                <IndentDivider />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={"row"} pl={1} justifyContent="space-between">
                  <Stack direction={"row"}>
                    {onEdit && (
                      <IconButton
                        disabled={
                          subscription.status ===
                          ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
                        }
                        size="small"
                        color="primary"
                        onClick={() => onEdit(subscription)}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {onDelete && (
                      <IconButton
                        disabled={
                          subscription.status ===
                          ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
                        }
                        size="small"
                        color="secondary"
                        onClick={() => onDelete(subscription)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                  {onActivate && (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={
                            subscription.status ===
                              ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE ||
                            !subscription.contractId ||
                            !subscription.productId
                          }
                          checked={
                            subscription.status ===
                            ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
                          }
                          onChange={(e, c) => {
                            onActivate(c, subscription);
                          }}
                        />
                      }
                      label="Activate"
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <></>
          )
        }
      />
    </>
  );
}

export default SubscriptionCard;
