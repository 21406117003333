import { ApiCSVField, ApiCSVFieldType, ApiCurrency } from "@incendium/api";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Autocomplete,
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatEnumVal } from "Helpers/enumToText";
import produce from "immer";
import { useState, useEffect } from "react";
import { useSelectedProject } from "Hooks";
import { CurrencyPicker } from "features/currency";

interface IUploaderManualProps {
  field: ApiCSVField;
  userValue: { [key: string]: string };
  setUserValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
}

function UploaderManual({
  field,
  userValue,
  setUserValue,
}: IUploaderManualProps) {
  const { selectedProject } = useSelectedProject();
  const [currency, setCurrency] = useState<ApiCurrency>(
    selectedProject?.currency || ApiCurrency.USD
  );
  const [value, setValue] = useState("");

  useEffect(() => {
    // timeout to force next tick, so does not clash with parent component that is resetting the value
    setTimeout(() => {
      setUserValue((userValue) =>
        produce(userValue, (draft) => {
          const name = field.id || "";
          draft[name] =
            field.type === ApiCSVFieldType.CSV_TYPE_CURRENCY && currency
              ? currency
              : value;
        })
      );
    }, 1);
  }, [value, currency, field, setUserValue]);

  return (
    <Box>
      <Box mb={1}>
        <Typography variant="subtitle2">
          Manual value will apply to every row
        </Typography>
      </Box>

      {(field.allowedValues || []).length === 2 ? (
        <ToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={(e, v) => {
            setValue(v);
          }}
        >
          <ToggleButton value={(field.allowedValues || [])[0].value || ""}>
            <Tooltip
              placement="top"
              title={(field.allowedValues || [])[0].description || ""}
              arrow
            >
              <span>{(field.allowedValues || [])[0].name}</span>
            </Tooltip>
          </ToggleButton>

          <ToggleButton value={(field.allowedValues || [])[1]}>
            <Tooltip
              placement="top"
              title={(field.allowedValues || [])[1].description || ""}
              arrow
            >
              <span>{(field.allowedValues || [])[1].name}</span>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      ) : (field.allowedValues || []).length > 0 ? (
        <Box mt={1}>
          <Autocomplete
            options={field.allowedValues || []}
            getOptionLabel={(o) => formatEnumVal(o.name || "")}
            isOptionEqualToValue={(o, v) => o.value === v}
            onChange={(e, v) => {
              setValue(v?.value || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Option"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
      ) : field.type === ApiCSVFieldType.CSV_TYPE_CURRENCY ? (
        <CurrencyPicker currency={currency} setCurrency={setCurrency} />
      ) : field.id === "accuracy" ? (
        <ToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={(e, v) => {
            setValue(v);
          }}
        >
          <ToggleButton value={"0"}>
            <Tooltip placement="top" title="(e.g $10 could be 1000)" arrow>
              <span>Cents</span>
            </Tooltip>
          </ToggleButton>

          <ToggleButton value={"1"}>
            <Tooltip placement="top" title="(e.g $10 could be 10.00)" arrow>
              <span>Dollars / Pounds</span>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      ) : field.type === ApiCSVFieldType.CSV_TYPE_DATETIME ? (
        <DatePicker
          label={formatEnumVal(field.id || "")}
          value={value}
          onChange={(d) => {
            setValue(d || "");
          }}
        />
      ) : (
        <TextField
          label={`Set Value for ${formatEnumVal(field.id || "")}`}
          type={
            field.type &&
            [
              ApiCSVFieldType.CSV_TYPE_INT,
              ApiCSVFieldType.CSV_TYPE_FLOAT,
            ].includes(field.type)
              ? "number"
              : "text"
          }
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          size="small"
        />
      )}
    </Box>
  );
}

export default UploaderManual;
