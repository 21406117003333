import { ApiClient, ApiProject } from "@incendium/api";
import { Menu, MenuItem, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { initializeProjectsThunk } from "Actions";
import clsx from "clsx";
import { useSelectedProject } from "Hooks";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { projectListSelector } from "Selectors/projectListSelector";

const useStyles = makeStyles((theme: Theme) => ({
  detailsInner: {
    flex: 1,
    overflow: "hidden",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
    },
  },
  detailsOpen: {
    borderRadius: "0px 0px 10px 0px",
  },
  dropdown: {
    width: 260,
    left: "0!important",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    color: "white",
    background: "transparent",
    "& .MuiButtonBase-root": {
      color: "white",
    },
    "& .MuiList-root": {
      background: "#292746",
    },
  },
  projectDetails: {
    width: "100%",
    border: `1px solid #37345d`,
    borderRadius: 10,
    "&.show": {
      minWidth: 222,
      "& $details": {
        opacity: 1,
        visibility: "visible",
      },
    },
    "&:hover": {
      "& $details": {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
  chooseproject: {
    display: "flex",
    cursor: "pointer",
    position: "relative",
    padding: "8px 20px 8px 19px",
    minHeight: 63,
    alignItems: "center",
  },
  iconDown: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px 0px 0px 10px",

    color: theme.palette.common.white,
    paddingLeft: 5,
    "& svg": {
      transition: "all 0.2s",
    },
  },
  iconDownOpen: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  details: {
    color: "white",
    width: "100%",
    minWidth: 1,
    zIndex: 100,
    height: "100%",
    transition: `opacity 0.3s linear`,
    opacity: 1,
    paddingBottom: 2,
    "&.isclosed": {
      opacity: 0,
      visibility: "hidden",
    },
  },
}));

interface IProjectClientSelectorProps {
  isCollapsed: boolean;
  clients: ApiClient[];
}

function ProjectClientSelector({
  isCollapsed,
  clients,
}: IProjectClientSelectorProps) {
  const [selectedClient, setSelectedClient] = useState<ApiClient | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<any | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedProject, selectedClient: client } = useSelectedProject();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (isMenuOpen) {
        return setIsMenuOpen(null);
      }
      setIsMenuOpen(e.currentTarget);
    },
    [isMenuOpen]
  );

  const handleClose = useCallback(() => {
    setIsMenuOpen(null);
  }, []);

  const projectsList = useSelector(
    selectedClient
      ? projectListSelector(selectedClient.id?.toString() as string)
      : () => null
  );

  const handleProjectClick = useCallback(
    (project: ApiProject) => () => {
      handleClose();

      history.push(`/clients/${selectedClient?.id}/projects/${project.id}`);
    },
    [handleClose, history, selectedClient?.id]
  );

  useEffect(() => {
    dispatch(initializeProjectsThunk(selectedClient?.id as number));
  }, [selectedClient, dispatch]);

  useEffect(() => {
    if (!Boolean(isMenuOpen)) {
      setSelectedClient(null);
    }
  }, [isMenuOpen]);

  const getProjectName = useCallback(() => {
    if (!Boolean(isMenuOpen) && selectedProject) {
      return selectedProject.name;
    }
    return "Select Project";
  }, [isMenuOpen, selectedProject]);

  const getClientName = useCallback(() => {
    if (selectedClient) {
      return selectedClient?.businessName;
    }
    if (!Boolean(isMenuOpen) && client) {
      return client?.businessName;
    }
    return "";
  }, [client, isMenuOpen, selectedClient]);

  const {
    projectDetails,
    chooseproject,
    iconDown,
    details,
    detailsInner,
    detailsOpen,
    dropdown,
    iconDownOpen,
  } = useStyles();
  return (
    <div className={clsx(projectDetails, { show: !!isMenuOpen })}>
      <div onClick={handleClick} className={chooseproject}>
        <div
          className={clsx(details, {
            isclosed: isCollapsed,
          })}
        >
          <div
            className={clsx(detailsInner, {
              [detailsOpen]: Boolean(isMenuOpen),
            })}
          >
            <Typography
              variant="subtitle1"
              color="inherit"
              noWrap
              fontWeight={600}
            >
              {getProjectName()}
            </Typography>
            <Typography variant="body2" color="inherit" noWrap>
              {getClientName()}
            </Typography>
          </div>
        </div>
        <div
          className={clsx(iconDown, {
            [iconDownOpen]: Boolean(isMenuOpen),
          })}
        >
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Menu
        anchorEl={isMenuOpen}
        PaperProps={{
          // elevation: 0,
          className: dropdown,
          variant: "outlined",
        }}
        open={Boolean(isMenuOpen)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        {!selectedClient && (
          <div>
            <MenuItem divider>
              <Typography variant="subtitle2" color={"inherit"} gutterBottom>
                Select Client
              </Typography>
            </MenuItem>
            {clients.map((client) => {
              return (
                <MenuItem
                  key={`client-${client.id}`}
                  onClick={() => setSelectedClient(client)}
                >
                  <Typography color={"inherit"}>
                    {" "}
                    {client.businessName}
                  </Typography>
                </MenuItem>
              );
            })}
          </div>
        )}
        {selectedClient && projectsList && (
          <div>
            <MenuItem divider>
              <Typography
                variant="subtitle2"
                color={"inherit"}
                noWrap
                gutterBottom
              >
                Select Project for {getClientName()}
              </Typography>
            </MenuItem>
            {projectsList.list.map(({ project }) => {
              return (
                <MenuItem
                  key={`project-${project.id}`}
                  onClick={handleProjectClick(project)}
                >
                  <Typography color={"inherit"}>{project.name}</Typography>
                </MenuItem>
              );
            })}
          </div>
        )}
      </Menu>
    </div>
  );
}

export default ProjectClientSelector;
