import { ApiChartAttribute } from "@incendium/api";
import { BoxProps, Box } from "@mui/material";
import ChartBuilderAttributionPill from "./ChartBuilderAttributionPill";
import { CallbackOrVal } from "Interfaces";

interface IChartAttributesSelectedProps extends BoxProps {
  attributes: ApiChartAttribute[];
  setAttributes: (val: CallbackOrVal<ApiChartAttribute[]>) => void;
  onClick?: (attribute: any) => void;
}

function ChartAttributesSelected({
  attributes,
  setAttributes,
  onClick,
  sx,
  ...rest
}: IChartAttributesSelectedProps) {
  return (
    <Box display={"flex"} sx={{ ...sx, flexWrap: "wrap" }} {...rest}>
      {attributes.map((a, i) => (
        <Box key={i} mr={1} mb={1}>
          <ChartBuilderAttributionPill
            attribute={a}
            attributes={attributes}
            setAttributes={setAttributes}
            onClick={onClick}
          />
        </Box>
      ))}
    </Box>
  );
}

export default ChartAttributesSelected;
