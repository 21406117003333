import { ApiMetric } from "@incendium/api";
import { truncate } from "Helpers/truncate";
import { TChartData } from "Interfaces";
import { IYKey } from "Interfaces";
import { useFormatMetric } from "features/analytics";
import { useMemo } from "react";
import { useUnmount } from "react-use";
import { XAxisProps, YAxisProps } from "recharts";
import { LayoutType } from "recharts/types/util/types";
import { DIMENSION_TRUNCATE } from "features/analytics/constants";

// we calc the height by creating a hidden element with each tick name in it, see its width and calculate based on angle and a set height of 18px (tick height)
export const useXHeight = (
  data: TChartData[],
  xAxisProps?: XAxisProps,
  layout?: LayoutType
) => {
  useUnmount(() => {
    const id = `inc-h-c-${names.join("-")}`;
    let el = document.getElementById(id);
    if (el) {
      el.remove();
    }
  });

  const names = useMemo(() => (data || []).map((d) => d.name), [data]);

  return useMemo(() => {
    if (!names || names.length === 0) {
      return 0;
    }
    const id = `inc-h-c-${names.join("-")}`;

    let el = document.getElementById(id);
    if (!el) {
      el = document.createElement("div");
      el.id = id;
      names.forEach((name, i) => {
        const innerElement = document.createElement("div");
        innerElement.innerHTML = xAxisProps?.tickFormatter
          ? xAxisProps.tickFormatter(name, i)
          : truncate(name, DIMENSION_TRUNCATE);
        innerElement.style.fontSize = xAxisProps?.fontSize
          ? `${xAxisProps?.fontSize}px`
          : "11px";
        el?.appendChild(innerElement);
      });

      el.style.visibility = "hidden";
      el.style.height = "12px";
      el.style.overflow = "hidden";
      el.style.top = "0";
      el.style.position = "absolute";
      el.style.zIndex = "-1";
      el.style.display = "inline-block";
      document.body.appendChild(el);
    }
    if (layout === "vertical") {
      return el.clientWidth + 10;
    }

    const height = el.clientWidth + 6;

    return height > 25 ? height : 25;
  }, [xAxisProps, names, layout]);
};

export const useYWidth = (
  data: TChartData[],
  yAxisKeys?: IYKey[],
  yProps?: YAxisProps[]
) => {
  const formatMetric = useFormatMetric();
  return useMemo(() => {
    if (!data || data.length === 0) {
      return [0];
    }
    let keys: IYKey[] | string[] = [];
    if (!yAxisKeys) {
      const { name, ...rest } = data[0];
      keys = Object.keys(rest);
    } else {
      keys = [...yAxisKeys];
    }

    const d = keys.map((y, i) => {
      const p = yProps && yProps[i];
      if (p?.width) {
        return p.width;
      }
      const charWidth = 7; // esitmate
      const k = typeof y === "object" ? y.key : y;
      if (!k) {
        return 0;
      }

      // loop through fields finding largest metric
      if (typeof y === "object") {
        let s = Math.max(
          ...(y.fields || []).map((f) => {
            return Math.max(
              ...data
                .filter((d) => d[f])
                .map((d) => {
                  if (p?.tickFormatter) {
                    return `${p.tickFormatter(d[f], i)}`.length;
                  }
                  return formatMetric(f as ApiMetric, Number(d[f])).length;
                })
            );
          })
        );

        const n =
          s * charWidth +
          (p &&
          typeof p.label === "object" &&
          p.label.hasOwnProperty("position")
            ? 40
            : 10);

        return n < 40 ? 40 : n;
      }
      return 40;
    });

    return d;
  }, [yAxisKeys, data, yProps, formatMetric]);
};
