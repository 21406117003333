import {
  Theme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import theme from "theme";
import { Router } from "Router";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import store, { persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";
import ConfirmationProvider from "Providers/ConfirmationProvider";
import LayoutProvider from "Providers/LayoutProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AppDndProvider from "Providers/AppDndProvider";
import ReportBuilderProvider from "Providers/ReportBuilderProvider";
import { CssBaseline } from "@mui/material";
import { useEffect } from "react";
import { setupCustomValidators } from "services/validators";
import Loading from "Components/Loading/Loading";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  useEffect(() => {
    return setupCustomValidators();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ConfirmationProvider>
            <AppDndProvider>
              <LayoutProvider>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateFormats={{
                    keyboardDate: "YYYY/MM/DD",
                  }}
                >
                  <PersistGate
                    persistor={persistor}
                    loading={<Loading fullHeight />}
                  >
                    <SnackbarProvider>
                      <ReportBuilderProvider>
                        <CssBaseline />
                        <Router />
                      </ReportBuilderProvider>
                    </SnackbarProvider>
                  </PersistGate>
                </LocalizationProvider>
              </LayoutProvider>
            </AppDndProvider>
          </ConfirmationProvider>
        </StyledEngineProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
