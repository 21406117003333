import {
  Box,
  Divider,
  Grid,
  Portal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ComparisonPill from "Components/ComparisonPill/ComparisonPill";
import GlassCard from "Components/GlassCard/GlassCard";
import { percentageOf } from "Helpers/percentage";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  AnalyticsFilterDropdown,
  ChartLegendItem,
  table5Rows,
  useChartData,
} from "features/analytics";
import {
  ApiChartLayout,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import Loading from "Components/Loading/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import CircularProgressBar from "Components/CircularProgressBar/CircularProgressBar";
import { AnalyticsSpacing, PageActionPreSlot } from "consts";
import { getDataValueOrZeroByKey } from "Helpers/analytics";

function LocationOverviewPage() {
  const { selectedProject } = useSelectedProject();
  const theme = useTheme();
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const { chartOutput, chartComparisonOutput } = useFromToContext();

  const onClick = useCallback((e) => {
    setSelectedLocation(e);
  }, []);

  const landingChart = useMemo(
    () => ({
      name: "",
      dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_SESSIONS_COUNT,
            ApiMetric.METRIC_PAGE_VIEWS_COUNT,
            ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
            ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
            ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
          ],
        },
      ],
      attributes: [],
    }),
    []
  );
  const locationChart = useMemo(
    () => ({
      name: "",
      dimension: [ApiDimension.DIMENSION_LOCATION],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_SESSIONS_COUNT,
            ApiMetric.METRIC_PAGE_VIEWS_COUNT,
            ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
            ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
            ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
          ],
        },
      ],
      attributes: [],
    }),
    []
  );

  const { chartData: keyLandingData, loading } = useChartData(
    selectedProject,
    landingChart,
    chartOutput
  );
  const { chartData: compKeyLandingData } = useChartData(
    selectedProject,
    landingChart,
    chartComparisonOutput
  );
  const { chartData: keyLocationData } = useChartData(
    selectedProject,
    locationChart,
    chartOutput
  );
  const { chartData: compKeyLocationData } = useChartData(
    selectedProject,
    locationChart,
    chartComparisonOutput
  );

  const selectedLandingLocationChartData = useMemo(() => {
    if (!keyLandingData) {
      return null;
    }
    return (keyLandingData.data || []).find(
      (row) => row.name === selectedLocation
    );
  }, [keyLandingData, selectedLocation]);

  const selectedLandingLocationComparismentChartData = useMemo(() => {
    if (!compKeyLandingData) {
      return null;
    }
    return (compKeyLandingData.data || []).find(
      (row) => row.name === selectedLocation
    );
  }, [compKeyLandingData, selectedLocation]);

  const selectedLocationChartData = useMemo(() => {
    if (!keyLocationData) {
      return null;
    }
    return (keyLocationData.data || []).find(
      (row) => row.name === selectedLocation
    );
  }, [keyLocationData, selectedLocation]);

  const selectedLocationComparismentChartData = useMemo(() => {
    if (!compKeyLocationData) {
      return null;
    }
    return (compKeyLocationData.data || []).find(
      (row) => row.name === selectedLocation
    );
  }, [compKeyLocationData, selectedLocation]);

  const percentageOfPageviews = useMemo(() => {
    if (!selectedLocationChartData || !selectedLocationComparismentChartData) {
      return [];
    }
    const totalCurrent = (keyLocationData?.data || []).reduce(
      (a, v) => a + Number(v[ApiMetric.METRIC_PAGE_VIEWS_COUNT] || 0),
      0
    );
    const totalCompare = (compKeyLocationData?.data || []).reduce(
      (a, v) => a + Number(v[ApiMetric.METRIC_PAGE_VIEWS_COUNT] || 0),
      0
    );
    return [
      percentageOf(
        Number(selectedLocationChartData[ApiMetric.METRIC_PAGE_VIEWS_COUNT]) ||
          0,
        totalCurrent
      ),
      percentageOf(
        Number(
          selectedLocationComparismentChartData[
            ApiMetric.METRIC_PAGE_VIEWS_COUNT
          ]
        ) || 0,
        totalCompare
      ),
    ];
  }, [
    keyLocationData,
    compKeyLocationData,
    selectedLocationChartData,
    selectedLocationComparismentChartData,
  ]);

  const keyStats = useMemo(() => {
    return [
      {
        name: "Landing sessions",
        current: getDataValueOrZeroByKey(
          selectedLandingLocationChartData,
          ApiMetric.METRIC_SESSIONS_COUNT
        ),
        comparison: getDataValueOrZeroByKey(
          selectedLandingLocationComparismentChartData,
          ApiMetric.METRIC_SESSIONS_COUNT
        ),
        isPercentage: false,
      },
      {
        name: "Page Views",
        current: getDataValueOrZeroByKey(
          selectedLocationChartData,
          ApiMetric.METRIC_PAGE_VIEWS_COUNT
        ),
        comparison: getDataValueOrZeroByKey(
          selectedLocationComparismentChartData,
          ApiMetric.METRIC_PAGE_VIEWS_COUNT
        ),
        isPercentage: false,
      },
      {
        name: "Percentage of pageviews",
        current: percentageOfPageviews[0],
        comparison: percentageOfPageviews[1],
        isPercentage: true,
      },
      {
        name: "Engagement rate",
        current: getDataValueOrZeroByKey(
          selectedLocationChartData,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedLocationComparismentChartData,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE
        ),
        isPercentage: true,
      },
      {
        name: "Landing sessions macro CR",
        current: getDataValueOrZeroByKey(
          selectedLandingLocationChartData,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedLandingLocationComparismentChartData,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE
        ),
        isPercentage: true,
      },
      {
        name: "Avg time on page",
        current: getDataValueOrZeroByKey(
          selectedLocationChartData,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedLocationComparismentChartData,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE
        ),
        isPercentage: false,
      },
      {
        name: "Avg viewed percentage",
        current: getDataValueOrZeroByKey(
          selectedLocationChartData,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedLocationComparismentChartData,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE
        ),
        isPercentage: true,
      },
      {
        name: "Avg scrolled percentage",
        current: getDataValueOrZeroByKey(
          selectedLocationChartData,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedLocationComparismentChartData,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE
        ),
        isPercentage: true,
      },
    ];
  }, [
    selectedLandingLocationChartData,
    selectedLandingLocationComparismentChartData,
    selectedLocationChartData,
    selectedLocationComparismentChartData,
    percentageOfPageviews,
  ]);

  const avgScrolledPercentage = useMemo(() => {
    const sum = (keyLocationData?.data || []).reduce((a, v) => {
      return a + Number(v[ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE] || 0);
    }, 0);
    return sum / (keyLocationData?.data || []).length;
  }, [keyLocationData]);

  const avgViewedPercentage = useMemo(() => {
    const sum = (keyLocationData?.data || []).reduce((a, v) => {
      return a + Number(v[ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE] || 0);
    }, 0);
    return sum / (keyLocationData?.data || []).length;
  }, [keyLocationData]);

  useEffect(() => {
    if (
      selectedLocation ||
      !keyLandingData ||
      !keyLandingData.data ||
      keyLandingData.data.length === 0
    ) {
      return;
    }
    setSelectedLocation(keyLandingData.data[0].name);
  }, [keyLandingData, selectedLocation]);

  return (
    <AnimatePresence mode="wait">
      {loading || !selectedLocation ? (
        <Loading text="  Fetching Location's Data" />
      ) : (
        <Box
          component={motion.div}
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <Portal container={() => document.getElementById(PageActionPreSlot)}>
            <AnalyticsFilterDropdown
              dimension={ApiDimension.DIMENSION_LOCATION}
              defaultIndex={(options) =>
                options.findIndex((o) => o === selectedLocation)
              }
              maxWidth={250}
              onChange={(v) => {
                if (!v) {
                  return;
                }

                setSelectedLocation(v);
              }}
              disableBlank
            />
          </Portal>
          <Grid container spacing={AnalyticsSpacing} mb={4}>
            <Grid item xs={12} container spacing={AnalyticsSpacing}>
              <Grid item xs={3}>
                <GlassCard
                  noShadow
                  boxProps={{
                    px: 3,
                    py: 2,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                    Key Statistics
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ lineHeight: 1.2 }}
                    color={"secondary"}
                    fontWeight={500}
                    mb={2}
                  >
                    See how this location performed vs. the previous equal time
                    period
                  </Typography>
                  <Divider />
                  <Box>
                    <Stack
                      my={2}
                      direction="row"
                      spacing={0.5}
                      justifyContent="space-between"
                    >
                      <ChartLegendItem item="this time period" index={1} />
                      <ChartLegendItem item="previous equal period" index={3} />
                    </Stack>
                    {(keyStats || []).map((stat) => (
                      <ComparisonPill
                        key={stat.name}
                        title={stat.name}
                        value={stat.current}
                        comparisonValue={stat.comparison}
                        isPercentage={stat.isPercentage}
                      />
                    ))}
                  </Box>
                </GlassCard>
              </Grid>

              <Grid item xs={6}>
                <Grid
                  container
                  spacing={AnalyticsSpacing}
                  direction="column"
                  height={"calc(100% + 16px)"}
                >
                  <Grid item xs={4.5} container spacing={AnalyticsSpacing}>
                    <Grid item xs={6}>
                      <AnalyticsCard
                        chart={{
                          name: "Top 5 Traffic sources",
                          description: "Based on Pageviews",
                          dimension: [ApiDimension.DIMENSION_CHANNEL],
                          template: ApiChartTemplate.SNAPSHOT,
                          type: ApiChartType.PIE,
                          limit: 5,
                          attributes: selectedLocation
                            ? [
                                {
                                  key: ApiDimension.DIMENSION_LOCATION,
                                  value: selectedLocation,
                                },
                              ]
                            : [],
                          yAxisKeys: [
                            {
                              key: "l",
                              fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                              chart: {
                                [ApiMetric.METRIC_SESSIONS_COUNT]:
                                  ApiYAxisChartType.BAR,
                              },
                            },
                          ],
                          displayOptions: {
                            noLegend: true,
                            noFilters: true,
                            layout: ApiChartLayout.VERTICAL,
                            showTotals: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AnalyticsCard
                        chart={{
                          name: "Top 5 Traffic sources",
                          description: "Based on Landing Pageviews",
                          dimension: [ApiDimension.DIMENSION_CHANNEL],
                          template: ApiChartTemplate.SNAPSHOT,
                          type: ApiChartType.PIE,
                          limit: 5,
                          attributes: selectedLocation
                            ? [
                                {
                                  key: ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
                                  value: selectedLocation,
                                },
                              ]
                            : [],
                          yAxisKeys: [
                            {
                              key: "l",
                              fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                              chart: {
                                [ApiMetric.METRIC_SESSIONS_COUNT]:
                                  ApiYAxisChartType.BAR,
                              },
                            },
                          ],
                          displayOptions: {
                            noLegend: true,
                            noFilters: true,
                            layout: ApiChartLayout.VERTICAL,
                            showTotals: true,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={7.5}>
                    <AnalyticsCard
                      chart={{
                        name: "Pageviews & Page Engagement Rate Trend",
                        dimension: [
                          ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                        ],
                        template: ApiChartTemplate.TREND,
                        type: ApiChartType.GRAPH,
                        attributes: selectedLocation
                          ? [
                              {
                                key: ApiDimension.DIMENSION_LOCATION,
                                value: selectedLocation,
                              },
                            ]
                          : [],
                        yAxisKeys: [
                          {
                            key: "l",
                            fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                            chart: {
                              [ApiMetric.METRIC_PAGE_VIEWS_COUNT]:
                                ApiYAxisChartType.BAR,
                            },
                          },
                          {
                            key: "r",
                            fields: [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE],
                          },
                        ],
                        displayOptions: {
                          noFilters: true,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  spacing={AnalyticsSpacing}
                  direction="column"
                  height={"calc(100% + 16px)"}
                >
                  <Grid item xs={4}>
                    <AnalyticsCard
                      chart={{
                        name: "Recorded Audience Composition",
                        dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                        template: ApiChartTemplate.SNAPSHOT,
                        type: ApiChartType.PIE,
                        limit: 5,
                        attributes: selectedLocation
                          ? [
                              {
                                key: ApiDimension.DIMENSION_LOCATION,
                                value: selectedLocation,
                              },
                            ]
                          : [],
                        yAxisKeys: [
                          {
                            key: "l",
                            fields: [ApiMetric.METRIC_LEADS_COUNT],
                          },
                        ],
                        displayOptions: {
                          noFilters: true,
                          showTotals: true,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <GlassCard noShadow sx={{ height: "100%" }}>
                      <Stack
                        p={2}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ height: "100%" }}
                      >
                        <StyledMiddleBox flexDirection={"column"}>
                          <CircularProgressBar
                            value={avgScrolledPercentage}
                            customColour={theme.palette.charts[4]}
                          />
                          <Typography mt={1} align="center" variant="body2">
                            Avg. scrolled <br />
                            percentage <br />
                            (all locations)
                          </Typography>
                        </StyledMiddleBox>
                        <StyledMiddleBox flexDirection={"column"}>
                          <CircularProgressBar
                            value={avgViewedPercentage}
                            customColour={theme.palette.charts[2]}
                          />
                          <Typography mt={1} align="center" variant="body2">
                            Avg. viewed
                            <br /> percentage <br />
                            (all locations)
                          </Typography>
                        </StyledMiddleBox>
                      </Stack>
                    </GlassCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{ minHeight: table5Rows }}>
              <AnalyticsCard
                onClick={{
                  [ApiDimension.DIMENSION_LOCATION]: onClick,
                }}
                chart={{
                  name: "Pages Locations",
                  dimension: [ApiDimension.DIMENSION_LOCATION],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                        ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
                        ApiMetric.METRIC_SESSION_REVENUE,
                        ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                        ApiMetric.METRIC_BOUNCE_RATE,
                        ApiMetric.METRIC_EXIT_RATE,
                      ],
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AnalyticsCard
                chart={{
                  name: "Landing Page Location",
                  dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes: [],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                        ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
                        ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                        ApiMetric.METRIC_SESSION_REVENUE,
                        ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                      ],
                    },
                  ],
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </AnimatePresence>
  );
}

export default LocationOverviewPage;
