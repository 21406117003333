import { ApiSimpleContract } from "@incendium/api";
import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import SavingButton from "Components/UI/SavingButton";
import SpacedList from "Components/UI/SpacedList";
import { StyledDrawerContainer } from "Components/UI/StyledDrawer";
import { cell2Icons } from "consts";
import {
  deleteContract,
  saveContract,
  useContracts,
  useSubscriptions,
} from "features/subscription";
import { SimpleTextEditor } from "features/uiBuilder";
import { truncate } from "Helpers/truncate";
import withInfoColumn from "HoC/withInfoColumn";
import { useNotification } from "Hooks";
import { useConfirmation } from "Hooks/useConfirmation";
import { useSave } from "Hooks/useSave";
import produce from "immer";
import { useCallback, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IContractsPageInnerProps {
  contracts: ApiSimpleContract[];
  onEdit: (c: ApiSimpleContract) => void;
  onDelete: (c: ApiSimpleContract) => void;
}

function ContractsPageInner({
  contracts,
  onEdit,
  onDelete,
}: IContractsPageInnerProps) {
  const { subscriptions } = useSubscriptions();

  const hasSub = useCallback(
    (contract: ApiSimpleContract) => {
      return (subscriptions || []).some((v) => v.contractId === contract.id);
    },
    [subscriptions]
  );

  return (
    <SpacedList title="Contracts List">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Body</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contracts.map((contract) => (
          <TableRow key={contract.id}>
            <TableCell>{contract.name}</TableCell>
            <TableCell>{truncate(contract.description || "", 30)}</TableCell>
            <TableCell>{truncate(contract.body || "", 50)}</TableCell>
            <TableCell align="right" color="primary" sx={{ width: cell2Icons }}>
              <IconButton
                disabled={hasSub(contract)}
                size="small"
                color="primary"
                onClick={() => onEdit(contract)}
              >
                <Edit />
              </IconButton>
              <IconButton
                disabled={hasSub(contract)}
                size="small"
                color="secondary"
                onClick={() => onDelete(contract)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

function ContractsPage() {
  const { contracts, setContracts } = useContracts();
  const [contract, setContract] = useState<ApiSimpleContract>({});
  const { saving, save } = useSave();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const handleDelete = useConfirmation();

  const onSubmit = useCallback(() => {
    try {
      save(async () => {
        const res = await saveContract(contract);
        setContract({});
        setContracts((cont) =>
          produce(cont, (draft) => {
            const idx = draft.findIndex((d) => d.id === res.id);
            if (idx >= 0) {
              draft[idx] = res;
            } else {
              draft.push(res);
            }
          })
        );
        showSuccessNotification("Contract Saved");
      });
    } catch (error) {
      showErrorNotification("Failed to save Contract, please try again");
    }
  }, [
    save,
    contract,
    showSuccessNotification,
    showErrorNotification,
    setContracts,
  ]);

  const onEdit = useCallback((c: ApiSimpleContract) => {
    setContract(c);
  }, []);

  const onDelete = useCallback(
    (c: ApiSimpleContract) => {
      handleDelete({
        title: `Are you sure you want to delete this contract`,
        body: `This action cannot be undone.`,
        callback: async () => {
          await deleteContract(c.id || "");
          setContracts((subs) =>
            produce(subs, (draft) => {
              const idx = draft.findIndex((d) => d.id === c.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );
          return "Contract Deleted";
        },
      });
    },
    [handleDelete, setContracts]
  );

  return withInfoColumn(ContractsPageInner)({
    contracts,
    onEdit,
    onDelete,
    infoColumn: (
      <ValidatorForm onSubmit={onSubmit}>
        <StyledDrawerContainer>
          <GlassCard noShadow>
            <Stack spacing={2}>
              <Typography variant="subtitle1">Create New Contract</Typography>
              <TextValidator
                label="Contract Name"
                value={contract.name || ""}
                variant="outlined"
                name="name"
                size="small"
                fullWidth
                validators={["required"]}
                errorMessages={["Name is required"]}
                onChange={(e: any) =>
                  setContract((contract) =>
                    produce(contract, (draft) => {
                      draft.name = e.target.value;
                    })
                  )
                }
              />
              <TextValidator
                label="Contract Description"
                value={contract.description || ""}
                variant="outlined"
                name="description"
                size="small"
                fullWidth
                multiline
                rows={2}
                validators={["required"]}
                errorMessages={["Description is required"]}
                onChange={(e: any) =>
                  setContract((contract) =>
                    produce(contract, (draft) => {
                      draft.description = e.target.value;
                    })
                  )
                }
              />

              <SimpleTextEditor
                content={contract.body || ""}
                setContent={(v: string) =>
                  setContract((contract) =>
                    produce(contract, (draft) => {
                      draft.body = v;
                    })
                  )
                }
              />
            </Stack>

            <Stack
              mt={4}
              direction={"row"}
              spacing={2}
              justifyContent="flex-end"
            >
              <Button onClick={(e) => setContract({})} color="secondary">
                Cancel
              </Button>
              <SavingButton
                variant="contained"
                color="primary"
                type="submit"
                saving={saving}
              >
                Save
              </SavingButton>
            </Stack>
          </GlassCard>
        </StyledDrawerContainer>
      </ValidatorForm>
    ),
  });
}

export default ContractsPage;
