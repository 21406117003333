import { ApiBucketResponse } from "@incendium/api";
import { Portal } from "@mui/material";
import { trafficBucketService } from "Apis";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import TrafficBucketsList from "Components/TrafficBuckets/TrafficBucketsList";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useConfirmation } from "Hooks/useConfirmation";
import { useSelectedProject } from "Hooks/useSelectedProject";
import { useTrafficBuckets } from "Hooks/useTrafficBuckets";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export const TrafficBucketsPage = () => {
  const { selectedProject } = useSelectedProject();
  const { trafficBuckets, setTrafficBuckets } = useTrafficBuckets();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = useConfirmation();

  const newTrafficBucket = () => {
    setOpen(true);
  };

  const editTrafficBucket = (trafficBucket: ApiBucketResponse) => {
    history.push(`buckets/${trafficBucket.id}`);
  };

  const deleteTrafficBucket = (trafficBucket: ApiBucketResponse) => {
    handleDelete({
      title: `Are you sure you want to delete this Traffic Bucket`,
      body: `This action can not be undone`,
      callback: async () => {
        await trafficBucketService.bucketServiceDeleteBucket({
          projectId: selectedProject?.id as number,
          bucketId: trafficBucket.id as number,
        });

        setTrafficBuckets(
          produce(trafficBuckets, (draft) => {
            const idx = trafficBuckets.findIndex(
              (ch) => ch.id === trafficBucket.id
            );
            if (idx !== -1) draft.splice(idx, 1);
          })
        );
        return `${trafficBucket.name || ""} Deleted`;
      },
    });
  };

  const onSave = async (name: string) => {
    try {
      const res = await trafficBucketService.bucketServiceCreateBucket({
        projectId: selectedProject?.id as number,
        payload: {
          name,
        },
      });

      setTrafficBuckets(
        produce(trafficBuckets, (draft) => {
          draft.push(res);
        })
      );

      enqueueSnackbar(`${name} Created, redirecting`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      editTrafficBucket(res);
    } catch (error) {
      enqueueSnackbar(`The was an internal error, please try again`, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  return (
    <>
      {selectedProject ? (
        <>
          <Portal container={() => document.getElementById("pageTitle")}>
            <TypographyHelp
              variant="h1"
              text={`${selectedProject.name} Traffic Buckets`}
              placement={"right"}
              tooltip={`Traffic Buckets group different traffic sources so that they can be compared in Analytics, for example in attribution modeling. The difference between Traffic Buckets and Channels is that these groupings allow for data to belong to multiple groupings at the same time, where as with channels data can only belongs to one group at any one time.`}
            />
          </Portal>
          <TrafficBucketsList
            onNew={newTrafficBucket}
            onEdit={editTrafficBucket}
            onDelete={deleteTrafficBucket}
            buckets={trafficBuckets || []}
          />
        </>
      ) : (
        "No Project selected"
      )}
      <CreateNameDialog
        open={open}
        setOpen={setOpen}
        onSaved={onSave}
        title="Add Traffic Bucket Name"
      />
    </>
  );
};
