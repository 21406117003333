import { alpha, Box, Stack, styled, Typography } from "@mui/material";
import produce from "immer";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  ApiChartType,
  ApiClient,
  ApiElementPosition,
  ApiProject,
  ApiReport,
  ApiTabElementResponse,
  ApiTabElementType,
  ApiTabResponse,
} from "@incendium/api";
import ChartLibraryDialog from "./ChartLibraryDialog";
import { IChart } from "Interfaces";
import { reportService } from "Apis";
import { useDebounce } from "react-use";
import { parseContextChartFromTabChart } from "Hooks/useCharts";
import { useFromToContext } from "Providers/FromToProvider";
import { AnalyticsChartStatCard } from "features/analytics";
import { downloadReport } from "features/reports";
import { useNotification } from "Hooks";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";

const ReactGridLayout = memo(WidthProvider(RGL));
const rowHeight = 53;

export const FixedReactGridLayout = styled(ReactGridLayout)(({ theme }) => ({
  "& .react-grid-item.react-grid-placeholder": {
    pointerEvents: "none",
    background: alpha(theme.palette.primary.main, 0.7),
    border: `2px solid ${theme.palette.primary.main}`,
    opacity: 1,
    borderRadius: theme.shape.borderRadius,
  },
  "& .dragH": {
    transition: "transform 0.15s ease-in-out",
  },
}));

FixedReactGridLayout.defaultProps = {
  margin: [16, 16],
};

export interface ILayout extends RGL.Layout {
  inner?: ILayout[];
  id?: number;
  parentId?: number;
  element?: ApiTabElementResponse;
  type?: ApiTabElementType;
  name?: string;
  description?: string;
}

const Item = memo(
  ({
    l,
    onRemove,
    onEdit,
    editMode,
    report,
    tab,
    project,
    client,
  }: {
    l: ILayout;
    onRemove: (l: ILayout) => void;
    onEdit: (l: ILayout) => void;
    editMode: boolean;
    report: ApiReport;
    tab: ApiTabResponse;
    project: ApiProject;
    client: ApiClient;
  }) => {
    const { showSuccessNotification, showErrorNotification } =
      useNotification();
    const { from, to, lastNDays } = useFromToContext();
    const chart = useMemo(() => {
      return parseContextChartFromTabChart(l.element?.chart || {});
    }, [l]);

    const edit = useCallback(async () => {
      await onEdit(l);
    }, [l, onEdit]);

    const remove = useCallback(async () => {
      await onRemove(l);
    }, [l, onRemove]);

    const onExport = async () => {
      try {
        await downloadReport(
          project?.id as number,
          report.id as number,
          tab.id as number,
          l.element?.chart?.id as number,
          {
            from: from
              ? from?.clone().utc().startOf("day").toDate()
              : undefined,
            to: to ? to?.clone().utc().endOf("day").toDate() : undefined,
            lastNDays: lastNDays ? lastNDays : undefined,
            timezone: client?.timezone,
          },
          l.element?.chart?.chart?.name
        );
        showSuccessNotification("Chart Exported");
      } catch (error) {
        showErrorNotification("Internal Server Error");
      }
    };

    if (chart.type === ApiChartType.STAT_CARD) {
      return (
        <AnalyticsChartStatCard
          chart={chart}
          onEdit={edit}
          fullHeight
          onDelete={remove}
          noToolbar={!editMode}
        />
      );
    }

    return (
      <AnalyticsCard
        chart={chart}
        onEdit={edit}
        onDelete={remove}
        onExport={onExport}
      />
    );
  }
);

interface IReportBuilderProps {
  layout: ILayout[];
  setLayout: React.Dispatch<React.SetStateAction<ILayout[]>>;
  project: ApiProject;
  client: ApiClient;
  report: ApiReport;
  tab: ApiTabResponse;
  editElement: (element: ApiTabElementResponse) => void;
  editMode: boolean | null;
}

function ReportBuilder({
  layout,
  setLayout,
  project,
  client,
  report,
  tab,
  editElement,
  editMode,
}: IReportBuilderProps) {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const onRefChange = useCallback((node) => {
    setRef(node);
  }, []);
  const [isDragging, setIsDragging] = useState(false);
  const [containerMinHeight, setContainerMinHeight] = useState(700);
  const { gridDimensions } = useReportBuilderContext();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [open, setOpen] = useState(false);
  const [droppedItem, setDroppedItem] = useState<ILayout | undefined>(
    undefined
  );

  const memoizedLayout = useMemo(() => {
    return layout.map((item) => ({ ...item, static: !editMode }));
  }, [layout, editMode]);

  const [debouncedLayout, setDebouncedLayout] =
    useState<ILayout[]>(memoizedLayout);
  useDebounce(
    () => {
      setDebouncedLayout(memoizedLayout);
    },
    300,
    [memoizedLayout]
  );

  const onLayoutChange = useCallback(
    (newLayout: RGL.Layout[]) => {
      if (!newLayout || newLayout.length === 0 || isDragging) {
        return;
      }

      setContainerMinHeight(700);

      setLayout(
        produce(layout, (draft) => {
          layout.forEach((l, i) => {
            const vIdx = newLayout.findIndex((nl) => nl.i === l.i);
            if (vIdx >= 0) {
              draft[i] = {
                ...draft[i],
                ...newLayout[vIdx],
              };
            }
          });
        })
      );
    },
    [isDragging, layout, setLayout]
  );

  const onDrop = useCallback(
    (lay: RGL.Layout[], item: ILayout, e: any) => {
      onLayoutChange(lay);
      // open chart selector
      let key: ApiTabElementType = e.dataTransfer.getData("dragData");
      if (key === ApiTabElementType.BLOCK) {
        setDroppedItem({ ...item, type: ApiTabElementType.BLOCK });
      } else {
        setOpen(true);
        setDroppedItem({ ...item, type: key });
      }

      return;
    },
    [onLayoutChange]
  );

  const addElement = useCallback(
    async (chart?: IChart) => {
      if (!droppedItem) {
        return;
      }

      const req = {
        projectId: project.id as number,
        reportId: report.id as number,
        tabId: tab.id as number,
        payload: {
          type: droppedItem.type,
          chart: {
            chartId: chart?.id as number,
          },
        },
      };

      const res = droppedItem.parentId
        ? await reportService.reportServiceAddElementToElement({
            ...req,
            elementId: droppedItem.parentId as number,
            parentId: droppedItem.parentId as number, // why do we need this
          })
        : await reportService.reportServiceAddElementToTab({ ...req });

      setLayout(
        produce(layout, (draft) => {
          let items = draft;
          if (droppedItem.parentId) {
            const idx = (draft || []).findIndex(
              (d) => d.id === droppedItem.parentId
            );

            if (idx >= 0) {
              if (!draft[idx].inner) {
                draft[idx].inner = [];
              }
              items = draft[idx].inner as ILayout[];
            }
          }

          let key = `${res.id}`;
          // check if key already used
          const found = items
            .filter((f) => f.i.startsWith(key))
            .map((f) => f.i.split("_")[1] || 0)
            .sort((a, b) => {
              if (a === b) return 0;
              return a > b ? -1 : 1;
            });
          if (found.length > 0) {
            key = `${key}_${Number(found[0]) + 1}`;
          }

          items.push({
            ...droppedItem,
            i: key,
            id: res.id as number,
            element: res,
            type: res.type,
          });
        })
      );
      setDroppedItem(undefined);
      setOpen(false);
    },
    [droppedItem, layout, project, report.id, tab.id, setLayout]
  );

  const onDelete = useCallback(
    async (id: number, fn: () => void) => {
      try {
        await reportService.reportServiceDeleteElement({
          projectId: project.id as number,
          reportId: report.id as number,
          tabId: tab.id as number,
          elementId: id,
        });
        fn();
        showSuccessNotification("Chart Removed");
      } catch (error) {
        showErrorNotification("There was an internal error, please try again");
      }
    },
    [
      project.id,
      report.id,
      tab.id,
      showSuccessNotification,
      showErrorNotification,
    ]
  );

  const onRemove = useCallback(
    (l: ILayout) => {
      onDelete(l.id as number, () =>
        setLayout(
          produce(layout, (draft) => {
            const idx = draft.findIndex((d) => d.i === l.i);
            if (idx >= 0) {
              draft.splice(idx, 1);
            }
          })
        )
      );
    },
    [layout, onDelete, setLayout]
  );

  const onEdit = useCallback(
    (l: ILayout) => {
      editElement(l.element || {});
    },
    [editElement]
  );

  const reOrder = useCallback(
    async (layout: ILayout[]) => {
      let positions: { [key: string]: ApiElementPosition } = {};
      // flatten all layouts
      const allLayouts = [
        ...layout,
        ...layout
          .filter((l) => l.inner && l.inner.length > 0)
          .map((l) => l.inner),
      ]
        .flat(2)
        .filter((l) => l && l.id) as ILayout[];

      allLayouts.forEach((l) => {
        positions[`${l.id}`] = {
          x: l.x,
          y: l.y,
          w: l.w,
          h: l.h,
        };
      });
      if (Object.keys(positions).length === 0) {
        return;
      }
      await reportService.reportServiceReorderElements({
        projectId: project.id as number,
        reportId: report.id as number,
        tabId: tab.id as number,
        payload: {
          positions,
        },
      });
      // enforces ReactGridLayout to rerender
      window.dispatchEvent(new Event("resize"));
    },
    [project.id, report.id, tab.id]
  );

  useEffect(() => {
    // call reorder, check if needed
    if (!debouncedLayout || debouncedLayout.length === 0) {
      return;
    }

    reOrder(debouncedLayout);
  }, [debouncedLayout, reOrder]);

  const onDragStart = useCallback(
    (item, e) => {
      setContainerMinHeight(
        (ref?.clientHeight || 700) + item.h * (rowHeight + 20)
      );

      e.stopPropagation();
    },
    [ref]
  );
  const dragTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onDropDragOver = useCallback(
    (e) => {
      if (isDragging) return;
      setIsDragging(true);

      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
      }

      dragTimeoutRef.current = setTimeout(() => {
        setIsDragging(false);
      }, 1000);

      return gridDimensions || { w: 4, h: 4 };
    },
    [isDragging, gridDimensions]
  );

  const children = useMemo(() => {
    if ((memoizedLayout || []).length === 0) {
      return (
        <Box>
          <Typography>Drag Chart or block to get started</Typography>
        </Box>
      );
    }
    return memoizedLayout.map((l) => (
      <Box key={l.i} className="dragH">
        {l.type === ApiTabElementType.BLOCK ? (
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              " &::before": {
                content: "''",
                display: editMode ? "block" : "none",
                zIndex: -1,
                position: "absolute",
                left: -2,
                height: "calc(100% + 4px)",
                width: "calc(100% + 4px)",
              },
            }}
            key={l.i}
          >
            not supported
          </Stack>
        ) : (
          <Item
            l={l}
            onRemove={onRemove}
            onEdit={onEdit}
            editMode={!!editMode}
            report={report}
            tab={tab}
            project={project}
            client={client}
          />
        )}
      </Box>
    ));
  }, [
    client,
    editMode,
    memoizedLayout,
    onEdit,
    onRemove,
    project,
    report,
    tab,
  ]);

  return (
    <Box mb={7} ref={onRefChange}>
      <FixedReactGridLayout
        draggableHandle=".dragH"
        onDragStart={(a, b, c, d, e) => {
          onDragStart(b, e);
        }}
        layout={memoizedLayout}
        rowHeight={rowHeight}
        cols={12}
        draggableCancel={".MuiButtonBase-root"}
        containerPadding={[0, 20]}
        onLayoutChange={onLayoutChange}
        onDropDragOver={onDropDragOver}
        isDroppable={!!editMode}
        isResizable={!!editMode}
        isDraggable={!!editMode}
        onDrop={onDrop}
        style={{
          minHeight: containerMinHeight,
        }}
      >
        {children}
      </FixedReactGridLayout>
      <ChartLibraryDialog
        open={open}
        setOpen={setOpen}
        onSelect={addElement}
        hideTabs={droppedItem?.type === ApiTabElementType.STAT}
        type={
          droppedItem?.type === ApiTabElementType.STAT
            ? ApiChartType.STAT_CARD
            : undefined
        }
      />
    </Box>
  );
}

export default ReportBuilder;
