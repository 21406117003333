import {
  ApiProject,
  ApiProjectSubscriptionStatus,
  ApiSimpleProjectSubscription,
  ApiSubscriptionStatus,
} from "@incendium/api";
import {
  Autocomplete,
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { initializeClientsThunk } from "Actions";
import CopyClipboardBtn from "Components/CopyClipboardBtn/CopyClipboardBtn";
import GlassCard from "Components/GlassCard/GlassCard";
import SavingButton from "Components/UI/SavingButton";
import SpacedList from "Components/UI/SpacedList";
import { StyledDrawerContainer } from "Components/UI/StyledDrawer";
import { PlatformURL } from "config";
import { AllProjectsSelector, listAllProjects } from "features/project";
import {
  createProjectSubscription,
  useSubscriptions,
} from "features/subscription";
import useProjectSubscriptions from "features/subscription/hooks/useProjectSubscriptions";
import { sortNum } from "Helpers/arrays";
import { friendlyDateTime } from "Helpers/dates";
import withInfoColumn from "HoC/withInfoColumn";
import { useNotification } from "Hooks";
import { useSave } from "Hooks/useSave";
import produce from "immer";
import { useCallback, useMemo, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { clientListSelector } from "Selectors/clientListSelector";

interface IProjectSubscriptionsPageInnerProps {
  projectSubscriptions: ApiSimpleProjectSubscription[];
  projects: ApiProject[];
}

function ProjectSubscriptionsPageInner({
  projectSubscriptions,
  projects,
}: IProjectSubscriptionsPageInnerProps) {
  const link = useCallback(
    (id) => {
      const project = projects.find((p) => p.id === id);
      if (!project) {
        return "";
      }
      return `${PlatformURL}/clients/${project.clientId}/projects/${project.id}/account/payment`;
    },
    [projects]
  );
  return (
    <SpacedList title="Project Subscriptions List">
      <TableHead>
        <TableRow>
          <TableCell>Subscription</TableCell>
          <TableCell>Project</TableCell>
          <TableCell>Link</TableCell>
          <TableCell>Last Seen</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projectSubscriptions.map((ps) => (
          <TableRow key={ps.id}>
            <TableCell>{ps.subscription?.name}</TableCell>
            <TableCell>{ps.project?.name}</TableCell>

            <TableCell>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <CopyClipboardBtn
                  isIconButton
                  variant="text"
                  text={link(ps.project?.id)}
                  size="extraSmall"
                />
                <Typography variant="body2">{link(ps.project?.id)}</Typography>
              </Stack>
            </TableCell>
            <TableCell>
              {ps.seenAt ? friendlyDateTime(ps.seenAt) : "Not Seen Yet"}
            </TableCell>
            <TableCell>
              {ps.status ===
              ApiProjectSubscriptionStatus.PROJECT_SUBSCRIPTION_STATUS_PAID
                ? "Paid"
                : ps.seenAt
                ? "Seen"
                : "Created"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

function ProjectSubscriptionsPage() {
  const { projectSubscriptions, setProjectSubscriptions } =
    useProjectSubscriptions();
  const { subscriptions, loading: sLoading } = useSubscriptions();
  const [projectId, setProjectId] = useState<number | null>(null);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const { saving, save } = useSave();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [projects, setProjects] = useState<ApiProject[]>([]);
  const clients = useSelector(clientListSelector);
  const dispatch = useDispatch();

  const getClientName = useCallback(
    (id) => {
      return clients.list.find((c) => c.id === id)?.businessName || "";
    },
    [clients]
  );

  useMount(async () => {
    dispatch(initializeClientsThunk());
    // not using hook as only place we fetch all projects
    const res = await listAllProjects();
    setProjects(
      sortNum(
        (res || []).map((p) => ({
          ...p,
          client: {
            id: p.clientId,
            businessName: getClientName(p.clientId),
          },
        })),
        "clientId"
      )
    );
  });

  const onSubmit = useCallback(() => {
    if (!projectId || !subscriptionId) {
      return;
    }
    try {
      save(async () => {
        const project = projects.find((p) => p.id === projectId);
        const res = await createProjectSubscription(
          projectId,
          subscriptionId,
          `${PlatformURL}/clients/${project?.clientId}/projects/${projectId}/account/payment?success=true`
        );
        setProjectId(null);
        setSubscriptionId(null);
        setProjectSubscriptions((cont) =>
          produce(cont, (draft) => {
            const idx = draft.findIndex((d) => d.id === res.id);
            if (idx >= 0) {
              draft[idx] = res;
            } else {
              draft.push(res);
            }
          })
        );
        showSuccessNotification("Project Subscription Saved");
      });
    } catch (error) {
      showErrorNotification(
        "Failed to save Project Subscription, please try again"
      );
    }
  }, [
    save,
    projectId,
    subscriptionId,
    showSuccessNotification,
    showErrorNotification,
    setProjectSubscriptions,
    projects,
  ]);

  const selectedProject = useMemo(() => {
    return projects.find((p) => p.id === projectId);
  }, [projects, projectId]);

  const selectedSubscription = useMemo(() => {
    return subscriptions.find((p) => p.id === subscriptionId);
  }, [subscriptions, subscriptionId]);

  const activeSubscriptions = useMemo(() => {
    return subscriptions.filter(
      (s) => s.status === ApiSubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
    );
  }, [subscriptions]);

  const onSetProject = useCallback((p) => {
    setProjectId(p.id);
  }, []);

  return withInfoColumn(ProjectSubscriptionsPageInner)({
    projectSubscriptions,
    projects,
    infoColumn: (
      <ValidatorForm onSubmit={onSubmit}>
        <StyledDrawerContainer>
          <GlassCard noShadow>
            <Stack spacing={2}>
              <Typography variant="subtitle1">
                Create New Project Subscription
              </Typography>
              <AllProjectsSelector
                project={selectedProject || null}
                setProject={onSetProject}
                variant="outlined"
              />
              <Autocomplete
                id="subscription-picker"
                size="small"
                onChange={(e, value) => {
                  setSubscriptionId(value.id || null);
                }}
                disableClearable
                value={selectedSubscription || {}}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                options={activeSubscriptions}
                loading={sLoading}
                getOptionLabel={(o) => o.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a subscription"
                    variant="outlined"
                  />
                )}
              />
            </Stack>

            <Stack
              mt={4}
              direction={"row"}
              spacing={2}
              justifyContent="flex-end"
            >
              <Button color="secondary">Cancel</Button>
              <SavingButton
                variant="contained"
                color="primary"
                type="submit"
                saving={saving}
                disabled={!projectId || !subscriptionId}
              >
                Save
              </SavingButton>
            </Stack>
          </GlassCard>
        </StyledDrawerContainer>
      </ValidatorForm>
    ),
  });
}

export default ProjectSubscriptionsPage;
