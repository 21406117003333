import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiReservedPageTypes,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { usePageTypes } from "Hooks/usePageTypes";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { useMemo } from "react";
import { AnalyticsSpacing } from "consts";

function OrganicSocialOverview({ channelName }: { channelName: string }) {
  const { pageTypes } = usePageTypes();
  const blogTag = useMemo(() => {
    return (
      pageTypes.find((pt) => pt._default === ApiReservedPageTypes.BLOG)?.name ||
      ""
    );
  }, [pageTypes]);
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={8} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Top 5 Sessions by Landing Location`,
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            limit: 5,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: channelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsCard
          chart={{
            name: `Top Sessions Composition by Landing Location`,
            dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            limit: 15,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: channelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 530 }}>
        <AnalyticsCard
          chart={{
            name: `Top Landing Page Location - Primary Metrics`,
            dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,

            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: channelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 530 }}>
        <AnalyticsCard
          chart={{
            name: `Top Platforms - Primary Metrics`,
            dimension: [ApiDimension.DIMENSION_SOURCE],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,

            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: channelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Top Audiences by Sessions & Revenue`,
            dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,

            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: channelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_REVENUE],
                chart: {
                  [ApiMetric.METRIC_SESSION_REVENUE]: ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              metricsOrder: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_SESSION_REVENUE,
              ],
            },
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Top Days by Sessions & Revenue`,
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_WEEKDAY],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            orderBy: `${ApiDimension.DIMENSION_SESSION_START_BY_WEEKDAY} ASC`,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: channelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_REVENUE],
                chart: {
                  [ApiMetric.METRIC_SESSION_REVENUE]: ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              metricsOrder: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_SESSION_REVENUE,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Top Paths - Primary Metrics`,
            dimension: [ApiDimension.DIMENSION_PATH],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: channelName,
              },

              {
                key: ApiDimension.DIMENSION_LOCATION_TAGGER,
                value: blogTag,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
                  ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default OrganicSocialOverview;
