export const formatFloat = (n: number | undefined = 0) =>
  n.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatInt = (n: number | undefined = 0) => n.toLocaleString();

// formats and returns string representation
export const formatNumber = (n: number | undefined = 0) =>
  isNaN(n) ? "0.00" : Number.isInteger(n) ? formatInt(n) : formatFloat(n);

export const stringToFloat = (s: string) =>
  parseFloat((s || "").replace(/,/g, ""));

export const sumArray = (arr: number[]): number => {
  return arr.reduce((a, b) => a + b, 0);
};

export const avgArray = (arr: number[]): number => {
  return sumArray(arr) / arr.length || 0;
};

function formatToKM(num: number): string {
  const million = 1000000;
  const thousand = 1000;
  if (num >= million) {
    return formatFloat(num / million) + "M";
  } else if (num >= thousand) {
    return formatFloat(num / thousand) + "k";
  }
  return formatFloat(num);
}

export const centsToDollars = (n: number | undefined = 0) => {
  const v = n / 100;
  return formatToKM(v);
};
