import useAppendSearchState from "Hooks/useAppendSearchState";
import { useLocalstorageState } from "Hooks/useLocalstorageState";
import { useEffect, useState } from "react";

export function useLocalAndSearchState<T>(
  key: string,
  initialValue: T,
  options?: {
    serialize?: (value: T | null) => string | null;
    deserialize?: (value: string | null) => T | null;
  }
): [T | null, React.Dispatch<React.SetStateAction<T | null>>, boolean] {
  const [isLoaded, setIsLoaded] = useState(false);
  const [localValue, setLocalValue, isLocalLoaded] = useLocalstorageState<T>(
    key,
    initialValue,
    false,
    {
      serialize: options?.serialize as (value: T) => string,
      deserialize: options?.deserialize as (value: string) => T,
    }
  );

  const [urlValue, setUrlValue] = useAppendSearchState<T>(key, initialValue, {
    serialize: options?.serialize,
    deserialize: options?.deserialize,
  });

  // Sync the two states, prioritizing URL state
  useEffect(() => {
    if (!isLocalLoaded) {
      return;
    }
    if (urlValue !== null && urlValue !== localValue) {
      setLocalValue(urlValue);
    } else if (urlValue === null && localValue !== initialValue) {
      setUrlValue(localValue);
    }
    setIsLoaded(true);
  }, [
    urlValue,
    setUrlValue,
    localValue,
    setLocalValue,
    initialValue,
    isLocalLoaded,
  ]);

  return [urlValue, setUrlValue, isLoaded];
}
