import { useCallback, useEffect, useMemo, useState } from "react";

export function useLocalstorageState<T>(
  key: string,
  initialValue: T,
  noLocal?: boolean,
  options?: {
    serialize?: (value: T) => string; // Custom serialization function
    deserialize?: (value: string) => T; // Custom deserialization function
  }
): [T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const deserializeFn = useMemo(
    () => options?.deserialize || JSON.parse,
    [options]
  );
  const serializeFn = useMemo(
    () => options?.serialize || JSON.stringify,
    [options]
  );

  const [state, setInternalState] = useState<T>(initialValue);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (noLocal) {
      setIsLoaded(true);
      return;
    }
    const value = localStorage.getItem(key);
    const parsedValue = value ? deserializeFn(value) : initialValue;
    if (JSON.stringify(parsedValue) !== JSON.stringify(state)) {
      setInternalState(parsedValue);
    }

    setIsLoaded(true);
  }, [key, noLocal, deserializeFn, initialValue, state]);

  const setState: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (valueOrUpdater: React.SetStateAction<T>) => {
      const newState =
        typeof valueOrUpdater === "function"
          ? (valueOrUpdater as (prevState: T) => T)(state)
          : valueOrUpdater;

      if (!noLocal) {
        localStorage.setItem(key, serializeFn(newState));
      }

      setInternalState(newState);
    },
    [noLocal, key, state, serializeFn]
  );

  return [state, setState, isLoaded];
}
