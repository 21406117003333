import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiProjectSettingKey,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { useSelectedProject } from "Hooks";
import { useState } from "react";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { AttributionHeader, BucketsROICharts } from "features/analytics";
import { AnalyticsSpacing } from "consts";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";

interface IAttributionInsightsProps {
  marketingDimension: ApiDimension;
  title: string;
  paidChannelAttributes: ApiChartAttribute[];
}

function AttributionInsights({
  marketingDimension,
  title,
  paidChannelAttributes,
}: IAttributionInsightsProps) {
  const { projectSettings } = useSelectedProject();
  const [lifetimeSelectedMetric, setLifetimeSelectedMetric] =
    useState<ApiMetric>(
      ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT
    );

  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={12}>
        <AttributionHeader
          dimension={marketingDimension}
          title={`Marketing ${title}`}
          attributes={[...paidChannelAttributes]}
        />
      </Grid>

      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: `Total Spend by ${title}`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [...paidChannelAttributes],
            limit: 15,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SPEND],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: `Sales Count by ${title}`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [...paidChannelAttributes],
            limit: 15,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_SALES_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSION_SALES_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              noLegend: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: `ROI by ${title}`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [...paidChannelAttributes],
            limit: 15,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI],
                chart: {
                  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              noLegend: true,
            },
          }}
        />
      </Grid>

      {projectSettings[
        ApiProjectSettingKey.SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS
      ] === "true" && (
        <Grid item xs={12}>
          <BucketsROICharts dimensions={[marketingDimension]} />
        </Grid>
      )}

      <Grid item xs={6} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Sessions & Engagement Levels by ${title}`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                chart: {
                  [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE],
                chart: {
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE]:
                    ApiYAxisChartType.DOT,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              metricsOrder: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
              ],
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Conversion Lifetime Value by ${title}`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [lifetimeSelectedMetric],
                chart: {
                  [ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_FIRST_VISIT_SESSION_SALES_COUNT]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL]:
                    ApiYAxisChartType.BAR,
                  [ApiMetric.METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
              metricsOrder: [
                ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT,
                ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
                ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
                ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
                ApiMetric.METRIC_FIRST_VISIT_SESSION_SALES_COUNT,
                ApiMetric.METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL,
                ApiMetric.METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL,
                ApiMetric.METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL,
              ],
              availableMetrics: [
                ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT,
                ApiMetric.METRIC_FIRST_VISIT_SESSION_SALES_COUNT,
              ],
            },
          }}
          metricsOverride={
            lifetimeSelectedMetric ===
            ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT
              ? [
                  ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
                  ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
                  ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
                ]
              : [
                  ApiMetric.METRIC_FIRST_VISIT_SESSION_SALES_COUNT,
                  ApiMetric.METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL,
                  ApiMetric.METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL,
                  ApiMetric.METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL,
                ]
          }
          onChangeMetric={setLifetimeSelectedMetric}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `${title} Performance by`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            limit: 20,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_SALES_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
              availableMetrics: [
                ApiMetric.METRIC_SESSION_SALES_COUNT,
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
                ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
              ],
            },
          }}
        />
      </Grid>

      <Grid item xs={8} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `${title} Revenue Performance`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [...paidChannelAttributes],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE,
                  ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT,
                  ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROI,
                  ApiMetric.METRIC_SPEND,
                  ApiMetric.METRIC_SESSION_ALL_INCLUSIVE_COST,
                  ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_LTV,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `CAC by ${title}`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [...paidChannelAttributes],
            limit: 10,
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_CAC],
                chart: {
                  [ApiMetric.METRIC_CAC]: ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `New Customer Acquisition Performance`,
            dimension: [marketingDimension],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [...paidChannelAttributes],
            limit: 10,
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_CPNV,
                  ApiMetric.METRIC_CAC,
                  ApiMetric.METRIC_ECR,
                  ApiMetric.METRIC_AVERAGE_SALE_VALUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              rowsPerPage: 5,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default withPaidChannelAttributes(AttributionInsights);
