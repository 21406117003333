import { Grid, Theme, Box, Typography, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Breadcrumb from "Components/Breadcrumb/Breadcrumb";
import { useLayoutContext } from "Providers/LayoutProvider";
import { motion } from "framer-motion";
import { PageAction, PageSubAction } from "consts";

const useHeaderStyle = makeStyles((theme: Theme) => ({
  content: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  primary: {
    color: theme.palette.primary.dark,
  },

  header: {
    display: "flex",
    alignItems: "baseline",
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  user: {
    backgroundColor: theme.palette.primary.dark,
    width: 42,
    height: 42,
    borderRadius: "100%",
    overflow: "hidden",
    border: `2px solid ${theme.palette.primary.dark}`,
    "& img": {
      maxWidth: "100%",
    },
  },

  icon: {
    color: theme.palette.primary.dark,
    fontSize: 32,
  },
}));

interface IHeaderProps {
  hideBreadcrumbs?: boolean;
}

export const Header = ({ hideBreadcrumbs }: IHeaderProps) => {
  const headerClasses = useHeaderStyle();
  const { pageTitle, titleAction } = useLayoutContext();

  return (
    <div className={headerClasses.content} id="header">
      <div className={headerClasses.header}>
        <Grid container spacing={4}>
          <Grid item container sm={5}>
            <Box sx={{ maxWidth: "100%" }}>
              {!hideBreadcrumbs && <Breadcrumb />}
              <Box
                id="pageTitle"
                component={motion.div}
                key={pageTitle?.toString() || ""}
                initial={{ opacity: 0, x: -40 }}
                animate={{ opacity: 1, x: 0 }}
                mt={1.5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {pageTitle && typeof pageTitle === "function" ? (
                  <>{pageTitle}</>
                ) : pageTitle ? (
                  <Typography variant="h1">{pageTitle}</Typography>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="flex-end"
            sm={7}
          >
            <Stack spacing={2} alignItems={"flex-end"} sx={{ width: "100%" }}>
              <Box id={PageAction}>
                {titleAction && <Box>{titleAction}</Box>}
              </Box>
              <Box
                id={PageSubAction}
                display="flex"
                justifyContent={"flex-end"}
                sx={{ width: "100%" }}
              />
            </Stack>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
