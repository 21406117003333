import {
  ApiChartPayload,
  ApiChartTemplate,
  ApiChartType,
  ApiChartYAxisKey,
  ApiDimension,
} from "@incendium/api";
import { analyticsService } from "Apis";
import {
  filterNonTrendDimensions,
  filterTrendDimensions,
  getUniqueDimensionValues,
  hasDayTrend,
  isDimension,
} from "features/analytics";
import { groupBy } from "Helpers/arrays";
import { friendlyDate, getDatesBetween } from "Helpers/dates";
import { IChart, TChartData, TFilterableDimensions } from "Interfaces";
import moment from "moment";

export const getFilterableDimensions = async (
  projectId: number,
  dimension?: ApiDimension
): Promise<TFilterableDimensions> => {
  const res = await analyticsService.analyticsServiceGetAllDimensionNames({
    projectId,
    dimension,
  });
  let result = {} as TFilterableDimensions;
  (res.result || []).forEach((value) => {
    result[value.dimension as string] = value.names;
  });
  return result;
};
export async function getPrimaryAnalytics(
  projectId: number,
  chart: IChart,
  from?: Date,
  to?: Date,
  lastNDays?: number
): Promise<{
  chartData: TChartData[];
  dimensions: string[];
  totals?: TChartData;
}> {
  const response = await fetchPrimaryMetrics(
    projectId,
    chart,
    from,
    to,
    lastNDays
  );

  if (!response.rows || response.rows.length === 0) {
    return emptyResult(response.dimensions);
  }

  let chartData = transformResponseRows(response);

  if (isTrendChart(chart)) {
    chartData = fillTrendGaps(chart, response, chartData, from, to, lastNDays);
  }

  const totals = chart.withTotals
    ? computeTotals(chart, response, chartData)
    : undefined;

  return {
    dimensions: response.dimensions || [],
    chartData,
    totals,
  };
}

// Fetch metrics from the analytics service
async function fetchPrimaryMetrics(
  projectId: number,
  chart: IChart,
  from?: Date,
  to?: Date,
  lastNDays?: number
) {
  return await analyticsService.analyticsServiceGetPrimaryMetrics({
    projectId,
    chartId: 0,
    payload: {
      from,
      to,
      lastNDays,
      chart: sanitizeChart(chart),
      leadId: chart.leadId,
    },
  });
}

// Sanitize the chart object
function sanitizeChart(chart: IChart): ApiChartPayload {
  return {
    ...chart,
    attributes: (chart.attributes || []).filter((a) => a.value),
    having: chart.having?.filter((h) => typeof h.value !== "undefined"),
    yAxisKeys: (chart.yAxisKeys as ApiChartYAxisKey[]).map((y) => ({
      ...y,
      fields: y.fields?.filter((f) => f),
    })),
  };
}

// Return an empty result
function emptyResult(dimensions: string[] | undefined) {
  return {
    chartData: [],
    dimensions: dimensions || [],
    totals: undefined,
  };
}

// Transform response rows into chart data
function transformResponseRows(res: any): TChartData[] {
  return (res.rows || []).map((row) => {
    const dimensions = mapToObject(row.dimensions, res.dimensions);
    const metrics = mapToMetrics(row.metrics, res.metrics);
    return {
      name: row.dimensions ? row.dimensions[0] : "",
      ...dimensions,
      ...metrics,
    };
  });
}

// Map dimensions array to an object
function mapToObject(values: any[], keys: string[] | undefined): any {
  const result: any = {};
  (values || []).forEach((value, index) => {
    if (keys && keys[index]) {
      result[keys[index]] = value;
    }
  });
  return result;
}

// Map metrics array to an object
function mapToMetrics(values: any[], keys: string[] | undefined): any {
  const result: any = {};
  (values || []).forEach((value, index) => {
    if (keys && keys[index]) {
      result[keys[index]] = value.type?.includes("Int")
        ? Number(value.value)
        : value.value;
    }
  });
  return result;
}

// Check if the chart is a trend chart
function isTrendChart(chart: IChart): boolean {
  return (
    chart.template === ApiChartTemplate.TREND &&
    chart.type === ApiChartType.GRAPH
  );
}

// Fill gaps in trend data
function fillTrendGaps(
  chart: IChart,
  res: any,
  chartData: TChartData[],
  from?: Date,
  to?: Date,
  lastNDays?: number
): TChartData[] {
  const { startDate, endDate } = determineDateRange(
    chartData,
    from,
    to,
    lastNDays,
    chart
  );
  const granularity = getGranularity(chart);
  const intervals = getDatesBetween(startDate, endDate, granularity);

  const groupedData = groupBy(chartData, "name");
  const dimensionValues = getUniqueDimensionValues(res.rows, 1);

  return generateFilledData(
    intervals,
    groupedData,
    dimensionValues,
    res,
    chart
  );
}

// Determine the date range for trend data
function determineDateRange(
  chartData: TChartData[],
  from?: Date,
  to?: Date,
  lastNDays?: number,
  chart?: IChart
) {
  let startDate = lastNDays
    ? moment().clone().subtract(lastNDays, "days").utc().startOf("day")
    : moment(from as Date).utc();

  let endDate = lastNDays
    ? moment().utc().startOf("day")
    : moment(to as Date).utc();

  if (!hasDayTrend(chart?.dimension as ApiDimension[])) {
    if (!startDate.isSame(chartData[0].name)) {
      startDate = moment(chartData[0].name);
    }
    if (!endDate.isSame(chartData[chartData.length - 1].name)) {
      endDate = moment(chartData[chartData.length - 1].name);
    }
  }

  return { startDate, endDate };
}

// Get granularity for the trend chart
function getGranularity(chart: IChart): "hours" | "days" | "weeks" | "months" {
  const dimension = filterTrendDimensions(chart.dimension as ApiDimension[])[0];
  switch (dimension) {
    case ApiDimension.DIMENSION_SESSION_START_BY_HOUR:
      return "hours";
    case ApiDimension.DIMENSION_SESSION_START_BY_DAY:
      return "days";
    case ApiDimension.DIMENSION_SESSION_START_BY_WEEK:
      return "weeks";
    case ApiDimension.DIMENSION_SESSION_START_BY_MONTH:
      return "months";
    default:
      return "days";
  }
}

// Generate filled data for all intervals
function generateFilledData(
  intervals: moment.Moment[],
  groupedData: any,
  dimensionValues: any[],
  res: any,
  chart: IChart
): TChartData[] {
  const data: TChartData[] = [];
  const secondDimension = filterNonTrendDimensions(res.dimensions || [])[0];

  intervals.forEach((interval) => {
    const key = friendlyDate(interval);
    const dateRows = groupedData[key];

    if (!secondDimension) {
      if (dateRows) {
        data.push(dateRows[0]);
      } else {
        data.push(generateEmptyRow(key, res.metrics));
      }
      return;
    }

    dimensionValues.forEach((value) => {
      const row = dateRows?.find((g) => g[secondDimension] === value);
      data.push(
        row ||
          generateEmptyRowWithDimension(
            key,
            value,
            secondDimension,
            res.metrics
          )
      );
    });
  });

  return data;
}

// Generate an empty row for missing data
function generateEmptyRow(name: string, metrics: any[]): TChartData {
  const row: any = { name };
  metrics.forEach((metric) => {
    row[metric] = 0;
  });
  return row;
}

// Generate an empty row with dimensions
function generateEmptyRowWithDimension(
  name: string,
  dimensionValue: string,
  secondDimension: string,
  metrics: any[]
): TChartData {
  const row: any = { name, [secondDimension]: dimensionValue };
  metrics.forEach((metric) => {
    row[metric] = 0;
  });
  return row;
}

// Compute totals for the chart
function computeTotals(
  chart: IChart,
  res: any,
  chartData: TChartData[]
): TChartData {
  const aggregate: any = { id: "Total", name: "Total" };
  if (res.totalsRow?.metrics?.length) {
    Object.entries(chartData[0]).forEach(([key]) => {
      if (["id", "name"].includes(key)) {
        aggregate[key] = "Total";
      } else if (isDimension(key)) {
        aggregate[key] = "Total";
      } else {
        const totalMetric = res.totalsRow.metrics.shift();
        aggregate[key] = (totalMetric.type || "").includes("Int")
          ? Number(totalMetric.value)
          : totalMetric.value;
      }
    });
  }
  return aggregate;
}
