import { ApiSimpleSubscription } from "@incendium/api";
import { Button, Grid } from "@mui/material";
import Loading from "Components/Loading/Loading";
import SpacedListContainer from "Components/UI/SpacedListContainer";
import {
  activateSubscription,
  deleteSubscription,
  saveSubcription,
  SubscriptionCard,
  SubscriptionDrawer,
} from "features/subscription";
import { useSubscriptions } from "features/subscription/";
import { useNotification } from "Hooks";
import { useConfirmation } from "Hooks/useConfirmation";
import { useSave } from "Hooks/useSave";
import produce from "immer";
import { useCallback, useState } from "react";

function SubscriptionPages() {
  const { subscriptions, setSubscriptions, loading } = useSubscriptions();
  const [open, setOpen] = useState(false);
  const [subscription, setSubscription] = useState<ApiSimpleSubscription>({});
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { saving, save } = useSave();
  const handleConfirmation = useConfirmation();

  const onEdit = useCallback((subscription: ApiSimpleSubscription) => {
    setSubscription(subscription);
    setOpen(true);
  }, []);

  const onDelete = useCallback(
    (subscription: ApiSimpleSubscription) => {
      handleConfirmation({
        title: `Are you sure you want to delete this subscription`,
        body: `This action cannot be undone.`,
        callback: async () => {
          await deleteSubscription(subscription.id || "");
          setSubscriptions((subs) =>
            produce(subs, (draft) => {
              const idx = draft.findIndex((d) => d.id === subscription.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );
          return "Subscription Deleted";
        },
      });
    },
    [handleConfirmation, setSubscriptions]
  );

  const onSave = useCallback(() => {
    try {
      save(async () => {
        const res = await saveSubcription(subscription);
        setSubscription({});
        setSubscriptions((subs) =>
          produce(subs, (draft) => {
            const idx = draft.findIndex((d) => d.id === res.id);
            if (idx >= 0) {
              draft[idx] = res;
            } else {
              draft.push(res);
            }
          })
        );
        setOpen(false);
        showSuccessNotification("Subscription Saved");
      });
    } catch (error) {
      showErrorNotification("Failed to save subscription, please try again");
    }
  }, [
    subscription,
    save,
    setSubscriptions,
    setSubscription,
    showErrorNotification,
    showSuccessNotification,
  ]);

  const onActivate = useCallback(
    (activate: boolean, sub: ApiSimpleSubscription) => {
      if (activate) {
        handleConfirmation({
          title: `Are you sure you want to activate this subscription`,
          body: `Once this is done you will no longer be able to edit or delete this subscription`,
          callback: async () => {
            const res = await activateSubscription(sub.id || "");
            setSubscriptions((subs) =>
              produce(subs, (draft) => {
                const idx = draft.findIndex((d) => d.id === sub.id);
                if (idx >= 0) {
                  draft[idx] = res;
                }
              })
            );
            return "Subscription Activated";
          },
        });
      }
    },
    [handleConfirmation, setSubscriptions]
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (loading) {
    return <Loading fullHeight />;
  }
  return (
    <>
      <SpacedListContainer
        title={"Manager Custom Subscriptions"}
        action={<Button onClick={() => setOpen(true)}>New Subscription</Button>}
      >
        <Grid container spacing={2}>
          {subscriptions.map((subscription) => (
            <Grid key={subscription.id} item xs={3}>
              <SubscriptionCard
                subscription={subscription}
                onEdit={onEdit}
                onDelete={onDelete}
                onActivate={onActivate}
              />
            </Grid>
          ))}
        </Grid>
      </SpacedListContainer>

      <SubscriptionDrawer
        open={open}
        onClose={onClose}
        subscription={subscription}
        setSubscription={setSubscription}
        onSubmit={onSave}
        saving={saving}
      />
    </>
  );
}

export default SubscriptionPages;
