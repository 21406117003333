/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiSubscriptionStatus;
(function (ApiSubscriptionStatus) {
    ApiSubscriptionStatus["SUBSCRIPTION_STATUS_INACTIVE"] = "SubscriptionStatusInactive";
    ApiSubscriptionStatus["SUBSCRIPTION_STATUS_ACTIVE"] = "SubscriptionStatusActive";
})(ApiSubscriptionStatus || (ApiSubscriptionStatus = {}));
export function ApiSubscriptionStatusFromJSON(json) {
    return ApiSubscriptionStatusFromJSONTyped(json, false);
}
export function ApiSubscriptionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiSubscriptionStatusToJSON(value) {
    return value;
}
