import { IChart, TChartData } from "Interfaces";
import { memo, useCallback, useEffect, useState } from "react";
import {
  AnalyticsComposedBridge,
  AnalyticsDataTable,
  AnalyticsDoughnutWrapper,
  AnalyticsFunnel,
  AnalyticsReportTreeMap,
  getFirstMetricFromY,
  metricOriginalNameToEnum,
  removeFirstDimension,
  useChartData,
} from "..";
import { IFromToChartOutput, useFromToContext } from "Providers/FromToProvider";
import { useSelectedProject } from "Hooks";
import { Box } from "@mui/material";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiChartYAxisKey,
  ApiMetric,
} from "@incendium/api";
import { StyledScatterChart } from "features/analytics";
import StyledRadar from "Components/StyledRadar/StyledRadar";
import Loading from "Components/Loading/Loading";
import { AnimatePresence, motion } from "framer-motion";
import AnalyticsGeoMap from "features/analytics/components/AnalyticsGeoMap";
import Error from "Components/Error";
import { useMemo } from "react";
import { useUpdateEffect } from "react-use";

interface IAnalyticsChartProps {
  chart: IChart;
  customDataFn?: (arr: TChartData[]) => TChartData[];
  overrideDate?: IFromToChartOutput;
  onClick?: { [field: string]: (v: string) => void };
  onDataChange?: (arr: TChartData[], comp?: TChartData[]) => void;
}

function AnalyticsChart({
  chart,
  customDataFn,
  overrideDate,
  onClick,
  onDataChange,
}: IAnalyticsChartProps) {
  const [editedChartDisplayOptions, setEditedChartDisplayOptions] = useState(
    chart.displayOptions
  );
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const { selectedProject } = useSelectedProject();

  const {
    chartData,
    dimensions,
    loading: initialLoading,
    error,
    totalsData,
  } = useChartData(selectedProject, chart, overrideDate || chartOutput);

  const {
    chartData: comparisonChartData,
    loading: compLoading,
    totalsData: compTotalsData,
    error: compError,
  } = useChartData(selectedProject, chart, chartComparisonOutput);

  const loading = useMemo(
    () => initialLoading || compLoading,
    [initialLoading, compLoading]
  );

  useUpdateEffect(() => {
    setEditedChartDisplayOptions(chart.displayOptions);
  }, [chart.displayOptions]);

  useEffect(() => {
    onDataChange &&
      onDataChange(chartData?.data || [], comparisonChartData?.data);
  }, [chartData, onDataChange, comparisonChartData]);

  const renderChart = useCallback(() => {
    if (!chartData) {
      return <></>;
    }

    if (chart.template === ApiChartTemplate.MAP) {
      return (
        <AnalyticsGeoMap
          data={chartData.data}
          metric={
            metricOriginalNameToEnum(
              ((chart.yAxisKeys[0] as ApiChartYAxisKey).fields || [])[0]
            ) as ApiMetric
          }
        />
      );
    }

    switch (chart.type) {
      case ApiChartType.TABLE:
        return (
          <AnalyticsDataTable
            data={customDataFn ? customDataFn(chartData.data) : chartData.data}
            totals={totalsData}
            onClick={onClick}
            comparisonChartData={comparisonChartData || undefined}
            comparisonTotals={compTotalsData || undefined}
            comparison={!!chartComparisonOutput}
            pageSize={editedChartDisplayOptions?.rowsPerPage}
            colWidths={chart.displayOptions?.tableColWidths}
            pinAt={chart.displayOptions?.tablePinAt}
            disableMetricClick={chart.displayOptions?.disabledMetricClick}
            chartAttributes={chart.attributes}
          />
        );
      case ApiChartType.PIE:
        return (
          <Box sx={{ height: "calc(100% - 5px)" }}>
            <AnalyticsDoughnutWrapper
              chartData={chartData}
              comparisonChartData={comparisonChartData || undefined}
              totals={totalsData}
              comparisonTotals={compTotalsData}
              dataKey={getFirstMetricFromY(chartData.y)}
              comparison={!!chartComparisonOutput}
              pieProps={{
                showTotalText: chart.displayOptions?.pieTotalText,
                totalFontSize: chart.displayOptions?.pieTotalFontSize,
              }}
            />
          </Box>
        );
      case ApiChartType.GRAPH:
        // snapshot graph uses 'name' as dimension, tmp fix, we remove other dimensions, but later
        // todo: when only using new analytics remove requirement of name property
        return (
          <Box sx={{ height: "calc(100% - 5px)" }}>
            <AnalyticsComposedBridge
              customDataFn={customDataFn}
              chartData={chartData}
              displayOptions={chart.displayOptions}
              dimensions={dimensions}
              comparisonChartData={
                !!chartComparisonOutput && comparisonChartData
                  ? comparisonChartData
                  : undefined
              }
              chartTemplate={chart.template}
            />
          </Box>
        );

      case ApiChartType.BUBBLE:
        return (
          <Box sx={{ height: "calc(100% - 5px)" }}>
            <StyledScatterChart
              showLabels={chart.displayOptions?.showLabels || false}
              dimension={
                dimensions && dimensions.length > 0 ? dimensions[0] : ""
              }
              data={chartData.data}
              yAxisKeys={chartData.y}
              showTooltip
            />
          </Box>
        );
      case ApiChartType.RADAR:
        return (
          <StyledRadar
            data={removeFirstDimension(chartData.data, dimensions)}
            yAxisKeys={chartData.y}
          />
        );
      case ApiChartType.TREE_MAP:
        return (
          <AnalyticsReportTreeMap
            chartData={chartData}
            dimensions={dimensions}
          />
        );
      case ApiChartType.FUNNEL:
        return (
          <AnalyticsFunnel
            chartData={
              customDataFn ? customDataFn(chartData.data) : chartData.data
            }
            comparisonChartData={
              comparisonChartData?.data ? comparisonChartData?.data : undefined
            }
            useLogFn={chart.displayOptions?.funnelUseLogFn}
            additionalMetrics={chart.displayOptions?.funnelAdditonalMetrics}
            chartAttributes={chart.attributes}
          />
        );
      default:
        break;
    }
  }, [
    chartData,
    comparisonChartData,
    dimensions,
    chart.yAxisKeys,
    chart.type,
    chart.template,
    chart.displayOptions,
    chart.attributes,
    customDataFn,
    onClick,
    editedChartDisplayOptions,
    totalsData,
    compTotalsData,
    chartComparisonOutput,
  ]);

  return (
    <AnimatePresence mode="wait">
      {loading ? (
        <Box>
          <Loading sx={{ height: "calc(100% - 15px)" }} />
        </Box>
      ) : error || compError ? (
        <Box sx={{ height: "calc(100% - 15px)" }}>
          <Error />
        </Box>
      ) : (
        <Box
          sx={{
            flex: "1 1 auto",
            minHeight: "min-content",
            maxWidth: "100%",
            position: "relative",
          }}
          component={motion.div}
          initial={{
            x: 300,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          exit={{
            x: -300,
            opacity: 0,
          }}
        >
          {renderChart()}
        </Box>
      )}
    </AnimatePresence>
  );
}

export default memo(AnalyticsChart);
