import {
  Box,
  Divider,
  FormControlLabel,
  Icon,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { formatPercentage, percentageOf } from "Helpers/percentage";
import { metricTitles } from "Interfaces/enums";
import { useMemo, useState } from "react";
import { PolarGrid, Radar, RadarChart, ResponsiveContainer } from "recharts";
import { ReactComponent as ViewRate } from "Assets/Images/Stats/icon-view_rate.svg";
import { ReactComponent as Vistis } from "Assets/Images/Stats/icon-visits.svg";
import { ReactComponent as Shop } from "Assets/Images/Stats/icon-add-to-bag.svg";
import { ReactComponent as Sale } from "Assets/Images/Stats/icon-sale.svg";
import { styled } from "@mui/system";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { ApiDimension, ApiMetric } from "@incendium/api";
import { useChartData, useFormatMetric } from "features/analytics";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { TChartData } from "Interfaces";
import Loading from "Components/Loading/Loading";

interface IJobKeyStatsCardProps {
  jobDimension: ApiDimension;
  selectedDimension: string;
}

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: theme.spacing(1),
  "& svg": {
    width: 30,
    height: 30,
    "& path:first-of-type": {
      fill: theme.palette.highlight,
    },
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  background: "transparent",
  "& .MuiTypography-root > .MuiTypography-root": {
    color: theme.palette.charts[1],
  },
  "& .MuiTypography-root:first-child > .MuiTypography-root": {
    color: theme.palette.charts[0],
  },
}));

function JobKeyStatsCard({
  jobDimension,
  selectedDimension,
}: IJobKeyStatsCardProps) {
  const theme = useTheme();
  const formatMetric = useFormatMetric();
  const [useActiveJobs, setuseActiveJobs] = useState(true);
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [jobDimension],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_JOB_IMPRESSION_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
            ApiMetric.METRIC_JOB_PAGEVIEW_COUNT,
            ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
          ],
        },
      ],
      attributes: [],
      includeEmpty: false,
    }),
    [jobDimension]
  );

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );

  const data: TChartData | undefined = useMemo(() => {
    if (!chartData?.data) {
      return undefined;
    }
    return chartData?.data.find((d) => d.name === selectedDimension);
  }, [chartData, selectedDimension]);

  const average = useMemo(() => {
    return (chartData?.data || [])
      .filter(
        (d) =>
          !useActiveJobs ||
          Number(d[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]) > 0
      )
      .reduce((acc: any, v, i, a) => {
        const { name, ...rest } = v;
        for (const key in rest) {
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] = acc[key] + Number(rest[key]) / a.length;
        }

        return acc;
      }, {});
  }, [useActiveJobs, chartData]);

  const differenceData = useMemo(() => {
    if (!average || !data) {
      return [];
    }

    return [
      {
        name: metricTitles.Impressions,
        average: 100,
        value:
          Number(data[ApiMetric.METRIC_JOB_IMPRESSION_COUNT]) ===
          Number(average[ApiMetric.METRIC_JOB_IMPRESSION_COUNT])
            ? 100
            : percentageOf(
                Number(data[ApiMetric.METRIC_JOB_IMPRESSION_COUNT]) || 0,
                Number(average[ApiMetric.METRIC_JOB_IMPRESSION_COUNT]) || 0
              ) || 0,
      },
      {
        name: metricTitles.Apply_Click,
        average: 100,
        value:
          Number(data[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT]) ===
          Number(average[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT])
            ? 100
            : percentageOf(
                Number(data[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT]) || 0,
                Number(average[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT]) ||
                  0
              ) || 0,
      },
      {
        name: metricTitles.Application,
        average: 100,
        value:
          Number(data[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]) ===
          Number(average[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT])
            ? 100
            : percentageOf(
                Number(data[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]) || 0,
                Number(average[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]) ||
                  0
              ) || 0,
      },
      {
        name: metricTitles.PageVistits,
        average: 100,
        value:
          Number(data[ApiMetric.METRIC_JOB_PAGEVIEW_COUNT]) ===
          Number(average[ApiMetric.METRIC_JOB_PAGEVIEW_COUNT])
            ? 100
            : percentageOf(
                Number(data[ApiMetric.METRIC_JOB_PAGEVIEW_COUNT]) || 0,
                Number(average[ApiMetric.METRIC_JOB_PAGEVIEW_COUNT]) || 0
              ) || 0,
      },
    ];
  }, [average, data]);

  return (
    <GlassCard
      noShadow
      boxProps={{
        px: 3,
        py: 2,
      }}
    >
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        mb={1}
        spacing={1}
      >
        <Typography variant="subtitle1">Key Statistics</Typography>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Switch
                checked={useActiveJobs}
                onChange={(e, checked) => setuseActiveJobs(checked)}
              />
            }
            label="Active"
          />

          <ClickableTooltip
            text={
              "select active to compare with all jobs that have had a application in selected time period."
            }
            placement="right"
            icon={<StyledHelp fontSize="small" />}
          />
        </Stack>
      </Stack>

      <Typography variant="body2" color={"secondary"} mb={2}>
        See how this Job{" "}
        {jobDimension === ApiDimension.DIMENSION_JOB_CATEGORY && "category"}{" "}
        compares to average Job{" "}
        {jobDimension === ApiDimension.DIMENSION_JOB_CATEGORY && "category"}{" "}
        performance
      </Typography>
      <Divider />
      <Stack
        py={1.5}
        alignItems="center"
        justifyContent={"center"}
        sx={{ minHeight: 309 }}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <Typography color={"interest"} mb={1}>
              as percentage of site average
            </Typography>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  minWidth: 45,
                  maxWidth: 45,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Box>
                  <Typography variant="body2" align="center">
                    page visits
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.charts[0] }}
                    align="center"
                    mr={1}
                  >
                    {formatPercentage(
                      differenceData.find(
                        (d) => d.name === metricTitles.PageVistits
                      )?.value || 0
                    )}
                  </Typography>
                </Box>
              </Box>

              <Stack
                sx={{
                  flexGrow: 1,
                  position: "relative",
                }}
                alignItems="center"
              >
                <Box
                  sx={{
                    position: "absolute",
                    paddingTop: "100%",
                    width: "100%",
                    top: "50%",
                    left: "50%",
                    borderRadius: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "rgba(255,255,255,0.5)",
                    boxShadow: `-3px 3px 10px #5E65E54D`,
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    height: 112,
                    width: 112,
                    top: "50%",
                    left: "50%",
                    borderRadius: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "rgba(255,255,255,0.5)",
                    boxShadow: `-3px 3px 10px #5E65E54D`,
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    height: 18,
                    width: 18,
                    top: "50%",
                    left: "50%",
                    borderRadius: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "rgba(255,255,255,0.5)",
                    boxShadow: `-3px 3px 10px #5E65E54D`,
                  }}
                />

                <Stack>
                  <Typography variant="body2" align="center">
                    {metricTitles.Impressions}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.charts[0] }}
                    align="center"
                    mb={1}
                  >
                    {formatPercentage(
                      differenceData.find(
                        (d) => d.name === metricTitles.Impressions
                      )?.value || 0
                    )}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    width: "100%",
                    aspectRatio: "1/1",
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <RadarChart
                      cx="50%"
                      cy="50%"
                      outerRadius="100%"
                      data={differenceData}
                    >
                      <PolarGrid
                        gridType="circle"
                        cx={0}
                        cy={0}
                        polarRadius={[]}
                        stroke="#EAEAEA"
                      />

                      <Radar
                        name="Average"
                        dataKey="average"
                        stroke={theme.palette.charts[1]}
                        strokeWidth={2}
                        fillOpacity={0}
                      />
                      <Radar
                        name="Current"
                        dataKey="value"
                        stroke={theme.palette.charts[0]}
                        strokeWidth={2}
                        fillOpacity={0}
                      />
                    </RadarChart>
                  </ResponsiveContainer>
                </Box>
                <Box>
                  <Typography variant="body2" mt={1} align="center">
                    applications
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.charts[0] }}
                    align="center"
                  >
                    {formatPercentage(
                      differenceData.find(
                        (d) => d.name === metricTitles.Application
                      )?.value || 0
                    )}
                  </Typography>
                </Box>
              </Stack>

              <Box
                sx={{
                  minWidth: 45,
                  maxWidth: 45,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="body2" ml={1} align="center">
                    click apply
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.charts[0] }}
                    align="center"
                  >
                    {formatPercentage(
                      differenceData.find(
                        (d) => d.name === metricTitles.Apply_Click
                      )?.value || 0
                    )}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </>
        )}
      </Stack>
      <Divider />
      <List>
        <StyledListItem disablePadding>
          <StyledIcon>
            <ViewRate />
          </StyledIcon>
          <ListItemText
            primary={
              <Typography>
                Job Impressions:{" "}
                <Typography fontWeight={"bold"} display={"inline"}>
                  {formatMetric(
                    ApiMetric.METRIC_JOB_IMPRESSION_COUNT,
                    data
                      ? Number(data[ApiMetric.METRIC_JOB_IMPRESSION_COUNT])
                      : 0
                  )}
                </Typography>
              </Typography>
            }
            secondary={
              <Typography variant="body2">
                Site Average:{" "}
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  display={"inline"}
                >
                  {formatMetric(
                    ApiMetric.METRIC_JOB_IMPRESSION_COUNT,
                    Number(average[ApiMetric.METRIC_JOB_IMPRESSION_COUNT]) || 0
                  )}
                </Typography>
              </Typography>
            }
          />
        </StyledListItem>
        <StyledListItem disablePadding>
          <StyledIcon>
            <Vistis />
          </StyledIcon>
          <ListItemText
            primary={
              <Typography>
                Page Visits:{" "}
                <Typography fontWeight={"bold"} display={"inline"}>
                  {formatMetric(
                    ApiMetric.METRIC_JOB_PAGEVIEW_COUNT,
                    data ? Number(data[ApiMetric.METRIC_JOB_PAGEVIEW_COUNT]) : 0
                  )}
                </Typography>
              </Typography>
            }
            secondary={
              <Typography variant="body2">
                Site Average:{" "}
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  display={"inline"}
                >
                  {formatMetric(
                    ApiMetric.METRIC_JOB_PAGEVIEW_COUNT,
                    Number(average[ApiMetric.METRIC_JOB_PAGEVIEW_COUNT]) || 0
                  )}
                </Typography>
              </Typography>
            }
          />
        </StyledListItem>
        <StyledListItem disablePadding>
          <StyledIcon>
            <Shop />
          </StyledIcon>
          <ListItemText
            primary={
              <Typography>
                Click Apply:{" "}
                <Typography fontWeight={"bold"} display={"inline"}>
                  {formatMetric(
                    ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
                    data
                      ? Number(
                          data[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT]
                        )
                      : 0
                  )}
                </Typography>
              </Typography>
            }
            secondary={
              <Typography variant="body2">
                Site Average:{" "}
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  display={"inline"}
                >
                  {formatMetric(
                    ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
                    Number(
                      average[ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT]
                    ) || 0
                  )}
                </Typography>
              </Typography>
            }
          />
        </StyledListItem>
        <StyledListItem disablePadding>
          <StyledIcon>
            <Sale />
          </StyledIcon>
          <ListItemText
            primary={
              <Typography>
                Applications:{" "}
                <Typography fontWeight={"bold"} display={"inline"}>
                  {formatMetric(
                    ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                    data
                      ? Number(
                          data[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]
                        )
                      : 0
                  )}
                </Typography>
              </Typography>
            }
            secondary={
              <Typography variant="body2">
                Site Average:{" "}
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  display={"inline"}
                >
                  {formatMetric(
                    ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                    Number(
                      average[ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]
                    ) || 0
                  )}
                </Typography>
              </Typography>
            }
          />
        </StyledListItem>
      </List>
    </GlassCard>
  );
}

export default JobKeyStatsCard;
