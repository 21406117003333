import { ApiProject, ApiProjectInput } from "@incendium/api";
import { projectService } from "Apis";

export const saveProject = async (clientId: number, project: ApiProject) => {
  const payload: ApiProjectInput = {
    name: project.name,
    sitemap: project.sitemap,
    timezone: project.timezone,
    currency: project.currency,
    domains: (project.domains || [])
      .filter((d) => d.url && d.url !== "")
      .map((d) => ({
        domain: d.url,
        crossDomainEnabled: d.crossDomainEnabled,
      })),
  };
  const fn =
    project.id && project.id > 0
      ? projectService.projectsServiceUpdateProject({
          clientId,
          projectId: project.id,
          payload,
        })
      : projectService.projectsServiceCreateProject({
          clientId,
          payload,
        });
  const res = await fn;
  return res;
};

export const listAllProjects = async () => {
  const res = await projectService.projectsServiceListAllProjects();
  return res.results;
};
