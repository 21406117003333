import {
  ApiEffectivenessRule,
  ApiSessionEffectivenessRule,
} from "@incendium/api";
import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  effectivenessRuleService,
  sessionEffectivenessRuleService,
} from "Apis";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { cell1Icon } from "consts";
import { useSelectedProject } from "Hooks";
import { useConfirmation } from "Hooks/useConfirmation";
import { useEffectivenessRules } from "Hooks/useEffectivenessRules";
import { useSessionEffectivenessRules } from "Hooks/useSessionEffectivenessRules";
import produce from "immer";
import { TEffectivenessRule } from "Interfaces";
import { useSnackbar } from "notistack";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const EffectivenessRulesList = ({
  rules,
  onEdit,
  onDelete,
  isPageview,
  title,
  subTitle,
  action,
}: {
  rules: TEffectivenessRule[];
  onEdit: (e: TEffectivenessRule) => void;
  onDelete: (e: TEffectivenessRule) => void;
  isPageview: boolean;
  title: string;
  subTitle: string;
  action?: React.ReactNode;
}) => {
  return (
    <SpacedList
      isEmpty={rules.length === 0}
      title={title}
      subTitle={subTitle}
      action={action}
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          {isPageview && <TableCell>Locations</TableCell>}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(rules || []).map((e) => (
          <TableRow key={e.id}>
            <TableCell>
              <SpacedLinkButton onClick={() => onEdit(e)}>
                {e.name || ""}
              </SpacedLinkButton>
            </TableCell>
            {isPageview && (
              <TableCell>
                {(e as ApiEffectivenessRule).locations &&
                ((e as ApiEffectivenessRule).locations || []).length > 0
                  ? ((e as ApiEffectivenessRule).locations || [])
                      .map((l) => l.name)
                      .join(", ")
                  : "All"}
              </TableCell>
            )}
            <TableCell align="right" style={{ width: cell1Icon }}>
              <IconButton size="small" onClick={() => onDelete(e)}>
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
};

function EffectivenessPage() {
  const { selectedProject } = useSelectedProject();
  const { setPageTitle } = useLayoutContext();
  const handleDelete = useConfirmation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { effectivenessRules, setEffectivenessRules } = useEffectivenessRules();
  const { sessionEffectivenessRules, setSessionEffectivenessRules } =
    useSessionEffectivenessRules();
  const [openPv, setOpenPv] = useState(false);
  const [openS, setOpenS] = useState(false);

  useLayoutEffect(() => {
    setPageTitle(
      <TypographyHelp
        variant="h1"
        text="Effectiveness"
        placement={"right"}
        tooltip={
          "Configure the rules which will define if a page visit or session was successful / effective"
        }
      />
    );
    return () => {
      setPageTitle("");
    };
  }, [selectedProject]);

  const isPageview = (rule: TEffectivenessRule) => {
    return rule.hasOwnProperty("locations");
  };

  const onSaved = (t: "pageview" | "session") => async (name: string) => {
    const payload = {
      name,
    };
    let res: TEffectivenessRule;

    switch (t) {
      case "pageview":
        res = await effectivenessRuleService.effectivenessRuleServiceCreateRule(
          {
            projectId: selectedProject?.id as number,
            payload,
          }
        );
        setEffectivenessRules(
          produce(effectivenessRules, (draft) => {
            draft.push(res as ApiEffectivenessRule);
          })
        );
        break;

      case "session":
        res =
          await sessionEffectivenessRuleService.sessionEffectivenessRuleServiceCreateRule(
            {
              projectId: selectedProject?.id as number,
              payload,
            }
          );
        setSessionEffectivenessRules(
          produce(sessionEffectivenessRules, (draft) => {
            draft.push(res as ApiSessionEffectivenessRule);
          })
        );
        break;
    }

    enqueueSnackbar(`Effectiveness Rule (${name}) Created, redirecting`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
    onEdit(res);
  };

  const onEdit = (rule: TEffectivenessRule) => {
    history.push(
      `/clients/${selectedProject?.clientId}/projects/${
        selectedProject?.id
      }/train/effectiveness/${isPageview(rule) ? "pageviews" : "sessions"}/${
        rule.id
      }`
    );
  };

  const onDelete = (rule: TEffectivenessRule) => {
    handleDelete({
      title: `Are you sure you want to delete this rule`,
      body: `This action will remove this rule and cannot be undone`,
      callback: async () => {
        switch (isPageview(rule)) {
          case true:
            await effectivenessRuleService.effectivenessRuleServiceDeleteRule({
              projectId: selectedProject?.id as number,
              ruleId: rule.id as number,
            });

            setEffectivenessRules(
              produce(effectivenessRules, (draft) => {
                const idx = draft.findIndex((e) => e.id === rule.id);
                if (idx !== -1) draft.splice(idx, 1);
              })
            );
            break;
          case false:
            await sessionEffectivenessRuleService.sessionEffectivenessRuleServiceDeleteRule(
              {
                projectId: selectedProject?.id as number,
                ruleId: rule.id as number,
              }
            );

            setSessionEffectivenessRules(
              produce(sessionEffectivenessRules, (draft) => {
                const idx = draft.findIndex((e) => e.id === rule.id);
                if (idx !== -1) draft.splice(idx, 1);
              })
            );
            break;
        }

        return "Rule Deleted";
      },
    });
  };

  return (
    <Box>
      <Box mb={2} sx={{ maxWidth: 1000 }}>
        <EffectivenessRulesList
          rules={effectivenessRules}
          onEdit={onEdit}
          onDelete={onDelete}
          isPageview={true}
          title="Pageview Effectiveness Rules"
          subTitle="These rules define whether a pageview has achieved its objective.
          This serves as a measure of success and engagement"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenPv(true)}
            >
              New Pageview Effectiveness rule
            </Button>
          }
        />
      </Box>
      <Box mb={2} sx={{ maxWidth: 1000 }}>
        <EffectivenessRulesList
          rules={sessionEffectivenessRules}
          onEdit={onEdit}
          onDelete={onDelete}
          isPageview={false}
          title="Session Effectiveness Rules"
          subTitle={`These rules define if a session has achieved its objective. This
          replaces "bounce rate" as a primary measure of engagement and
          success with a more complex and objective metric`}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenS(true)}
            >
              New Session Effectiveness rule
            </Button>
          }
        />
      </Box>

      <CreateNameDialog
        open={openPv}
        setOpen={setOpenPv}
        title="Set New Pageview Rule Name"
        onSaved={onSaved("pageview")}
      />
      <CreateNameDialog
        open={openS}
        setOpen={setOpenS}
        title="Set New Session Rule Name"
        onSaved={onSaved("session")}
      />
    </Box>
  );
}

export default EffectivenessPage;
