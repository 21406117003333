import { ApiChartTemplate, ApiChartType, ApiMetric } from "@incendium/api";
import { Box, Grid, Stack } from "@mui/material";
import FullWidthHeightGlass from "Components/Page/FullWidthHeightGlass";
import { AttributionPerformanceAnalysisTab } from "Pages/Analyse/MarketingPerformance/PerformanceAnalysisPage";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  metricByAttribution,
  metricsByAttributionType,
  orderAttributionMetrics,
  orderAttributionType,
} from "features/analytics";
import { AttributionMetric } from "features/analytics/types/types";
import ExplorerSidebar from "features/explorer/components/ExplorerSidebar";
import ExplorerTable from "features/explorer/components/ExplorerTable";
import { useExplorerContext } from "features/explorer/providers/ExplorerProvider";
import { useMemo } from "react";

interface IAttributionExplorerProps {
  tab: AttributionPerformanceAnalysisTab;
  includeBubble?: boolean;
}

function AttributionExplorer({
  tab,
  includeBubble,
}: IAttributionExplorerProps) {
  const { selectedAttributionTypes, dimension, attributes } =
    useExplorerContext();
  const metrics = useMemo(() => {
    switch (tab) {
      case "Attribution Explorer":
        const attMetrics = selectedAttributionTypes
          .map(metricsByAttributionType)
          .flat();
        return [
          ApiMetric.METRIC_SESSIONS_COUNT,
          ...orderAttributionMetrics(attMetrics),
        ];
      case "Geographic":
      case "Landing Pages":
        return [
          ApiMetric.METRIC_SESSIONS_COUNT,
          ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
          ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
          ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
          ApiMetric.METRIC_SESSION_REVENUE,
          ApiMetric.METRIC_SESSION_REVENUE,
          ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
          ApiMetric.METRIC_RANK,
        ];

      default:
        return [];
    }
  }, [selectedAttributionTypes, tab]);
  return (
    <FullWidthHeightGlass height={`calc(100vh - 165px)`}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `73% 27%`,
          height: "100%",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Stack sx={{ height: "100%" }}>
            {includeBubble && (
              <Grid
                container
                spacing={2}
                wrap="nowrap"
                sx={{ height: 400, overflowX: "auto", overflowY: "hidden" }}
              >
                {orderAttributionType(selectedAttributionTypes).map((model) => (
                  <Grid
                    item
                    xs={selectedAttributionTypes.length > 1 ? 6 : 12}
                    key={model + selectedAttributionTypes.length}
                    sx={{ minWidth: "50%" }}
                  >
                    <AnalyticsCard
                      noBorder={selectedAttributionTypes.length === 1}
                      square={selectedAttributionTypes.length === 1}
                      chart={{
                        name: ``,
                        dimension: [dimension],
                        template: ApiChartTemplate.SNAPSHOT,
                        type: ApiChartType.BUBBLE,
                        attributes,
                        yAxisKeys: [
                          {
                            key: "l",
                            fields: [
                              metricByAttribution(
                                model,
                                AttributionMetric.SALES
                              ) as ApiMetric,
                              metricByAttribution(
                                model,
                                AttributionMetric.ROI
                              ) as ApiMetric,
                              metricByAttribution(
                                model,
                                AttributionMetric.REVENUE
                              ) as ApiMetric,
                            ],
                          },
                        ],
                        displayOptions: {
                          noTitle: true,
                          noFilters: true,
                          noAttributionToggle: true,
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            <Box pr={1} sx={{ flex: 1 }}>
              <ExplorerTable metrics={metrics} />
            </Box>
          </Stack>
        </Box>
        <ExplorerSidebar />
      </Box>
    </FullWidthHeightGlass>
  );
}

export default AttributionExplorer;
