import { Box, Stack, Typography } from "@mui/material";
import GlassCard, { IGlassCardProps } from "Components/GlassCard/GlassCard";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";

interface ISpacedListContainerProps extends IGlassCardProps {
  title?: string;
  subTitle?: string;
  action?: React.ReactNode;
  height?: number | string;
}
function SpacedListContainer({
  title,
  subTitle,
  action,
  height,
  children,
}: ISpacedListContainerProps) {
  return (
    <GlassCard boxProps={{ px: 3, pt: 1.5, pb: 2 }} height={height}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={subTitle ? "flex-start" : "flex-end"}
        pb={1.5}
        spacing={4}
      >
        <Stack>
          <Typography variant="subtitle1">{title}</Typography>
          {subTitle && <Typography>{subTitle}</Typography>}
        </Stack>

        {action && (
          <Box
            sx={{
              minWidth: "33%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {action}
          </Box>
        )}
      </Stack>
      <IndentDivider />
      <Box pt={2}>{children}</Box>
    </GlassCard>
  );
}

export default SpacedListContainer;
