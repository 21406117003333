import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Glass } from "Components/Glass";
import {
  AccordianChartBuilderSidebarBlock,
  ChartBuilderAttributes,
  ChartBuilderSidebarBlock,
  ChartBuilderSidebarContainer,
} from "features/chartLibrary";
import produce from "immer";
import {
  ApiChartHaving,
  ApiChartRankDrection,
  ApiMetric,
} from "@incendium/api";
import {
  AnalyticsAttributionSwitch,
  dimensionToName,
  metricToName,
  metricsByAttributionType,
} from "features/analytics";
import { useEffect, useMemo, useState } from "react";
import { useExplorerContext } from "features/explorer";
import { useDebounce, usePrevious } from "react-use";
import { IChart } from "Interfaces";
import { AttributionType } from "features/analytics/types/types";
import { ArrowDownwardSharp, ArrowUpward } from "@mui/icons-material";

function ExplorerSidebar() {
  const {
    config,
    dimension,
    setDimension,
    selectedRanks,
    setSelectedRanks,
    having,
    setHaving,
    attributes,
    setAttributes,
    setSelectedAttributionTypes,
    rankingIndex,
    setRankingIndex,
  } = useExplorerContext();

  const prevConfig = usePrevious(config);
  const prevRankIndex = usePrevious(rankingIndex);

  const [initialRankSet, setInitialRankSet] = useState(false);
  useEffect(() => {
    if (
      config.title === prevConfig?.title &&
      rankingIndex === prevRankIndex &&
      (initialRankSet || having.length === 0)
    ) {
      return;
    }

    setInitialRankSet(true);
  }, [having, initialRankSet, config, prevConfig, rankingIndex, prevRankIndex]);

  const [initialHavingSet, setinitialHavingSet] = useState(false);
  const [debounceHaving, setDebounceHaving] = useState<ApiChartHaving[]>([]);
  useEffect(() => {
    if (
      config.title === prevConfig?.title &&
      (initialHavingSet || having.length === 0)
    ) {
      return;
    }

    setDebounceHaving(config.having?.havings || []);
    setinitialHavingSet(true);
  }, [having, initialHavingSet, config, prevConfig]);
  useDebounce(
    () => {
      setHaving(debounceHaving);
    },
    400,
    [debounceHaving]
  );

  const byDimensions = useMemo(
    () =>
      config.analyseBy?.grouping
        .map((g) =>
          g.dimensions.map((d) => ({
            group: g.option,
            dimension: d,
          }))
        )
        .flat(),
    [config.analyseBy]
  );

  const attributionChart: IChart = useMemo(() => {
    return {
      yAxisKeys: [
        {
          key: "l",
          fields: config.defaultAttributionTypes
            ? config.defaultAttributionTypes
                .map(metricsByAttributionType)
                .flat()
            : metricsByAttributionType(AttributionType.FIRST),
        },
      ],
      attributes: [],
    };
  }, [config.defaultAttributionTypes]);

  return (
    <Glass square boxShadowOpactity={100}>
      <ChartBuilderSidebarContainer>
        <ChartBuilderSidebarBlock>
          <Typography variant="h3" mb={1}>
            {config.title}
          </Typography>
          {config.desc.split("\n").map((s, i) => (
            <Typography key={i} variant="body2" color={"secondary"}>
              {s}
            </Typography>
          ))}
        </ChartBuilderSidebarBlock>

        {config.analyseBy && (
          <AccordianChartBuilderSidebarBlock
            title={`Analyse by`}
            subTitle={config.analyseBy.description}
          >
            <Autocomplete
              fullWidth
              groupBy={(option) => option.group}
              disableClearable
              onChange={(e, v) => {
                setDimension(v?.dimension);
              }}
              isOptionEqualToValue={(option) => option.dimension === dimension}
              value={byDimensions?.find((d) => d.dimension === dimension)}
              options={byDimensions || []}
              getOptionLabel={(option) => dimensionToName(option.dimension)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic"
                  label="Select a dimension"
                  variant="outlined"
                  size={"small"}
                />
              )}
            />
          </AccordianChartBuilderSidebarBlock>
        )}

        {config.ranking.length > 1 && (
          <AccordianChartBuilderSidebarBlock
            title={`I'm looking for.`}
            subTitle={`Choose an option to customize the default ranking, enabling you to pinpoint precisely what you're searching for.`}
          >
            <ToggleButtonGroup
              size="small"
              fullWidth
              value={rankingIndex}
              exclusive
              color="secondary"
              onChange={(e, v) => {
                setRankingIndex(v);
              }}
            >
              {config.ranking.map((r, i) =>
                r.tooltip ? (
                  <ToggleButton key={i} value={i}>
                    <Tooltip arrow title={r.tooltip}>
                      <span>{r.label || ""}</span>
                    </Tooltip>
                  </ToggleButton>
                ) : (
                  <ToggleButton key={i} value={i}>
                    {r.label}
                  </ToggleButton>
                )
              )}
            </ToggleButtonGroup>
          </AccordianChartBuilderSidebarBlock>
        )}

        {config.ranking[rankingIndex] &&
          (config.ranking[rankingIndex]?.ranks || []).length > 0 && (
            <>
              <AccordianChartBuilderSidebarBlock
                title={`Rank Weights`}
                subTitle={`Select what factors to take into account when ranking ${dimensionToName(
                  dimension
                )}'s, if no weights are assigned, all metrics will be considered. The table is ordered by rank.`}
              >
                <FormGroup>
                  {(config.ranking[rankingIndex]?.ranks || []).map((w) => (
                    <Stack
                      direction={"row"}
                      key={`${w.metric}-${w.direction}`}
                      alignItems={"center"}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedRanks.findIndex(
                                (s) =>
                                  s.metric === w.metric &&
                                  s.direction === w.direction
                              ) >= 0
                            }
                            onChange={(e) => {
                              setSelectedRanks((r) =>
                                produce(r, (draft) => {
                                  const idx = draft.findIndex(
                                    (d) =>
                                      d.metric === w.metric &&
                                      d.direction === w.direction
                                  );

                                  if (idx >= 0) {
                                    draft.splice(idx, 1);
                                  } else {
                                    // check if opersite direction selected
                                    const oIdx = draft.findIndex(
                                      (d) => d.metric === w.metric
                                    );
                                    if (oIdx >= 0) {
                                      draft[oIdx] = w;
                                    } else {
                                      draft.push(w);
                                    }
                                  }
                                })
                              );
                            }}
                          />
                        }
                        label={metricToName(w.metric as ApiMetric)}
                      />
                      {w.direction === ApiChartRankDrection.ASC ? (
                        <>
                          <ArrowUpward fontSize="inherit" />{" "}
                          <Typography variant="caption" ml={1}>
                            (asc)
                          </Typography>
                        </>
                      ) : (
                        <>
                          <ArrowDownwardSharp fontSize="inherit" />
                          <Typography variant="caption" ml={1}>
                            (desc)
                          </Typography>
                        </>
                      )}
                    </Stack>
                  ))}
                </FormGroup>
              </AccordianChartBuilderSidebarBlock>
            </>
          )}

        {(config.defaultAttributionTypes || []).length > 0 && (
          <AccordianChartBuilderSidebarBlock
            title={`Attribution Models`}
            subTitle={`Select what attribution models to include for ${dimensionToName(
              dimension
            )}'s.`}
          >
            <AnalyticsAttributionSwitch
              chart={attributionChart}
              setChart={() => {}}
              multiple
              onModelsChange={(m) => {
                setSelectedAttributionTypes(m);
              }}
            />
          </AccordianChartBuilderSidebarBlock>
        )}

        {config.having && (
          <AccordianChartBuilderSidebarBlock
            title={`Filter ${dimensionToName(dimension)}'s`}
            subTitle={config.having.description}
          >
            <Grid container spacing={2}>
              {config.having.havings.map((item) => (
                <Grid item xs={6} key={`${item.metric}-${item.label}`}>
                  <TextField
                    size="small"
                    fullWidth
                    type={"number"}
                    label={item.label}
                    value={
                      `${
                        debounceHaving.find(
                          (h) =>
                            h.metric === item.metric &&
                            h.operator === item.operator
                        )?.value
                      }` || ""
                    }
                    onChange={(e) => {
                      setDebounceHaving((having) =>
                        produce(having, (draft) => {
                          const idx = having.findIndex(
                            (h) =>
                              h.metric === item.metric &&
                              h.operator === item.operator
                          );
                          if (idx >= 0) {
                            if (e.target.value === "") {
                              draft.splice(idx, 1);
                            } else {
                              draft[idx].value = Number(e.target.value);
                            }
                          } else if (e.target.value !== "") {
                            draft.push({
                              metric: item.metric,
                              operator: item.operator,
                              value: Number(e.target.value),
                            });
                          }
                        })
                      );
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordianChartBuilderSidebarBlock>
        )}

        <ChartBuilderAttributes
          attributes={attributes}
          setAttributes={setAttributes}
        />
      </ChartBuilderSidebarContainer>
    </Glass>
  );
}

export default ExplorerSidebar;
