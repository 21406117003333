import { Grid } from "@mui/material";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { AnalyticsChartStatCard, table5Rows } from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
  ApiYAxisChartType,
} from "@incendium/api";
import { AnalyticsSpacing } from "consts";
import { useMemo } from "react";

function NewCustomerInsights() {
  const newCustomerAttribute = useMemo(() => {
    return {
      key: ApiDimension.DIMENSION_USER_TYPE,
      operator: ApiOperator.EQUAL,
      value: "New Customer",
    };
  }, []);

  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "Total New Customers",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_NEW_CUSTOMER_COUNT],
              },
            ],
            displayOptions: {
              statTitle: "Total New Customers",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "New Customer CAC",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_CAC],
              },
            ],
            displayOptions: {
              statTitle: "New Customer CAC",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "Customer Conversion Rate",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_TO_NEW_CUSTOMER_RATE],
              },
            ],
            displayOptions: {
              statTitle: "Customer Conversion Rate",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "New Customer Revenue",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [newCustomerAttribute],
            displayOptions: {
              noFilters: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSION_REVENUE],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Total New Customers",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_WEEK],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            displayOptions: {
              noFilters: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_NEW_CUSTOMER_COUNT],
                chart: {
                  [ApiMetric.METRIC_NEW_CUSTOMER_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "No. Visits to conversion",
            dimension: [
              ApiDimension.DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION,
            ],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            orderBy: `${ApiDimension.DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION}`,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_LEADS_COUNT],
                chart: {
                  [ApiMetric.METRIC_LEADS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "No. Days to conversion",
            dimension: [ApiDimension.DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            orderBy: `${ApiDimension.DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION}`,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_LEADS_COUNT],
                chart: {
                  [ApiMetric.METRIC_LEADS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "New Customers by Channel",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            includeEmpty: true,
            displayOptions: {
              showTotals: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_NEW_CUSTOMER_COUNT],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "New customers and new customer revenue by product",
            dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.TABLE,
            attributes: [newCustomerAttribute],
            displayOptions: {
              noFilters: true,
              noTitle: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                  ApiMetric.METRIC_PRODUCT_REVENUE,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_SESSION_SALES_COUNT, // needs checking
                  ApiMetric.METRIC_SALES_COMPLETION_RATE,
                  ApiMetric.METRIC_SALES_REVENUE,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                ],
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}

export default NewCustomerInsights;
