import {
  Alert,
  CircularProgress,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useState, useEffect, useCallback } from "react";
import produce from "immer";
import { ApiProductTagger, ApiProductTaggerUniqueField } from "@incendium/api";
import { StyledButton, TextFieldHeading } from "Components/TagPages";
import { ProductTaggerUniqueFields } from "config";
import { formatEnumVal } from "Helpers/enumToText";
import { AnimatePresence, motion } from "framer-motion";
import { CheckCircleOutline } from "@mui/icons-material";
import { SidebarStyledDivider } from "Components/UI/Sidebar";
import { usePrevious } from "react-use";
import { THighlight, debugCheckProductSchema } from "@incendium/inc-ts-helpers";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { useIframeContext } from "features/smartIFrame/providers/OldIframeProvider";
import { useSmartIframeContext } from "features/smartIFrame/providers/SmartIFrameProvider";

type TState = { [key: string]: string };
type TField = { label: string; model: ApiProductTaggerUniqueField };

export const debuggerColours = [
  "f5ce9d",
  "e8f59d",
  "bbf59d",
  "9df5b8",
  "9df1f5",
  "9db4f5",
  "b39df5",
  "f59d9d",
];

function ProductTaggerForm({
  productSchemaFound,
  productTagger,
  onCancel,
  onSubmit,
  active,
}: {
  productSchemaFound: boolean;
  productTagger: ApiProductTagger;
  onCancel: () => void;
  onSubmit: (input: ApiProductTagger) => void;
  active: boolean;
}) {
  const { sendMessage } = useSmartIframeContext();
  const { activateIframe, selectedSelector } = useIframeContext();
  const [state, setState] = useState<TState>({
    [ApiProductTaggerUniqueField.TITLE]: "",
    [ApiProductTaggerUniqueField.DESCRIPTION]: "",
    [ApiProductTaggerUniqueField.SKU]: "",
    [ApiProductTaggerUniqueField.PRICE]: "",
    [ApiProductTaggerUniqueField.IMAGE]: "",
    [ApiProductTaggerUniqueField.CATEGORY]: "",
  });
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [activeField, setActiveField] = useState(
    ApiProductTaggerUniqueField.TITLE
  );
  const prevActiveField = usePrevious(activeField);
  const [uniqueField, setUniqueField] = useState(
    ApiProductTaggerUniqueField.TITLE
  );

  const [useLinkedData, setUseLinkedData] = useState(false);
  const fields: TField[] = [
    { label: "Product Title", model: ApiProductTaggerUniqueField.TITLE },
    {
      label: "Product Description",
      model: ApiProductTaggerUniqueField.DESCRIPTION,
    },
    { label: "Product SKU", model: ApiProductTaggerUniqueField.SKU },
    { label: "Product Price", model: ApiProductTaggerUniqueField.PRICE },
    { label: "Product Image", model: ApiProductTaggerUniqueField.IMAGE },
    { label: "Product Category", model: ApiProductTaggerUniqueField.CATEGORY },
    { label: "Product Brand", model: ApiProductTaggerUniqueField.BRAND },
    { label: "Product Id", model: ApiProductTaggerUniqueField.PRODUCT_ID },
  ];

  const handleChange =
    (field: TField) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setState(
        produce(state, (draft) => {
          draft[field.model] = e.target.value;
        })
      );
    };

  useEffect(() => {
    setUseLinkedData(productSchemaFound);
  }, [productSchemaFound]);

  useEffect(() => {
    // send check for product schema message
    sendMessage({
      type: debugCheckProductSchema,
    });
    // activateIframe(undefined, "");
    // return () => deactivateIframe();
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule("hasSelector", (value) => {
      return (
        useLinkedData || state[value as ApiProductTaggerUniqueField] !== ""
      );
    });
    return () => {
      ValidatorForm.removeValidationRule("hasSelector");
    };
  }, [state, useLinkedData]);

  useEffect(() => {
    if (!selectedSelector || !activeField || prevActiveField !== activeField) {
      return;
    }
    setState((state) =>
      produce(state, (draft) => {
        draft[activeField] = selectedSelector;
      })
    );
  }, [selectedSelector, activeField, prevActiveField]);

  useEffect(() => {
    setState({
      [ApiProductTaggerUniqueField.TITLE]: productTagger.titleSelector || "",
      [ApiProductTaggerUniqueField.DESCRIPTION]:
        productTagger.descriptionSelector || "",
      [ApiProductTaggerUniqueField.SKU]: productTagger.skuSelector || "",
      [ApiProductTaggerUniqueField.PRICE]: productTagger.priceSelector || "",
      [ApiProductTaggerUniqueField.IMAGE]: productTagger.imageUrlSelector || "",
      [ApiProductTaggerUniqueField.BRAND]: productTagger.brandSelector || "",
      [ApiProductTaggerUniqueField.CATEGORY]:
        productTagger.categorySelector || "",
    });
    setUniqueField(
      productTagger.uniqueField || ApiProductTaggerUniqueField.TITLE
    );
    setUseLinkedData(productTagger.useLinkedData || false);
  }, [productTagger]);

  useEffect(() => {
    activateIframe(
      undefined,
      "",
      undefined,
      undefined,
      undefined,
      formatState(state),
      undefined
    );
  }, [state, activateIframe]);

  const handleSubmit = useCallback(async () => {
    setSaving(true);
    const start = new Date().getTime();
    await onSubmit({
      id: productTagger.id,
      titleSelector: state[ApiProductTaggerUniqueField.TITLE],
      descriptionSelector: state[ApiProductTaggerUniqueField.DESCRIPTION],
      skuSelector: state[ApiProductTaggerUniqueField.SKU],
      priceSelector: state[ApiProductTaggerUniqueField.PRICE],
      imageUrlSelector: state[ApiProductTaggerUniqueField.IMAGE],
      categorySelector: state[ApiProductTaggerUniqueField.CATEGORY],
      uniqueField: uniqueField,
      useLinkedData,
    });
    let t = new Date().getTime() - start;
    if (t > 1000) {
      t = 0;
    }
    t = 1000 - t;
    setTimeout(() => {
      setSaved(true);
    }, t);
    setTimeout(() => {
      setSaved(false);
      setSaving(false);
    }, t + 1000);
  }, [productTagger, onSubmit, state, uniqueField, useLinkedData]);

  const formatState = (state: TState): THighlight[] => {
    let elems: THighlight[] = [];
    let i = 0;
    for (const key in state) {
      i++;
      if (state[key] === "") {
        continue;
      }
      elems.push({
        colour: `#${debuggerColours[i - 1]}`,
        el: state[key],
      });
    }
    return elems;
  };

  return (
    <>
      <AnimatePresence mode="wait">
        {saving ? (
          <Box
            component={motion.div}
            display="flex"
            key="saving-box"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: "80%" }}
            p={6}
          >
            <AnimatePresence mode="wait">
              {saved ? (
                <motion.div
                  key="cir-saved"
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <CheckCircleOutline color="primary" sx={{ fontSize: 42 }} />
                </motion.div>
              ) : (
                <motion.div
                  key="cir-saving"
                  initial={{ y: -50, opacity: 0 }}
                  exit={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <CircularProgress size={40} />
                </motion.div>
              )}
              {saved ? (
                <motion.div
                  key="txt-saved"
                  initial={{ y: 50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Product Saved
                  </Typography>
                </motion.div>
              ) : (
                <motion.div
                  key="txt-saving"
                  initial={{ y: 50, opacity: 0 }}
                  exit={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Saving Product Info
                  </Typography>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>
        ) : (
          <Box
            component={motion.div}
            display="flex"
            key="form-box"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {active && (
              <ValidatorForm onSubmit={handleSubmit} style={{ width: "100%" }}>
                {productSchemaFound && (
                  <>
                    <Box mb={2}>
                      <StyledFlexBox>
                        <Box display="flex" alignItems="center">
                          <Switch
                            checked={useLinkedData}
                            onChange={(e, checked) => setUseLinkedData(checked)}
                          />
                          <Typography
                            color={useLinkedData ? "primary" : "secondary"}
                          >
                            Enable Product schema
                          </Typography>
                        </Box>
                        <ClickableTooltip
                          placement="right"
                          text={
                            <>
                              <p>
                                Product schema is a structured data format used
                                to provide search engines with detailed
                                information about a product, improving its
                                visibility and enhancing the presentation of its
                                listings in search results.
                              </p>
                              <p>
                                If you are using product schema in your code,
                                activate this feature to leverage this data for
                                product tracking and analytics reports.
                              </p>
                            </>
                          }
                          icon={<StyledHelp />}
                        />
                      </StyledFlexBox>
                    </Box>
                    <Box mb={2}>
                      <SidebarStyledDivider />
                    </Box>
                  </>
                )}
                {productSchemaFound && useLinkedData && (
                  <Box mb={3}>
                    <Alert severity="warning">
                      We detected this page contains a product schema, to tag
                      the product instead disable Product Schema.
                    </Alert>
                  </Box>
                )}
                <RadioGroup
                  value={activeField}
                  onChange={(e) =>
                    setActiveField(
                      e.target.value as ApiProductTaggerUniqueField
                    )
                  }
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldHeading variant="body1" color="textPrimary">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          Unique Field
                          <ClickableTooltip
                            placement="left"
                            text="This is the field that shall be used to identify a unique product"
                            icon={<StyledHelp />}
                          />
                        </Box>
                      </TextFieldHeading>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        validators={["hasSelector"]}
                        errorMessages={["Unique field selector is emtpy"]}
                        name="uniqueField"
                        value={uniqueField}
                        variant="outlined"
                        size="small"
                        select
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setUniqueField(
                            e.target.value as ApiProductTaggerUniqueField
                          )
                        }
                      >
                        {ProductTaggerUniqueFields.map((item) => (
                          <MenuItem key={item} value={item}>
                            {formatEnumVal(item)}{" "}
                            {!useLinkedData && (
                              <> ({state[item] || "not set"})</>
                            )}
                          </MenuItem>
                        ))}
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12}>
                      <SidebarStyledDivider />
                    </Grid>
                    {!useLinkedData && (
                      <>
                        <Grid item xs={12}>
                          <TextFieldHeading variant="body1" color="textPrimary">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              mb={2}
                            >
                              Selector
                              <ClickableTooltip
                                placement="left"
                                text="This is the css selector that is used to scrape the product from pages, you can either click into the input and select the correct element from the left, or manually type the correct selector"
                                icon={<StyledHelp />}
                              />
                            </Box>
                          </TextFieldHeading>
                        </Grid>

                        {fields.map((field, idx) => (
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={1}
                            key={field.model}
                            alignItems="center"
                          >
                            <Grid item xs={1}>
                              <Box
                                sx={{
                                  bgcolor: `#${debuggerColours[idx]}`,
                                  height: "38px",
                                  width: "100%",
                                  borderRadius: 10,
                                }}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <TextValidator
                                value={state[field.model]}
                                variant="outlined"
                                name="selector"
                                label={field.label}
                                fullWidth
                                size="small"
                                autoFocus={activeField === field.model}
                                disabled={saving}
                                onChange={handleChange(field)}
                                onFocus={() => setActiveField(field.model)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Radio value={field.model} />
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </RadioGroup>

                <Box mt={4} display="flex" justifyContent="space-between">
                  <StyledButton
                    disableElevation
                    size="large"
                    variant="contained"
                    type="submit"
                    fullWidth
                    color="primary"
                  >
                    {saving ? (
                      <CircularProgress size={"1rem"} color="inherit" />
                    ) : (
                      "save"
                    )}
                  </StyledButton>

                  <StyledButton
                    disableElevation
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={onCancel}
                    color="secondary"
                  >
                    cancel
                  </StyledButton>
                </Box>
              </ValidatorForm>
            )}
          </Box>
        )}
      </AnimatePresence>
    </>
  );
}

export default ProductTaggerForm;
