import { CircularProgress, Typography } from "@mui/material";
import {
  ChartBuilderAddittionalDimensionSelector,
  ChartBuilderDisplayOptions,
  ChartBuilderGranularity,
  ChartBuilderSidebarBlock,
  ChartBuilderSidebarContainer,
  ChartBuilderStatCardOptions,
} from "../";
import ChartBuilderYAxis from "./ChartBuilderYAxis";
import ChartBuilderXAxis from "./ChartBuilderXAxis";
import { ChartBuilderAttributes } from "../";
import { useNotification, useSelectedProject } from "Hooks";
import ChartBuilderName from "./ChartBuilderName";
import { useCallback, useEffect, useMemo } from "react";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { ChartBuilderChartType } from "../";
import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiChartYAxisKey,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { ChartBuilderOrderLimit } from "../";
import produce from "immer";
import { enumToArray } from "Helpers/enumToText";
import {
  filterNonTrendDimensions,
  metricIsCompatible,
  metricToName,
} from "features/analytics";
import { useDebounce, usePrevious, useUpdateEffect } from "react-use";
import useArray from "Hooks/useArray";
import { useAppDndContext } from "Providers/AppDndProvider";
import { DropResult } from "react-beautiful-dnd";

function ChartBuilderSnapshot() {
  const { selectedProject } = useSelectedProject();
  const { chart, setChart } = useReportBuilderContext();
  const prevChart = usePrevious(chart);
  const { showInfoNotification } = useNotification();
  // removed metrics
  const { push, clear, array, forEachUnique } = useArray<ApiMetric>([]);
  const { setCallBacks } = useAppDndContext();

  useEffect(() => {
    setCallBacks({
      onDragEnd,
    });
  }, [chart]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result) {
        return;
      }

      if (result.draggableId.includes("xAxis")) {
        setChart(
          produce(chart, (draft) => {
            const sourceIdx =
              chart.template === ApiChartTemplate.TREND
                ? result.source.index + 1
                : result.source.index;
            const destinationIdx =
              chart.template === ApiChartTemplate.TREND
                ? (result.destination?.index || 0) + 1
                : result.destination?.index;
            const tmp = (draft.dimension || [])[sourceIdx];
            (draft.dimension || []).splice(sourceIdx, 1);
            (draft.dimension || []).splice(destinationIdx || 0, 0, tmp);
          })
        );
        return;
      }

      setChart(
        produce(chart, (draft) => {
          const y = result.destination?.droppableId.replace("yAxis-", "");
          const idx = draft.yAxisKeys.findIndex((v: any) => v.key === y);

          if (idx < 0) {
            return;
          }

          const tmp = (draft.yAxisKeys[idx] as any).fields[result.source.index];
          (draft.yAxisKeys[idx] as any).fields.splice(result.source.index, 1);
          (draft.yAxisKeys[idx] as any).fields.splice(
            result.destination?.index || 0,
            0,
            tmp
          );
        })
      );
    },
    [chart]
  );

  useEffect(() => {
    setChart((chart) =>
      produce(chart, (draft) => {
        // set 3 empty metrics for bubble, 1 empty for rest
        const idx = draft.yAxisKeys.findIndex((y) => {
          if (typeof y === "string") {
            return false;
          }
          return y.key === "l";
        });

        const y = draft.yAxisKeys[idx] as ApiChartYAxisKey;
        if (y) {
          const f = y.fields || [];
          // set target metrics length
          switch (chart.type) {
            case ApiChartType.BUBBLE:
              let fields = ["", "", ""];
              if (idx >= 0) {
                fields = [f[0] || "", f[1] || "", f[2] || ""];
                draft.yAxisKeys = [
                  {
                    key: "l",
                    fields,
                  },
                ];
              }
              break;
            case ApiChartType.TABLE:
              draft.yAxisKeys = [
                {
                  key: "l",
                  fields: draft.yAxisKeys
                    .map((v) =>
                      typeof v === "string"
                        ? v
                        : (v.fields || []).map((fv) => fv || "")
                    )
                    .flat(),
                },
              ];
              break;
            case ApiChartType.PIE:
            case ApiChartType.TREE_MAP:
            case ApiChartType.STAT_CARD:
              draft.yAxisKeys = [
                {
                  key: "l",
                  fields: [f[0] || ""],
                },
              ];
              break;
            default:
              if (idx >= 0) {
                y.fields = (y.fields || []).filter((f) => f !== "");
                if (y.fields.length === 0) {
                  y.fields = [""];
                }
              }
              break;
          }
        }

        //  set target dimensions length
        // only tables and graph can have trend dimensions, if not that type remove them
        const d = draft.dimension || [];
        const nonTrend = filterNonTrendDimensions(d as ApiDimension[]);

        switch (chart.type) {
          case ApiChartType.TREE_MAP:
            draft.dimension = [nonTrend[0] || "", nonTrend[1] || ""];
            break;
          case ApiChartType.PIE:
          case ApiChartType.RADAR:
            draft.dimension = [nonTrend[0] || ""];
            break;
          case ApiChartType.STAT_CARD:
            draft.dimension = [];
            break;
          case ApiChartType.GRAPH:
            if (chart.template === ApiChartTemplate.TREND) {
              draft.dimension = [d[0] || "", nonTrend[0] || ""];
            } else {
              draft.dimension = [nonTrend.length > 0 ? nonTrend[0] : ""];
            }
            break;

          default:
            break;
        }
      })
    );
  }, [chart.type, chart.template, setChart]);

  useUpdateEffect(() => {
    // clear removed metrics
    clear();
    setChart((chart) =>
      produce(chart, (draft) => {
        draft.yAxisKeys.forEach((_, i) => {
          const y = draft.yAxisKeys[i] as ApiChartYAxisKey;
          y.fields = (y.fields || []).filter((f) => {
            if (
              metricIsCompatible(
                f as ApiMetric,
                draft.dimension as ApiDimension[]
              )
            ) {
              return true;
            }
            // push to remove metrics
            push(f as ApiMetric);
            // remove from chart
            if (y.chart) {
              delete y.chart[f];
            }
            return false;
          });
        });
      })
    );
  }, [chart.dimension]);

  useDebounce(
    () => {
      forEachUnique((metric) => {
        showInfoNotification(
          `${metricToName(
            metric
          )} removed due to incomptability with selected dimensions`
        );
      });
    },
    300,
    [array]
  );

  const availableMetrics = useMemo(() => {
    let arr = [
      {
        title: "Traffic & pageview counts",
        metrics: [
          ApiMetric.METRIC_SESSIONS_COUNT,
          ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
          ApiMetric.METRIC_NEW_SESSION_COUNT,
          ApiMetric.METRIC_RETURNING_SESSION_COUNT,
          // ApiMetric.METRIC_SESSION_PAGEVIEW_COUNT,
          ApiMetric.METRIC_LEADS_COUNT,
          ApiMetric.METRIC_NEW_LEADS_COUNT,
          ApiMetric.METRIC_NEW_CUSTOMER_COUNT,
          ApiMetric.METRIC_RETURNING_LEADS_COUNT,
          ApiMetric.METRIC_RETURNING_LEAD_PERCENTAGE,
          ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT,
          ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE,
          ApiMetric.METRIC_PAGE_VIEWS_COUNT,
          ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT,
          ApiMetric.METRIC_LANDING_SESSION_COUNT,
          ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT,
          ApiMetric.METRIC_OUT_OF_STOCK_PAGES_COUNT,
          ApiMetric.METRIC_BOUNCED_SESSION_COUNT,
          ApiMetric.METRIC_BOUNCE_RATE,
          ApiMetric.METRIC_EXIT_PAGEVIEW_COUNT,
          ApiMetric.METRIC_EXIT_RATE,
        ],
      },
      {
        title: "Session engagement",
        metrics: [
          ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
          ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
          ApiMetric.METRIC_AVERAGE_DAYS_TO_SECOND_VISIT,
          ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
          ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD,
          ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
          ApiMetric.METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE,
          ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION,
          ApiMetric.METRIC_SESSION_REVENUE,
          ApiMetric.METRIC_PRODUCT_REVENUE,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
          ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
          ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
        ],
      },
      {
        title: "Page engagement",
        metrics: [
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
          ApiMetric.METRIC_AVERAGE_SCROLL_SPEED,
          ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT,
          ApiMetric.METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
          ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
          ApiMetric.METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK,
          ApiMetric.METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE,
        ],
      },
      {
        title: "Web Vitals",
        metrics: [
          ApiMetric.METRIC_AVERAGE_LCP_SCORE,
          ApiMetric.METRIC_AVERAGE_CLS_SCORE,
          ApiMetric.METRIC_AVERAGE_INP_SCORE,
          ApiMetric.METRIC_AVERAGE_FCP_SCORE,
          ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
        ],
      },
      {
        title: "Attribution",
        metrics: [
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV,

          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROILTV,

          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV,

          ApiMetric.METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROASLTV,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROILTV,

          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV,

          ApiMetric.METRIC_PUBLISHER_CLICKS,
          ApiMetric.METRIC_PUBLISHER_CONVERSIONS,
          ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS,
        ],
      },
      {
        title: "Ecommerce",
        metrics: [
          ApiMetric.METRIC_SALES_COUNT,
          ApiMetric.METRIC_SESSION_ALL_INCLUSIVE_COST,
          ApiMetric.METRIC_SPEND,
          ApiMetric.METRIC_CPA,
          ApiMetric.METRIC_CAC,
          ApiMetric.METRIC_ECR,
          ApiMetric.METRIC_CPNV,
          ApiMetric.METRIC_LTVCAC_RATIO,
          ApiMetric.METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE,
          ApiMetric.METRIC_SESSION_CONTRIBUTION_MARGIN,
        ],
      },
    ];

    let flatArr = arr.map((a) => a.metrics).flat();

    let remaining = enumToArray(ApiMetric)
      .filter((m) => !flatArr.includes(m))
      .filter((a) => a !== ApiMetric.METRIC_NOT_SET);

    arr.push({
      title: "To Categorize",
      metrics: remaining,
    });

    return arr.map((item) => {
      return {
        ...item,
        metrics: item.metrics.filter((m) =>
          metricIsCompatible(m, chart.dimension as ApiDimension[])
        ),
      };
    });
  }, [chart.dimension]);

  const dimensions: ApiDimension[] = useMemo(() => {
    let filtered: ApiDimension[] = [
      ApiDimension.DIMENSION_NOT_SET,
      ApiDimension.DIMENSION_PRODUCT,
      ApiDimension.DIMENSION_JOB,
      ApiDimension.DIMENSION_LOCATION_TAGGER,
    ];
    return filterNonTrendDimensions(enumToArray(ApiDimension)).filter(
      (d) => !filtered.includes(d)
    );
  }, []);

  useEffect(() => {
    if (!chart?.includeSubdomains && chart.dimension !== prevChart?.dimension) {
      setChart(
        produce(chart, (draft) => {
          draft.includeSubdomains = chart.dimension?.includes(
            ApiDimension.DIMENSION_DOMAIN
          );
        })
      );
    }
  }, [chart, setChart, prevChart]);

  const displayFeatures:
    | ("rows" | "compaison" | "total" | "attribution")[]
    | undefined = useMemo(() => {
    return chart.type === ApiChartType.GRAPH
      ? ["attribution", "compaison"]
      : chart.type === ApiChartType.PIE
      ? ["attribution", "compaison", "total"]
      : [
          ApiChartType.BUBBLE,
          ApiChartType.RADAR,
          ApiChartType.TREE_MAP,
        ].includes(chart.type!)
      ? ["attribution"]
      : undefined;
  }, [chart.type]);

  if (!selectedProject) {
    return <CircularProgress />;
  }

  return (
    <ChartBuilderSidebarContainer>
      <ChartBuilderSidebarBlock>
        <Typography variant="h3">Snapshot Chart Setup</Typography>
      </ChartBuilderSidebarBlock>
      <ChartBuilderName />
      <ChartBuilderChartType
        types={[
          ApiChartType.TABLE,
          ApiChartType.GRAPH,
          ApiChartType.PIE,
          ApiChartType.BUBBLE,
          ApiChartType.RADAR,
          ApiChartType.TREE_MAP,
          ApiChartType.STAT_CARD,
        ]}
      />
      {[ApiChartType.GRAPH, ApiChartType.TABLE].includes(
        chart.type || ApiChartType.UNDEFINED
      ) && <ChartBuilderGranularity />}

      {chart.type !== ApiChartType.STAT_CARD && (
        <>
          <ChartBuilderXAxis
            dimensions={dimensions}
            noDrag={chart.type !== ApiChartType.TABLE}
            isMultiple={chart.type === ApiChartType.TABLE}
            title={
              chart.type === ApiChartType.PIE
                ? "Pie Dimension"
                : chart.type === ApiChartType.BUBBLE
                ? "Bubble Dimension"
                : chart.type === ApiChartType.RADAR
                ? "Radar Points"
                : chart.type === ApiChartType.TREE_MAP
                ? "Treemap Dimension"
                : chart.type === ApiChartType.TABLE
                ? "Rows / Dimension"
                : undefined
            }
            subTitle={
              chart.type === ApiChartType.PIE
                ? `Select a dimension that the pie chart shall be sliced into`
                : chart.type === ApiChartType.BUBBLE
                ? "Select a dimension for the focus of the bubble chart"
                : chart.type === ApiChartType.RADAR
                ? "Select a dimension for each point of the radar chart"
                : chart.type === ApiChartType.TREE_MAP
                ? "Choose a dimension for each square on the treemap. Use the first field to select a category, such as audience, to group your results."
                : chart.type === ApiChartType.TABLE
                ? "These are the values you wish to group your rows by, e.g session date or channel name"
                : undefined
            }
            project={selectedProject}
          />

          <ChartBuilderAddittionalDimensionSelector
            dimensions={dimensions}
            chart={chart}
            setChart={setChart}
          />
        </>
      )}

      <ChartBuilderYAxis
        maxMetrics={chart.type === ApiChartType.STAT_CARD ? 2 : undefined}
        noDrag={
          ![
            ApiChartType.TABLE,
            ApiChartType.PIE,
            ApiChartType.STAT_CARD,
          ].includes(chart.type || ApiChartType.UNDEFINED)
        }
        availableMetrics={availableMetrics}
        y={"l"}
        noControls={[
          ApiChartType.PIE,
          ApiChartType.BUBBLE,
          ApiChartType.RADAR,
          ApiChartType.TREE_MAP,
          ApiChartType.TABLE,
          ApiChartType.STAT_CARD,
        ].includes(chart.type || ApiChartType.UNDEFINED)}
        noRemove={
          chart.type === ApiChartType.PIE ||
          chart.type === ApiChartType.BUBBLE ||
          chart.type === ApiChartType.TREE_MAP
        }
        title={
          chart.type === ApiChartType.PIE
            ? "Pie Metric"
            : chart.type === ApiChartType.BUBBLE
            ? "Bubble Metrics"
            : chart.type === ApiChartType.RADAR
            ? "Radar Metrics"
            : chart.type === ApiChartType.TREE_MAP
            ? "Treemap Metric"
            : chart.type === ApiChartType.TABLE
            ? "Columns / Metrics"
            : undefined
        }
        subTitle={
          chart.type === ApiChartType.PIE
            ? `Select a Metric which each section will represent`
            : chart.type === ApiChartType.BUBBLE
            ? "Select 3 metrics for bubble chart, 1st is X, 2nd is Y and 3rd is bubble size"
            : chart.type === ApiChartType.RADAR
            ? "Select a or multiple metrics that will be laid out on the radar chart"
            : chart.type === ApiChartType.TREE_MAP
            ? "Select a Metric that the size of each square will represent"
            : chart.type === ApiChartType.TABLE
            ? "These are the values you want per dimension / row"
            : chart.type === ApiChartType.STAT_CARD
            ? "Select Which metric you want to display as you primary stat"
            : undefined
        }
      />
      {chart.type !== ApiChartType.STAT_CARD && (
        <>
          {[ApiChartType.GRAPH].includes(
            chart.type || ApiChartType.UNDEFINED
          ) && (
            <ChartBuilderYAxis
              noDrag
              availableMetrics={availableMetrics}
              y={"r"}
            />
          )}

          <ChartBuilderOrderLimit
            hideOrder={chart.type === ApiChartType.TREE_MAP}
            maxLimit={chart.type === ApiChartType.RADAR ? 10 : undefined}
          />
          <ChartBuilderDisplayOptions features={displayFeatures} />
        </>
      )}
      {chart.type === ApiChartType.STAT_CARD && <ChartBuilderStatCardOptions />}
      <ChartBuilderAttributes
        attributes={chart.attributes}
        setAttributes={(attributes) =>
          setChart((chart) =>
            produce(chart, (draft) => {
              draft.attributes = attributes as ApiChartAttribute[];
            })
          )
        }
      />
    </ChartBuilderSidebarContainer>
  );
}

export default ChartBuilderSnapshot;
