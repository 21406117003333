import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Grid } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import { metricToName } from "features/analytics";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { useState } from "react";

function ContentInsights() {
  const [metric, setMetric] = useState<ApiMetric>(
    ApiMetric.METRIC_SESSIONS_COUNT
  );

  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={8} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: "Top 5 Landing Locations by",
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            limit: 5,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
            ],
            displayOptions: {
              availableMetrics: [
                ApiMetric.METRIC_SESSIONS_COUNT,
                ApiMetric.METRIC_SESSION_SALES_COUNT,
                ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
                ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
              ],
            },
          }}
          onChangeMetric={setMetric}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsCard
          chart={{
            name: `Top 15 Landing Locations by ${metricToName(metric)}`,
            dimension: [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            limit: 15,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [metric],
              },
            ],
            displayOptions: {
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: 800 }}>
        <AnalyticsCard
          chart={{
            name: "",
            dimension: [ApiDimension.DIMENSION_LANDING_PAGE_PATH],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            displayOptions: {
              rowsPerPage: 15,
            },
            attributes: [],
            orderBy: `${ApiMetric.METRIC_SESSIONS_COUNT} desc`,
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_REVENUE,
                ],
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ContentInsights;
