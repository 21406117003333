import { ApiSimpleUTM, ApiSyncType, ApiUTMMappingType } from "@incendium/api";
import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import AnimatedList from "Components/AnimatedList/AnimatedList";
import Loading from "Components/Loading/Loading";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { formatUTMSyncType } from "features/utms/utils";
import { enumToArray } from "Helpers/enumToText";
import produce from "immer";
import { CallbackOrVal } from "Interfaces";
import { Suspense, useCallback, useMemo } from "react";

interface IUtmMappingListRowProps {
  row: ApiSimpleUTM;
  onEdit: (v: ApiSimpleUTM) => void;
  rowProps?: { showProvider?: boolean };
}

const UtmMappingListRow: React.FC<IUtmMappingListRowProps> = ({
  row,
  onEdit,
  rowProps,
}) => {
  const availableProviders = useMemo(() => {
    return enumToArray(ApiSyncType);
  }, []);

  const handleChange = useCallback(
    (field: keyof ApiSimpleUTM, value: any) => {
      onEdit(
        produce(row, (draft) => {
          draft[field] = value;
        })
      );
    },
    [onEdit, row]
  );
  return (
    <Stack spacing={2}>
      {row._default ? (
        <>
          <Typography variant="subtitle2" fontStyle={"italic"}>
            Default (read only)
          </Typography>
        </>
      ) : rowProps?.showProvider ? (
        <TextField
          size="small"
          label="provider"
          value={row.syncType}
          select
          sx={{ width: "50%" }}
          onChange={(e) => handleChange("syncType", e.target.value)}
        >
          {availableProviders.map((typ) => (
            <MenuItem key={typ} value={typ}>
              {formatUTMSyncType(typ)}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <></>
      )}
      <Stack direction={"row"} spacing={1}>
        <TextField
          disabled={row._default}
          size="small"
          label="Source"
          value={row.utmSource}
          onChange={(e) => handleChange("utmSource", e.target.value)}
        />
        <TextField
          disabled={row._default}
          size="small"
          label="Medium"
          value={row.utmMedium}
          onChange={(e) => handleChange("utmMedium", e.target.value)}
        />
        <TextField
          disabled={row._default}
          size="small"
          label="Campaign"
          value={row.utmCampaign}
          onChange={(e) => handleChange("utmCampaign", e.target.value)}
        />
        <TextField
          disabled={row._default}
          size="small"
          label="Content"
          value={row.utmContent}
          onChange={(e) => handleChange("utmContent", e.target.value)}
        />
        <TextField
          disabled={row._default}
          size="small"
          label="Term"
          value={row.utmTerm}
          onChange={(e) => handleChange("utmTerm", e.target.value)}
        />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <TypographyHelp
          variant="body1"
          iconSize="small"
          placement="right"
          tooltip={
            <>
              <p>
                The Campaign ID and Group ID fields enable us to map the data to
                a specific campaign and ad group/ad set (content) for linked
                providers.
              </p>
            </>
          }
          text="Advanced Settings"
        />
        <TextField
          disabled={row._default}
          size="small"
          label="Match Type"
          value={row.utmMatchType}
          onChange={(e) => handleChange("utmMatchType", e.target.value)}
        />
        <TextField
          disabled={row._default}
          size="small"
          label="Campaign Id"
          value={row.utmCampaignId}
          onChange={(e) => handleChange("utmCampaignId", e.target.value)}
        />
        <TextField
          disabled={row._default}
          size="small"
          label="Group Id (Content)"
          value={row.utmAdgroupId}
          onChange={(e) => handleChange("utmAdgroupId", e.target.value)}
        />
      </Stack>
    </Stack>
  );
};

interface IUtmMappingListProps {
  utms: ApiSimpleUTM[];
  setUTMs: (val: CallbackOrVal<ApiSimpleUTM[]>) => void;
  syncType?: ApiSyncType;
  showProvider?: boolean;
}

function UtmMappingList({
  utms,
  setUTMs,
  syncType,
  showProvider,
}: IUtmMappingListProps) {
  const paramUtms = useMemo(() => {
    return [
      ...utms.filter(
        (utm) => utm.type === ApiUTMMappingType.UTM_MAPPING_TYPE_PARAM
      ),
    ].sort((a, b) => (b.order || 0) - (a.order || 0));
  }, [utms]);

  const filteredUtms = useMemo(() => {
    if (!syncType) {
      return paramUtms;
    }

    return paramUtms.filter((u) => u._default || u.syncType === syncType);
  }, [syncType, paramUtms]);

  const onAdd = useCallback(() => {
    setUTMs((prevUTMS) =>
      produce(prevUTMS, (draft) => {
        // push above default (always last)
        draft.splice(prevUTMS.length - 1, 0, {
          id: 0 - draft.length,
          syncType,
          type: ApiUTMMappingType.UTM_MAPPING_TYPE_PARAM,
          order: draft.length * 5,
        });
      })
    );
  }, [setUTMs, syncType]);

  const onRemove = useCallback(
    (idx: number, value: ApiSimpleUTM) => {
      setUTMs((prevUTMS) =>
        produce(prevUTMS, (draft) => {
          const index = draft.findIndex((d) => d.id === value.id);
          draft?.splice(index, 1);
        })
      );
    },
    [setUTMs]
  );
  const onEdit = useCallback(
    (idx: number) => (value: ApiSimpleUTM) => {
      setUTMs((prevUTMS) =>
        produce(prevUTMS, (draft) => {
          if (!draft) {
            draft = [];
          }
          const index = draft.findIndex((d) => d.id === value.id);
          draft[index] = value;
        })
      );
    },
    [setUTMs]
  );

  return (
    <Suspense fallback={<Loading />}>
      <AnimatedList
        onAdd={onAdd}
        onRemove={onRemove}
        onEdit={onEdit}
        items={filteredUtms || []}
        uniqueProp={"id"}
        RowComponent={UtmMappingListRow}
        rowSpacing={3}
        rowShowDivider
        rowDisableDelete={(row) => row._default!!}
        rowProps={{ showProvider }}
      />
    </Suspense>
  );
}

export default UtmMappingList;
