import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Portal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ComparisonPill from "Components/ComparisonPill/ComparisonPill";
import GlassCard from "Components/GlassCard/GlassCard";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useCallback, useMemo, useState } from "react";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  AnalyticsFilterDropdown,
  ChartLegendItem,
  useChartData,
} from "features/analytics";
import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiProjectType,
  ApiYAxisChartType,
} from "@incendium/api";
import Loading from "Components/Loading/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { avgArray, formatNumber, sumArray } from "Helpers/numbers";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import CircularProgressBar from "Components/CircularProgressBar/CircularProgressBar";
import { useCampaigns } from "Hooks/useCampaigns";
import { percentageOf } from "Helpers/percentage";
import moment from "moment";
import { friendlyDate } from "Helpers/dates";
import { useSearchParam, useUpdateEffect } from "react-use";
import Error from "Components/Error";
import { TChartData } from "Interfaces";
import { cumulativeSumArray, sortByDate } from "Helpers/arrays";
import { AnalyticsSpacing, PageActionPreSlot } from "consts";
import { getDataValueOrZeroByKey } from "Helpers/analytics";
import { useHasProjectType } from "features/project";

const VARIANT = "Variant";
const CONTROL = "Control";

function CampaignABOverviewPage() {
  const { selectedProject } = useSelectedProject();
  const hasProjectType = useHasProjectType();
  const theme = useTheme();
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);
  const { chartOutput, setLastNDays } = useFromToContext();
  const [avgVariantMacroConversions, setAvgVariantMacroConversions] =
    useState(0);
  const [avgControlMacroConversions, setAvgControlMacroConversions] =
    useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [lastFromStartChartData, setLastFromStartChartData] = useState<
    TChartData[]
  >([]);
  const [
    avgVariantImpactedMacroConversions,
    setAvgVariantImpactedMacroConversions,
  ] = useState(0);
  const [
    avgControlImpactedMacroConversions,
    setAvgControlImpactedMacroConversions,
  ] = useState(0);
  const { campaigns } = useCampaigns();
  const [loadingText, setloadingText] = useState("Fetching Campaign Data");
  const [error, setError] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const campaignParam = useSearchParam("campaign");

  const campaignChart = useMemo(
    () => ({
      name: "",
      dimension: [
        ApiDimension.DIMENSION_AB_CAMPAIGN,
        ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS,
      ],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_PAGE_VIEWS_COUNT,
            ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
            ApiMetric.METRIC_LEADS_COUNT,
            ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
            ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
            ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
            ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
            ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
            ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
            ApiMetric.METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE,
          ],
        },
      ],
      includeSubdomains: true,
      attributes: [],
    }),
    []
  );

  const { chartData: keyData, loading } = useChartData(
    selectedProject,
    campaignChart,
    chartOutput
  );

  const selectedControlChartData = useMemo(() => {
    if (!keyData) {
      return null;
    }
    return (keyData.data || []).find(
      (row) =>
        row.name === selectedCampaign &&
        row[ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS] === CONTROL
    );
  }, [keyData, selectedCampaign]);

  const selectedVariantChartData = useMemo(() => {
    if (!keyData) {
      return null;
    }
    return (keyData.data || []).find(
      (row) =>
        row.name === selectedCampaign &&
        row[ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS] === VARIANT
    );
  }, [keyData, selectedCampaign]);

  const chartAttributes = useCallback(
    (t?: string) => {
      const arr: ApiChartAttribute[] = [];
      if (selectedCampaign) {
        arr.push({
          key: ApiDimension.DIMENSION_AB_CAMPAIGN,
          value: selectedCampaign,
        });
      }
      if (t) {
        arr.push({
          key: ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS,
          value: t,
        });
      }
      return arr;
    },
    [selectedCampaign]
  );

  const keyStats = useMemo(() => {
    return [
      {
        name: "Pageviews",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_PAGE_VIEWS_COUNT
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_PAGE_VIEWS_COUNT
        ),
        isPercentage: false,
        metric: ApiMetric.METRIC_PAGE_VIEWS_COUNT,
      },
      {
        name: "Effective Pageview Rate",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE
        ),
        isPercentage: true,
        metric: ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
      },
      {
        name: "Average Time on Page",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE
        ),
        isPercentage: false,
        metric: ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
      },
      {
        name: "Average Viewed Percentage",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE
        ),
        isPercentage: true,
        metric: ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
      },
      {
        name: "Average Scrolled Percentage",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE
        ),
        isPercentage: true,
        metric: ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
      },

      {
        name: "Interactions",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT
        ),
        isPercentage: false,
        metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
      },
      {
        name: "Interactions Rate",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE
        ),
        isPercentage: true,
        metric: ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
      },
      {
        name: "Impacted Conversions",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT
        ),
        isPercentage: false,
        metric:
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
      },
      {
        name: "Impacted Conversion Rate",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE
        ),
        isPercentage: true,
        metric:
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE,
      },
      {
        name: "Session Conversions",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT
        ),
        isPercentage: false,
        metric: ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
      },
      {
        name: "Session Conversion Rate",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE
        ),
        isPercentage: true,
        metric: ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
      },
      {
        name: "Landing Page Session Conversion Rate",
        current: getDataValueOrZeroByKey(
          selectedVariantChartData,
          ApiMetric.METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE
        ),
        comparison: getDataValueOrZeroByKey(
          selectedControlChartData,
          ApiMetric.METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE
        ),
        isPercentage: true,
        metric: ApiMetric.METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE,
      },
    ];
  }, [selectedControlChartData, selectedVariantChartData]);

  const apiCampaign = useMemo(() => {
    return (campaigns || []).find((c) => c.name === selectedCampaign);
  }, [campaigns, selectedCampaign]);

  const fromStartChartDate = useMemo(
    () =>
      apiCampaign
        ? {
            from: apiCampaign?.startDate || apiCampaign?.createdAt,
            to: new Date(),
            lastNDays: undefined,
          }
        : null,
    [apiCampaign]
  );

  const estimatedEndDate = useMemo(() => {
    if (!lastFromStartChartData || !apiCampaign?.maxNumberOfLeads) {
      return null;
    }
    // get per day
    const total = sumArray(
      (lastFromStartChartData || []).map((d) =>
        Number(d[ApiMetric.METRIC_LEADS_COUNT])
      )
    );
    // work out what percentage we have been doing per day
    const numberOfDays = (lastFromStartChartData || []).length;
    const complete = percentageOf(total, apiCampaign.maxNumberOfLeads);
    const daysNeeded = 100 / (complete / numberOfDays);
    return friendlyDate(moment().add(daysNeeded, "days"));
  }, [lastFromStartChartData, apiCampaign]);

  useUpdateEffect(() => {
    if (loading || initialLoad || campaigns.length === 0) {
      return;
    }
    if (!keyData || !keyData.data || keyData.data.length === 0) {
      if ((chartOutput.lastNDays || 0) >= 365) {
        setError(true);
        return;
      }
      setloadingText(
        `No Campaign data found, trying longer date range (Last ${chartOutput.lastNDays} days)`
      );
      setLastNDays((chartOutput.lastNDays || 0) + 30);

      return;
    }
    setInitialLoad(true);

    const campaignWithDataNames = keyData.data.map((d) => d.name);
    if (
      campaignParam &&
      campaignParam !== "" &&
      campaignWithDataNames.includes(campaignParam)
    ) {
      setSelectedCampaign(campaignParam);
      return;
    }
    const sortedCampaigns = sortByDate([...campaigns], "createdAt");

    // set selected campaign based on the newist campaign with data
    for (let i = 0; i < sortedCampaigns.length; i++) {
      if (
        sortedCampaigns[i].name &&
        campaignWithDataNames.includes(sortedCampaigns[i].name!)
      ) {
        setSelectedCampaign(sortedCampaigns[i].name as string);
        break;
      }
    }
  }, [keyData, loading, initialLoad, campaigns, campaignParam]);

  const onCampaignLifespanChange = useCallback((data) => {
    setLastFromStartChartData(data);
    setTotalLeads(
      sumArray(
        data.map((d) => Number(d[ApiMetric.METRIC_NEW_CAMPAIGN_LEAD_COUNT]))
      )
    );
  }, []);

  const tableMetrics = useMemo(() => {
    const arr = [
      ApiMetric.METRIC_PAGE_VIEWS_COUNT,
      ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
      ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE,
      ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
      ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
    ];
    if (hasProjectType(ApiProjectType.TYPE_ECOMMERCE) || true) {
      arr.push(
        ...[
          ApiMetric.METRIC_SESSION_REVENUE,
          ApiMetric.METRIC_SESSION_SALES_COUNT,
          ApiMetric.METRIC_AVERAGE_SALE_VALUE,
        ]
      );
    }
    return arr;
  }, [hasProjectType]);

  return (
    <AnimatePresence mode="wait">
      {error ? (
        <Error text="No A/B Campaign test results available." />
      ) : loading || !selectedCampaign || selectedCampaign === "" ? (
        <Loading text={loadingText} />
      ) : (
        <Box
          component={motion.div}
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <Portal container={() => document.getElementById(PageActionPreSlot)}>
            <AnalyticsFilterDropdown
              dimension={ApiDimension.DIMENSION_AB_CAMPAIGN}
              defaultIndex={(options) =>
                options.findIndex((o) => o === selectedCampaign)
              }
              maxWidth={250}
              onChange={(v) => {
                if (!v) {
                  return;
                }

                setSelectedCampaign(v);
              }}
              disableBlank
            />
          </Portal>
          <Grid container spacing={AnalyticsSpacing} mb={4}>
            <Grid item xs={12} container spacing={AnalyticsSpacing}>
              <Grid item xs={3} sx={{ height: 1080 }}>
                <GlassCard
                  noShadow
                  boxProps={{
                    px: 3,
                    py: 2,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                    Key Statistics
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ lineHeight: 1.2 }}
                    color={"secondary"}
                    fontWeight={500}
                    mb={2}
                  >
                    See how variants's performed vs. control
                  </Typography>
                  <Divider />
                  <Box>
                    <Stack
                      my={2}
                      direction="row"
                      spacing={0.5}
                      justifyContent="space-between"
                    >
                      <ChartLegendItem item="Variant" index={1} />
                      <ChartLegendItem item="Control" index={3} />
                    </Stack>
                    {(keyStats || []).map((stat) => (
                      <ComparisonPill
                        key={stat.name}
                        title={stat.name}
                        value={stat.current}
                        comparisonValue={stat.comparison}
                        isPercentage={stat.isPercentage}
                        metric={stat.metric}
                      />
                    ))}
                  </Box>
                </GlassCard>
              </Grid>

              <Grid item xs={5.5} container spacing={AnalyticsSpacing}>
                <Grid item xs={6} sx={{ height: 300 }}>
                  <AnalyticsCard
                    chart={{
                      name: "Variant - Top 5 Traffic sources",
                      description: "Based on Pageviews",
                      dimension: [ApiDimension.DIMENSION_CHANNEL],
                      template: ApiChartTemplate.SNAPSHOT,
                      type: ApiChartType.PIE,
                      limit: 5,
                      attributes: chartAttributes(VARIANT),
                      includeSubdomains: true,
                      yAxisKeys: [
                        {
                          key: "l",
                          fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                        },
                      ],
                      displayOptions: {
                        noLegend: true,
                        noFilters: true,
                        showTotals: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ height: 300 }}>
                  <AnalyticsCard
                    chart={{
                      name: "Control - Top 5 Traffic sources",
                      description: "Based on Pageviews",
                      dimension: [ApiDimension.DIMENSION_CHANNEL],
                      template: ApiChartTemplate.SNAPSHOT,
                      type: ApiChartType.PIE,
                      limit: 5,
                      attributes: chartAttributes(CONTROL),
                      includeSubdomains: true,
                      yAxisKeys: [
                        {
                          key: "l",
                          fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                        },
                      ],
                      displayOptions: {
                        noLegend: true,
                        noFilters: true,
                        showTotals: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ height: 390 }}>
                  <AnalyticsCard
                    chart={{
                      name: "Variant vs. Control Pageviews",
                      dimension: [
                        ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                        ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS,
                      ],
                      orderBy: `${ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS}`,
                      template: ApiChartTemplate.TREND,
                      type: ApiChartType.GRAPH,
                      attributes: chartAttributes(),
                      includeSubdomains: true,
                      yAxisKeys: [
                        {
                          key: "l",

                          fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                        },
                      ],
                      displayOptions: {
                        noFilters: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ height: 390 }}>
                  <AnalyticsCard
                    chart={{
                      name: "Variant vs. Control Effective Pageview Rate",
                      dimension: [
                        ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                        ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS,
                      ],
                      template: ApiChartTemplate.TREND,
                      type: ApiChartType.GRAPH,
                      attributes: chartAttributes(),
                      includeSubdomains: true,
                      orderBy: `${ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS}`,
                      yAxisKeys: [
                        {
                          key: "l",
                          fields: [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE],
                        },
                      ],
                      displayOptions: {
                        noFilters: true,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3.5} container spacing={AnalyticsSpacing}>
                <Grid item xs={12} sx={{ height: 455 }}>
                  <GlassCard
                    noShadow
                    boxProps={{
                      px: 2,
                      py: 1.5,
                    }}
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Stack sx={{ height: "100%" }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          mb={2}
                          sx={{ lineHeight: 1.2 }}
                        >
                          Leads Shown the AB Test {totalLeads} /{" "}
                          {apiCampaign?.maxNumberOfLeads || "N/A"}
                        </Typography>
                        <LinearProgress
                          value={50}
                          variant="determinate"
                          color="primary"
                          sx={{
                            height: 16,
                            borderRadius: 8,
                          }}
                        />
                        {apiCampaign?.maxNumberOfLeads &&
                        totalLeads >= apiCampaign?.maxNumberOfLeads ? (
                          <Typography
                            variant="subtitle2"
                            color={"secondary"}
                            mt={1}
                          >
                            Campaign Complete
                          </Typography>
                        ) : estimatedEndDate ? (
                          <Typography
                            variant="subtitle2"
                            color={"secondary"}
                            mt={1}
                          >
                            Estimated completion date: {estimatedEndDate}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {apiCampaign && fromStartChartDate && (
                          <AnalyticsCard
                            noBorder
                            backgroundColor="transparent"
                            boxProps={{
                              paddingTop: 0,
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                            customDataFn={(data) => {
                              return cumulativeSumArray(
                                data,
                                ApiMetric.METRIC_NEW_CAMPAIGN_LEAD_COUNT
                              );
                            }}
                            overrideDate={fromStartChartDate}
                            chart={{
                              name: "",
                              dimension: [
                                ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                              ],
                              template: ApiChartTemplate.TREND,
                              type: ApiChartType.GRAPH,
                              attributes: chartAttributes(),
                              includeSubdomains: true,
                              yAxisKeys: [
                                {
                                  key: "l",
                                  fields: [
                                    ApiMetric.METRIC_NEW_CAMPAIGN_LEAD_COUNT,
                                  ],
                                  chart: {
                                    [ApiMetric.METRIC_NEW_CAMPAIGN_LEAD_COUNT]:
                                      ApiYAxisChartType.AREA,
                                  },
                                },
                              ],
                              displayOptions: {
                                noFilters: true,
                                noLegend: true,
                                noTitle: true,
                              },
                            }}
                            onDataChange={onCampaignLifespanChange}
                          />
                        )}
                      </Box>
                    </Stack>
                  </GlassCard>
                </Grid>

                <Grid item xs={12} sx={{ height: 625 }}>
                  <GlassCard
                    noShadow
                    boxProps={{
                      px: 3,
                      py: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ lineHeight: 1.2 }}
                      mb={5}
                    >
                      Variant vs. Control Conversions
                    </Typography>
                    <Grid container spacing={5}>
                      <Grid item xs={6}>
                        <StyledMiddleBox flexDirection={"column"} mb={1}>
                          <Typography variant="subtitle2">Variant</Typography>
                          <Box sx={{ height: 150, width: "100%" }}>
                            <AnalyticsCard
                              noBorder
                              noShadow
                              noToolbar
                              backgroundColor="transparent"
                              boxProps={{
                                padding: 0,
                              }}
                              chart={{
                                name: "",
                                dimension: [
                                  ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                                ],
                                template: ApiChartTemplate.TREND,
                                type: ApiChartType.GRAPH,
                                attributes: chartAttributes(VARIANT),
                                includeSubdomains: true,
                                yAxisKeys: [
                                  {
                                    key: "l",
                                    fields: [
                                      ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                                    ],
                                  },
                                ],
                                displayOptions: {
                                  noFilters: true,
                                  noLegend: true,
                                  noTitle: true,
                                  noAxis: true,
                                },
                              }}
                              onDataChange={(data) => {
                                setAvgVariantMacroConversions(
                                  avgArray(
                                    data
                                      .map((d) =>
                                        Number(
                                          d[
                                            ApiMetric
                                              .METRIC_SESSION_MACRO_CONVERSION_COUNT
                                          ]
                                        )
                                      )
                                      .filter((d) => d > 0)
                                  )
                                );
                              }}
                            />
                          </Box>
                          <Typography variant="body2" textAlign={"center"}>
                            {formatNumber(avgVariantMacroConversions)} avg.
                            daily session conversions
                          </Typography>
                        </StyledMiddleBox>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledMiddleBox flexDirection={"column"} mb={1}>
                          <Typography variant="subtitle2">Control</Typography>
                          <Box sx={{ height: 150, width: "100%" }}>
                            <AnalyticsCard
                              noBorder
                              noShadow
                              noToolbar
                              backgroundColor="transparent"
                              boxProps={{
                                padding: 0,
                              }}
                              chart={{
                                name: "",
                                dimension: [
                                  ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                                ],
                                template: ApiChartTemplate.TREND,
                                type: ApiChartType.GRAPH,
                                attributes: chartAttributes(CONTROL),
                                includeSubdomains: true,
                                yAxisKeys: [
                                  {
                                    key: "l",
                                    fields: [
                                      ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                                    ],
                                  },
                                ],
                                displayOptions: {
                                  noFilters: true,
                                  noLegend: true,
                                  noTitle: true,
                                  noAxis: true,
                                },
                              }}
                              onDataChange={(data) => {
                                setAvgControlMacroConversions(
                                  avgArray(
                                    data
                                      .map((d) =>
                                        Number(
                                          d[
                                            ApiMetric
                                              .METRIC_SESSION_MACRO_CONVERSION_COUNT
                                          ]
                                        )
                                      )
                                      .filter((d) => d > 0)
                                  )
                                );
                              }}
                            />
                          </Box>
                          <Typography variant="body2" textAlign={"center"}>
                            {formatNumber(avgControlMacroConversions)} avg.
                            daily session conversions
                          </Typography>
                        </StyledMiddleBox>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledMiddleBox
                          flexDirection={"column"}
                          alignItems="center"
                          mb={1}
                        >
                          <Typography variant="subtitle2">Variant</Typography>
                          <Box sx={{ height: 150, width: "100%" }}>
                            <AnalyticsCard
                              noBorder
                              noShadow
                              noToolbar
                              backgroundColor="transparent"
                              boxProps={{
                                padding: 0,
                              }}
                              chart={{
                                name: "",
                                dimension: [
                                  ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                                ],
                                template: ApiChartTemplate.TREND,
                                type: ApiChartType.GRAPH,
                                attributes: chartAttributes(VARIANT),
                                includeSubdomains: true,
                                yAxisKeys: [
                                  {
                                    key: "l",
                                    fields: [
                                      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                                    ],
                                  },
                                ],
                                displayOptions: {
                                  noFilters: true,
                                  noLegend: true,
                                  noTitle: true,
                                  noAxis: true,
                                  noAttributionToggle: true,
                                },
                              }}
                              onDataChange={(data) => {
                                setAvgVariantImpactedMacroConversions(
                                  avgArray(
                                    data
                                      .map((d) =>
                                        Number(
                                          d[
                                            ApiMetric
                                              .METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT
                                          ]
                                        )
                                      )
                                      .filter((d) => d > 0)
                                  )
                                );
                              }}
                            />
                          </Box>
                          <Typography variant="body2" textAlign={"center"}>
                            {formatNumber(avgVariantImpactedMacroConversions)}{" "}
                            avg. daily impacted Conversions
                          </Typography>
                        </StyledMiddleBox>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledMiddleBox flexDirection={"column"} mb={1}>
                          <Typography variant="subtitle2">Control</Typography>
                          <Box sx={{ height: 150, width: "100%" }}>
                            <AnalyticsCard
                              noBorder
                              noShadow
                              noToolbar
                              backgroundColor="transparent"
                              boxProps={{
                                padding: 0,
                              }}
                              chart={{
                                name: "",
                                dimension: [
                                  ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                                ],
                                template: ApiChartTemplate.TREND,
                                type: ApiChartType.GRAPH,
                                attributes: chartAttributes(CONTROL),
                                includeSubdomains: true,
                                yAxisKeys: [
                                  {
                                    key: "l",
                                    fields: [
                                      ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                                    ],
                                  },
                                ],
                                displayOptions: {
                                  noFilters: true,
                                  noLegend: true,
                                  noTitle: true,
                                  noAxis: true,
                                  noAttributionToggle: true,
                                },
                              }}
                              onDataChange={(data) => {
                                setAvgControlImpactedMacroConversions(
                                  avgArray(
                                    data
                                      .map((d) =>
                                        Number(
                                          d[
                                            ApiMetric
                                              .METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT
                                          ]
                                        )
                                      )
                                      .filter((d) => d > 0)
                                  )
                                );
                              }}
                            />
                          </Box>
                          <Typography variant="body2" textAlign={"center"}>
                            {formatNumber(avgControlImpactedMacroConversions)}{" "}
                            avg. daily impacted Conversions
                          </Typography>
                        </StyledMiddleBox>
                      </Grid>
                    </Grid>
                  </GlassCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ height: 320 }}>
              <GlassCard
                noShadow
                boxProps={{
                  px: 3,
                  py: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }} mb={4}>
                  Variant vs. Control Effective Pageview Rate
                </Typography>
                <Stack direction="row" justifyContent="space-around">
                  <StyledMiddleBox flexDirection={"column"}>
                    <Typography gutterBottom variant="subtitle2">
                      Variant
                    </Typography>
                    <CircularProgressBar
                      value={Number(
                        selectedVariantChartData
                          ? selectedVariantChartData[
                              ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE
                            ]
                          : 0
                      )}
                      customColour={theme.palette.charts[4]}
                    />
                    <Typography mt={1} align="center" variant="body2">
                      Effective Pageview Rate
                    </Typography>
                  </StyledMiddleBox>
                  <StyledMiddleBox flexDirection={"column"}>
                    <Typography gutterBottom variant="subtitle2">
                      Control
                    </Typography>
                    <CircularProgressBar
                      value={Number(
                        selectedControlChartData
                          ? selectedControlChartData[
                              ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE
                            ]
                          : 0
                      )}
                      customColour={theme.palette.charts[2]}
                    />
                    <Typography mt={1} align="center" variant="body2">
                      Effective Pageview Rate
                    </Typography>
                  </StyledMiddleBox>
                </Stack>
              </GlassCard>
            </Grid>
            <Grid item xs={4} sx={{ height: 320 }}>
              <GlassCard
                noShadow
                boxProps={{
                  px: 3,
                  py: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }} mb={4}>
                  Variant vs. Control Session Conversion Rate
                </Typography>
                <Stack direction="row" justifyContent="space-around">
                  <StyledMiddleBox flexDirection={"column"}>
                    <Typography gutterBottom variant="subtitle2">
                      Variant
                    </Typography>
                    <CircularProgressBar
                      value={Number(
                        selectedVariantChartData
                          ? selectedVariantChartData[
                              ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE
                            ]
                          : 0
                      )}
                      customColour={theme.palette.charts[4]}
                    />
                    <Typography mt={1} align="center" variant="body2">
                      Session
                      <br /> Conversion Rate
                    </Typography>
                  </StyledMiddleBox>
                  <StyledMiddleBox flexDirection={"column"}>
                    <Typography gutterBottom variant="subtitle2">
                      Control
                    </Typography>
                    <CircularProgressBar
                      value={Number(
                        selectedControlChartData
                          ? selectedControlChartData[
                              ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE
                            ]
                          : 0
                      )}
                      customColour={theme.palette.charts[2]}
                    />
                    <Typography mt={1} align="center" variant="body2">
                      Session
                      <br /> Conversion Rate
                    </Typography>
                  </StyledMiddleBox>
                </Stack>
              </GlassCard>
            </Grid>
            <Grid item xs={4} sx={{ height: 320 }}>
              <GlassCard
                noShadow
                boxProps={{
                  px: 3,
                  py: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }} mb={4}>
                  Variant vs. Control Impacted Conversion Rate
                </Typography>
                <Stack direction="row" justifyContent="space-around">
                  <StyledMiddleBox flexDirection={"column"}>
                    <Typography gutterBottom variant="subtitle2">
                      Variant
                    </Typography>
                    <CircularProgressBar
                      value={Number(
                        selectedVariantChartData
                          ? selectedVariantChartData[
                              ApiMetric
                                .METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE
                            ]
                          : 0
                      )}
                      customColour={theme.palette.charts[4]}
                    />
                    <Typography mt={1} align="center" variant="body2">
                      Impacted Conversion
                      <br /> Conversion Rate
                    </Typography>
                  </StyledMiddleBox>
                  <StyledMiddleBox flexDirection={"column"}>
                    <Typography gutterBottom variant="subtitle2">
                      Control
                    </Typography>
                    <CircularProgressBar
                      value={Number(
                        selectedControlChartData
                          ? selectedControlChartData[
                              ApiMetric
                                .METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE
                            ]
                          : 0
                      )}
                      customColour={theme.palette.charts[2]}
                    />
                    <Typography mt={1} align="center" variant="body2">
                      Impacted Conversion
                      <br /> Conversion Rate
                    </Typography>
                  </StyledMiddleBox>
                </Stack>
              </GlassCard>
            </Grid>

            <Grid item xs={6} sx={{ minHeight: 350 }}>
              <AnalyticsCard
                chart={{
                  name: "Variant - Device Performance",
                  dimension: [ApiDimension.DIMENSION_DEVICE],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes: chartAttributes(VARIANT),
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: tableMetrics,
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                    noAttributionToggle: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 350 }}>
              <AnalyticsCard
                chart={{
                  name: "Control - Device Performance",
                  dimension: [ApiDimension.DIMENSION_DEVICE],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes: chartAttributes(CONTROL),
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: tableMetrics,
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                    noAttributionToggle: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 470 }}>
              <AnalyticsCard
                chart={{
                  name: "Variant - Browser Performance",
                  dimension: [ApiDimension.DIMENSION_BROWSER],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes: chartAttributes(VARIANT),
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: tableMetrics,
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                    noAttributionToggle: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 470 }}>
              <AnalyticsCard
                chart={{
                  name: "Control - Browser Performance",
                  dimension: [ApiDimension.DIMENSION_BROWSER],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes: chartAttributes(CONTROL),
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: tableMetrics,
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                    noAttributionToggle: true,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </AnimatePresence>
  );
}

export default CampaignABOverviewPage;
