import { ApiChartAttribute } from "@incendium/api";
import useAppendSearchState from "Hooks/useAppendSearchState";
import { CallbackOrVal } from "Interfaces";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";

const attributeAbbreviationMap = {
  key: "k",
  operator: "o",
  value: "v",
} as const;

const reverseAttributeAbbreviationMap = Object.entries(
  attributeAbbreviationMap
).reduce(
  (acc, [full, abbrev]) => ({ ...acc, [abbrev]: full }),
  {} as Record<string, string>
);

const abbreviateAttributes = (
  attributes: ApiChartAttribute[]
): Record<string, any>[] =>
  attributes.map((attr) =>
    Object.entries(attr).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [attributeAbbreviationMap[key] || key]: value,
      }),
      {}
    )
  );

const expandAttributes = (
  abbreviatedAttributes: Record<string, any>[]
): ApiChartAttribute[] =>
  abbreviatedAttributes.map((attr) =>
    Object.entries(attr).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [reverseAttributeAbbreviationMap[key] || key]: value,
      }),
      {} as ApiChartAttribute
    )
  );

interface SelectedAttributesContextType {
  selectedAttributes: ApiChartAttribute[];
  setSelectedAttributes: (v: CallbackOrVal<ApiChartAttribute[]>) => void;
}

const SelectedAttributesContext = createContext<SelectedAttributesContextType>({
  selectedAttributes: [],
  setSelectedAttributes: () => {},
});

export const useSelectedAttributes = () =>
  useContext(SelectedAttributesContext);

const SelectedAttributesProvider = ({
  children,
  initialAttributes,
}: {
  children: ReactNode;
  initialAttributes: ApiChartAttribute[];
}) => {
  // Abbreviate initial attributes for storage in the URL
  const abbreviatedInitialAttributes = useMemo(
    () => abbreviateAttributes(initialAttributes),
    [initialAttributes]
  );

  const [abbreviatedSelectedAttributes, setAbbreviatedSelectedAttributes] =
    useAppendSearchState<Record<string, any>[]>(
      "at",
      abbreviatedInitialAttributes
    );

  // Expand attributes for use in the application
  const selectedAttributes = useMemo(
    () =>
      expandAttributes(abbreviatedSelectedAttributes as Record<string, any>[]),
    [abbreviatedSelectedAttributes]
  );

  const setSelectedAttributes = useCallback(
    (value: CallbackOrVal<ApiChartAttribute[]>) => {
      setAbbreviatedSelectedAttributes((prev) => {
        const resolvedValue =
          typeof value === "function"
            ? value(expandAttributes(prev as Record<string, any>[]))
            : value;
        return abbreviateAttributes(resolvedValue);
      });
    },
    [setAbbreviatedSelectedAttributes]
  );

  const contextValue = useMemo(
    () => ({ selectedAttributes, setSelectedAttributes }),
    [selectedAttributes, setSelectedAttributes]
  );

  return (
    <SelectedAttributesContext.Provider value={contextValue}>
      {children}
    </SelectedAttributesContext.Provider>
  );
};

export default SelectedAttributesProvider;
