import { ApiMetric } from "@incendium/api";
import { StatCardTypography } from "Components/UI/StatCard";
import { formatNumber } from "Helpers/numbers";
import { TChartData } from "Interfaces";
import AnalyticsStatCard from "features/analytics/components/AnalyticsStatCard";
import { IAnalyticsStatCardProps } from "features/analytics/types/types";
import { percentageTopChangeByMetric } from "features/analytics/utils/utils";
import { ReactNode } from "react";
import { useFromToContext } from "Providers/FromToProvider";

interface IAnalyticsIncreaseDecreaseStatCardProps
  extends Omit<IAnalyticsStatCardProps, "renderBody"> {
  metric: ApiMetric;
  renderBody?: (data: TChartData[], comp?: TChartData[]) => ReactNode;
}

function AnalyticsIncreaseDecreaseStatCard({
  chart,
  metric,
  renderBody,
  ...rest
}: IAnalyticsIncreaseDecreaseStatCardProps) {
  const { recordedIn, chartOutput } = useFromToContext();
  return (
    <AnalyticsStatCard
      recordedIn={recordedIn(chartOutput)}
      minHeight={!renderBody ? 90 : undefined}
      {...rest}
      setDirection={(data, compData) =>
        percentageTopChangeByMetric(data, compData || [], metric) > 0
          ? "up"
          : "down"
      }
      chart={chart}
      renderBody={
        renderBody
          ? renderBody
          : (data, compData) => {
              if (!compData) {
                return <>-</>;
              }

              const change = percentageTopChangeByMetric(
                data,
                compData,
                metric
              );

              return (
                <>
                  <StatCardTypography size="xs" opactity={0.8} mb={1}>
                    {change >= 0 ? "An Increase" : "A Decrease"} of
                  </StatCardTypography>
                  <StatCardTypography mb={1} size="large">
                    {formatNumber(Math.abs(change))}%
                  </StatCardTypography>
                </>
              );
            }
      }
    />
  );
}

export default AnalyticsIncreaseDecreaseStatCard;
