import { ApiSimpleProjectSubscription } from "@incendium/api";
import { listProjectSubscriptions } from "features/subscription";
import { useSharedState } from "Hooks/useSharedState";

const fetchProjectSubscriptions = async () => {
  const projectSubscriptions = await listProjectSubscriptions();
  return projectSubscriptions || [];
};
const useProjectSubscriptions = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiSimpleProjectSubscription,
    ApiSimpleProjectSubscription[]
  >("projectSubscriptions", [], fetchProjectSubscriptions);

  return {
    projectSubscriptions: state,
    setProjectSubscriptions: setState,
    loading,
    refetch,
  };
};
export default useProjectSubscriptions;
