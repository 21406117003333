import { channelService } from "Apis";
import { useSharedState } from "./useSharedState";
import {
  ApiChannel,
  ApiChartAttribute,
  ApiDimension,
  ApiOperator,
  ApiProject,
} from "@incendium/api";
import { useMemo } from "react";

const fetchChannels = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    const channels = await channelService.channelServiceListChannels({
      projectId: selectedProject.id as number,
    });
    return channels.results || [];
  }
  return [];
};
export const useChannels = () => {
  const { state, setState, loading } = useSharedState(
    "channels",
    [],
    fetchChannels
  );

  const paidChannelAttributes = useMemo(() => {
    return state
      .filter((c) => c.isPaidChannel)
      .map((pc) => ({
        key: ApiDimension.DIMENSION_CHANNEL,
        operator: ApiOperator.EQUAL,
        value: pc.name,
      })) as ApiChartAttribute[];
  }, [state]);

  return {
    channels: state as ApiChannel[],
    setChannels: setState as React.Dispatch<React.SetStateAction<ApiChannel[]>>,
    loading,
    paidChannelAttributes,
  };
};
