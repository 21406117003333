import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";
import StyledDrawer, {
  StyledDrawerContainer,
} from "Components/UI/StyledDrawer";
import { formatNumber } from "Helpers/numbers";
import { ReactNode, useCallback, useState } from "react";

interface IBaseSubscriptionPaymentCardProps {
  name: string;
  description: string;
  price: number; // in cents
  paid?: boolean;
  hidePayment?: boolean;
  qr?: string | null; // base64
}

interface IWithPaymentProps extends IBaseSubscriptionPaymentCardProps {
  hidePayment?: false;
  link: string;
  terms: string;
  action?: undefined;
}

interface IWithoutPaymentProps extends IBaseSubscriptionPaymentCardProps {
  hidePayment: true;
  link?: undefined;
  terms?: undefined;
  action: ReactNode;
}

type ISubscriptionPaymentCardProps = IWithPaymentProps | IWithoutPaymentProps;

function SubscriptionPaymentCard({
  name,
  description,
  price,
  link,
  terms,
  qr,
  paid,
  hidePayment,
  action,
}: ISubscriptionPaymentCardProps) {
  const [termsAccepeted, setTermsAccepeted] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const goToPayment = useCallback(() => {
    window.location.href = link || "";
  }, [link]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <GlassCard>
      <Typography variant="h5">Your Subscription</Typography>
      <Stack my={2}>
        <Typography variant="subtitle2" mb={1}>
          {name}
        </Typography>
        {description.split("\n").map((s, i) => (
          <Typography key={i} variant="body2">
            {s}
          </Typography>
        ))}
      </Stack>
      <IndentDivider />
      <Grid container spacing={2} mt={2}>
        <Grid item xs={8}>
          <Typography variant="subtitle2">Subscription Total</Typography>
          <Typography variant="body2">
            This will be autopaid every month
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <Stack direction={"row"} spacing={1} alignItems="baseline">
            <Typography variant="body1">(USD)</Typography>
            <Typography variant="h5">
              <small>$</small>
              {formatNumber(price / 100)}
            </Typography>
          </Stack>
        </Grid>
        {!hidePayment && (
          <>
            {paid ? (
              <Grid item xs={12}>
                <Box mt={10}>
                  <Alert severity="success">Payment Process Successfully</Alert>
                  <Typography variant="body2" mt={1}>
                    Your access will be ready in a few minutes. If you cannot
                    gain acess please submit a support ticket and we will look
                    into it.
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent={"center"}
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={5.5} textAlign="center">
                    <Button onClick={goToPayment} disabled={!termsAccepeted}>
                      Pay with stripe
                    </Button>
                  </Grid>
                  <Grid item xs={1} textAlign="center" sx={{ height: "80%" }}>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item xs={5.5} textAlign="center">
                    {qr ? (
                      <img
                        style={{ opacity: termsAccepeted ? 1 : 0.05 }}
                        width={"100%"}
                        src={qr}
                        alt="Scan QR to pay on Mobile"
                      />
                    ) : (
                      <p>Loading image...</p>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      componentsProps={{
                        typography: {
                          variant: "body1",
                        },
                      }}
                      control={
                        <Switch
                          checked={termsAccepeted}
                          onChange={(e, checked) => {
                            setTermsAccepeted(true);
                          }}
                        />
                      }
                      label="Accept"
                      sx={{
                        marginRight: 0,
                      }}
                    />
                    <Button onClick={() => setOpen(true)} variant="text">
                      terms and conditions
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="warning">
                    You shall be redirected to Stripe, on payment you shall be
                    brought back to Incendium.
                  </Alert>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      {action && action}
      <StyledDrawer open={open} onClose={onClose}>
        <StyledDrawerContainer width={900}>
          <div dangerouslySetInnerHTML={{ __html: terms || "" }} />
        </StyledDrawerContainer>
      </StyledDrawer>
    </GlassCard>
  );
}

export default SubscriptionPaymentCard;
