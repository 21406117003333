import {
  Accordion,
  AccordionDetails,
  alpha,
  Box,
  BoxProps,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { StyledAccordianSummary } from "Components/UI/AccordianGlassCard";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

export const ChartBuilderSidebarBlock = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
    borderTop: `1px solid  rgba(255, 255, 255, 0.7)`,
    "&:first-of-type": {
      borderTop: "none",
    },
    "&:not(:has(~ &))": {
      borderBottom: "none",
    },
  };
});

export const ChartBuilderSidebarContainer = styled(({ ...rest }: BoxProps) => (
  <Box {...rest} />
))(({ theme }) => ({
  overflowX: "hidden",
  overflowY: "auto",
  height: `100%`,
}));

interface IAccordianChartBuilderSidebarBlockProps
  extends Omit<BoxProps, "title"> {
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  defaultClosed?: boolean;
  onOpenClose?: (b: boolean) => void; // overides open close function
  icon?: React.ComponentType<any>;
}
export const AccordianChartBuilderSidebarBlock = ({
  title,
  subTitle,
  children,
  defaultClosed,
  onOpenClose,
  icon: Icon,
  ...boxProps
}: IAccordianChartBuilderSidebarBlockProps) => {
  const [open, setOpen] = useState(defaultClosed ? !defaultClosed : true);

  useUpdateEffect(() => {
    setOpen(!defaultClosed);
  }, [defaultClosed]);

  return (
    <ChartBuilderSidebarBlock {...boxProps}>
      <Accordion
        disableGutters
        sx={{ background: "transparent", padding: 0 }}
        elevation={0}
        expanded={open}
        onChange={() => (onOpenClose ? onOpenClose(!open) : setOpen(!open))}
      >
        <StyledAccordianSummary>
          <Stack
            sx={{ width: "100%" }}
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              {typeof title === "function" ? (
                title
              ) : (
                <Typography variant="subtitle1">{title}</Typography>
              )}
              {subTitle && typeof subTitle === "function" ? (
                subTitle
              ) : (
                <Typography variant="body2">{subTitle}</Typography>
              )}
            </Box>
            {Icon && <Icon />}
          </Stack>
        </StyledAccordianSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Box mt={1}>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </ChartBuilderSidebarBlock>
  );
};
