/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSimpleSubscriptionFromJSON, ApiSimpleSubscriptionToJSON, } from './ApiSimpleSubscription';
export function ApiSimpleContractFromJSON(json) {
    return ApiSimpleContractFromJSONTyped(json, false);
}
export function ApiSimpleContractFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'subscriptions': !exists(json, 'subscriptions') ? undefined : (json['subscriptions'].map(ApiSimpleSubscriptionFromJSON)),
    };
}
export function ApiSimpleContractToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'body': value.body,
        'subscriptions': value.subscriptions === undefined ? undefined : (value.subscriptions.map(ApiSimpleSubscriptionToJSON)),
    };
}
