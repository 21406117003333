import { ApiSimpleContract } from "@incendium/api";
import { listContracts } from "features/subscription";
import { useSharedState } from "Hooks/useSharedState";

const fetchContracts = async () => {
  const contracts = await listContracts();
  return contracts || [];
};
const useContracts = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiSimpleContract,
    ApiSimpleContract[]
  >("contracts", [], fetchContracts);

  return {
    contracts: state,
    setContracts: setState,
    loading,
    refetch,
  };
};
export default useContracts;
